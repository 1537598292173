import React, {Component} from 'react';
import AgendaTemplate from "./AgendaTemplate";
import TemplateEditor from "./TemplateEditor";
import {Rest} from "../../../../util/rest";
import {withUser} from "../../../../context/UserContext";
import {FileAddOutlined} from "@ant-design/icons";
import AdminHeader from "../AdminHeader";
import {toast} from "react-toastify";

class AgendaAdmin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            templates: [],
            editing: false,
            isLoading: true
        };
        this.addNewItem = this.addNewItem.bind(this);
        this.editTemplate = this.editTemplate.bind(this);
        this.deleteTemplate = this.deleteTemplate.bind(this);
        this.editorCloseCallBack = this.editorCloseCallBack.bind(this);
    }

    componentDidMount() {
        this.getTemplates("");
    }

    render() {
        let templates = this.state.templates;
        const editing = this.state.editing;
        return (
                <div>
                    {!editing ?
                     <div>
                         <AdminHeader
                                 empty={""}
                                 label={"Agenda Templates"}
                                 onChange={(event) => this.getTemplates(event.target.value)}
                                 onClickIcon={this.addNewItem}
                                 icon={<FileAddOutlined type="file-add" title="Add Template"/>}
                         />
                         {this.state.isLoading &&
                          <div className="text-center my-5">
                              <button className="btn btn-primary-color btn-lg rounded shadow-sm" type="button" disabled>
                                  <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"/>
                                  Loading...
                              </button>
                          </div>}
                         <div>
                             {
                                 templates.map((template) => {
                                     return <AgendaTemplate key={template.id} template={template}
                                                            onEdit={this.editTemplate}
                                                            onDelete={this.deleteTemplate}/>
                                 })
                             }
                         </div>
                     </div> :
                     <div>
                         <TemplateEditor template={this.state.template}
                                         onEditorClose={this.editorCloseCallBack}
                                         newMode={this.state.newMode}
                         />
                     </div>
                    }
                </div>

        );
    }

    addNewItem() {
        this.setState({
                          editing: true,
                          newMode: true,
                          template: {
                              id: 0,
                              name: "New Agenda Template",
                              default: false,
                              items: []
                          }
                      })
    }

    editTemplate(template) {
        this.setState({
                          editing: true,
                          newMode: false,
                          template: template
                      })
    }

    editorCloseCallBack() {
        this.setState({
                          editing: false,
                          newMode: false,
                          template: null
                      }, () => this.getTemplates(""));
    }

    getTemplates(searchTerm) {
        this.setState({isLoading: true})
        Rest.authFetch(this.props.user, `/rest/agenda/searchTemplates/${searchTerm}`).then(response => {
            if(response.status === 401 || response.status === 403) {
                toast.error("Unauthorized!")
                this.setState({isLoading: false})
            }
            else {
                this.setState({
                                  templates: response.content,
                                  isLoading: false
                              });
            }
        })
                .catch(err => {
                    console.log(err);
                })
    }

    deleteTemplate(id) {
        Rest.authFetch(this.props.user, "/rest/agenda/removeTemplate/" + id).then(response => {
            if(response.status === 401 || response.status === 403) {
                toast.error("Unauthorized!")
            }
            else {
                this.getTemplates("");
            }
        })
                .catch(err => {
                    console.log(err);
                })
    }

}

export default withUser(AgendaAdmin);