import React, {Component} from 'react';
import {flow as compose, isEqual} from 'lodash';
// import { Button, CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress'
import GenericProfile from "../../../images/profile.png";
import NumberFormat from "react-number-format";
import Achievements from "../../login/Achievements";
import toast from "../../../util/toast";
import {withUser} from '../../../context/UserContext';

class StaffProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resetPasswordLoading: false
        };

        this.updatePassword = this.updatePassword.bind(this);
    }

    async updatePassword() {
        const { history, user } = this.props;

        this.setState({ resetPasswordLoading: true });

        var myHeaders = new Headers();
        myHeaders.append("Authorization", `${user.tokenType} ${user.accessToken}`);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "email": user.email
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch('/server/rest/users/send/otp', requestOptions)
            .then(response => response.text())
            .then((response) => {
                const result = JSON.parse(response);
                if (isEqual(result.message, EMAIL_SEND_SUCCESS)) {
                    toast.success(`Email Sent ${user.email}`);
                    setTimeout(() => {
                        this.setState({ resetPasswordLoading: false });
                        history.push(`/reset-password/${result.userId}/${result.userName}`);
                    }, 2000);
                }
                else if (isEqual(result.message, EMAIL_EMPTY)) toast.error(EMAIL_EMPTY);
                else if (isEqual(result.message, NOT_VALID_EMAIL)) toast.error(NOT_VALID_EMAIL);
            })
            .catch(error => console.log('error', error));
    }

    render() {
        const profile = this.props.profile;
        const imgSrc = (profile && profile.imageType && profile.photo) ? profile.imageType + "," + profile.photo : GenericProfile;
        const fullName = profile ? profile.fullName : "Not a Staff Member";
        const practice = profile ? profile.typeOfPractice : "";
        const email = profile ? profile.email : "";
        const phone = profile && profile.phone ? profile.phone : "";
        const fax = profile && profile.fax ? profile.fax : "";
        const stars = profile && profile.stars ? profile.stars : null;
        return (
            <div className="card pcmh-profile-card">
                <div className="card-header pcmh-profile-card-header">
                    Profile
                </div>
                <div className="card-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <img src={imgSrc} alt={fullName} className="pcmh-profile-photo" />
                                <div style={{ margin: '10px 0' }}>
                                    <Button fullWidth variant="outlined" onClick={this.updatePassword}>
                                        {this.state.resetPasswordLoading
                                            ? <CircularProgress color="secondary" className="loadingSpinner" size={24} />
                                            : 'Reset Password'}
                                    </Button>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-12">
                                        {stars ? <Achievements stars={stars} /> : null}
                                    </div>
                                </div>
                                <div className="row pcmh-profile-demographics">
                                    <div className="col-md-12">
                                        {practice}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 pcmh-profile-demographics">
                                        {email}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 pcmh-profile-demographics">
                                        P:&nbsp;<NumberFormat value={phone} displayType={'text'}
                                            format="(###)###-####" />&nbsp;&nbsp;&nbsp;
                                        F:&nbsp;<NumberFormat value={fax} displayType={'text'}
                                            format="(###)###-####" />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

const EMAIL_SEND_SUCCESS = 'Email send success with an OTP';
const NOT_VALID_EMAIL = "Entered email is not valid";
const EMAIL_EMPTY = "Please enter an Email";

export default compose(
    withUser,
)(StaffProfile);
