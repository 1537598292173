import React, {useEffect, useState} from 'react'
import {withUser} from "../../../../context/UserContext";
import {TextField, Tabs, Tab, Button} from "@mui/material";
import {Rest} from "../../../../util/rest";
import {toast} from "react-toastify";
import './DataSet.css'
import {reorder} from "../../../../util/reorder";
import FiltersTab from './FiltersTab';
import HealthDataElementSelector from "./HealthDataElementSelector";
import DisplayElementsView from "./DisplayElementsView";
import FilterElementsView from "./FilterElementsView";

function DataSetColumnEditor(props) {

    const [column, setColumn] = useState();
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        setColumn(props.column)
    }, []);

    function updateColumn(){
        if(props.onUpdate)
            props.onUpdate(column);
    }
    const transferAttributeToDisplay = (attribute) => {
        if(attribute) {
            let columnAttributes = column.attributes;
            if(!columnAttributes)
                columnAttributes = [];
            columnAttributes.push({
                                      id: 0,
                                      columnId: column.id,
                                      attributeId: attribute.id,
                                      attributeName: attribute.name,
                                      attributeDataType: attribute.dataType,
                                      display: "IN_COLUMN",
                                      order: columnAttributes.length,
                                      attributeHealthElementTypeClassHealthElementClass: attribute.healthElementTypeClassHealthElementClass,
                                      attributeHealthElementTypeClassName: attribute.healthElementTypeClassName,
                                      attributeHealthElementTypeClassType: attribute.healthElementTypeClassType
                                  })
            setColumn({...column, attributes: columnAttributes})
            console.log('column attribute->',column)
            if(props.onUpdate)
                props.onUpdate(column);
        }

    };

    const transferAttributeToFilter = (attribute) => {
        if(attribute) {
            let filterGroup = {
                id:0,
                columnId: column.id,
                grouping: "AND",
                order:column.filterGroups.length + 1,
                filters :[{
                    id: 0,
                    attributeId: attribute.id,
                    attributeName: attribute.name,
                    attributeDataType: attribute.dataType,
                    filterValue1: null,
                    filterValue2: null,
                    grouping: "AND" ,
                    order: 0,
                    attributeHealthElementTypeClassHealthElementClass: attribute.healthElementTypeClassHealthElementClass,
                    attributeHealthElementTypeClassName: attribute.healthElementTypeClassName,
                    attributeHealthElementTypeClassType: attribute.healthElementTypeClassType
                }]
            }
            let filterGroups = column.filterGroups;
            if(!filterGroups)
                filterGroups = [];
            filterGroups.push(filterGroup)
            console.log('filterGroups->',filterGroups);
            setColumn({...column, filterGroups: filterGroups})
            if(props.onUpdate)
                props.onUpdate(column);
        }
    };

    const updateAllDisplayElements = (displayElements) => {
        setColumn({...column, attributes: displayElements});
    }

    const removeDisplayAttribute = (index) => {
        const attributes = column.attributes;
        attributes.splice(index, 1);
        attributes.forEach((column, index) => {column.order = index})
        setColumn({...column, attributes: attributes});
        updateColumn();
    };

    const removeFilter = (index) => {
        const filters = column.filters;
        filters.splice(index, 1);
        filters.forEach((column, index) => {column.order = index})
        setColumn({...column, filters: filters});
    };

    const updateColumnLabel = (columnTitle) => {
        if(!columnTitle)
            columnTitle = "";
        setColumn({...column, title: columnTitle})
    };

    const updateDisplayOption = (index, displayOption) => {
        const attributes = column.attributes;
        attributes[index].display = displayOption;
        setColumn({...column, attributes: attributes});
    };

    const updateFilter = (index, filter) => {
        const filters = column.filters;
        filters[index] = filter;
        setColumn({...column, filters: filters});
    };

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const displayElements = (
            <DisplayElementsView columnAttributes={(column && column.attributes) ? column.attributes : []}
                                 onUpdate={updateDisplayOption} onDelete={removeDisplayAttribute} onReorder={updateAllDisplayElements}/>
    );
    const filterElements = (
            <FilterElementsView filterGroups={(column && column.filterGroups)? column.filterGroups :[]}
            onUpdate={updateFilter} onDelete={removeFilter}/>
    )

    return (
            <div className="column-editor container-fluid">
                <div className="row">
                    <div className="col-11">
                        <h5>Column Editor</h5>
                    </div>
                    <div className="col-1">
                        <Button className="editorButton" size="small"
                                onClick={() => {props.cancelColumnEdit && props.cancelColumnEdit()}}>Exit</Button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12" style={{marginBottom: "8px"}}>
                        <TextField
                                label={"Column Label"}
                                fullWidth
                                size="small"
                                value={(column && column.title) ? column.title : ""}
                                onChange={(e) => {setColumn({...column,title:e.target.value})}}
                                onBlur={()=>updateColumn()}
                                />
                    </div>
                </div>

                <div className="row">
                    <Tabs value={activeTab} onChange={(t, newValue) => setActiveTab(newValue)}
                          style={{marginBottom: "8px"}}>
                        <Tab label="Display" {...a11yProps(0)}/>
                        <Tab label="Filters" {...a11yProps(1)}/>
                    </Tabs>

                    {activeTab === 0 &&
                     <div className="row">
                         <div className="col-12">
                             <HealthDataElementSelector ResultsWindows={displayElements} addAttribute={transferAttributeToDisplay}/>
                         </div>
                     </div>

                    }
                    {activeTab === 1 &&
                     <div className="row">
                         <div className="col-12">
                             <HealthDataElementSelector ResultsWindows={filterElements} addAttribute={transferAttributeToFilter}/>
                         </div>
                     </div>
                    }
                </div>
            </div>


    );

}

export default withUser(DataSetColumnEditor);