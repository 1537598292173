import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './toast.css'

const autoClose = 1500;
class Toast {

    // Keep the signature of the original toast object
    // Doing so you can pass additional options
    success(msg, options = {}) {
        return toast.success(msg, {
            // Merge additionals options
            ...options,
            className: 'toast-ases-success',
            autoClose: autoClose
        });
    }

    error(msg, options = {}) {
        return toast.error(msg, {
            ...options,
            className: 'toast-ases-error',
            autoClose: autoClose
        });
    }

    warning(msg, options = {}) {
        return toast.error(msg, {
            ...options,
            className: 'toast-ases-warning',
            autoClose: autoClose
        });
    }

}

// const myToaster = new Toast();

export default Toast;
