import React, {Component} from 'react';
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {Rest} from "../../../util/rest";
import toast from "../../../util/toast";
import './PatientSupport.css'
import {withUser} from "../../../context/UserContext";
import {SearchOutlined} from "@ant-design/icons";

class PatientHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patients: [],
            searching: true,
            patient: props.patient
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if(JSON.stringify(prevProps.patient) !== JSON.stringify(this.props.patient)) {
            this.setState({
                              patient: this.props.patient,
                              searching: this.props.patient === null
                          })
        }
    }

    render() {
        const patient = this.state.patient ? this.state.patient : {patient: {fullName: ""}}
        return (
                <div className="pcmh-content-header">
                    {this.state.searching ?
                     <div className="row">
                         <div className={"col-md-8 name my-auto"}>
                             Patient
                         </div>
                         <div className={"col-md-4 my-auto"}>
                             <Autocomplete
                                     id="patients"
                                     fullWidth
                                     size="small"
                                     onChange={(event, patient) => this.patientSelected(event, patient)}
                                     onInputChange={this.handleInputChange}
                                     options={this.state.patients}
                                     // onBlur={() => this.setState({searching: false})}
                                     getOptionLabel={(option) => option.fullName}
                                     renderInput={(params) =>
                                             <TextField {...params} placeholder="&#xf002; Search Patient"
                                                        className={"search-term"}
                                                        variant="outlined"
                                                        autoFocus
                                                        id="search-input"/>}/>
                         </div>
                     </div> :
                     <div className="row">
                         <div className={"col-md-11"}>
                             <span className={"name"}>{patient.fullName}</span>
                         </div>
                         <div className={"col-md-1"}>
                             <SearchOutlined title={"Search"} className={"icon"}
                                             onClick={() => this.searchStarted()}
                             />
                         </div>
                     </div>
                    }

                </div>
        );
    }

    patientSelected(e, patient) {
        this.setState({searching: false})
        // this.setState({patient: patient, searching: false})
        if(this.props.onPatientSelected)
            this.props.onPatientSelected(patient);
    }

    searchStarted() {
        this.setState({searching: true})
    }

    handleInputChange(event) {
        const searchTerm = event.target.value
        if(searchTerm.length > 0) {
            Rest.authFetch(this.props.user, "/rest/patients/searchPatient/" + searchTerm)
                    .then(response => {
                        if(response.status === 401 || response.status === 403) {
                            toast.error("Unauthorized!")
                        }
                        else {
                            this.setState({
                                              patients: response
                                          });
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })
        }
    }
}

export default withUser(PatientHeader);