import React from 'react'
import {withUser} from "../../../../context/UserContext";
import Section from "../../common/Section";
import Tab from "../../common/Tab";
import AllPatients from "./AllPatients/AllPatients";
import PatientGraph from "../../../PatientGraph/PatientGraph";

function PatientsViews(){
    
    
    return (
            <>
                <div className={"staff-support"}>
                    <Section title="Patients" className={"section"}>
                        <Tab text="Patients" content={AllPatients} active />
                        <Tab text="Lab Results" content={PatientGraph} />
                    </Section>
                </div>

            </>
    )
}
export default withUser(PatientsViews)