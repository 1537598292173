import React, {Component} from 'react';
import TextField from '@mui/material/TextField';
import NumberFormat from 'react-number-format';
import MultipleAutocompleteDialog from "../MultipleAutocompleteDialog";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {faCommentDots, faEnvelope, faMobileAlt, faVideo} from "@fortawesome/free-solid-svg-icons";
import {faFacebook} from "@fortawesome/free-brands-svg-icons";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const inputLabelProps = {
    formlabelclasses: {
        root: 'textField',
        focused: 'focused'
    }
};

class CommunicationsEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            communications: props.communications,
            personId: props.personId
        };

        this.saveCommunication = this.saveCommunication.bind(this);
        this.handleCommunicationRemove = this.handleCommunicationRemove.bind(this);
    }

    render() {
        return (
                <MultipleAutocompleteDialog
                    value={this.state.communications.map(c => {
                        let mIcon = ""
                        if (c.communicationType === "PHONE") {
                            mIcon = faMobileAlt
                        } else if (c.communicationType === "EMAIL") {
                            mIcon = faEnvelope
                        } else if (c.communicationType === "CHAT") {
                            mIcon = faCommentDots
                        }  else if (c.communicationType === "VIDEOCONFERENCE") {
                            mIcon = faVideo
                        } else if (c.communicationType === "SOCIALMEDIA") {
                            mIcon = faFacebook
                        }

                        return {
                            ...c,
                            icon: mIcon
                        }
                    })}
                    defaultEmptyValues={{personId: this.state.personId}}
                    titleName={"communicationType"}
                    onDialogSubmit={(comm) => this.saveCommunication(comm)}
                    onInputChange={(event, newValue) => console.log(newValue)}
                    onChipDelete={this.handleCommunicationRemove}
                    options={[]}
                    btnOkLabel={"Ok"}
                    chipIcon={true}
                    variant={"outlined"}
                    optionName={"Communication"}
                    optionDisplayProp={"communicationAddress"}
                    dialogContent={(dialogValue, getProps) =>
                        <div className={"row"}>
                            <div className={"col-sm-6"}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel>Communication Type</InputLabel>
                                    <Select
                                        {...getProps}
                                        name={"communicationType"}
                                        value={dialogValue.communicationType}
                                        className={"textField bg-white"}>
                                        <MenuItem  value={"PHONE"}>Phone</MenuItem>
                                        <MenuItem  value={"EMAIL"}>Email</MenuItem>
                                        <MenuItem  value={"CHAT"}>Chat</MenuItem>
                                        <MenuItem  value={"VIDEOCONFERENCE"}>Videoconference</MenuItem>
                                        <MenuItem  value={"SOCIALMEDIA"}>Social Media</MenuItem>
                                        <MenuItem  value={"WEBSITE"}>Website</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className={"col-sm-6"}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    onFocus={(event) => event.target.select()}
                                    name={"communicationSubType"}
                                    value={dialogValue.communicationSubType}
                                    label="Communication Sub Type"
                                    {...getProps}
                                    className="textField"
                                    variant="outlined"
                                />
                            </div>
                            <div className={"col-sm-6"}>
                                {dialogValue.communicationType === "PHONE" || dialogValue.communicationType === "FAX" ?
                                    <NumberFormat
                                        format="(###)###-####"
                                        customInput={TextField}
                                        margin="dense"
                                        name={"communicationAddress"}
                                        label={"Enter " + dialogValue.communicationType.toLowerCase()}
                                        type={dialogValue.communicationType === "PHONE" ? "tel" : "text"}
                                        variant="outlined"
                                        InputLabelProps={inputLabelProps}
                                        className="textField"
                                        fullWidth
                                        onFocus={(event) => event.target.select()}
                                        required
                                        onValueChange={(e) => getProps.onChange({target: {name: "communicationAddress", value: e.value}})}
                                        isNumericString
                                        value={dialogValue.communicationAddress}
                                    />
                                    :
                                    <TextField
                                        fullWidth
                                        margin="dense"
                                        label={dialogValue.communicationType ? "Enter " + dialogValue.communicationType.toLowerCase() : "Communication Address"}
                                        type={dialogValue.communicationType === "EMAIL" ? "email" : "text"}
                                        onFocus={(event) => event.target.select()}
                                        name={"communicationAddress"}
                                        value={dialogValue.communicationAddress}
                                        {...getProps}
                                        spellCheck={false}
                                        className="textField"
                                        variant="outlined"
                                        required
                                    />
                                }
                            </div>
                            <div className={"col-sm-6"}>
                                <FormControlLabel
                                    classes={{root: 'FormControlLabel'}}
                                    label={"Preferred"}
                                    labelPlacement="end"
                                    control={
                                        <Checkbox
                                            classes={{root: "Checkbox", checked: "checked"}}
                                            checked={dialogValue.preferred}
                                            name={"preferred"}
                                            {...getProps}
                                        />
                                    }
                                />
                            </div>
                        </div>
                    }
                />
        );
    }

    saveCommunication(communication) {
        if (communication.id >= 1) {
            this.setState((state) => ({
                communications: state.communications.map((c) => c.id === communication.id ? communication : c)
            }), () => this.hasChanged())
        } else {
            this.setState((state) => ({
                communications: [...state.communications, communication]
            }), () => this.hasChanged())
        }
    }

    handleCommunicationRemove(communication) {
        this.setState((state) => ({
            communications: state.communications.filter((c) => c.id !== communication.id)
        }), () => this.hasChanged())
    }

    hasChanged() {
        this.props.onChange({
            target: {
                name: this.props.name,
                value: this.state.communications
            }
        })
    }
}

export default CommunicationsEditor;