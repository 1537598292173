import React, {Component} from 'react';
import {StarOutlined} from "@ant-design/icons";
import './Staff.css';

const TOTAL_STARS = 5;

class Rater extends Component {
    
    render() {
        const rating = this.props.rating;
        let i = 0;
        let stars = [];
        for(i = 0; i < TOTAL_STARS; i++) {
            stars[i] = i < rating;
        }
        return (

                <div className="pcmh-rater rates">
                    {stars.map((value, index) => {
                        return <StarOutlined onClick={((e) => this.rate(e, index))}
                                             theme={value ? "filled" : ""} key={index} className="rate"/>
                    })}
                </div>
        );
    }

    rate(e, index) {
        if(this.props.onChange)
            this.props.onChange(index + 1);
    }
}

export default Rater;