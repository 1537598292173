import React, {useState} from 'react'
import {withUser} from "../../../../../context/UserContext";
import PatientSearch from "./PatientSearch";
import PatientResults from "./PatientResults";
import moment from "moment";

function AllPatients(props){
    
    const [date,setDate] = useState(moment())
    return (
            <>
                <PatientSearch onDateChange={(d)=>setDate(d)}/>
                <PatientResults date={date}/>
            </>
    )
}
export default withUser(AllPatients);