import React from 'react';
import {Navigate} from 'react-router-dom';
import {withUser} from "../../context/UserContext";

const AuthorizeRoute = (props) => {
    const authenticated = !!(props.user && props.user.authorities && props.user.authorities.some(r => props.allowedRoles.includes(r)) === true);
    // If authorized, return child elements
    // If not, return element that will navigate to login page
    return authenticated ? props.children : <Navigate to="/login"/>;
}

export default withUser(AuthorizeRoute);