import React, { useEffect, useState } from 'react'
import AdminHeader from "../../admin/AdminHeader";
import { FolderAddOutlined } from "@ant-design/icons";
import { Rest } from "../../../../util/rest";
import { toast } from "react-toastify";
import { withUser } from "../../../../context/UserContext";

import "../DataSet/DataSet.css";
import DataSet from "../DataSet/DataSet";
import PopulationSetEditor from "./PopulationSetEditor";

function PopulationSetAdmin(props) {

	const [populationSets, setPopulationSets] = useState([]);
	const [addNew, setAddNew] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [editingDataSet, setEditingDataSet] = useState();
	const [deleting, setDeletingDataSet] = useState();
	const [searchTerm, setSearchTerm] = useState("");

	useEffect(() => {
		searchPopulationSets().then();
	}, [])

	useEffect(() => {
		if (deleting && deleting.id > 0) {
			deletePopulationSet(deleting.id).then();
		}

	}, [deleting])

	useEffect(() => {
		searchPopulationSets().then()
	}, [searchTerm]);

	useEffect(() => {
		if (addNew && populationSets.findIndex((d) => d.id === 0) === -1) {
			const dataSet = {
				id: 0,
				name: "New Population Set",
				description: "This is a new population set",
				dataSetType:"POPULATIONSET",
				columns: [{
					id:0,dataSetId:0,title:"Population Set Dummy Column",order:0,attributes:[],filters: []
				}]
				
			};
			populationSets.push(dataSet);
			setEditingDataSet(dataSet);
			setAddNew(false);
		}

	}, [addNew])

	const cancelEdit = () => {
		setEditingDataSet(null);
		setAddNew(false);
		const newIndex = populationSets.findIndex((d) => d.id === 0)
		if (newIndex >= 0)
			populationSets.splice(newIndex, 1);
		searchPopulationSets().then();
	};

	const searchPopulationSets = async () => {
		setLoading(true);
		let st = "";
		if (searchTerm)
			st = searchTerm;

		const response = await Rest.authFetch(
			props.user,
			`/rest/dataset/search/${st}?page=${page - 1}&type=POPULATIONSET`,
			{
				method: "GET"
			}
		);
		if (response.status === 401 || response.status === 403) toast.error("Unauthorized!")
		else if (response.status === 500)
			toast.error("Error Getting Datasets")

		else {
			setPopulationSets(response.content);
			setPage(response.number + 1);
			setTotalPages(response.totalPages);
			setAddNew(false);
		}
		setLoading(false);
	};

	const deletePopulationSet = async (id) => {
		const response = await Rest.authFetch(
			props.user,
			`/rest/dataset/${id}`,
			{
				method: "DELETE"
			}
		);
		if (response.status === 401 || response.status === 403) toast.error("Unauthorized!")
		else if (response.status === 500)
			toast.error("Error Deleting Dataset")

		else {
			setDeletingDataSet(null);
		}
		searchPopulationSets().then();
	};

	const handlePageChange = (event, value) => {
		setPage(value);
	}

	return (
		<div className={"dataset-admin"}>
			{!editingDataSet &&
				<>
					<AdminHeader
						label={"Population Sets"}
						onChange={(event) => setSearchTerm(event.target.value)}
						onClickIcon={() => setAddNew(true)}
						icon={<FolderAddOutlined title="Population Data Set" />}
						onPageChange={handlePageChange}
						pageNumber={page}
						totalPages={totalPages}
					/>
					<div className={"datasets-container"}>
						{populationSets && populationSets.length > 0 && populationSets.map((dataSet => {
							return (<DataSet key={dataSet.id} dataSet={dataSet} onEdit={setEditingDataSet}
								onDelete={setDeletingDataSet} />)
						}))}
					</div>
					{isLoading &&
						<div className="text-center my-5">
							<button className="btn btn-primary-color btn-lg rounded shadow-sm" type="button" disabled>
								<span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" />
								Loading...
							</button>
						</div>
					}
				</>}
			{editingDataSet &&
				<PopulationSetEditor dataSet={editingDataSet} cancelEdit={cancelEdit}
					onFinishEditing={() => setEditingDataSet(null)} />
			}
		</div>
	);
}

export default withUser(PopulationSetAdmin);