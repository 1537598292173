import React from 'react';
import PropTypes from 'prop-types';
import {isEqual} from 'lodash';

import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from '@mui/material';

import {ResetPasswordStyle} from '../ResetPasswordStyle';


const UnauthPasswordReset = (props) => {
    const {
        activeView,
        disableEmailSubmit,
        disablePasswordSubmitBtn,
        handleClose,
        otpField,
        OTPLoading,
        passwordConfirmationHandler,
        passwordValidation,
        passwordValidationHandler,
        resendOTP,
        resetPasswordHandler,
        submitOTPHandler,
        submitPasswordLoading,
        userInfoChangeHandler,
    } = props;

    return (
        <Dialog fullWidth open onClose={handleClose}>
            <DialogTitle>Reset Password</DialogTitle>
            <ResetPasswordStyle>
                {isEqual(activeView, EMAIL_FORM) && (
                    <>
                        <DialogContent>
                            <TextField
                                autoFocus
                                fullWidth
                                margin="dense"
                                label="Email"
                                type="email"
                                onChange={(event) => userInfoChangeHandler(event, 'email')}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} ariant="outlined" color="primary">
                                Cancel
                            </Button>
                            <Button disabled={!disableEmailSubmit} onClick={resendOTP} ariant="outlined" color="primary">
                                Send OTP
                            </Button>
                        </DialogActions>
                    </>
                )}

                {isEqual(activeView, OTP_FORM) && (
                    <>
                        <DialogContent>
                            <TextField
                                fullWidth
                                margin="dense"
                                label="OTP"
                                variant="outlined"
                                className="textField"
                                type="number"
                                onChange={(event) => userInfoChangeHandler(event, 'otp')}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" color="primary" onClick={resendOTP}>
                                {OTPLoading
                                    ? <CircularProgress color="secondary" className="loadingSpinner" size={24} />
                                    : 'Resend OTP'}
                            </Button>
                            <Button
                                variant="outlined"
                                disabled={isEqual(otpField, 0)}
                                className={isEqual(otpField, 0) ? '' : 'submit-btn'}
                                onClick={submitOTPHandler}>
                                Validate
                            </Button>
                        </DialogActions>
                    </>
                )}

                {isEqual(activeView, PASSWORD_CONFIRMATION) && (
                    <>
                        <DialogContent>
                            <div className="otp-form-inner">
                                <div>
                                    <div>Password must contain the following:</div>
                                    <div className={passwordValidation.letter}>A <b>lowercase</b> letter</div>
                                    <div className={passwordValidation.capital}>A <b>capital (uppercase)</b> letter</div>
                                    <div className={passwordValidation.number}>A <b>number</b></div>
                                    <div className={passwordValidation.length}>Minimum <b>6 characters</b></div>
                                    <div className={passwordValidation.confirmPassword}>Confirm Password</div>
                                </div>

                                <TextField
                                    fullWidth
                                    className="textField"
                                    margin="dense"
                                    label="Password"
                                    type="password"
                                    variant="outlined"
                                    onChange={passwordValidationHandler}
                                />
                                <TextField
                                    fullWidth
                                    className="textField"
                                    margin="dense"
                                    label="Confirm Password"
                                    type="password"
                                    variant="outlined"
                                    onChange={passwordConfirmationHandler}
                                />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" onClick={resendOTP}>
                                {OTPLoading
                                    ? <CircularProgress color="secondary" className="loadingSpinner" size={24} />
                                    : 'Resend OTP'}
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={resetPasswordHandler}
                                disabled={disablePasswordSubmitBtn}
                            >{submitPasswordLoading
                                ? <CircularProgress color="secondary" className="loadingSpinner" size={24} />
                                : 'Submit'}
                            </Button>
                        </DialogActions>
                    </>
                )}
            </ResetPasswordStyle>
        </Dialog >
    )
}

const EMAIL_FORM = 'EMAIL_FORM';
const OTP_FORM = 'OTP_FORM';
const PASSWORD_CONFIRMATION = 'PASSWORD_CONFIRMATION';

UnauthPasswordReset.propTypes = {
    OTPLoading: PropTypes.bool,
    activeView: PropTypes.string,
    disableEmailSubmit: PropTypes.bool,
    disablePasswordSubmitBtn: PropTypes.bool,
    handleClose: PropTypes.func,
    otpField: PropTypes.number,
    passwordConfirmationHandler: PropTypes.func,
    passwordValidation: PropTypes.shape({
        letter: PropTypes.string,
        capital: PropTypes.string,
        number: PropTypes.string,
        length: PropTypes.string,
        password: PropTypes.string,
        confirmPassword: PropTypes.string,
    }),
    passwordValidationHandler: PropTypes.func,
    resendOTP: PropTypes.func,
    resetPasswordHandler: PropTypes.func,
    submitOTPHandler: PropTypes.func,
    submitPasswordLoading: PropTypes.bool,
    userInfoChangeHandler: PropTypes.func,
};

export default UnauthPasswordReset;
