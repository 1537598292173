import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
    cloneDeep,
    filter,
    find,
    includes,
    isEmpty,
    isEqual,
    isNumber,
    map,
    // maxBy,
    orderBy,
    toLower
} from 'lodash'

import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Select,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from '@mui/material';

import {
    DeleteOutlined,
    EditOutlined,
    MinusCircleOutlined,
    PlusCircleOutlined,
    RollbackOutlined
} from "@ant-design/icons";

import { Rest } from "../../../../util/rest";

import HealthElementStyle from './HealthElementStyle';


const HealthElement = (props) => {
    const { handleClose, reloadDataElements, reloadHealthElements, user } = props;
    const [showDeleteRowMessage, setShowDeleteRowMessage] = useState(null);
    const [healthElements, setHealthElements] = useState(reshapedData(props.healthElements, props.healthAttributes));
    const [editMode, setEditMode] = useState({})

    const modalActiveHandler = () => handleClose('cancel');

    const checkboxOnChangeHandler = (attributeId, checked) => {
        const healthAttributes = cloneDeep(healthElements.attributes);
        const editedAttribute = find(healthAttributes, (data) => data.id === attributeId);
        editedAttribute.required = checked;

        const newHealthAttributes = map(healthElements.attributes, (attribute) => {
            if (attribute.id === editedAttribute.id) return editedAttribute;
            else return attribute;
        });
        setHealthElements((prevState) => ({
            ...prevState,
            attributes: newHealthAttributes
        }));
    };

    const editHealthFieldsHandler = ({ target }) =>
        setHealthElements((prevState) => ({
            ...prevState,
            [target.name]: target.value
        }));

    const inputRef = React.useRef();
    const addHealthAttrOptions = () => {
        const duplicateValue = includes(
            map(editMode.options, ({ name }) => toLower(name)),
            toLower(inputRef.current.value));
        if (duplicateValue) return;

        const healthAttributes = cloneDeep(healthElements.attributes);
        // const lastOrder = maxBy(editMode.options, 'id')?.id || 0;
        const newOption = {
            id: 0,
            // id: lastOrder + 1,
            name: inputRef.current.value,
            value: inputRef.current.value,
            healthDataElementId: editMode.id
            // newOption: true,
        };

        setEditMode((prevState) => ({
            ...prevState,
            options: [newOption, ...prevState.options]
        }));

        const editedAttribute = find(healthAttributes, (data) => data.id === editMode.id);
        editedAttribute.options = [
            ...editMode.options,
            newOption
        ];

        const newHealthAttributes = map(healthAttributes, (attribute) => {
            if (attribute.id === editedAttribute.id) return editedAttribute;
            else return attribute;
        });
        setHealthElements((prevState) => ({
            ...prevState,
            attributes: newHealthAttributes
        }));

        inputRef.current.value = '';
    };

    const deleteHealthAttrOptions = (id) => {
        const newOptions = filter(editMode.options, (option) => option.id != id);

        setEditMode((prevState) => ({
            ...prevState,
            options: newOptions
        }));

        const newHealthAttributes = map(healthElements.attributes, (attribute) => {
            if (attribute.id === editMode.id) return { ...editMode, options: newOptions };
            else return attribute;
        });
        setHealthElements((prevState) => ({
            ...prevState,
            attributes: newHealthAttributes
        }));
    };

    const editHealthAttributeHandler = (fieldValue, field) => {
        const healthAttributes = cloneDeep(healthElements.attributes);
        const editedAttribute = find(healthAttributes, (data) => data.id === editMode.id);

        editedAttribute[field] = fieldValue;

        setEditMode(editedAttribute);

        const newHealthAttributes = map(healthAttributes, (attribute) => {
            if (attribute.id === editedAttribute.id) return editedAttribute;
            else return attribute;
        });
        setHealthElements((prevState) => ({
            ...prevState,
            attributes: newHealthAttributes
        }));
    };

    const deleteRowHandler = () => {
        const newHealthAttributes = filter(
            healthElements.attributes,
            (attribute) => attribute.id != showDeleteRowMessage.id
        );

        setHealthElements((prevState) => ({
            ...prevState,
            attributes: newHealthAttributes
        }));

        setShowDeleteRowMessage(null);
    };

    const saveHealthElements = async () =>
        Rest.authFetch(user, '/rest/health/element/type-class', {
            method: "PUT",
            body: JSON.stringify(healthElements)
        });

    const saveHealthAttributes = async (attribute) =>
        Rest.authFetch(user, '/rest/health/element/type-class/health-data-element', {
            method: "PUT",
            body: JSON.stringify(attribute)
        });

    const saveHealthOptions = async (attribute) =>
        Rest.authFetch(user, `/rest/health/element/type-class/health-data-element-option/${attribute.id}`, {
            method: "PUT",
            body: JSON.stringify(attribute.options)
        });

    const saveHealthAttributesHandler = async () => {
        for (const attribute of healthElements.attributes) {
            await saveHealthAttributes(attribute);
        }
    };

    const saveHealthOptionsHandler = async () => {
        for (const attribute of healthElements.attributes) {
            await saveHealthOptions(attribute);
        }
    }

    const onSubmitHandler = async () => {
        await saveHealthElements();
        await saveHealthAttributesHandler();
        await saveHealthOptionsHandler()
        reloadHealthElements();
        reloadDataElements();
        modalActiveHandler();
    };

    const orderedAttributes = orderBy(healthElements.attributes, 'order');

    return (
        <Dialog
            fullWidth
            open
            onClose={modalActiveHandler}
        >
            <HealthElementStyle>
                <DialogTitle id="title">Health Elements</DialogTitle>
                <DialogContent id="content">
                    <div id="form-fields">
                        <div>
                            <TextField
                                fullWidth
                                margin="dense"
                                label="Type"
                                name="type"
                                variant="outlined"
                                onChange={editHealthFieldsHandler}
                                value={healthElements.type}
                            />
                        </div>
                        <div>
                            <TextField
                                fullWidth
                                margin="dense"
                                label="Class"
                                name="healthElementClass"
                                variant="outlined"
                                onChange={editHealthFieldsHandler}
                                value={healthElements.healthElementClass}
                            />
                        </div>
                        <div>
                            <TextField
                                    fullWidth
                                    margin="dense"
                                    label="Name"
                                    name="name"
                                    variant="outlined"
                                    onChange={editHealthFieldsHandler}
                                    value={healthElements.name}
                            />
                        </div>
                    </div>

                    <div id="divider" />

                    <h3>Attributes</h3>
                    <div id="attributes-table">
                        {isEmpty(editMode) && !showDeleteRowMessage && (
                            <TableContainer>
                                <Table size="small">
                                    <TableHead id="table-head">
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Data Type</TableCell>
                                            <TableCell>Required</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {map(orderedAttributes, (attribute) => (
                                            <TableRow key={attribute.id}>
                                                <TableCell>{attribute.name}</TableCell>
                                                <TableCell>{attribute.dataType}</TableCell>
                                                <TableCell>
                                                    <Checkbox
                                                        checked={attribute.required}
                                                        onChange={(e, checked) => checkboxOnChangeHandler(attribute.id, checked)} />
                                                </TableCell>
                                                <TableCell>
                                                    <EditOutlined
                                                        className="ant-icon"
                                                        onClick={() => setEditMode(attribute)} />
                                                    <DeleteOutlined
                                                        className="ant-icon"
                                                        onClick={() => setShowDeleteRowMessage({
                                                            id: attribute.id,
                                                            name: attribute.name
                                                        })} />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                        {!isEmpty(editMode) && !showDeleteRowMessage && (
                            <div>
                                <Button fullWidth id="undo-btn" onClick={() => setEditMode({})}>
                                    <RollbackOutlined className="ant-icon" />
                                </Button>
                                <div>
                                    <div>
                                        <TextField
                                            fullWidth
                                            margin="dense"
                                            label="Name"
                                            variant="outlined"
                                            value={editMode.name}
                                            onChange={({ target }) => editHealthAttributeHandler(target.value, 'name')}
                                        />
                                    </div>
                                    <div>
                                        <FormControl variant="outlined" fullWidth margin="dense">
                                            <InputLabel>Data Type</InputLabel>
                                            <Select
                                                fullWidth
                                                margin="dense"
                                                label="Data Type"
                                                value={editMode.dataType}
                                                onChange={({ target }) => editHealthAttributeHandler(target.value, 'dataType')}
                                            >
                                                <MenuItem value="STRING">STRING</MenuItem>
                                                <MenuItem value="TEXT">TEXT</MenuItem>
                                                <MenuItem value="DECIMAL">DECIMAL</MenuItem>
                                                <MenuItem value="BOOLEAN">BOOLEAN</MenuItem>
                                                <MenuItem value="DATE">DATE</MenuItem>
                                                <MenuItem value="DATETIME">DATETIME</MenuItem>
                                                <MenuItem value="OPTIONS">OPTIONS</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div id="addOptionSwitch">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={editMode.canAddOption}
                                                    onChange={({ target }) => editHealthAttributeHandler(target.checked, 'canAddOption')}
                                                    name="canAddOption"
                                                />
                                            }
                                            label="Can Add Option"
                                        />
                                    </div>
                                    {isEqual(editMode.dataType, 'OPTIONS') && (
                                        <div id="select-container">
                                            <div id="freeTextSelection">
                                                <TextField
                                                    margin="dense"
                                                    label="Add Option"
                                                    variant="outlined"
                                                    inputRef={inputRef}
                                                />
                                                <IconButton className="ant-icon-button" onClick={addHealthAttrOptions}>
                                                    <PlusCircleOutlined className="ant-icon" />
                                                </IconButton>
                                            </div>
                                            <div id="list-options">
                                                <List dense>
                                                    {map(editMode.options, (row) => (
                                                        <ListItem key={row.id} dense>
                                                            <ListItemText primary={row.name} />
                                                            <IconButton className="ant-icon-button" onClick={() => deleteHealthAttrOptions(row.id)}>
                                                                <MinusCircleOutlined className="ant-icon" />
                                                            </IconButton>
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </div>
                                        </div>
                                    )}

                                    {editMode.dataType === RANGE && (
                                        <div id="range-container">
                                            <TextField
                                                disabled
                                                margin="dense"
                                                label="Lower Range"
                                                variant="outlined"
                                                value={isNumber(editMode.options[0]) ? editMode.options[0] : ''}
                                            />
                                            <TextField
                                                disabled
                                                margin="dense"
                                                label="Upper Range"
                                                variant="outlined"
                                                value={isNumber(editMode.options[1]) ? editMode.options[1] : ''}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        {showDeleteRowMessage && (
                            <div id="delete-row-div">
                                <div>Are you sure you want to delete <b>{showDeleteRowMessage.name}</b></div>
                                <div id="delete-row-action-buttons">
                                    <div id="divider" />
                                    <Button variant="contained" onClick={() => setShowDeleteRowMessage(null)}>
                                        Cancel
                                    </Button>
                                    <Button id="delete-row-btn" variant="contained" onClick={deleteRowHandler}>
                                        Delete
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                </DialogContent>
                <DialogActions id="action-buttons">
                    <Button variant="contained" onClick={modalActiveHandler}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={onSubmitHandler}>
                        Save
                    </Button>
                </DialogActions>
            </HealthElementStyle>
        </Dialog >
    )
};

const reshapedData = (healthData, healthAttributes) => ({
    ...healthData,
    attributes: healthAttributes
});

const RANGE = 'range';

HealthElement.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        class: PropTypes.string.isRequired,
        attributes: PropTypes.arrayOf(
            PropTypes.shape({
                healthElementId: PropTypes.number.isRequired,
                id: PropTypes.number.isRequired,
                order: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                type: PropTypes.string.isRequired,
                dataType: PropTypes.string.isRequired,
                required: PropTypes.bool.isRequired,
                options: PropTypes.oneOfType([
                    PropTypes.arrayOf(
                        PropTypes.shape({
                            id: PropTypes.number.isRequired,
                            name: PropTypes.string.isRequired,
                            value: PropTypes.string.isRequired,
                        })
                    ),
                    PropTypes.arrayOf(PropTypes.number.isRequired),
                ])
            })
        ),
    }),
    handleClose: PropTypes.func.isRequired,
    reloadDataElements: PropTypes.func.isRequired,
    reloadHealthElements: PropTypes.func.isRequired,
    user: PropTypes.shape({
        accessToken: PropTypes.string,
        authorities: PropTypes.arrayOf(PropTypes.string),
        email: PropTypes.string,
        expiration: PropTypes.string,
        name: PropTypes.string,
        staffMemberId: PropTypes.number,
        tokenType: PropTypes.string,
        username: PropTypes.string,
    }).isRequired,
}

export default HealthElement;
