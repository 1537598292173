import React, {Component} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import "../../../SmartPcmhClientApp.css";
import 'react-tabs/style/react-tabs.css';
import './Huddle.css';
import {Rest} from "../../../util/rest";
import toast from "../../../util/toast";
import HuddleHeader from "./HuddleHeader";
import DateDiff from "date-diff";
import AgendaItems from "./agenda/AgendaItems";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import CreateAgenda from "./agenda/CreateAgenda";
import AgendaNotesIssues from "./agenda/AgendaNotesIssues";
import {withUser} from "../../../context/UserContext";
import CallList from "./nextday/CallList";
import moment from "moment";
import PatientData from "./today/PatientData";

const MAX_DAYS = 365;

class Huddle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            agenda: null,
            staffMember: null,
            today: new Date(),
            selectedDate: new Date(),
            issues: null
        };
        this.onPreviousDate = this.onPreviousDate.bind(this);
        this.onNextDate = this.onNextDate.bind(this);
        this.agendaCreated = this.agendaCreated.bind(this);
        this.getIssues = this.getIssues.bind(this);
        this.getAgenda = this.getAgenda.bind(this);
        this.updateAgendaItems = this.updateAgendaItems.bind(this);
    }

    componentDidMount() {
        this.getAgenda(new Date());
        this.loadStaffMember();
        this.getIssues();
    }

    render() {
        const staffMember = this.state.staffMember;
        const team = staffMember && staffMember.teams && staffMember.teams.length > 0 ? staffMember.teams[0] : {id: 0};
        const selectedDate = this.state.selectedDate;
        const agenda = this.state.agenda;
        const issues = this.state.issues;
        return (
                <div className="pcmh-huddle">
                    <HuddleHeader date={selectedDate} onBefore={this.onPreviousDate}
                                  onAfter={this.onNextDate} agenda={agenda} team={team}/>
                    {agenda ?
                     <Tabs className="tabs">
                         <TabList className="agendaTabList">
                             <Tab selected className="un-selected-tab unSelectable"
                                  selectedClassName="selected-tab">Agenda</Tab>
                             <Tab className="un-selected-tab unSelectable" selectedClassName="selected-tab">Today's
                                 Patient List</Tab>
                             <Tab className="un-selected-tab unSelectable" selectedClassName="selected-tab">Patient Call
                                 List</Tab>
                         </TabList>
                         <TabPanel className="notesTabPanel" selectedClassName="selected-panel">
                             <div className="tab-container">
                                 <div className="container-fluid">
                                     <div className="row">
                                         <div className="col-md-8">
                                             <AgendaItems agenda={agenda} onUpdate={this.updateAgendaItems}/>
                                         </div>
                                         <div className="col-md-4">
                                             <AgendaNotesIssues agenda={agenda} staffMember={staffMember}
                                                                notesUpdated={this.getAgenda} issues={issues}
                                                                issuesUpdated={this.getIssues} team={team}/>
                                         </div>
                                     </div>
                                 </div>
                             </div>
                         </TabPanel>
                         <TabPanel className="notesTabPanel" selectedClassName="selected-panel">
                             <div><PatientData listDate={agenda.meetingDate}/></div>
                         </TabPanel>
                         <TabPanel className="notesTabPanel" selectedClassName="selected-panel">
                             <CallList teamId={team.id}
                                       callDate={agenda.meetingDate}
                                       staffMember={staffMember}/>
                         </TabPanel>
                     </Tabs> :
                     <div className="row">
                         <div className="col-md-4 offset-4">
                             <CreateAgenda date={selectedDate} onAgendaCreated={this.agendaCreated} team={team}/>
                         </div>
                     </div>

                    }
                </div>
        )
    }

    updateAgendaItems(items){
        if(this.state.agenda){
            this.setState((state) => ({
                agenda: {
                    ...state.agenda,
                    items: items
                }
            }))
        }
    }
    loadStaffMember() {
        if(this.props.user && this.props.user.staffMemberId) {
            Rest.authFetch(this.props.user, "/rest/staff/" + this.props.user.staffMemberId).then(response => {
                if(response.status === 401 || response.status === 403) {
                    this.setState({files: []});
                    toast.error("Unauthorized!")
                }
                else {
                    this.setState({staffMember: response});
                }
            })
                    .catch(err => {
                        console.log(err);
                    });
        }
    }

    onPreviousDate() {
        let previousDate = new Date(this.state.selectedDate.getTime());
        previousDate.setDate(previousDate.getDate() - 1);
        let diff = new DateDiff(this.state.today, previousDate);
        if(diff.days() <= MAX_DAYS) {
            this.setState({
                              selectedDate: previousDate
                          });
            this.getAgenda(previousDate);
            this.getIssues();
        }

    }

    onNextDate() {
        let nextDate = new Date(this.state.selectedDate.getTime());
        nextDate.setDate(nextDate.getDate() + 1);
        let diff = new DateDiff(nextDate, this.state.today);
        if(diff.days() <= MAX_DAYS) {
            this.setState({
                              selectedDate: nextDate
                          });
            this.getAgenda(nextDate);
            this.getIssues();
        }
    }

    getAgenda(date) {
        if(date && !(date instanceof Date))
            date = new Date(date);

        let path = "/rest/agenda/" + moment(date).format("yyyyMMDD");
        Rest.authFetch(this.props.user, path).then((response) => {
            if(response) {
                const agenda = Huddle.convertAgendaDates(response);
                this.setState({agenda: agenda});
            }
            else {
                this.setState({agenda: null})
            }
        })
                .catch(err => {
                    console.log(err);
                })
    }

    getIssues() {
        if(this.state.staffMember && this.state.staffMember.teams && this.state.staffMember.teams.length > 0) {
            let path = "/rest/huddle/issues/" + this.state.staffMember.teams[0].id;
            Rest.authFetch(this.props.user, path).then(response => {
                let issues = [];
                response.map(issue => {
                    issues.push(Huddle.convertIssueDates(issue));
                    return null;
                });
                this.setState({issues: issues});
            })
                    .catch(err => {
                        if(err === 204) {
                            this.setState({agenda: null})
                        }
                        else
                            console.log(err);
                    });

        }
    }


    agendaCreated(agenda) {
        this.setState({agenda: agenda})
    }

    static convertIssueDates(issue) {
        if(issue) {
            if(issue.createdDate)
                issue.createdDate = this.convertToDate(issue.createdDate);
            if(issue.closedDate)
                issue.closedDate = this.convertToDate(issue.closedDate);
        }
        return issue;
    }

    static convertAgendaDates(agenda) {
        if(agenda) {
            if(agenda.meetingDate) {
                agenda.meetingDate = this.convertToDate(agenda.meetingDate);
            }
            if(agenda.notes && agenda.notes.length > 0) {
                for(let note of agenda.notes) {
                    note.dateEntered = this.convertToDate(note.dateEntered)
                }
            }
        }
        return agenda;
    }

    static convertToDate(date) {
        if(date && !(date instanceof Date)) {
            return new Date(date);
        }
        else if(date instanceof Date)
            return date;
        else
            return null;
    }
}

export default withUser(Huddle);