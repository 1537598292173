import React, {Component} from 'react';
import Popper from "@mui/material/Popper";
import './Message.css'
import {ClickAwayListener} from "@mui/material";

const options = {
    year: 'numeric', month: 'numeric', day: 'numeric',
    hour: 'numeric', minute: 'numeric', second: 'numeric',
    hour12: true,
    timeZone: 'America/Puerto_Rico'
};

class Message extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: props.open,
            message: props.message,
            anchorEl: this.props.anchorEl
        };
        this.closeMe = this.closeMe.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.open !== this.props.open)
            this.setState({open: this.props.open})
        if(prevProps.message !== this.props.message)
            this.setState({message: this.props.message})
        if(prevProps.anchorEl !== this.props.anchorEl)
            this.setState({anchorEl: this.props.anchorEl})
    }

    render() {
        const message = this.state.message;
        const announcement = message ? message.announcement : null;
        const anchorEl = this.state.anchorEl;
        const open = this.state.open;
        const messageText = announcement ? announcement.message : "";
        const sender = announcement ? announcement.sender.fullName : "";
        const subject = announcement ? announcement.subject : "";
        const sentDate = announcement ? Intl.DateTimeFormat('en-US', options).format(new Date(announcement.sentDateTime)) : "";

        return (
                <Popper
                        anchorOrigin={{
                            vertical: 'middle',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={open}
                        anchorEl={anchorEl}
                        disablePortal
                        style={{zIndex: 1800}}>
                    <ClickAwayListener onClickAway={this.closeMe}>
                        <div className={"message"} onClick={this.closeMe}>
                            <div className={"row header"}>
                                <div className={"col-md-12"}>
                                    <div className={"row"}>
                                        <div className={"col-md-12 sender"}>{sender}</div>
                                    </div>
                                    <div className={"row"}>
                                        <div className={"col-md-12 subject"}>{subject}</div>
                                    </div>
                                    <div className={"row"}>
                                        <div className={"col-md-12 date"}>{sentDate}</div>
                                    </div>
                                </div>
                            </div>
                            <div className={"row message-body"}>
                                {messageText}
                            </div>
                        </div>
                    </ClickAwayListener>
                </Popper>
        );
    }

    closeMe(e) {
        e.preventDefault();
        e.stopPropagation();
        if(this.props.onClose)
            this.props.onClose();
    }
}

export default Message;