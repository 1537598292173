import React, {Component} from 'react';
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {withUser} from "../../../../context/UserContext";
import {Rest} from "../../../../util/rest";
import toast from "../../../../util/toast";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import "./PersonAssignDialog.css";
import moment from "moment";

class PersonAssignDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assignment: {
                id: 0,
                personId: props.person ? props.person.id : null,
                assignmentType: this.props.assignmentType,
                assignable: { id: 0 },
                note: "",
                dateDue: moment(new Date()).add(1, 'months').format('YYYY-MM-DD')
            },
            options: []
        };

        this.save = this.save.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getOptions = this.getOptions.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.prepareUrlForOptions = this.prepareUrlForOptions.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if(prevProps.assignmentType !== this.props.assignmentType) {
            this.setState((state) => ({
                assignment: {
                    ...state.assignment,
                    assignmentType: this.props.assignmentType
                }
            }))
            this.prepareUrlForOptions(this.props.assignmentType)
        }
        if(prevProps.person !== this.props.person) {
            this.setState((state) => ({
                assignment: {
                    ...state.assignment,
                    personId: this.props.person.id
                }
            }))
        }
    }

    render() {
        const fullName = this.props.person ? this.props.person.fullName : "";

        return (
                <Dialog
                        className={"person-assign-dialog"}
                        open={this.props.open}
                        onClose={this.handleClose}
                        fullWidth={true} maxWidth={"sm"}
                        aria-labelledby="sendMessage-dialog-title">

                    <DialogTitle classes={{root: "editorHeader"}} id={"sendMessage-dialog-title"}>
                        <span className="unSelectable">Assignment</span>
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                                fullWidth
                                margin="dense"
                                value={fullName}
                                label={this.props.personType}
                                className="textField textBox"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                    readOnly: true
                                }}
                                spellCheck={false}
                        />

                        <FormControl variant="outlined" className={"my-3"} fullWidth>
                            <InputLabel>Assignment Type</InputLabel>
                            <Select
                                    name={"assignmentType"}
                                    className={"w-100 textField"}
                                    onChange={this.handleChange}
                                    label="Assignment Type"
                                    value={this.state.assignment.assignmentType}
                            >
                                <MenuItem value="assessment">Assessment</MenuItem>
                                <MenuItem value="curriculum">Curriculum</MenuItem>
                                <MenuItem value="external">LMS Course</MenuItem>
                                <MenuItem value="workflow">Workflow</MenuItem>
                                <MenuItem value="other/group">Other/Group</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl variant="outlined" className={"my-3"} fullWidth>
                            <InputLabel>{`Choose the ${this.state.assignment.assignmentType}`}</InputLabel>
                            <Select
                                    name={"assignable"}
                                    className={"w-100 textField"}
                                    onChange={this.handleChange}
                                    value={this.state.assignment.assignable.name}
                                    label={`Choose the ${this.state.assignment.assignmentType}`}
                            >
                                <MenuItem value="0" disabled><span
                                        className={"disabled-option"}>- Choose -</span></MenuItem>
                                {this.state.options.length < 1 ?
                                 <MenuItem value="" disabled>No data available</MenuItem>
                                                               :
                                 this.state.options.map((option) => <MenuItem key={option.id}
                                                                              value={option}>{option.name}</MenuItem>)
                                }
                            </Select>
                        </FormControl>

                        <div className={"row"}>
                            <div className={"col-sm-5"}>
                                <TextField
                                        fullWidth
                                        margin="dense"
                                        name="dateDue"
                                        value={this.state.assignment.dateDue}
                                        onChange={this.handleChange}
                                        label="Due Date"
                                        type="date"
                                        className="textField textBox"
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{inputProps: {min: new Date().toISOString().split("T")[0]}}}
                                />
                            </div>

                            <div className={"col-sm-7"}>
                                <TextField
                                        fullWidth
                                        margin="dense"
                                        name="note"
                                        multiline
                                        rows={4}
                                        value={this.state.assignment.note}
                                        onChange={this.handleChange}
                                        label="Note"
                                        className="textField textBox"
                                        variant="outlined"
                                        spellCheck={false}
                                />
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} className="editorButton" variant="contained">
                            Cancel
                        </Button>
                        <Button
                                onClick={() => this.save(false)}
                                disabled={this.state.assignment.personId < 1 ||
                                          (this.state.assignment.assignable.id < 1 && this.state.assignment.assignmentType !== "external") ||
                                          // (this.state.assignment.assignmentType==="external" && this.state.assignable.lmsId < 1 )|| 
                                          this.state.assignment.dateDue.trim() === ""}
                                className="editorButton"
                                variant="contained"
                        >
                            Assign
                        </Button>
                        {this.state.assignment.assignmentType === "assessment" &&
                         <Button
                                 onClick={() => this.save(true)}
                                 disabled={this.state.assignment.personId < 1 || this.state.assignment.assignable.id < 1 || this.state.assignment.dateDue.trim() === ""}
                                 className="editorButton"
                                 variant="contained"
                         >
                             Start
                         </Button>
                        }

                    </DialogActions>
                </Dialog>
        );
    }

    handleClose() {
        this.props.onClose()
        this.setState({
            assignment: {
                id: 0,
                personId: this.props.person ? this.props.person.id : null,
                assignmentType: this.props.assignmentType,
                assignable: { id: 0 },
                note: "",
                dateDue: moment(new Date()).format('YYYY-MM-DD')
            }
        })
    }

    handleChange(event) {
        const {name, value} = event.target

        this.setState((state) => ({
            assignment: {
                ...state.assignment,
                [name]: value
            }
        }), () => {
            if(name === "assignmentType") {
                this.prepareUrlForOptions(value)
            }
        })
    }

    prepareUrlForOptions(value) {
        if(value === "assessment") {
            this.getOptions("/rest/assessment/templates?filters=PUBLISHED");
        }
        else if(value === "curriculum") {
            this.getOptions("/rest/curriculums");
        }
        else if(value === "external") {
            this.getOptions("/rest/lms-courses");
        }
        else {
            this.setState((state) => ({
                options: [],
                assignment: {
                    ...state.assignment,
                    assignable: {id: 0}
                }
            }));
        }
    }

    getOptions(url) {
        Rest.authFetch(this.props.user, url)
                .then(response => {
                    if(response.status === 401 || response.status === 403) {
                        toast.error("Unauthorized!")
                    }
                    else {
                        this.setState((state) => ({
                            options: response,
                            assignment: {
                                ...state.assignment,
                                assignable: { id: 0 }
                            }
                        }));
                    }
                })
                .catch(err => {
                    console.log(err);
                })
    }

    save(complete = false) {
        let children = []
        if (this.state.assignment.assignable.type === "CURRICULUM") {
            const curriculumSelected = this.state.options.find((o) => o.id === this.state.assignment.assignable.id)
            children = curriculumSelected.trainings.map(t => ({
                id: 0,
                personId: this.props.person.id,
                assignable: t,
                dateDue: this.state.assignment.dateDue,
                parentId: 0
            }))
        }

        const payload = {
            ...this.state.assignment,
            children: children
        }
        console.log(payload);
        Rest.authFetch(this.props.user, `/rest/assignments/${payload.assignable.type.toLowerCase()}/${payload.id}`, {
            method: "PUT",
            body: JSON.stringify(payload)
        })
                .then(response => {
                    if(response) {
                        toast.success("Assignment saved");
                        if(complete)
                            this.props.history.push({
                                                        pathname: `/${response.assignable.type}/${response.id}`,
                                                        state: {from: this.props.location}
                                                    });
                        this.handleClose();
                    }
                    else {
                        toast.error("Error saving Assignment");
                    }
                });
    }
}


export default withUser(PersonAssignDialog);