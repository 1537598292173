import React, {useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {DeleteOutlined, PlusCircleOutlined} from "@ant-design/icons"; //TODO: change Icon Logic to new antd V4+
import "./AssessmentAnswersTable.css"
import Checkbox from "@mui/material/Checkbox";

const columns = [
    {name: 'answer', type: 'text', label: 'Answer', style: {cursor: "default", width: "63%"}},
    {name: 'score', type: 'number', label: 'Score', style: {cursor: "default", width: "16%"}},
    {name: 'hasFreeText', type: 'checkbox', label: 'FreeText', style: {cursor: "pointer", width: "5%"}},
    {name: 'remove', type: 'delete', label: '', align: 'right', style: {cursor: "pointer", width: "16%"}}
]

const useStyles = makeStyles({
                                 root: {
                                     width: '100%',
                                 },
                                 container: {
                                     maxHeight: 200,
                                 },
                                 cellHeader: {
                                     height: 6,
                                     paddingTop: 0,
                                     paddingBottom: 0,
                                 },
                                 colStyle: {
                                     paddingBottom: 4,
                                     paddingTop: 4
                                 }
                             });

export default function AssessmentAnswersTable(props) {
    const [rows, setRows] = useState(props.answers)

    useEffect(() => {
        setRows(props.answers)
    }, [props])

    const classes = useStyles();

    function handleChange(event, index) {
        let {name, value, type, checked} = event.target
        const newRows = [...rows]

        if(type === "number")
            value = parseInt(value)
        else if (type === "checkbox")
            value = checked

        newRows[index][name] = value
        setRows(newRows)
    }

    function addAnswer() {
        const newRows = rows
        newRows.push({
            id: 0,
            questionId: props.questionId,
            answer: "",
            score: 0,
            order: rows.length + 1
        })

        setRows([...rows, newRows])
        props.onUpdateAnswers(newRows)
    }

    function deleteAnswer(answerId) {
        const newRows = rows.filter((a) => a.id !== answerId)
        setRows(newRows)
        props.onUpdateAnswers(newRows)
    }

    function handleMouseLeave() {
        if (rows !== props.answers) {
            props.onUpdateAnswers(rows)
        }
    }

    return (
        <div onMouseLeave={handleMouseLeave}>
            <Paper className={classes.root}>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow style={{height: 10}}>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.name}
                                        align={column.align}
                                        style={column.style}
                                        className={classes.cellHeader}
                                    >
                                        {column.name === "remove" && !props.readOnly ?
                                         <PlusCircleOutlined title="Add Answer" className="icon"
                                                             onClick={addAnswer}/> : column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, index) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id} style={{marginBottom: 6}}
                                              className={"my-5 shadow-sm border"}>
                                        {columns.map((column) => {
                                            const value = row[column.name];
                                            return (
                                                <TableCell key={column.name}
                                                           className={classes.colStyle}
                                                           style={column.style}
                                                           align={column.align}
                                                >
                                                    {column.name === "remove" ?
                                                     !props.readOnly && <DeleteOutlined title="Delete Answer"
                                                                                        onClick={() => deleteAnswer(row.id)}/> :
                                                     <>
                                                            {column.type === "checkbox" ?
                                                                <Checkbox
                                                                        classes={{ root: 'Checkbox', checked: "checked" }}
                                                                        id={column.name + row.id}
                                                                        name={column.name}
                                                                        style={{width: "100%"}}
                                                                        size={"small"}
                                                                        checked={value}
                                                                        onChange={(e) => handleChange(e, index)}
                                                                        disabled={props.readOnly}
                                                                />
                                                                :
                                                                <input
                                                                     id={column.name + row.id}
                                                                     name={column.name}
                                                                     type={column.type}
                                                                     style={{width: "100%", border: "none", outline: "none"}}
                                                                     value={value}
                                                                     onChange={(e) => handleChange(e, index)}
                                                                     spellCheck={false}
                                                                     readOnly={props.readOnly}
                                                                />
                                                            }
                                                        </>
                                                    }
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
}