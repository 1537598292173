import React, {useEffect, useState} from 'react'
import {withUser} from "../../../../context/UserContext";
import '../DataSet/DataSet.css'
import {Autocomplete, Button, List, ListItemButton, ListItemText, Tab, Tabs, TextField} from "@mui/material";
import {map} from "lodash";
import {RightCircleOutlined} from "@ant-design/icons";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import AttributeDisplay from "../DataSet/AttributeDisplay";
import AttributeFilter from "../DataSet/AttributeFilter";



function FilterEditor(props) {
    
    const [filter,setFilter] = useState(null);


    useEffect(() => {
        
    }, []);
    
    
    return(
            <div className="column-editor container-fluid">
                <div className="row">
                    <div className="col-6">
                        <h5>Filter Editor</h5>
                    </div>
                    <div className="offset-3 col-3">
                        <Button className="editorButton"
                                onClick={() => {props.onUpdate && props.onUpdate(column)}}
                                size="small"
                                disabled={column === undefined || column.title === "New Column" || column.attributes.length === 0}>
                            Save Filter
                        </Button>
                        <Button className="editorButton" size="small"
                                onClick={() => {props.cancelColumnEdit && props.cancelColumnEdit()}}>Cancel</Button>
                    </div>
                </div>
                
                <div className="row">
                    <Tabs value={activeTab} onChange={(t, newValue) => setActiveTab(newValue)}
                          style={{marginBottom: "8px"}}>
                        {setOfData === 'Data Set' ? <Tab label="Display" {...a11yProps(0)}/> : []}
                        <Tab label="Filters" {...a11yProps(1)}/>
                    </Tabs>
                    <div className="row">
                        <div className="col-12">
                            <Autocomplete
                                    fullWidth
                                    size="small"
                                    freeSolo
                                    onChange={healthElementHandler}
                                    filterOptions={(x) => x}
                                    options={map(healthElements, (healthElement) =>
                                            ({
                                                value: healthElement,
                                                label: `${healthElement.type}:${healthElement.healthElementClass}:${healthElement.name}`,
                                            }))}
                                    renderInput={(params) => <TextField {...params} label="Search Health Data"
                                                                        onChange={(e) => searchDataElements(e.target.value)}/>}/>


                        </div>
                    </div>
                    {activeTab === 0 &&
                     <div className="col-12 outer-box">
                         <div className="row">
                             {setOfData === 'Data Set' && (
                                     <div className="col-md-5 col-xl-3">
                                         Available Attributes
                                     </div>
                             )}
                             <div className="col-auto">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                             <div className="col-md-6 col-xl-8">
                                 Attributes Display
                             </div>
                         </div>
                         <div className="row inner-box">
                             {setOfData === 'Data Set' && (
                                     <>
                                         <div className="col-md-5 col-xl-3" style={{overflowY: "scroll", height: "100%"}}>
                                             <List dense>
                                                 {availableAttributes && availableAttributes.length > 0 && availableAttributes.map((attribute, index) => (
                                                         <ListItemButton selected={index + 1 === selectedAttribute}
                                                                         onClick={() => setSelectedAttribute(index + 1)}
                                                                         key={index}>
                                                             <ListItemText primary={attribute.name}/>
                                                         </ListItemButton>
                                                 ))}
                                             </List>
                                         </div>
                                         <div className="col-auto">
                                             <div className="row transfer">
                                                 <div className="col-12 edit" onClick={() => transferAttributeToDisplay()}>
                                                     <RightCircleOutlined/>
                                                 </div>
                                             </div>
                                         </div>
                                     </>
                             )}
                             <div className="col-md-6 col-xl-8 container-fluid "
                                  style={{overflowY: "scroll", height: "100%", width: setOfData === 'Population Data Set' ? '100%' : ''}}>
                                 <DragDropContext onDragEnd={reorderDisplayAttributes}>
                                     <Droppable droppableId="droppable-attributes" direction="vertical">
                                         {(droppableProvided) => (
                                                 <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps} >
                                                     {column && column.attributes && column.attributes.length > 0 &&
                                                      column.attributes.sort((a, b) => {return a.order - b.order}).map((attribute, index) => (
                                                              <AttributeDisplay
                                                                      key={attribute.order + 1}
                                                                      index={attribute.order}
                                                                      attribute={attribute}
                                                                      onDelete={removeDisplayAttribute}
                                                                      onUpdate={updateDisplayOption}/>))}
                                                     {droppableProvided.placeholder}
                                                 </div>)}
                                     </Droppable>
                                 </DragDropContext>
                             </div>
                         </div>


                     </div>
                    }
                    {activeTab === 1 &&
                     <div className="col-12 outer-box">
                         <div className="row">
                             <div className="col-md-5 col-xl-3">
                                 Available Attributes
                             </div>
                             <div className="col-auto">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                             <div className="col-md-6 col-xl-8">
                                 Attributes Filters
                             </div>
                         </div>
                         <div className="row inner-box">
                             <div className="col-md-5 col-xl-3" style={{overflowY: "scroll", height: "100%"}}>
                                 <List dense>
                                     {availableAttributes && availableAttributes.length > 0 && availableAttributes.map((attribute, index) => (
                                             <ListItemButton selected={index + 1 === selectedAttribute}
                                                             onClick={() => setSelectedAttribute(index + 1)}
                                                             key={index}>
                                                 <ListItemText primary={attribute.name}/>
                                             </ListItemButton>
                                     ))}
                                 </List>
                             </div>
                             <div className="col-auto">
                                 <div className="row transfer">
                                     <div className="col-12 edit"
                                          onClick={() => transferAttributeToFilter()}>
                                         <RightCircleOutlined/>
                                     </div>
                                 </div>
                             </div>
                             <div className="col-md-6 col-xl-8 container-fluid"
                                  style={{overflowY: "scroll", height: "100%"}}>
                                 <DragDropContext onDragEnd={reorderFilters}>
                                     <Droppable droppableId="droppable-filters" direction="vertical">
                                         {(droppableProvided) => (
                                                 <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps} >
                                                     {column && column.attributes && column.filters.length > 0 &&
                                                      column.filters.sort((a, b) => {return a.order - b.order}).map((filter, index) => (
                                                              <AttributeFilter key={filter.order + 1}
                                                                               index={filter.order}
                                                                               filter={filter}
                                                                               onDelete={removeFilter}
                                                                               onUpdate={updateFilter}
                                                              />))}
                                                     {droppableProvided.placeholder}
                                                 </div>)}
                                     </Droppable>
                                 </DragDropContext>
                             </div>

                         </div>

                     </div>
                    }
                </div>
            </div>
    )
    
}
export default withUser(FilterEditor);
