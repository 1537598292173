import React, {Component} from 'react';

const MAX_CHARS = 300;

class AgendaIssueEntry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            issue: props.issue
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.addIssue = this.addIssue.bind(this);
    }

    render() {
        const issue = this.state.issue;
        const issueText = issue && issue.issueText ? issue.issueText : "";
        return (
                <div className="box">
                    <div className="row">
                        <div className="col-12">
                                        <textarea name="noteEntry" id="noteEntry"
                                                  placeholder="Add your issues here"
                                                  className="noteEntry"
                                                  value={issueText}
                                                  onChange={this.handleInputChange}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 counter">
                            {issueText.length}/{MAX_CHARS}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <button onClick={this.addIssue} disabled={issueText.length === 0}>Add Issue</button>
                        </div>
                    </div>

                </div>
        );
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;

        this.setState((state) => ({
            issue: {
                ...state.issue,
                issueText: value
            }
        }));
    }

    addIssue() {
        if(this.props.onNew) {
            this.props.onNew(this.state.issue);
        }
        this.setState({
                          issue: null
                      })
    }
}

export default AgendaIssueEntry;