import { forEach, isEmpty, isEqual, reduce } from 'lodash';

const xAxisHelper = ({ vitals, LabResultsBUNCREA, LabResultsLDL, LabResultsHDL, medicationResults }) => {
	const calculateBMI = (weight, height) => 
	(weight / (height ** 2)) * 703;

	const convertBMIValue = (element) =>
		element && !isEqual(element.weight_lbs, "") && !isEqual(element.height_in, "")
		? calculateBMI(Number(element.weight_lbs), Number(element.height_in)).toFixed(2)
		: ''

	const medicationRowCreator = () => {
		const initialValue = [
		{
			gridLines: {
				display: false,
			},
			scaleLabel: {
				display: true,
				labelString: 'Medications'
			},
			ticks: {
				callback: function () {
					return ''
				},
			},
		}]
		forEach(medicationResults, (value, key) => {
			initialValue.push({
				gridLines: {
					display: false,
				},
				scaleLabel: {
					display: true,
					labelString: key
				},
				ticks: {
					callback: function () {
						return ''
					},
				},
			})
			initialValue.push({
				gridLines: {
					display: true,
				},
				scaleLabel: {
					display: true,
					lineHeight: 0,
				},
				ticks: {
					callback: function (e, index) {
						const labelIndex = monthIndexMap[index];
						return value[labelIndex] ? 'X' : '';
					},
				},
			})
		})

		xAxisConfig.push(...initialValue);
	};

	const xAxisConfig = [
		{
			gridLines: {
				display: true,
			},
			scaleLabel: {
				display: true,
				labelString: 'Past 24 Months',
				lineHeight: 0,
			},
		},
		{
			gridLines: {
				display: false,
			},
			scaleLabel: {
				display: true,
				labelString: 'BMI',
			},
			ticks: {
				callback: function () {
					return ''
				},
			},
		},
		{
			gridLines: {
				display: true,
			},
			scaleLabel: {
				display: true,
				lineHeight: 0,
			},
			ticks: {
				callback: function (e, index) {
					const labelIndex = monthIndexMap[index];
					return convertBMIValue(vitals[labelIndex]);
				},
			},
		},
		{
			gridLines: {
				display: false,
			},
			scaleLabel: {
				display: true,
				labelString: 'Blood Pressure'
			},
			ticks: {
				callback: function () {
					return ''
				},
			},
		},
		{
			gridLines: {
				display: false,
			},
			scaleLabel: {
				display: true,
				labelString: 'Systolic'
			},
			ticks: {
				callback: function () {
					return ''
				},
			},
		},
		{
			scaleLabel: {
				display: true,
				lineHeight: 0,
			},
			ticks: {
				fontSize: 10,
				callback: function (e, index) {
					const labelIndex = monthIndexMap[index];
					return vitals[labelIndex] ? vitals[labelIndex].bp_systolic : ''
				},
			},
		},
		{
			gridLines: {
				display: false,
			},
			scaleLabel: {
				display: true,
				labelString: 'Diastolic'
			},
			ticks: {
				callback: function () {
					return ''
				},
			},
		},
		{
			scaleLabel: {
				display: true,
				lineHeight: 0,
			},
			ticks: {
				fontSize: 10,
				callback: function (e, index) {
					const labelIndex = monthIndexMap[index];
					return vitals[labelIndex] ? vitals[labelIndex].bp_diastolic : ''
				},
			},
		},
		{
			gridLines: {
				display: false,
			},
			scaleLabel: {
				display: true,
				labelString: 'Lab Results'
			},
			ticks: {
				callback: function () {
					return ''
				},
			},
		},
		{
			gridLines: {
				display: false,
			},
			scaleLabel: {
				display: true,
				labelString: 'BUN/Creatinine Ratio'
			},
			ticks: {
				callback: function () {
					return ''
				},
			},
		},
		{
			gridLines: {
				display: true,
			},
			scaleLabel: {
				display: true,
				lineHeight: 0,
			},
			ticks: {
				callback: function (e, index) {
					const labelIndex = monthIndexMap[index];
					return LabResultsBUNCREA[labelIndex] ? LabResultsBUNCREA[labelIndex].value : '';
				},
			},
		},
		{
			gridLines: {
				display: false,
			},
			scaleLabel: {
				display: true,
				labelString: 'LDL'
			},
			ticks: {
				callback: function () {
					return ''
				},
			},
		},
		{
			gridLines: {
				display: true,
			},
			scaleLabel: {
				display: true,
				lineHeight: 0,
			},
			ticks: {
				callback: function (e, index) {
					const labelIndex = monthIndexMap[index];
					return LabResultsLDL[labelIndex] ? LabResultsLDL[labelIndex].value : '';
				},
			},
		},
		{
			gridLines: {
				display: false,
			},
			scaleLabel: {
				display: true,
				labelString: 'HDL'
			},
			ticks: {
				callback: function () {
					return ''
				},
			},
		},
		{
			gridLines: {
				display: true,
			},
			scaleLabel: {
				display: true,
				lineHeight: 0,
			},
			ticks: {
				callback: function (e, index) {
					const labelIndex = monthIndexMap[index];
					return LabResultsHDL[labelIndex] ? LabResultsHDL[labelIndex].value : '';
				},
			},
		}
	];

	if (!isEmpty(medicationResults)) medicationRowCreator();
	
	return xAxisConfig;
}

const monthIndexMap = {
	0: 23,
	1: 22,
	2: 21,
	3: 20,
	4: 19,
	5: 18,
	6: 17,
	7: 16,
	8: 15,
	8: 14,
	10: 13,
	11: 12,
	12: 11,
	13: 10,
	14: 9,
	15: 8,
	16: 7,
	17: 6,
	18: 5,
	19: 4,
	20: 3,
	21: 2,
	22: 1,
	23: 0,
}

export default xAxisHelper;
