import React, {Component} from 'react';
import RecommendedReadingItem from "./RecommendedReadingItem";

class RecommendedReading extends Component {
    constructor(props) {
        super(props);
        this.state = {
            readings: props.reading
        };
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if(prevProps.readings !== this.props.readings)
            this.setState({readings: this.props.readings})
    }

    render() {
        const readings = this.state.readings ? this.state.readings : [];
        return (
                <div className="card pcmh-profile-card">
                    <div className="card-header pcmh-profile-card-header">{this.props.heading}</div>
                    <div className="card-body">
                        {readings.map(reading => <RecommendedReadingItem reading={reading}/>)}
                    </div>
           </div>     
        );
    }        
}
export default RecommendedReading;