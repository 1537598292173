import React, {Component} from 'react';
import AssessmentTemplate from "./AssessmentTemplate";
import AssessmentTemplateEditor from "./AssessmentTemplateEditor";
import {Rest} from "../../../../util/rest";
import {withUser} from "../../../../context/UserContext";
import "./AssessmentAdmin.css"
import {FileAddOutlined} from "@ant-design/icons";
import AdminHeader from "../AdminHeader";
import {toast} from "react-toastify";

class AssessmentAdmin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            templates: [],
            editing: false,
            isLoading: true,
            filters: [{filterTitle: "Draft", filter: "DRAFT", checked: true},
                {filterTitle: "Published", filter: "PUBLISHED", checked: true},
                {filterTitle: "Inactive", filter: "INACTIVE", checked: false},
            ],
            filterString: "DRAFT,PUBLISHED",
            pageNumber: 1,
            totalPages: 1
        };

        this.addNewTemplate = this.addNewTemplate.bind(this);
        this.editTemplate = this.editTemplate.bind(this);
        this.deleteTemplate = this.deleteTemplate.bind(this);
        this.editorCloseCallBack = this.editorCloseCallBack.bind(this);
        this.handleTemplateUpdated = this.handleTemplateUpdated.bind(this);
        this.getTemplates = this.getTemplates.bind(this);
        this.filterChanged = this.filterChanged.bind(this);
        this.cloneTemplate = this.cloneTemplate.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
    }

    componentDidMount() {
        this.getTemplates("");
    }

    handlePageChange(event, value) {
        this.setState({
                          pageNumber: value
                      }, () => this.getTemplates(this.state.searchTerm))
    };

    render() {
        const filteredTemplates = !this.state.templates && this.state.filters ? [] : this.state.templates.reduce((filtered, template) => {
            const t = this.state.filters.find((f) => f.filter === template.status && f.checked)
            if(t) {
                filtered.push(template);
            }
            return filtered;
        }, []);

        return (
                <div className={"assessment-templates"}>
                    {!this.state.editing ?
                     <div>
                         <AdminHeader
                                 label={"Assessment Templates"}
                                 onChange={(event) => this.getTemplates(event.target.value)}
                                 onClickIcon={this.addNewTemplate}
                                 icon={<FileAddOutlined title="Add Template"/>}
                                 filters={this.state.filters}
                                 onFilterChanged={this.filterChanged}
                                 onPageChange={this.handlePageChange}
                                 pageNumber={this.state.pageNumber}
                                 totalPages={this.state.totalPages}
                         />
                         {this.state.isLoading &&
                          <div className="text-center my-5">
                              <button className="btn btn-primary-color btn-lg rounded shadow-sm" type="button" disabled>
                                  <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"/>
                                  Loading...
                              </button>
                          </div>}
                         <div className={"templates-container"}>
                             {/*{this.state.templates &&*/}
                             {/* this.state.templates.map((template) =>*/}
                             {filteredTemplates &&
                              filteredTemplates.map((template) =>
                                                            <AssessmentTemplate key={template.id}
                                                                                template={template}
                                                                                onEdit={this.editTemplate}
                                                                                onDelete={this.deleteTemplate}
                                                                                readOnly={template && template.status !== "DRAFT"}
                                                                                onClone={this.cloneTemplate}
                                                            />
                              )
                             }
                         </div>
                     </div> :
                     <div>
                         <AssessmentTemplateEditor
                                 template={this.state.template}
                                 onEditorClose={this.editorCloseCallBack}
                                 newMode={this.state.newMode}
                                 onTemplateUpdated={this.handleTemplateUpdated}
                                 readOnly={this.state.template && this.state.template.status !== "DRAFT"}
                         />
                     </div>
                    }
                </div>
        );
    }

    addNewTemplate() {
        this.setState({
                          editing: true,
                          newMode: true,
                          template: {
                              id: 0,
                              name: "New Assessment Template",
                              description: "",
                              instructions: "",
                              questions: [],
                              groups: [],
                              status: "DRAFT"
                          }
                      })
    }

    handleTemplateUpdated(template) {
        this.setState({template: template});
        this.setState((state) => {
            const templates = state.templates
            const index = templates.findIndex(t => t.id === template.id)
            templates[index] = template
            return {
                templates: templates
            }
        })
    }

    editTemplate(template) {
        this.setState({
                          editing: true,
                          newMode: false,
                          template: template
                      })
    }

    editorCloseCallBack() {
        this.setState({
                          editing: false,
                          newMode: false,
                          template: null
                      }, () => this.getTemplates(""));

    }

    getTemplates(searchTerm) {
        this.setState({isLoading: true, templates: []})
        if(!searchTerm)
            searchTerm = "";
        Rest.authFetch(this.props.user, `/rest/assessment/searchTemplates/${searchTerm}?page=${this.state.pageNumber - 1}&filters=${this.state.filterString}`).then(response => {
            if(response.status === 401 || response.status === 403) {
                toast.error("Unauthorized!");
                this.setState({isLoading: false})
            }
            else {
                this.setState({
                                  templates: response.content,
                                  pageNumber: response.number + 1,
                                  totalPages: response.totalPages,
                                  searchTerm: searchTerm,
                                  isLoading: false
                              });

            }
        })
                .catch(err => {
                    console.log(err);
                })
    }

    deleteTemplate(id) {
        Rest.authFetch(this.props.user, "/rest/assessment/templates/" + id, {
            method: 'DELETE'
        })
                .then(response => {
                    if(response.status === 401 || response.status === 403) {
                        toast.error("Unauthorized!")
                    }
                    else {
                        this.getTemplates("");
                    }
                })
                .catch(err => {
                    console.log(err);
                })
    }

    filterChanged(filters) {
        if(filters) {
            this.setState({
                              filters: filters,
                              pageNumber: 1, totalPages: 1,
                              filterString: filters.filter(f => f.checked).map((f) => {
                                  return f.filter;
                              }).join(",")
                          }, this.getTemplates);

        }
    }

    cloneTemplate(templateId) {
        Rest.authFetch(this.props.user, "/rest/assessment/templates/clone/" + templateId, {
            method: 'POST'
        })
                .then(response => {
                    if(response.status === 401 || response.status === 403) {
                        toast.error("Unauthorized!")
                    }
                    else {
                        this.getTemplates("");
                    }
                })
                .catch(err => {
                    console.log(err);
                })
    }
}

export default withUser(AssessmentAdmin);