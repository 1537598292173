import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {flow as compose, includes, isEqual} from 'lodash';


import toast from "../../util/toast";

import AuthPasswordReset from './views/AuthPasswordReset';
import UnauthPasswordReset from './views/UnauthPasswordReset';
import {withUser} from '../../context/UserContext';

const ResetPassword = ({ history, match, unAuthenticated, user }) => {
    const [activeView, setActiveView] = useState(unAuthenticated ? EMAIL_FORM : OTP_FORM);
    const [OTPLoading, setOTPLoading] = useState(false);
    const [submitPasswordLoading, setSubmitPasswordLoading] = useState(false);

    const [passwordValidation, setPasswordValidation] = useState({
        letter: 'invalid',
        capital: 'invalid',
        number: 'invalid',
        length: 'invalid',
        password: '',
        confirmPassword: 'invalid',
    });

    const [userInfo, setUserInfo] = useState({
        id: Number(match.params.userId) || 0,
        email: user ? String(user.email) : '',
        username: String(match.params.username) || '',
        otp: 0
    });

    useEffect(() => {
        if (unAuthenticated) history.replace('/reset-password');
    }, []);

    const submitOTPHandler = async () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        const response = await fetch(
            `/server/rest/users/otp/validate/${userInfo.otp}/${userInfo.username}`,
            requestOptions
        ).catch(error => console.log('error', error));

        const result = await response.text();
        if (isEqual(result, OTP_SEND_SUCCESS)) setActiveView(PASSWORD_CONFIRMATION);
        else if (isEqual(result, OTP_SEND_FAIL)) toast.error(OTP_SEND_FAIL);
    };

    const resendOTP = async () => {
        setActiveView(OTP_FORM);

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({ email: userInfo.email }),
            redirect: 'follow'
        };

        setOTPLoading(true);
        const response = await fetch("/server/rest/users/send/otp", requestOptions)
            .catch(error => console.log('error', error));

        const result = await response.json();
        setOTPLoading(false);
        if (isEqual(result.message, EMAIL_SEND_SUCCESS)) {
            toast.success(`Email Sent ${userInfo.email}`);
            if(unAuthenticated && !includes(window.location.href, `reset-password/${result.userId}/${result.userName}`)) {
                match.params.userId = result.userId;
                match.params.username = result.userName;
                history.replace({pathname: `reset-password/${result.userId}/${result.userName}`});

                setUserInfo((prevState) => ({
                    ...prevState,
                    id: result.userId,
                    username: result.userName
                }));
            }
        }
        else if (isEqual(result.message, EMAIL_EMPTY)) toast.error(EMAIL_EMPTY);
        else if (isEqual(result.message, NOT_VALID_EMAIL)) toast.error(NOT_VALID_EMAIL);
    };

    const resetPasswordHandler = async () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            password: passwordValidation.password,
            confirmPassword: passwordValidation.password,
            email: userInfo.email
        });

        const requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        setSubmitPasswordLoading(true);
        const response = await fetch(
            `/server/rest/users/resetPassword/${userInfo.id}/${userInfo.otp}/${userInfo.username}`,
            requestOptions
        ).catch(error => console.log('error', error));

        const result = await response.text();
        if (isEqual(result, PASSWORD_RESET_SUCCESSFULLY)) {
            toast.success(PASSWORD_RESET_SUCCESSFULLY);
            setTimeout(() => {
                setSubmitPasswordLoading(false);
                history.push('/profile');
            }, 2000);
        }
    };

    const passwordValidationHandler = ({ target: { value } }) =>
        setPasswordValidation((prevState) => ({
            ...prevState,
            capital: value.match(/[A-Z]/g) ? 'valid' : 'invalid', // capital letters
            length: value.length >= 6 ? 'valid' : 'invalid', // length
            letter: value.match(/[a-z]/g) ? 'valid' : 'invalid', // lowercase letters
            number: value.match(/[0-9]/g) ? 'valid' : 'invalid', // numbers
            password: value,
            confirmPassword: isEqual(prevState.password, value) ? 'valid' : 'invalid'
        }));

    const passwordConfirmationHandler = ({ target: { value } }) =>
        setPasswordValidation((prevState) => ({
            ...prevState,
            confirmPassword: isEqual(prevState.password, value) ? 'valid' : 'invalid'
        }));

    const userInfoChangeHandler = ({ target }, field) =>
        setUserInfo((prevState) => ({
            ...prevState,
            [field]: isEqual(field, 'otp') ? Number(target.value) : target.value
        }));

    const disablePasswordSubmitBtn = !(isEqual(passwordValidation.capital, 'valid')
        && isEqual(passwordValidation.length, 'valid')
        && isEqual(passwordValidation.letter, 'valid')
        && isEqual(passwordValidation.number, 'valid')
        && isEqual(passwordValidation.confirmPassword, 'valid')
        && !isEqual(passwordValidation.password, ''));

    const handleClose = () => history.push('/');

    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const disableEmailSubmit = re.test(userInfo.email);

    const passwordResetProps = {
        activeView,
        disableEmailSubmit,
        disablePasswordSubmitBtn,
        handleClose,
        otpField: userInfo.otp,
        OTPLoading,
        passwordConfirmationHandler,
        passwordValidation,
        passwordValidationHandler,
        resendOTP,
        resetPasswordHandler,
        submitOTPHandler,
        submitPasswordLoading,
        userInfoChangeHandler
    };

    return unAuthenticated
        ? <UnauthPasswordReset {...passwordResetProps} /> // FORM IF NOT AUTHENTICATED
        : <AuthPasswordReset {...passwordResetProps} />; // FORM IF AUTHENTICATED
};

const EMAIL_SEND_SUCCESS = 'Email send success with an OTP';
const NOT_VALID_EMAIL = "Entered email is not valid";
const EMAIL_EMPTY = "Please enter an Email";

const OTP_SEND_SUCCESS = "Entered OTP is valid";
const OTP_SEND_FAIL = "Entered OTP is NOT valid. Please Retry!";

const EMAIL_FORM = 'EMAIL_FORM';
const OTP_FORM = 'OTP_FORM';
const PASSWORD_CONFIRMATION = 'PASSWORD_CONFIRMATION';

const PASSWORD_RESET_SUCCESSFULLY = 'Password reset successfully';

ResetPassword.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func,
    }),
    match: PropTypes.shape({
        params: PropTypes.shape({
            userId: PropTypes.string,
            username: PropTypes.string,
        })
    }),
    unAuthenticated: PropTypes.bool,
    user: PropTypes.shape({
        email: PropTypes.string,
    }),
}

export default compose(
    withUser,
)(ResetPassword);
