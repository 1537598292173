import React, {useEffect, useRef, useState} from 'react';
import './Messaging.css'
import {withUser} from "../../context/UserContext";
import GenericProfile from "../../images/profile.png";

const options = {
    year: 'numeric', month: 'numeric', day: 'numeric',
    hour: 'numeric', minute: 'numeric', second: 'numeric',
    hour12: true,
    timeZone: 'America/Puerto_Rico'
};

function SenderItem(props) {


    const [sender, setSender] = useState();
    const [photoSrc, setPhotoSrc] = useState(GenericProfile);
    const [unreadCount, setUnreadCount] = useState(0);
    const [lastMessage, setLastMessage] = useState(null);

    useEffect(() => {
        console.log("MessageGroup:")
        console.log(props.messageGroup)

        if(props.messageGroup && props.messageGroup.messageList.length > 0) {
            setSender(props.messageGroup.sender);
            setUnreadCount(props.messageGroup.unreadCount);
            setLastMessage(props.messageGroup.lastMessage);
        }
    }, [props.messageGroup]);


    useEffect(() => {
        if(sender) {
            if(sender.imageType && sender.photo) setPhotoSrc(sender.imageType + "," + sender.photo); else setPhotoSrc(GenericProfile);
        }

    }, [sender])
    return (<div className={"gx-0 sender-item" + (props.selected ? " active" : "")}
                 onClick={() => props.onClick ? props.onClick(props.messageGroup) : null}>
        {sender && <div className="row">
            <div className="col-1 sender-photo justify-content-start"><img src={photoSrc}/></div>
            <div className="col-11">
                <div className="row">
                    <div className="col-10 px-3 sender-name">{sender.fullName}</div>
                    {(unreadCount > 0) &&
                     <div className="col-1 unread-count">{unreadCount}</div>
                    }
                </div>
                {lastMessage &&
                 <div className="row">
                     <div className="col-6 px-3 sent-date">{Intl.DateTimeFormat('en-US', options).format(new Date(lastMessage.announcement.sentDateTime))}</div>
                 </div>
                }
            </div>
        </div>}
    </div>)


}

export default withUser(SenderItem)