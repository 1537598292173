import React, {Component} from 'react';
import PropTypes from 'prop-types';
import "./TeamMemberCard.css"
import GenericProfile from '../../../images/profile.png'

class TeamMemberCard extends Component {

    render() {
        const profile = this.props.profile;
        const imgSrc = (profile && profile.imageType && profile.photo) ? profile.imageType + "," + profile.photo : GenericProfile;
        const imgAlt = profile?profile.fullName:"Not a Staff Member";
        return (
                <div className="card tm_profileCard">
                    <div className="card-body tm_profileCardInner">
                        <div className="tm_profileCardPic"><img
                                src={imgSrc}
                                alt={imgAlt}/>
                        </div>

                        <div className="th_profileCardInfo">
                            {profile ? <div>
                                <h3>{profile.fullName}</h3>
                                <p>{profile.email}</p>
                                <span>{profile.typeOfPractice}</span></div> : null}
                        </div>
                    </div>
                </div>
        );
    }
}

TeamMemberCard.propTypes = {
    profile: PropTypes.object
};
export default TeamMemberCard;