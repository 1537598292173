import React, { Component, Suspense, lazy } from 'react';
import "antd/dist/antd.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "./SmartPcmhClientApp.css";
import { HashRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import { UserContext } from './context/UserContext';
import { Auth } from "./util/auth";
import toast from "./util/toast";
import StaffSupport from "./components/section/staffsupport/StaffSupport";
import Assessment from "./components/section/assessment/Assessment";
import Overview from "./components/section/overview/Overview";
import PatientSupport from "./components/section/patientsupport/PatientSupport";
import Profile from "./components/section/Profile/Profile";
import NewLogin from "./components/login/NewLogin";
import AuthorizeRoute from "./components/authorization/AuthorizeRoute"
import Document from "./components/documents/Document";
import Huddle from "./components/section/huddle/Huddle";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import PCMHMenu from "./components/navigation/PCMHMenu";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.minimal.css';
import AssessmentAdmin from "./components/section/admin/assessments/AssessmentAdmin";
import AssessmentsBatches from "./components/section/assessments/AssessmentsBatches";
import ProfileAdmin from "./components/section/admin/staff/ProfileAdmin";
import TeamAdmin from "./components/section/admin/teams/TeamAdmin";
import AgendaAdmin from "./components/section/admin/agenda/AgendaAdmin";
import ContentAdmin from "./components/section/admin/content/ContentAdmin";
import CurriculumAdmin from "./components/section/admin/curriculums/CurriculumAdmin";
import TrainingAdmin from "./components/section/admin/trainings/TrainingAdmin";
import HealthElements from "./components/section/admin/healthdata/HealthDataAdmin";
import Messaging from "./components/messaging/Messaging";
import AssessmentReview from "./components/section/patientsupport/AssessmentReview";
import PatientGraph from "./components/PatientGraph/PatientGraph";
import DataSetAdmin from "./components/section/patientsupport/DataSet/DataSetAdmin";
import PopulationSetAdmin from "./components/section/patientsupport/PopulationSet/PopulationSetAdmin";
import PatientsViews from "./components/section/patientsupport/PatientsViews/PatientsViews";

const renewTokenTimeInMs = 120000;  //If token has less than amount of milliseconds before expiring it will be renewed.  A timer will verify every half of that time!
const UserAdminLazy = lazy(() => import("./components/section/admin/users/UserAdmin.js"))

class SmartPcmhClientApp extends Component {

	constructor(props) {
		super(props);
		this.state = {
			prevUser: null,
			user: null,
			tokenVersion: 0,
			setUser: (user) => {
				this.updateUser(user);
			}
		};
	}

	updateUser(user) {
		const prevUser = this.state.user;
		this.setState((prevState) => ({
			user: user,
			prevUser: prevUser,
			tokenVersion: prevState.tokenVersion + 1
		}));

		if (!prevUser && user) { //User just logged in
			this.setState({ tokenVersion: 0 }, () => (this.startRenewTimer()));

		}
		else if (!user && prevUser) { //User just logged out
			this.setState({ tokenVersion: 0 }, () => (this.stopRenewTimer()));
		}
		else if (user.username === prevUser.username) {  //same user

		}
		else if (user.username !== prevUser.username) { //new user
			this.setState({ tokenVersion: 0 });
		}
	}


	startRenewTimer() {
		console.log("Timer Started for: " + this.state.user.username);
		this.timer = setInterval(this.renewToken.bind(this), renewTokenTimeInMs / 2);
	}

	stopRenewTimer() {
		clearInterval(this.timer);
	}

	renewToken() {
		if (this.state.user && (new Date(this.state.user.expiration).getTime() + renewTokenTimeInMs) < (new Date().getTime())) {
			Auth.renewToken(this.state.user)
				.then((authResult) => {
					this.setState({
						authenticationError: !authResult.authenticated
					});
					if (authResult.authenticated) {
						this.setState({ user: authResult.user });
					}
					else {
						this.setState({ user: null });
						toast.error("Error renewing token!")
					}
				});
		}
	}

	render() {
		return (
			<UserContext.Provider value={this.state}>
				<HashRouter>
					<Suspense fallback={<div>Loading...</div>}>
						<div className="app">
							<PCMHMenu sidebarDocked={true} />
							<div className="content">
								<div className="pcmh-content">
									<Routes>
										{/*<React.StrictMode>*/}
										<Route exact path="/" element={
											<AuthorizeRoute allowedRoles={["ROLE_USER"]}>
												<Dashboard />
											</AuthorizeRoute>
										} />
										<Route exact path="/login" element={<NewLogin />} />
										<Route exact path="/dashboard"
											element={
												<AuthorizeRoute allowedRoles={["ROLE_USER"]}>
													<Dashboard />
												</AuthorizeRoute>
											} />
										<Route exact path="/overview"
											element={
												<AuthorizeRoute allowedRoles={["ROLE_USER"]}>
													<Overview />
												</AuthorizeRoute>} />
										<Route exact path="/profile"
											element={
												<AuthorizeRoute allowedRoles={["ROLE_USER"]}>
													<Profile />
												</AuthorizeRoute>} />

										<Route exact path="/staffsupport"
											element={
												<AuthorizeRoute allowedRoles={["ROLE_USER"]}>
													<StaffSupport />
												</AuthorizeRoute>} />
										<Route exact path="/patientsupport/patient"
											element={
												<AuthorizeRoute allowedRoles={["ROLE_USER"]}>
													<PatientSupport />
												</AuthorizeRoute>} />
										<Route exact path="/patientsupport/assessment"
											element={
												<AuthorizeRoute allowedRoles={["ROLE_USER"]}>
													<AssessmentReview />
												</AuthorizeRoute>} />
										<Route exact path="patientsupport/patients"
											element={
												<AuthorizeRoute allowedRoles={["ROLE_USER"]}>
													<PatientsViews />
												</AuthorizeRoute>} />
										<Route exact path="/patientsupport/datasetadmin"
											element={
												<AuthorizeRoute allowedRoles={["ROLE_USER"]}>
													<DataSetAdmin />
												</AuthorizeRoute>} />
										<Route exact path="/patientsupport/populationset"
											element={
												<AuthorizeRoute allowedRoles={["ROLE_USER"]}>
													<PopulationSetAdmin />
												</AuthorizeRoute>} />
										<Route exact path="/assessment/:id"
											element={
												<AuthorizeRoute allowedRoles={["ROLE_USER"]}>
													<Assessment />
												</AuthorizeRoute>} />
										<Route exact path="/document/:id"
											element={
												<AuthorizeRoute allowedRoles={["ROLE_USER"]}>
													<Document />
												</AuthorizeRoute>} />
										<Route exact path="/huddle"
											element={
												<AuthorizeRoute allowedRoles={["ROLE_USER"]}>
													<Huddle />
												</AuthorizeRoute>} />
										<Route exact path="/assessments/templates"
											element={
												<AuthorizeRoute allowedRoles={["ROLE_USER"]}>
													<AssessmentAdmin />
												</AuthorizeRoute>} />
										<Route exact path="/assessments/batches"
											element={
												<AuthorizeRoute allowedRoles={["ROLE_USER"]}>
													<AssessmentsBatches />
												</AuthorizeRoute>} />
										<Route exact path="/admin/staff"
											element={
												<AuthorizeRoute allowedRoles={["ROLE_USER", "ROLE_ADMIN"]}>
													<ProfileAdmin />
												</AuthorizeRoute>} />
										<Route exact path="/admin/teams"
											element={
												<AuthorizeRoute allowedRoles={["ROLE_USER", "ROLE_ADMIN"]}>
													<TeamAdmin />
												</AuthorizeRoute>} />
										<Route exact path="/admin/agenda"
											element={
												<AuthorizeRoute allowedRoles={["ROLE_USER", "ROLE_ADMIN"]}>
													<AgendaAdmin />
												</AuthorizeRoute>} />
										<Route exact path="/admin/users"
											element={
												<AuthorizeRoute allowedRoles={["ROLE_USER", "ROLE_ADMIN"]}>
													<UserAdminLazy />
												</AuthorizeRoute>} />
										<Route exact path="/admin/content"
											element={
												<AuthorizeRoute allowedRoles={["ROLE_USER", "ROLE_ADMIN"]}>
													<ContentAdmin />
												</AuthorizeRoute>} />
										<Route exact path="/admin/curriculums"
											element={
												<AuthorizeRoute allowedRoles={["ROLE_USER", "ROLE_ADMIN"]}>
													<CurriculumAdmin />
												</AuthorizeRoute>} />
										<Route exact path="/admin/trainings"
											element={
												<AuthorizeRoute allowedRoles={["ROLE_USER", "ROLE_ADMIN"]}>
													<TrainingAdmin />
												</AuthorizeRoute>} />
										<Route exact path="/messages"
											element={
												<AuthorizeRoute allowedRoles={["ROLE_USER"]}>
													<Messaging />
												</AuthorizeRoute>} />
										<Route exact path="/reset-password/:userId/:username"
											element={
												<AuthorizeRoute allowedRoles={["ROLE_USER"]}>
													<ResetPassword />
												</AuthorizeRoute>} />

										<Route exact path="/health-elements"
											element={
												<AuthorizeRoute allowedRoles={["ROLE_USER"]}>
													<HealthElements />
												</AuthorizeRoute>} />

										<Route exact path="/patient-graph"
											element={
												<AuthorizeRoute allowedRoles={["ROLE_USER"]}>
													<PatientGraph />
												</AuthorizeRoute>} />
										{/*</React.StrictMode>*/}
									</Routes>
									<div className="footer text-muted unSelectable">
										Copyright © 2022 Impactivo
									</div>
								</div>
							</div>
							<ToastContainer
								bodyClassName={() => "toast-body"}
								position="top-right"
								autoClose={2000}
							// className={()=>"toast-container"}
							// bodyClassName='toast-body'
							/>
						</div>
					</Suspense>
				</HashRouter>
			</UserContext.Provider>

		)
	}

}

export default SmartPcmhClientApp;
