import React, {Component} from 'react';
import Training from "./Training";
import toast from "../../../../util/toast";
import {Rest} from "../../../../util/rest";
import {withUser} from "../../../../context/UserContext";
import "./TrainingAdmin.css"
import {FolderAddOutlined} from "@ant-design/icons";
import AdminHeader from "../AdminHeader";

class TrainingAdmin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            trainings: [],
            isLoading: true
        };
        this.addNewTraining = this.addNewTraining.bind(this);
        this.deleteTraining = this.deleteTraining.bind(this);
        this.handleTrainingUpdated = this.handleTrainingUpdated.bind(this);
    }

    componentDidMount() {
        this.getTrainings("");
    }

    render() {
        return (
            <div className={"trainings"}>
                <div>
                    <AdminHeader
                        label={"Trainings"}
                        onChange={(event) => this.getTrainings(event.target.value)}
                        onClickIcon={this.addNewTraining}
                        icon={<FolderAddOutlined title="Add Training"/>}
                    />
                    <div>
                        {this.state.isLoading &&
                        <div className="text-center my-5">
                            <button className="btn btn-primary-color btn-lg rounded shadow-sm" type="button" disabled>
                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"/>
                                Loading...
                            </button>
                        </div>
                        }
                        {this.state.trainings.map((training) =>
                            <Training
                                key={training.id}
                                training={training}
                                onDelete={this.deleteTraining}
                                onTrainingChanged={this.handleTrainingUpdated}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }

    addNewTraining() {
        this.setState((state) => ({
            trainings: [...state.trainings, {
                id: 0,
                name: "New Training",
                order: state.trainings.length,
                trainings: []
            }]
        }))
    }

    handleTrainingUpdated(training) {
        this.setState((state) => ({
            trainings: state.trainings.map(c => c.id === training.id ? training : c)
        }), () => this.getTrainings(""))
    }

    getTrainings(searchTerm) {
        Rest.authFetch(this.props.user, `/rest/searchTrainings/${searchTerm}`).then(response => {
            if(response.status === 401 || response.status === 403) {
                toast.error("Unauthorized!")
            }
            else {
                this.setState({
                    trainings: response,
                    isLoading: false
                });
            }
        })
        .catch(err => {
            console.log(err);
            this.setState({
                isLoading: false
            });
        })
    }

    deleteTraining(id) {
        Rest.authFetch(this.props.user, "/rest/trainings/" + id,{
            method:'DELETE'
        })
            .then(response => {
                if(response.status === 401 || response.status === 403) {
                    toast.error("Unauthorized!")
                }
                else {
                    this.getTrainings("");
                }
            })
            .catch(err => {
                console.log(err);
            })
    }
}

export default withUser(TrainingAdmin);