import React, {useRef, useState} from 'react'
import PropTypes from 'prop-types';

import {Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {FileTextOutlined, PlusCircleOutlined} from "@ant-design/icons";
import FileViewer from "../../../../common/fileviewer/FileViewer";

import ContentStyle from "./ContentStyle";
import {cloneDeep, includes, isEmpty, isEqual, join, map, replace} from 'lodash';

const ContentUploader = (props) => {
    const {activeContent, closeModal, submitContent} = props;
    const createMode = !Boolean(activeContent);

    const [contentForm, setContentForm] = useState({
        authors: createMode ? [] : activeContent.authors,
        badge: createMode ? '' : activeContent.badge,
        badgeImageType: createMode ? '' : activeContent.badgeImageType,
        content: createMode ? '' : activeContent.content,
        contentLen: createMode ? 0 : activeContent.contentLen,
        contentType: 'application/pdf',
        fileName: createMode ? '' : activeContent.fileName,
        id: createMode ? 0 : activeContent.id,
        keywords: createMode ? [] : activeContent.keywords,
        readingTime: createMode ? 0 : activeContent.readingTime,
        title: createMode ? '' : activeContent.title,
    })

    const authorRef = useRef(null);
    const keywordRef = useRef(null);

    const addChips = (ref, chipType) => {
        const newList = cloneDeep(contentForm[chipType]);
        if (isEqual(ref.current.value, '') || includes(newList, ref.current.value)) return;
        newList.push(ref.current.value);
        setContentForm((prevState) => ({
            ...prevState,
            [chipType]: newList
        }));
        ref.current.value = '';
    };

    const removeChip = (chip, chipType) => {
        const newList = cloneDeep(contentForm[chipType]);
        const index = newList.indexOf(chip);
        newList.splice(index, 1);
        setContentForm((prevState) => ({
            ...prevState,
            [chipType]: newList
        }));
    };

    const inputOnChangeHandler = ({ type, value }, contentFormKey) =>
        setContentForm((prevState) => ({
            ...prevState,
            [contentFormKey]: isEqual(type, 'number') ? Number(value) : value
        }));

    const fileUploadOnChangeHandler = (files, contentFormKey) => {
        const file = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = ({ target: { result } }) =>
            setContentForm((prevState) => {
                let badge = prevState.badge;
                let badgeImageType = prevState.badgeImageType;
                if (isEqual(contentFormKey, 'BADGE')) {
                    badgeImageType = `data:${file.type};base64`;
                    badge = replace(result, `${badgeImageType},`, '');
                }

                let content = prevState.content;
                let contentLen = prevState.contentLen;
                let fileName = prevState.fileName;
                if (isEqual(contentFormKey, 'CONTENT')) {
                    content = replace(result, `${`data:${prevState.contentType};base64`},`, '');
                    contentLen = file.size;
                    fileName = file.name;
                }

                return {
                    ...prevState,
                    badge,
                    badgeImageType,
                    content,
                    contentLen,
                    fileName,
                }
            });
    };

    const submitDisabled =
        isEqual(contentForm.authors, []) ||
        isEqual(contentForm.badge, '') ||
        isEqual(contentForm.badgeImageType, '') ||
        isEqual(contentForm.content, '') ||
        isEqual(contentForm.contentLen, 0) ||
        isEqual(contentForm.fileName, '') ||
        contentForm.readingTime <= 0 ||
        isEqual(contentForm.title, '');

    const onSubmitHandler = () => {
        const contentFormCopy = cloneDeep(contentForm);
        if (!createMode) {
            contentFormCopy.authors = join(contentForm.authors, '|');
            contentFormCopy.keywords = join(contentForm.keywords, '|');
        }
        submitContent(contentFormCopy);
    }

    return (
        <Dialog open fullWidth onClose={closeModal} maxWidth="md">
            <DialogTitle>{createMode ? 'Create' : 'Edit'} Content</DialogTitle>
            <DialogContent>
                <ContentStyle>
                    <div className="contentForm">
                        <TextField
                            margin="dense"
                            label="Title"
                            variant="outlined"
                            className="textField title-input"
                            fullWidth
                            onChange={({ target }) => inputOnChangeHandler(target, 'title')}
                            value={contentForm.title}
                        />
                        <div className="input-group">
                            <span className="input-group-span">
                                <TextField
                                    margin="dense"
                                    label="Read Time (minutes)"
                                    variant="outlined"
                                    className="textField"
                                    type="number"
                                    onChange={({ target }) => inputOnChangeHandler(target, 'readingTime')}
                                    value={contentForm.readingTime}
                                />
                            </span>
                            <span className="input-group-span">
                                <TextField
                                    margin="dense"
                                    label="Add Authors"
                                    variant="outlined"
                                    className="textField"
                                    inputRef={authorRef}
                                />
                                <PlusCircleOutlined
                                    className="icon"
                                    title="Add Authors"
                                    onClick={() => addChips(authorRef, 'authors')} />
                            </span>
                            <span className="input-group-span">
                                <TextField
                                    margin="dense"
                                    label="Add Keywords"
                                    variant="outlined"
                                    className="textField"
                                    inputRef={keywordRef}
                                />
                                <PlusCircleOutlined
                                    className="icon"
                                    title="Add Keyword"
                                    onClick={() => addChips(keywordRef, 'keywords')} />
                            </span>
                        </div>
                    </div>
                    <div className="chips-container-outter">
                        {!isEmpty(contentForm.authors) && (
                            <div className="chips-container-inner">
                                <div className="chips-container-title">Author</div>
                                {map(contentForm.authors, (author) => (
                                    <Chip
                                        key={author}
                                        className="chip"
                                        label={author}
                                        onDelete={() => removeChip(author, 'authors')} />
                                ))}
                            </div>
                        )}
                    </div>
                    <div className="chips-container-outter">
                        {!isEmpty(contentForm.keywords) && (
                            <div className="chips-container-inner">
                                <div className="chips-container-title">Keyword</div>
                                {map(contentForm.keywords, (keyword) => (
                                    <Chip
                                        key={keyword}
                                        className="chip"
                                        label={keyword}
                                        onDelete={() => removeChip(keyword, 'keywords')} />
                                ))}
                            </div>
                        )}
                    </div>
                    <div className="contentImages">
                        <div className="content-document">
                            <div className="content-document-div">
                                <div className="content-document-display">
                                    {createMode && isEqual(contentForm.content, '')
                                        ? <FileTextOutlined className="empty-document" />
                                        : <FileViewer
                                            key={contentForm.content}
                                            blob={contentForm.content}
                                            type={contentForm.contentType}
                                            className="document-viewer" />}
                                </div>
                            </div>
                            <label htmlFor="upload-pdf-document">
                                <input
                                    className="upload-input"
                                    accept="application/pdf"
                                    id="upload-pdf-document"
                                    type="file"
                                    onChange={({ target: { files } }) => fileUploadOnChangeHandler(files, 'CONTENT')} />
                                <Button
                                    className="editorButton"
                                    component="span">
                                    Upload PDF Document
                                </Button>
                            </label>
                        </div>

                        <div className="content-document">
                            <div className="content-document-div">
                                <div className="content-document-display">
                                    {createMode && isEqual(contentForm.badge, '')
                                        ? <FileTextOutlined className="empty-document" />
                                        : <img
                                            src={`${contentForm.badgeImageType},${contentForm.badge}`}
                                            className="document-viewer"
                                            alt="Badge Image" />}
                                </div>
                            </div>
                            <label htmlFor="upload-badge-image">
                                <input
                                    className="upload-input"
                                    accept="image/jpeg,image/gif,image/png"
                                    id="upload-badge-image"
                                    type="file"
                                    onChange={({ target: { files } }) => fileUploadOnChangeHandler(files, 'BADGE')} />
                                <Button
                                    className="editorButton"
                                    component="span">
                                    Upload Badge Image
                                </Button>
                            </label>
                        </div>
                    </div>
                </ContentStyle>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={closeModal}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    className={`editorButton ${submitDisabled ? 'submitContentDisabled' : ''}`}
                    disabled={submitDisabled}
                    onClick={onSubmitHandler}>
                    {createMode ? 'Save' : 'Update'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

ContentUploader.propTypes = {
    activeContent: PropTypes.shape({
        authors: PropTypes.arrayOf(PropTypes.string),
        badge: PropTypes.string,
        badgeImageType: PropTypes.string,
        content: PropTypes.string,
        contentLen: PropTypes.number,
        contentType: PropTypes.string,
        fileName: PropTypes.string,
        id: PropTypes.number,
        keywords: PropTypes.arrayOf(PropTypes.string),
        modalType: PropTypes.string,
        readingTime: PropTypes.number,
        title: PropTypes.string,
    }),
    closeModal: PropTypes.func.isRequired,
    submitContent: PropTypes.func.isRequired,
};

ContentUploader.defaultProps = {
    activeContent: null,
};

export default ContentUploader;
