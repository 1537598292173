import React, {useEffect, useRef, useState} from 'react';
import './Messaging.css'
import {withUser} from "../../context/UserContext";
import {Allotment} from "allotment";
import "allotment/dist/style.css";
import {Rest} from "../../util/rest";
import toast from "../../util/toast";
import MessageItem from "./MessageItem";
import SenderItem from "./SenderItem";

function Messaging(props) {

    const [messageList, setMessageList] = useState([]);
    const [groupedMessages, setGroupedMessages] = useState([])
    const [activeGroup, setActiveGroup] = useState();
    const [activeMessageList, setActiveMessageList] = useState([]);

    useEffect(() => {
        getMessageList()
    }, []);

    useEffect(() => {
        setGroupedMessages(groupBy(messageList, message => message.announcement.sender.id));
    }, [messageList]);

    useEffect(() => {
        if(!activeGroup && groupedMessages && groupedMessages.length > 0) {
            setActiveGroup(groupedMessages[0]);
        }
        return () => {
            setActiveGroup(null);
        };
    }, [groupedMessages]);


    useEffect(() => {
        if(activeGroup && activeGroup.messageList) {
            setActiveMessageList(activeGroup.messageList)
        }
        else {
            setActiveMessageList([])
        }

    }, [activeGroup]);


    function getMessageList() {
        Rest.authFetch(props.user, "/rest/messaging/" + props.user.staffMemberId)
                .then(response => {
                    if(response.status === 401 || response.status === 403) {
                        setMessageList([]);
                        toast.error('Unauthorized!')
                    }
                    else {
                        setMessageList(response);
                    }
                })
                .catch(err => {
                    console.log(err);
                });
    }

    function deleteMessage(e) {
        // TODO: Implement
    }

    function groupBy(list, keyGetter) {
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if(!collection) {
                map.set(key, [item]);
            }
            else {
                collection.push(item);
            }
        });
        const grouped = [];
        map.forEach((value) => {
            console.log(value);
            value.sort((a, b) => {
                return new Date(b.announcement.sentDateTime) - new Date(a.announcement.sentDateTime);
            });
            const group = {
                messageList: value,
                sender: value[0].announcement.sender,
                unreadCount: value.filter((msg) => msg.readDateTime === null).length,
                lastMessage: value[0]
            };

            grouped.push(group);
        })
        return grouped;

    }


    return (
            <div className="announcement-list" style={{margin: '0', padding: '0'}}>
                <Allotment defaultSizes={[300, 700]}>
                    <Allotment.Pane preferredSize={"30%"} minSize={200}>
                        <div className="senders">
                            {groupedMessages.map((group) => (
                                    <SenderItem selected={activeGroup && activeGroup.sender.id === group.sender.id}
                                                onClick={setActiveGroup} key={group.sender.id} messageGroup={group}/>
                            ))}
                        </div>
                    </Allotment.Pane>

                    <Allotment.Pane preferredSize={"70%"} minSize={200}>
                        <div className="messages">
                            {activeMessageList.map((message) => (
                                    <MessageItem index={message.id} key={message.id} message={message}
                                                 deleteHandler={deleteMessage}/>
                            ))}
                        </div>
                    </Allotment.Pane>
                </Allotment>
            </div>
    );
}

export default withUser(Messaging)