import React, {Component} from 'react';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import {withUser} from "../../../../context/UserContext";
import Button from "@mui/material/Button";
import './ProfileEditor.css'
import {Rest} from "../../../../util/rest";
import toast from "../../../../util/toast";
import TeamItem from "./TeamItem";

class AddTeam extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: props.open,
            teams: [],
            originalTeams: props.originalTeams
        };
        this.handleClose = this.handleClose.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.teamChanged = this.teamChanged.bind(this);
    }

    componentDidMount() {
        this.getTeams();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //todo:replace function below with this one


    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.open !== this.props.open)
            this.setState({ open: this.props.open });
        if (this.props.open && this.props.originalTeams !== this.state.originalTeams) {
            this.setState({ originalTeams: this.props.originalTeams });
            let teams = this.state.teams;
            if (teams && this.props.originalTeams && this.props.originalTeams.length > 0) {
                let origTeams = this.props.originalTeams;
                teams.map((team, i) => {
                    if (origTeams.findIndex(oteam => oteam.id === team.id) >= 0) {
                        teams[i] = {
                            ...teams[i],
                            selected: true
                        }
                    }
                    else {
                        teams[i] = {
                            ...teams[i],
                            selected: false
                        }
                    }
                    return null;
                });
            }
            this.setState({ teams: teams })

        }
    }

    render() {
        const teams = this.state.teams;
        return (
            <div>
                <Dialog open={this.state.open} aria-labelledby="addTeam-dialog-title">
                    <DialogTitle classes={{ root: "editorHeader" }} id="addTeam-dialog-title">Teams</DialogTitle>
                    <DialogContent>
                        {teams.map((team, index) => {
                            return <TeamItem team={team} key={index} onChange={this.teamChanged} />
                        })}
                    </DialogContent>
                    <DialogActions>
                        <div onClick={this.handleCancel} id="cancel">
                            <Button variant="contained" title="cancel"
                                className="editorButton">
                                Cancel
                            </Button>
                        </div>
                        <div onClick={this.handleClose} title="ok" id="ok">
                            <Button variant="contained"
                                className="editorButton">
                                Ok
                            </Button>
                        </div>
                    </DialogActions>
                </Dialog>

            </div>
        );
    }

    handleCancel() {
        this.setState({ open: false });
        if (this.props.onClose)
            this.props.onClose(this.state.originalTeams);
    }

    handleClose() {
        this.setState({ open: false });
        if (this.props.onClose) {
            let selectedTeams = [];
            const teams = this.state.teams;
            teams.map(team => {
                if (!(typeof team.selected === "undefined" || !team.selected)) {
                    selectedTeams.push(team);
                }
                return null;
            });
            this.props.onClose(selectedTeams);
        }
    }

    teamChanged(team) {
        let teams = this.state.teams;
        let index = teams.findIndex(eteam => eteam.id === team.id);
        if (index > 0) {
            teams[index] = team;
            this.setState({ teams: teams });
        }
    }

    getTeams() {
        Rest.authFetch(this.props.user, `/rest/staff/searchTeam/`).then(response => {
            if (response.status === 401 || response.status === 403) {
                this.setState({ teams: [] });
                toast.error("Unauthorized!")
            }
            else if (response.status === 404) {
                this.setState({ teams: [] });
            }
            else {
                this.setState({ teams: response.content });
            }
        })
            .catch(err => {
                console.log(err);
            })

    }
}

export default withUser(AddTeam);