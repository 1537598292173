import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {ImageViewer, PDFViewer} from './viewers';
import {FileTextOutlined} from "@ant-design/icons";


class FileViewer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blob: props.blob,
            type: props.type
        };
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if(prevProps.blob !== this.state.blob)
            this.setState({blob: this.props.blob});
        if(prevProps.type !== this.props.type)
            this.setState({type: this.props.type})

    }

    componentDidMount() {
        const container = document.getElementById('pcmh-file-viewer');
        const height = container ? container.clientHeight : 0;
        const width = container ? container.clientWidth : 0;
        this.setState({height, width});

        if(this.props.type) {
            const type = this.props.type.toLowerCase();
            console.log(type);
            if(type.includes("pdf")) {
                this.setState({
                                  viewer: PDFViewer,
                                  blob: atob(this.props.blob)
                              });
            }
            else if(type.includes("image")) {
                this.setState({
                                  viewer: ImageViewer,
                              });
            }
        }
    }

    render() {
        const Viewer = this.state.viewer;
        const blob = this.state.blob;
        return (
                <div id="pcmh-file-viewer">
                    {(Viewer && blob) ?
                     <Viewer {...this.props} blob={blob} width={this.state.width} height={this.state.height}/> :
                     <FileTextOutlined className="empty-document my-auto" type={"file-text"}/>
                    }
                </div>
        );

    }

}

FileViewer.propTypes = {
    blob: PropTypes.oneOfType([
                                  PropTypes.string,
                                  PropTypes.object,
                              ]).isRequired,
    // file: PropTypes.oneOfType([
    //                               PropTypes.string,
    //                               PropTypes.object,
    //                           ]).isRequired,
    type: PropTypes.string,
    page: PropTypes.number,
    mime: PropTypes.string,
    onDocumentComplete: PropTypes.func,
    scale: PropTypes.number,
    cMapUrl: PropTypes.string,
    cMapPacked: PropTypes.bool,
    className: PropTypes.string,
};

export default FileViewer;                            