import React, {Component} from 'react';
import './MetaDataElement.css'
import {CloseCircleOutlined} from "@ant-design/icons";

class MetaDataElement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.itemId,
            name: props.item,
            onDelete: props.onDelete,
            canRemove: props.canRemove
        };
        this.handleRemove = this.handleRemove.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if(prevProps.item !== this.props.item)
            this.setState({name: this.props.item});
        if(prevProps.itemId !== this.props.itemId)
            this.setState({id: this.props.itemId});
        if(prevProps.onDelete !== this.props.onDelete)
            this.setState({onDelete: this.props.onDelete});
        if(prevProps.canRemove !== this.props.canRemove)
            this.setState({canRemove: this.props.canRemove});

    }

    render() {
        const name = this.state.name;
        return (
                <div className="pcmh-document-metadata">
                    {this.props.canRemove ?
                     <CloseCircleOutlined className="remove-item" title="Remove Item"
                                          onClick={this.handleRemove}/> : null
                    }
                    <div className="item-name">
                        {name}
                    </div>
                </div>
        );
    }

    handleRemove() {
        if(this.state.canRemove && this.state.onDelete){
            this.state.onDelete(this.state.id);
        }
    }
}

export default MetaDataElement;