import React from 'react'
import {withUser} from "../../../context/UserContext";
import {CaretLeftOutlined, CaretRightOutlined} from "@ant-design/icons";
import Announcement from "../../announcements/Announcement";

function HuddleHeader(props) {


    function next() {
        if(props.onAfter)
            props.onAfter();
    }

    function previous() {
        if(props.onBefore)
            props.onBefore();
    }

    return (
            <div className="row pcmh-content-header">
                <div className="col-md-4 text-left">
                    <h2 className="unSelectable">Daily Huddle ({props.team ? props.team.name : "Not a member of a team"})</h2>
                </div>
                <div className="col-md-4 my-auto text-center">
                    <span className="headerNav my-auto" onClick={previous}><CaretLeftOutlined/></span>
                    <span className="unSelectable selected-date">{(props.date).toDateString()}</span>
                    <span className="headerNav" onClick={next}><CaretRightOutlined/></span>
                </div>
                <div className="col-md-2 offset-md-1 offset-lg-2 text-right">
                    <Announcement/>
                </div>
            </div>

    );
}

export default HuddleHeader;