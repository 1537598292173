import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
import registerServiceWorker from "./registerServiceWorker";
import SmartPcmhClientApp from './SmartPcmhClientApp'
import {createRoot} from 'react-dom/client'

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<SmartPcmhClientApp/>);

registerServiceWorker();
