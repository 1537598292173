import styled from 'styled-components';
import { colors } from '../../../../SmartPcmhClientAppConstantsStyle';

const ResultsTableStyle = styled.div`
	.header-row {
    background-color: ${colors['--smartpcmh-green']};

		.header-cell {
			color: #ffffff !important;
		}
	}
`;

export default ResultsTableStyle;
