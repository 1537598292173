import React, {Component} from 'react';
import PatientHeader from "./PatientHeader";
import PatientProfile from "./PatientProfile";
import AssignmentsListTables from "./assigments/AssignmentsListTables";


class Patient extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patient: props.patient
        };
        this.patientSelected = this.patientSelected.bind(this);
    }


    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if(JSON.stringify(prevProps.patient) !== JSON.stringify(this.props.patient)) {
            this.setState({patient: this.props.patient});
        }
    }

    componentWillUnmount() {
        if(this.props.onPatientChanged)
            this.props.onPatientChanged(null);
        return true;
    }

    render() {
        const patient = this.state.patient;
        return (
                <div>
                    <PatientHeader onPatientSelected={this.patientSelected} patient={patient}/>
                    {this.state.patient &&
                     <div className="row" style={{overflowY: "auto", maxHeight: "80vh"}}>
                         <div className="col-lg-12 col-xl-6 my-1">
                             <PatientProfile patient={patient}/>
                         </div>
                         <div className="col-lg-12 col-xl-6 my-1">
                             <AssignmentsListTables personType={"Patient"} person={patient} history={window.history}/>
                         </div>
                     </div>
                }
            </div>
        );
    }

    patientSelected(patient) {
        if(this.props.onPatientChanged)
            this.props.onPatientChanged(patient);
    }
    
}

export default Patient;