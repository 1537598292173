var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Client } from "@stomp/stompjs";
import { Component } from "react";
import isEqual from "lodash.isequal";
var noOp = function () { };
var StompClient = /** @class */ (function (_super) {
    __extends(StompClient, _super);
    function StompClient(props) {
        var _this = _super.call(this, props) || this;
        _this.handleStompMessage = _this.handleStompMessage.bind(_this);
        _this.state = {
            stompClient: _this.getNewClient()
        };
        return _this;
    }
    StompClient.prototype.componentDidMount = function () {
        this.activateAndSubscribe(this.state.stompClient);
    };
    StompClient.prototype.componentDidUpdate = function (prevProps) {
        if (isEqual(this.props, prevProps)) {
            return; // no need to set the state
        }
        else {
            this.state.stompClient.deactivate();
            var newStompClient = this.getNewClient();
            this.activateAndSubscribe(newStompClient);
            this.setState({
                stompClient: newStompClient
            });
        }
    };
    StompClient.prototype.componentWillUnmount = function () {
        this.state.stompClient.deactivate();
    };
    StompClient.prototype.render = function () {
        return this.props.children;
    };
    StompClient.prototype.activateAndSubscribe = function (stompClient) {
        var _this = this;
        var topic = this.props.topic;
        stompClient.onConnect = function () {
            if (topic) {
                stompClient.subscribe("/" + topic, _this.handleStompMessage);
            }
        };
        stompClient.activate();
    };
    StompClient.prototype.handleStompMessage = function (stompMessage) {
        var onMessage = this.props.onMessage;
        if (onMessage) {
            onMessage(stompMessage);
        }
    };
    StompClient.prototype.getNewClient = function () {
        var _a = this.props, endpoint = _a.endpoint, debugMode = _a.debugMode, reconnectDelay = _a.reconnectDelay, heartbeatIncoming = _a.heartbeatIncoming, heartbeatOutgoing = _a.heartbeatOutgoing;
        return new Client({
            brokerURL: endpoint,
            debug: debugMode ? console.debug : noOp,
            reconnectDelay: reconnectDelay || StompClient.RECONNECT_DELAY,
            heartbeatIncoming: heartbeatIncoming || StompClient.HEARBEAT_FREQUENCY,
            heartbeatOutgoing: heartbeatOutgoing || StompClient.HEARBEAT_FREQUENCY
        });
    };
    StompClient.defaultProps = {
        debugMode: false
    };
    StompClient.RECONNECT_DELAY = 3000;
    StompClient.HEARBEAT_FREQUENCY = 30000;
    return StompClient;
}(Component));
export default StompClient;
