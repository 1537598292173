import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './NextDay.css'
import {Rest} from "../../../../util/rest";
import {withUser} from "../../../../context/UserContext";
import toast from "../../../../util/toast";

import moment from "moment";

class CallList extends Component {

    static propTypes = {
        teamId: PropTypes.number,
        callDate: PropTypes.instanceOf(Date),
        staffMember: PropTypes.object.isRequired,
    };

    static defaultProps = {
        callDate: new Date(),
    };

    constructor(props) {
        super(props);
        this.state = {
            records: [],
            callStatus: []
        }
    }

    componentDidMount() {
        this.loadCallList();
        this.loadCallStatusList();
    }

    render() {

        return (
                <div className="pcmh-nextday-container">
                    {/*{JSON.stringify(this.props.user)}*/}
                    <table>
                        <thead>
                        <tr>
                            <th>Patient Name</th>
                            <th>Phone Number</th>
                            <th>Email</th>
                            <th>Reason for Call</th>
                            <th>Status</th>
                            <th>Caller</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.records.map((record, idx) => (
                                                           <tr key={`callList-${record.id}`}>
                                                               <td>{record.patient.fullName}</td>
                                                               <td>{record.patient.phone}</td>
                                                               <td>{record.patient.email}</td>
                                                               <td>{record.reasonDesc || record.reason}</td>
                                                               <td className="smartpcmh-editor">
                                                                   <select value={record.status}
                                                                           onChange={(evt) => this.changeStatus(idx, evt.target.value)}
                                                                   >
                                                                       {
                                                                           this.state.callStatus.map(it => (
                                                                                   <option value={it.value}>{it.description}</option>
                                                                           ))
                                                                       }
                                                                   </select>
                                                               </td>
                                                               <td>{record.callerStaffMember && [
                                                                   record.callerStaffMember.title,
                                                                   record.callerStaffMember.firstName,
                                                                   // record.callerStaffMember.middleInitial,
                                                                   record.callerStaffMember.firstLastName,
                                                                   // record.callerStaffMember.secondLastName,
                                                                   // record.callerStaffMember.suffix,
                                                               ].join(' ')}</td>
                                                           </tr>
                                                   )
                            )
                        }
                        </tbody>
                    </table>
                </div>
        );
    }

    changeStatus(recordIdx, newStat) {
        if(this.state.records[recordIdx] === newStat) {
            return;
        }

        let records = [...this.state.records];
        let updatedRecord = {
            ...records[recordIdx],
            status: newStat,
            callerStaffMember: this.props.staffMember,
        };

        Rest.authFetch(this.props.user, "/rest/huddle/updateCallListItem", {
            method: "PUT",
            body: JSON.stringify(updatedRecord)
        }).then(response => {
            if(response && response.id > 0) {
                records[recordIdx] = updatedRecord;
                this.setState({
                                  records: records
                              });
            }
            else {
                toast.error("Error updating Call List");
            }
        });


    }

    loadCallList() {
        if(this.props.callDate) {
            const formattedDate = moment(this.props.callDate).format("yyyyMMDD");
            let path = `/rest/huddle/calllist/${this.props.teamId}/${formattedDate}`;
            Rest.authFetch(this.props.user, path)
                    .then(response => {
                        this.setState({records: response});
                    })
                    .catch(err => {
                        console.error(err);
                    })
        }
    }

    loadCallStatusList() {
        Rest.authFetch(this.props.user, '/rest/reference/callstatus')
                .then(response => {
                    this.setState({callStatus: response});
                })
                .catch(err => {
                    console.error(err);
                })
    }

}

export default withUser(CallList);