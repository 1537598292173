import React, {Component} from 'react';
import './Today.css'

const MAX_SCORE = 5;

class AppointmentPatient extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patient: props.patient,
            score: 0
        };
    }

    componentDidMount() {
        this.setState({patient: this.props.patient})
    }


    render() {

        const patient = this.state.patient;//?this.state.patient:{fullname:"",dob:"",gender:"",mrn:"",insurance:""};
        const score = (Math.random() * (MAX_SCORE - 1)) + 1;      //Between 1-5
        // const score = this.state.score?this.state.score:0;
        const backColor = AppointmentPatient.percentageToHsl(score / MAX_SCORE, 120, 0);
        return (
                <div className="container-fluid">
                    {
                        patient ?
                        <div className="row">
                            <div className="col-10">
                                <div className="pcmh-today-name row">{patient.fullName}</div>
                                <div className="pcmh-today-identifiers row">DOB: {patient.dob} |
                                    Gender: {patient.gender}</div>
                                <div className="pcmh-today-identifiers row">MRN: {patient.internalId} |
                                    Ins: {patient.insurance ? patient.insurance : "N/A"}</div>
                            </div>
                            <div className="col-2 my-auto">
                                {/*<div className="risk-score" style={{background: backColor}}>*/}
                                {/*    {score.toLocaleString(undefined, {minimumFractionDigits: 1,maximumFractionDigits:1})}*/}
                                {/*</div>*/}
                            </div>
                        </div>
                                :
                        <div className="available">
                            <div>Available</div>
                        </div>
                    }
                </div>
        );
    }

    static percentageToHsl(percentage, hue0, hue1) {
        var hue = (percentage * (hue1 - hue0)) + hue0;
        return 'hsl(' + hue + ', 100%, 50%)';
    }
}

export default AppointmentPatient;