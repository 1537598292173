import React, {useEffect} from 'react'
import AgendaItem from "./AgendaItem";
import {withUser} from "../../../../context/UserContext";
import {Rest} from "../../../../util/rest";
import {toast} from "react-toastify";


function AgendaItems(props) {


    function saveItem(item) {
        Rest.authFetch(props.user, "/rest/agenda/item", {
            method: "PUT",
            body: JSON.stringify(item)
        })
                .then(response => {
                    if(response && response.id > 0) {
                        let items = props.agenda.items;
                        let index = items.findIndex((existingItem => existingItem.id === item.id));
                        if(index >= 0) {
                            items[index] = response;
                        }
                        else {
                            index = items.findIndex((existingItem => existingItem.id === 0));
                            if(index >= 0)
                                items[index] = response;
                            else
                                items.push(response);
                        }
                        if(props.onUpdate)
                            props.onUpdate(items);
                    }
                    else {
                        toast.error("Error updating Item");
                    }
                });

    }

    return (
            <div>
                <div>
                    {props.agenda && props.agenda.items &&
                     props.agenda.items.filter(item => item.completed === false).map((item) =>
                                                                                             <AgendaItem item={item}
                                                                                                         key={item.id}
                                                                                                         onItemChanged={saveItem}/>
                     )
                    }
                </div>
                <div>
                    <div className="row completedItemPanel">
                        <h3 className="unSelectable">Completed</h3>
                        <div className="col-md-12">
                            {
                                    props.agenda && props.agenda.items &&
                                    props.agenda.items.filter(item => item.completed === true).map((item) =>
                                                                                                           <AgendaItem
                                                                                                                   item={item}
                                                                                                                   key={item.id}
                                                                                                                   onItemChanged={saveItem}/>
                                    )
                            }
                        </div>
                    </div>
                </div>
            </div>
    );
}

export default withUser(AgendaItems);