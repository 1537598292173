import React, { useState } from 'react';
import { withUser } from "../../../../context/UserContext";
import Checkbox from "@mui/material/Checkbox";
import { DeleteOutlined, EditOutlined, BarsOutlined } from "@ant-design/icons";
import TextField from "@mui/material/TextField";
import { Rest } from "../../../../util/rest";
import {toast} from 'react-toastify';
import { MenuItem } from "@mui/material";
import HealthDataElementOptionsDialog from "./HealthDataElementOptionsDialog";

const dataTypes = {
    STRING: "Text (255 characters)",
    TEXT: "Free Text",
    INT: "Number",
    DECIMAL: "Decimal",
    BOOLEAN: "Boolean",
    DATE: "Date",
    TIME: "Time",
    DATETIME: "Date/Time",
    OPTIONS: "Options"
}

function HealthDataElement(props) {
    const [healthElement, setHealthElement] = useState(props.healthElement)
    const [nameEditMode, setNameEditMode] = useState(false);
    const [dataTypeEditMode, setDataTypeEditMode] = useState(false);
    const [editOptions, setEditOptions] = useState(false);
    const [name, setName] = useState(props.healthElement ? props.healthElement.name : null);
    const [dataType, setDataType] = useState(props.healthElement ? props.healthElement.dataType : null);
    const [required, setRequired] = useState(props.healthElement ? props.healthElement.required : false);

    function endEditing() {
        healthElement.name = name;
        healthElement.dataType = dataType;
        healthElement.required = required;
        setNameEditMode(false);
        setDataTypeEditMode(false);
        saveHealthDataElement();
    }

    function saveHealthDataElement() {
        Rest.authFetch(props.user, `/rest/health/element/type-class/health-data-element`, {
            method: "PUT",
            body: JSON.stringify(healthElement)
        }).then(response => {
            if (response) {
                setHealthElement(response);
            }
            else {
                toast.error("Error saving Attribute");
            }
        });
    }

    function handleOptionsClose(action, data) {
        setEditOptions(false);
    }

    return (
        healthElement &&
        <div className="row header">
            <div className="col-3 col-first" onClick={() => setNameEditMode(true)}>
                {!nameEditMode ?
                    <span onClick={() => setNameEditMode(!nameEditMode)}>{healthElement.name}</span>
                    :

                    <TextField
                        autoComplete="off"
                        autoFocus
                        fullWidth
                        onFocus={(event) => event.target.select()}
                        margin="dense"
                        name="name"
                        defaultValue={healthElement.name}
                        onChange={e => setName(e.target.value)}
                        // label="Name"
                        type="text"
                        className="textField textBox"
                        variant="outlined"
                        required
                        onBlur={() => endEditing()}
                    />
                }
            </div>
            <div className="col-4 col-middle">
                {!dataTypeEditMode ?
                    <span onClick={() => setDataTypeEditMode(true)}>{dataTypes[healthElement.dataType]}</span> :
                    <TextField
                        autoComplete="off"
                        select
                        autoFocus
                        fullWidth
                        margin="dense"
                        name="dataType"
                        defaultValue={healthElement.dataType}
                        onChange={e => setDataType(e.target.value)}
                        className="textField textBox"
                        variant="outlined"
                        required
                        onBlur={() => endEditing()}
                    >
                        {
                            Object.keys(dataTypes).map((key) => {
                                return <MenuItem value={key}>{dataTypes[key]}</MenuItem>
                            })
                        }
                    </TextField>

                }
            </div>
            <div className="col-3 col-middle">
                <div className={"row"}>
                    <div className={"col-10"} style={{ textOverflow: "ellipsis" }}>
                        {healthElement.options.map((option) => <span>{option}</span>)}&nbsp;
                    </div>
                    {dataType === 'OPTIONS' &&
                        <div className={"col-2 icon text-right"} onClick={() => setEditOptions(true)}>
                            <EditOutlined title="Edit Options" />
                            <HealthDataElementOptionsDialog options={healthElement.options} open={editOptions}
                                handleClose={handleOptionsClose} />
                        </div>}
                </div>
            </div>
            
            <div className="col-1 col-middle">
                <Checkbox
                    classes={{ root: 'Checkbox', checked: "checked" }}
                    name={"required"}
                    value={required}
                    onChange={(event) => {
                        setRequired(event.target.value);
                        endEditing();
                    }}
                />
            </div>
            <div className={"col-1 col-last icon"}>
                <DeleteOutlined title="Delete Attribute" style={{ margin: "6px" }} />
            </div>
        </div>

    );

}


export default withUser(HealthDataElement);