import styled from 'styled-components';

const ContentStyle = styled.div`
    .contentForm {
        .input-group {
            display: flex;
            justify-content: space-between;

            .input-group-span {
                display: flex;
                align-items: center;
                flex-basis: 33%;

                .icon {
                    font-size: 25px;
                    margin-left: 2px;
                    width: 10%;
                }
            }
        }
        .textField {
            color: #a9a9a9 !important;
            border-color: rgba(226, 226, 226, 0.75) !important;
            margin-top: 8px;
            margin-bottom: 8px;
            width: 90%;
        }
    
        .textField.title-input {
            width: 100%;
        }
    }

    .contentImages {
        display: flex;
        justify-content: space-evenly;

        .content-document {
            text-align: center;

            .content-document-div {
                display: flex;
                justify-content: center;
                border: 1px solid lightgrey;
                margin-bottom: 10px;

                .content-document-display {
                    height: 250px;
                    width: 200px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
    
                    .empty-document {
                        color     : rgba(226, 226, 226, 0.75) !important;
                        font-size : 150px;
                    }

                    .document-viewer {
                        display: block;
                        max-height: 250px;
                        max-width: 200px;
                    }
                }
            }

            .editorButton {
                width: 190px;
            }

            .upload-input {
                display: none;
            }
        }
    }

    .chips-container-outter {
        min-height: 65px;
        display: flex;

        .chips-container-inner {
            border: 1px solid hsla(0,0%,88.6%,.75)!important;
            border-radius: 4px;
            margin: 10px 0;
            padding: 7px 2px 2px;
            width: 100%;

            .chips-container-title {
                margin: -17px 0 0 4px;
                padding: 0 5px;
                font-size: 11.5px;
                background: #fff;
                display: block;
                position: absolute;
                border: none;
            }

            .chip {
                background: #edf3ed;
                border: 1px solid #cfcfcf;
                margin: 0 .5px;
            }
        }
    }
`;

export default ContentStyle;
