import React, {useEffect, useState} from 'react'
import {withUser} from "../../../../context/UserContext";
import '../DataSet/DataSet.css'
import {Button, TextField} from "@mui/material";
import {Rest} from "../../../../util/rest";
import {toast} from "react-toastify";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {
    ArrowDropDownOutlined,
    ArrowDropUpOutlined,
    DeleteOutline,
    EditOutlined,
    ReorderOutlined
} from "@mui/icons-material";
import {reorder} from "../../../../util/reorder";
import ResultsTable from '../PopulationSet/ResultsTable';
import {isEqual} from 'lodash';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FilterEditor from "./FilterEditor";
import FilterElementsView from "../DataSet/FilterElementsView";

const operands = {
    "EQUALS": "equals",
    "LESS_THAN": "less than",
    "LESS_THAN_OR_EQUAL_TO": "less than or equal to",
    "GREATER_THAN": "greater than",
    "GREATER_THAN_OR_EQUAL_TO": "greater than or equal to",
    "CONTAINS": "contains",
    "BETWEEN": "between",
    "LAST_N_MONTHS": "last months"
}

function PopulationSetEditor(props) {

    const [populationSet, setPopulationSet] = useState();
    const [isLoading, setLoading] = useState(false);
    const [dataSets, setDataSets] = useState([{id: 0, name: ""}]);
    const [selectedDataSet, setSelectedDataSet] = useState(0);
    const [showFilters, setShowFilters] = useState(true);
    const [showResults, setShowResults] = useState(false);
    const [filters, setFilters] = useState([]);
    const [activeFilter, setActiveFilter] = useState();

    useEffect(() => {
        getDataSets().then();
        if(props.dataSet)
            getActivePopulationSet(props.dataSet.id).then();
    }, [])

    const getDataSets = async () => {
        const response = await Rest.authFetch(
                props.user,
                `/rest/dataset/search/?size=10000&type=POPULATIONSET`,
                {
                    method: "GET"
                }
        );
        if(response.status === 401 || response.status === 403)
            toast.error("Unauthorized!")
        else if(response.status === 500)
            toast.error("Error Getting Datasets")
        else {
            const dataSets = response.content;
            dataSets.unshift({id: 0, name: ""});
            setDataSets(dataSets);
        }
    };
    const getActivePopulationSet = async (id) => {
        if(id > 0) {
            setLoading(true);

            const response = await Rest.authFetch(
                    props.user,
                    `/rest/dataset/${id}`,
                    {
                        method: "GET"
                    }
            );
            if(response.status === 401 || response.status === 403)
                toast.error("Unauthorized!")
            else if(response.status === 500)
                toast.error("Error Getting Dataset")
            else {
                setPopulationSet(response);
            }
            setLoading(false);
        }
        else {
            setPopulationSet(props.dataSet);
        }
    };
    

    const newFilter = () => {
        const filter = {
            id: 0,
            columnId: 0,
            attributeId: 0,
            attributeDataType: "STRING",
            attributeName: "",
            groupId: 0,
            order: populationSet.columns[0].filters ? populationSet.columns[0].filters.length : 0
        };
        const filters = populationSet.columns[0].filters ? populationSet.columns[0].filters : [];
        filters.push(filter);
        populationSet.columns[0].filters = filters;
    };

    const cancelColumnEdit = () => {
        setActiveFilter(null);
    };

    const updateColumn = (column) => {
        const columns = populationSet.columns;
        const index = findColumnIndex(columns, column);
        if(index >= 0) {
            saveColumn(column, index).then();
        }
    };

    const removeColumn = (column) => {
        const columns = populationSet.columns;
        const index = findColumnIndex(columns, column);
        if(index >= 0) {
            columns.splice(index, 1);
            setPopulationSet({...populationSet, columns: columns});
        }
        if(column.id > 0) {
            deleteColumn(column.id).then();

        }
    }

    const findColumnIndex = (columns, column) => {
        return columns.findIndex((c) => c.id === column.id);
    }

    const saveDataSet = async () => {

        const saveDS = {...populationSet};
        // saveDS.columns = [];
        // saveDS.filters = [];
        const response = await Rest.authFetch(
                props.user,
                `/rest/dataset/`,
                {
                    method: "PUT",
                    body: JSON.stringify(saveDS)
                }
        );

        if(response.status === 401 || response.status === 403) toast.error("Unauthorized!")
        else if(response.status === 500)
            toast.error("Error Saving Dataset")

        else {
            setPopulationSet(response);
        }
    };

    const saveColumn = async (column, index) => {
        const response = await Rest.authFetch(
                props.user,
                `/rest/dataset/column`,
                {
                    method: "PUT",
                    body: JSON.stringify(column)
                }
        );

        if(response.status === 401 || response.status === 403) toast.error("Unauthorized!")
        else if(response.status === 500)
            toast.error("Error Saving Column")

        else {
            const columns = populationSet.columns;
            columns[index] = column;
            setPopulationSet({...populationSet, columns: columns});
            setActiveFilter(null);
        }

    }

    const deleteColumn = async (columnId) => {
        const response = await Rest.authFetch(
                props.user,
                `/rest/dataset/column/${columnId}`,
                {
                    method: "DELETE"
                }
        );
        if(response.status === 401 || response.status === 403) toast.error("Unauthorized!")
        else if(response.status === 500)
            toast.error("Error Deleting Column")
    }

    return (
            <div className="dataset-editor container-fluid">
                <h4>Population Set</h4>
                {populationSet &&
                 <>
                     <div className="row">
                         <div className="col-5">
                             <TextField
                                     fullWidth
                                     margin="dense"
                                     label="Population Set Name"
                                     name="name"
                                     variant="outlined"
                                     autoComplete="off"
                                     size="small"
                                     onChange={(e) => setPopulationSet({...populationSet, name: e.target.value})}
                                     value={populationSet.name}
                                     maxLength="100"
                                     autoFocus
                                     onFocus={event => { event.target.select(); }}
                                     onBlur={() => saveDataSet().then()}
                             />
                         </div>
                         <div className="col-6">
                             <TextField
                                     multiline
                                     maxRows={2}
                                     fullWidth
                                     margin="dense"
                                     label="Description"
                                     name="description"
                                     variant="outlined"
                                     autoComplete="off"
                                     size="small"
                                     value={populationSet.description}
                                     onChange={(e) => setPopulationSet({...populationSet, description: e.target.value})}
                                     maxLength="500"
                                     onBlur={() => saveDataSet().then()}
                             />
                         </div>
                         <div className="col-1">
                             <Button className="editorButton" size="small"
                                     onClick={() => {if(props.cancelEdit) props.cancelEdit()}}>Exit</Button>
                         </div>
                     </div>
                     <div className="row divider"/>
                     <div className="row">
                         <div className="col-12">
                             <div className="row align-content-center" style={{marginBottom: "5px"}}>
                                 <div className="col-11">
                                     {showFilters &&
                                      <ArrowDropUpOutlined style={{fontSize: "1.3vw", cursor: "pointer"}}
                                                           onClick={() => setShowFilters(!showFilters)}/>
                                     }
                                     {!showFilters &&
                                      <ArrowDropDownOutlined style={{fontSize: "1.3vw", cursor: "pointer"}}
                                                             onClick={() => setShowFilters(!showFilters)}/>
                                     }
                                     <span className="unSelectable"
                                           style={{marginLeft: "4px", fontSize: "1.4vw"}}>Filters</span>
                                 </div>
                                 <div className="col-1 edit align-content-end">
                                     <Button className="editorButton" size="small" onClick={() => newFilter()}>
                                         New Filter
                                     </Button>

                                 </div>
                             </div>
                             {showFilters &&
                              <div id="elements-list" className="row">
                                  <FilterElementsView filterGroups={(populationSet && populationSet.columns && 
                              populationSet.columns.length>0 && populationSet.columns[0].filterGroups)? populationSet.columns[0].filterGroups :[]}
                                                      onUpdate={updateFilter} onDelete={removeFilter}/>
                                 
                                  {/*<DragDropContext onDragEnd={reorderElements}>*/}
                                  {/*    <Droppable droppableId="droppable" direction="vertical">*/}
                                  {/*        {(droppableProvided) => (*/}
                                  {/*                <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps} >*/}
                                  {/*                    {populationSet && populationSet.columns && populationSet.columns.length > 0 && populationSet.columns.map((filter, index) => (*/}
                                  {/*                            <Draggable key={index + 1} draggableId={String(index)}*/}
                                  {/*                                       index={index}>*/}
                                  {/*                                {(draggableProvided, snapshot) => (*/}
                                  {/*                                        <div className={"row column" + (activeFilter && filter.id === activeFilter.id ? " highlighted" : "")}*/}
                                  {/*                                             key={index + 1}*/}
                                  {/*                                             ref={draggableProvided.innerRef} {...draggableProvided.draggableProps}>*/}
                                  {/*                                            <div className="element-reorder col-auto align-self-center" {...draggableProvided.dragHandleProps}>*/}
                                  {/*                                                <ReorderOutlined/>*/}
                                  {/*                                            </div>*/}
                                  {/*                                            <div className="col-10 align-self-center element-text">*/}
                                  {/*                                                {generateFilterDescription(filter)}*/}
                                  {/*                                            </div>*/}
                                  {/*                                            <div className="element-actions col-1 align-self-center">*/}
                                  {/*                                                <div className="row justify-content-end">*/}
                                  {/*                                                    <div className="col-1 offset-7">*/}
                                  {/*                                                        <EditOutlined className="edit"*/}
                                  {/*                                                                      onClick={() => setActiveFilter(filter)}/>*/}
                                  {/*                                                    </div>*/}
                                  {/*                                                    <div className="col-1">*/}
                                  {/*                                                        <DeleteOutline*/}
                                  {/*                                                                className="edit"*/}
                                  {/*                                                                onClick={() => removeColumn(filter)}/>*/}
                                  {/*                                                    </div>*/}
                                  {/*                                                </div>*/}
                                  {/*                                            </div>*/}
                                  {/*                                        </div>*/}
                                  {/*                                )}*/}
                                  {/*                            </Draggable>*/}
                                  {/*                    ))}*/}
                                  {/*                    {populationSet && populationSet.columns && populationSet.columns.length === 0 &&*/}
                                  {/*                     <div style={{fontSize: "1.2vw", marginLeft: "1.2vw"}}>*/}
                                  {/*                         No Filters*/}
                                  {/*                     </div>*/}
                                  {/*                    }*/}
                                  {/*                    {droppableProvided.placeholder}*/}
                                  {/*                </div>*/}
                                  {/*        )}*/}
                                  {/*    </Droppable>*/}
                                  {/*</DragDropContext>*/}
                              </div>
                             }
                             {activeFilter &&
                              <div>
                                  <FilterEditor column={activeFilter} cancelColumnEdit={cancelColumnEdit}
                                                onUpdate={updateColumn}/>
                              </div>
                             }
                         </div>
                     </div>
                     <div className="row divider"/>
                     <div className="row align-content-center" style={{marginBottom: "5px"}}>
                         <div className="col-11">
                             {showResults &&
                              <ArrowDropUpOutlined style={{fontSize: "1.3vw", cursor: "pointer"}}
                                                   onClick={() => setShowResults(!showResults)}/>
                             }
                             {!showResults &&
                              <ArrowDropDownOutlined style={{fontSize: "1.3vw", cursor: "pointer"}}
                                                     onClick={() => setShowResults(!showResults)}/>
                             }
                             <span className="unSelectable"
                                   style={{marginLeft: "4px", fontSize: "1.4vw"}}>Results</span>
                         </div>
                     </div>
                     {showResults &&
                      <>
                          <div className="row" style={{marginTop: "10px", marginBottom: "10px"}}>
                              <div className="col-3">
                                  <FormControl fullWidth>
                                      <InputLabel id="data-set-label">Data Set</InputLabel>
                                      <Select
                                              labelId="data-set-label"
                                              size="small"
                                              value={selectedDataSet}
                                              onChange={(e) => setSelectedDataSet(e.target.value)}
                                      >
                                          {dataSets &&
                                           dataSets.map((ds) => (
                                                   <MenuItem value={ds.id}>{ds.name}</MenuItem>
                                           ))
                                          }
                                      </Select>
                                  </FormControl>
                              </div>
                              <div className="col-2 offset-7">
                                  <Button size="small" className="editorButton" disabled={!selectedDataSet}>Show
                                      Results</Button>
                              </div>
                          </div>
                          <div className="row divider"/>
                          <>
                              {populationSet && isEqual(populationSet.dataSetType, 'POPULATIONSET') && (
                                      <ResultsTable data={dataTest}/>
                              )}

                          </>
                      </>
                     }
                 </>
                }
                {isLoading &&
                 <div className="text-center my-5">
                     <button className="btn btn-primary-color btn-lg rounded shadow-sm" type="button" disabled>
                         <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"/>
                         Loading...
                     </button>
                 </div>
                }
            </div>
    )


}

const dataTest = {
    "population_set": [
        {
            "row_id": 1,
            "row_order": 1,
            "patient": {
                "patient_id": "123456789"
            },
            "columns": [
                {
                    "column_id": 1,
                    "column_name": "Blood Test",
                    "values": [
                        {
                            "health_element_attribute_id": 268,
                            "health_element_datatype": "STRING",
                            "health_element_attribute_name": "Test Name",
                            "display": "MOUSEOVER",
                            "value": "x10E3/ul",
                            "instance_id": "2"
                        },
                        {
                            "health_element_attribute_id": 263,
                            "health_element_datatype": "STRING",
                            "health_element_attribute_name": "Testing Still",
                            "display": "IN_COLUMN",
                            "value": "WBC",
                            "patient_id": "123456789",
                            "instance_id": "2"
                        }
                    ]
                },
                {
                    "column_id": 2,
                    "column_name": "Temperature",
                    "values": [
                        {
                            "health_element_attribute_id": 138,
                            "health_element_datatype": "DATETIME",
                            "health_element_attribute_name": "Date Taken",
                            "display": "IN_COLUMN",
                            "value": "10-05-2022 10:49 AM",
                            "patient_id": "123456789",
                            "instance_id": "2"
                        },
                        {
                            "health_element_attribute_id": 139,
                            "health_element_datatype": "DECIMAL",
                            "health_element_attribute_name": "Value",
                            "display": "IN_COLUMN",
                            "value": "98.9",
                            "patient_id": "123456789",
                            "instance_id": "2"
                        },
                        {
                            "health_element_attribute_id": 140,
                            "health_element_datatype": "STRING",
                            "health_element_attribute_name": "Unit",
                            "display": "IN_COLUMN",
                            "value": "F",
                            "patient_id": "123456789",
                            "instance_id": "2"
                        }
                    ]
                }
            ]
        }
    ]
}

export default withUser(PopulationSetEditor)