import React, {useEffect, useRef, useState} from 'react'
import {withUser} from "../../../../context/UserContext";
import {Droppable} from "react-beautiful-dnd";
import AttributeFilter from "./AttributeFilter";
import {Select} from "antd";
import {Rest} from "../../../../util/rest";
import {toast} from "react-toastify";
import './DataSet.css';

export const groupingOptions = [{value: 'AND', label: "And"}, {value: 'OR', label: "Or"}];

function FilterGroup(props) {

    const [filterGroup, setFilterGroup] = useState(props.filterGroup);
    const previousFilterGroup = useRef(props.filterGroup);
    useEffect(() => {
        setFilterGroup(props.filterGroup)
        previousFilterGroup.current = props.filterGroup;
    }, []);

    useEffect(() => {
        if(JSON.stringify(filterGroup) !== JSON.stringify(previousFilterGroup.current)) {
            saveGroup().then();
        }
    }, [filterGroup])

    function setGrouping(grouping) {
        setFilterGroup({...filterGroup, grouping: grouping});
    }

    function filterSelected(filter) {
        //TODO: Implement
    }

    async function saveGroup() {
        const response = await Rest.authFetch(
                props.user,
                `/rest/dataset/column/filterGroup`,
                {
                    method: "PUT",
                    body: JSON.stringify(filterGroup)
                }
        );

        if(response.status === 401 || response.status === 403) toast.error("Unauthorized!")
        else if(response.status === 500)
            toast.error("Error Saving Filter Group")

        else {
            setFilterGroup(response);
            previousFilterGroup.current = response;
        }
    }

    return (
            <div className="row">

                {filterGroup && props.index > 0 &&
                 <div>    <Select label="Grouping" options={groupingOptions} style={{width: "75px",margin:"10px 0 0 0"}}
                                  value={filterGroup.grouping} onChange={(e, newValue) => setGrouping(newValue.value)}/>
                 
                 </div>}
                <div className={ "row " + ((filterGroup && filterGroup.filters && filterGroup.filters.length > 1) ? "filter-group" : "filter-group-single")}>
                    <Droppable droppableId="droppable-filter-groups" direction="vertical">
                        {(droppableProvided) => (
                                <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps} >
                                    {filterGroup && filterGroup.filters &&
                                     filterGroup.filters.sort((a, b) => a.order - b.order).map((filter, index) => (
                                             <>

                                                 <AttributeFilter
                                                         key={index}
                                                         index={index}
                                                         filter={filter}
                                                         filterGroup={filterGroup}
                                                         onDelete={props.onDelete}
                                                         onUpdate={props.onUpdate}
                                                         linked={filterGroup.filters.length > 1}
                                                         onSelect={filterSelected}/>
                                             </>
                                     ))
                                    }
                                    {droppableProvided.placeholder}
                                </div>
                        )}
                    </Droppable>
                </div>
            </div>
    )
}

export default withUser(FilterGroup);
