import React, {Component} from 'react';
import './AgendaItem.css'
import {withUser} from "../../../../context/UserContext";

class AgendaItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: props.item
            
        };
        this.toggleCompleted = this.toggleCompleted.bind(this);
    }

    /* no-unused-expressions: 0 */
    render() {
        const item = this.state.item;
        return (
                <div className="row agendaItemRow">
                    <div className="col-md-1 agendaItemOrder">{item.itemOrder}</div>
                    <div className="col-md-10 agendaItemName">{item.name}</div>
                    <div className="col-md-1">
                        <span className="completedBox" onClick={this.toggleCompleted}>
                        {item.completed ?
                         <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                             <path d="M9.9 1.414L8.486 0L4.95 3.536L1.414 0L0 1.414L3.536 4.95L0 8.486L1.414 9.9L4.95 6.364L8.486 9.9L9.9 8.486L6.364 4.95L9.9 1.414Z"/>
                         </svg>
                                        :
                         <svg width="16" height="13" viewBox="0 0 16 13" xmlns="http://www.w3.org/2000/svg">
                             <path d="M5.558 12.0708C5.14123 12.0708 4.72528 11.9121 4.407 11.5938L0 7.18681L2.30199 4.88401L5.558 8.14001L13.698 0L16 2.30281L6.709 11.5938C6.39072 11.9121 5.97477 12.0708 5.558 12.0708Z"/>
                         </svg>
                        }
                        </span>
                    </div>
                </div>
        );
    }

    toggleCompleted() {
        this.setState((state) => ({
            item: {
                ...state.item,
                completed: !state.item.completed
            }
        }), () => (
                this.props.onItemChanged ? this.props.onItemChanged(this.state.item) : null
        ));
    }
}

export default withUser(AgendaItem);