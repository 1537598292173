import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { forEach, isEqual, map, reduce, uniq } from 'lodash';

import {
	Paper,
	Popover,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
	TableCell
} from '@mui/material';

import ResultsTableStyle from './ResultsTableStyle';

const MouseOverPopover = ({ header, headerColumns, cellData, data }) => {
	const [anchorEl, setAnchorEl] = useState(null);

	const handlePopoverOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	const hidePopup = reduce(cellData, (results, element) => {
		if (isEqual(element.display, 'MOUSEOVER')) return true;
		return results;
	}, false);

	return (
		<div>
			<div onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
				{map(cellData, (d) => (
					<div key={d.attribute_id}>
						{isEqual(d.display, 'IN_COLUMN') && (
							<div><b>{d.health_element_attribute_name}</b>: {d.value}</div>
						)}
					</div>
				))}
			</div>
			{hidePopup && (
				<Popover
					style={{ pointerEvents: "none" }}
					open={open}
					anchorEl={anchorEl}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center"
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "right"
					}}
					onClose={handlePopoverClose}
					disableRestoreFocus
				>
					<div style={{ padding: '20px', minWidth: '150px' }}>
						<div>
							{map(cellData, (d) => (
								<div key={d.attribute_id}>
									{isEqual(d.display, 'MOUSEOVER') && (
										<div>{d.health_element_attribute_name}: {d.value}</div>
									)}
								</div>
							))}
						</div>
					</div>
				</Popover>
			)}
		</div>
	);
}

const ResultsTable = ({ data }) => {

	const headerColumns = uniq(reduce(data.population_set, (results, element) => {
		const patientHealthElements = map(element.columns, 'column_name');
		return [...results, ...patientHealthElements];
	}, []));

	const reshapedData = reduce(data.population_set, (results, element) => {
		const obj = {}
		forEach(element.columns, (element) => {
			obj[element.column_name] = element.values;
		});

		results.push(obj);
		return results;
	}, []);


	return (
		<ResultsTableStyle>
			<TableContainer component={Paper}>
				<Table border="1">
					<TableHead className="header-row">
						<TableRow>
							{map(headerColumns, (headerRow) => (
								<TableCell   style={{borderRight:"1px solid #000"}}
									key={headerRow}
									className="header-cell">
									{headerRow}
								</TableCell>
							))}
						</TableRow>

					</TableHead>
					<TableBody>
						{map(reshapedData, (dataRow, index) => (
							<TableRow key={index}>
								{map(headerColumns, (header) => (
									<TableCell key={header} style={{borderRight:"1px solid #000"}}>
										<MouseOverPopover
											header={header}
											cellData={dataRow[header]}
											data={dataRow}
											headerColumns={headerColumns} />
									</TableCell>
								))}
							</TableRow>
						))}
					</TableBody>

				</Table>
			</TableContainer>
		</ResultsTableStyle>
	)
}

ResultsTable.propTypes = {}

export default ResultsTable