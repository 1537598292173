import React, {Component} from 'react';
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {Rest} from "../../../../util/rest";
import toast from "../../../../util/toast";
import {withUser} from "../../../../context/UserContext";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Avatar from "@mui/material/Avatar";
import DialogActions from "@mui/material/DialogActions";
import './TrainingAdmin.css';
import FormControl from "@mui/material/FormControl";
import {FormLabel} from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";

class Training extends Component {
    constructor(props) {
        super(props);
        this.state = {
            training: props.training,
            dialogOpen: false,
            dialogValue: {},
            prerequisites: [],
            documents: []
        };

        this.save = this.save.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.getDocuments = this.getDocuments.bind(this);
        this.uploadPhoto = this.uploadPhoto.bind(this);
        this.handleDialogSubmit = this.handleDialogSubmit.bind(this);
        this.handlePrerequisiteDelete = this.handlePrerequisiteDelete.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.training !== this.props.training) {
            this.setState({training: this.props.training})
        }
    }

    componentDidMount() {
        this.getDocuments()
    }

    render() {
        return (
            <div className="training-box container-fluid">
                <div className="row">
                    <div className="col-md-12 col-lg-5 col-xl-4 name my-auto d-flex justify-content-start" onClick={() => this.setState({dialogOpen: true, dialogValue: this.state.training })} title={"Edit Training"}>
                        <Avatar alt={this.state.training.name} src={this.state.training.imageType + "," + this.state.training.photo} />
                        <span className={"ml-2"}>{this.state.training.name}</span>
                    </div>
                    <div className="col-md-8 col-lg-5 col-xl-6 multiple-autocomplete-dialog">
                        <Autocomplete
                            multiple
                            fullWidth
                            onChange={(event, newValue) => this.handleChange({target: {name: "prerequisites", value: newValue}})}
                            name={"prerequisites"}
                            value={this.state.training.prerequisites}
                            onInputChange={(event, searchTerm) => this.searchPrerequisites(searchTerm, this.state.dialogValue.id)}
                            options={this.state.prerequisites}
                            getOptionLabel={(option) => option.name}
                            filterSelectedOptions
                            renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => (
                                    <Chip
                                        label={option.name}
                                        {...getTagProps({ index })}
                                        onDelete={() => this.handleChange({target: {name: "prerequisites", value: this.state.dialogValue.prerequisites.filter((p) => p.id !== option.id)}}) }
                                        className={"chip-item"}
                                        variant={"outlined"}
                                        avatar={<Avatar alt={option.name} src={option.imageType + ',' + option.photo} />}
                                    />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField style={{backgroundColor: "transparent"}} {...params} variant="standard" label="Prerequisites" />
                            )}
                        />
                    </div>
                    <div className="col-md-2 col-lg-1 edit my-auto" onClick={() => this.setState({dialogOpen: true, dialogValue: this.state.training})} title="Edit">
                        <EditOutlined title="Edit Training"/>
                    </div>
                    <div className="col-md-2 col-lg-1 edit my-auto" onClick={() => this.props.onDelete(this.state.training.id)}>
                        <DeleteOutlined title="Delete Training"/>
                    </div>
                </div>

                <Dialog open={this.state.dialogOpen} fullWidth={true} onClose={this.handleClose} className={"training-dialog"}>
                    <form onSubmit={this.handleDialogSubmit}>
                        <DialogTitle classes={{root: "editorHeader"}}>Edit Training</DialogTitle>
                        <DialogContent>
                            <div className={"row"}>
                                <div className={"col-sm-7"}>
                                    <div className={"row"}>
                                        <div className={"col-sm-12"}>
                                            <TextField
                                                fullWidth
                                                autoFocus={true}
                                                margin="dense"
                                                onFocus={(event) => event.target.select()}
                                                name={"name"}
                                                value={this.state.dialogValue.name}
                                                label="Name"
                                                onChange={this.handleChange}
                                                className="textField"
                                                variant="outlined"
                                            />
                                        </div>
                                        <div className={"col-sm-12"}>
                                            <TextField
                                                fullWidth
                                                margin="dense"
                                                onFocus={(event) => event.target.select()}
                                                name={"keywords"}
                                                value={this.state.dialogValue.keywords}
                                                label={"Keywords"}
                                                onChange={this.handleChange}
                                                className="textField"
                                                variant="outlined"
                                            />
                                        </div>
                                        <div className={"col-sm-12"}>
                                            <TextField
                                                fullWidth
                                                margin="dense"
                                                onFocus={(event) => event.target.select()}
                                                multiline={true}
                                                rows={4}
                                                name={"description"}
                                                value={this.state.dialogValue.description}
                                                onChange={this.handleChange}
                                                label={"Description"}
                                                className="textField"
                                                variant="outlined"
                                            />
                                        </div>
                                        <div className={"col-sm-12"}>
                                            <TextField
                                                fullWidth
                                                margin="dense"
                                                onFocus={(event) => event.target.select()}
                                                name={"header_text"}
                                                value={this.state.dialogValue.headerText}
                                                onChange={this.handleChange}
                                                label={"Header Text"}
                                                className="textField"
                                                variant="outlined"
                                            />
                                        </div>
                                        <div className={"col-sm-12"}>
                                            <TextField
                                                fullWidth
                                                margin="dense"
                                                onFocus={(event) => event.target.select()}
                                                name={"footer_text"}
                                                value={this.state.dialogValue.footerText}
                                                onChange={this.handleChange}
                                                label={"Footer Text"}
                                                className="textField"
                                                variant="outlined"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className={"row"}>
                                        <div className="col-md-12 photo">
                                            {this.state.dialogValue.photo ?
                                                <img alt={this.state.dialogValue.name} src={this.state.dialogValue.imageType + ',' + this.state.dialogValue.photo} width={"100%"}/>
                                                : ""
                                            }
                                        </div>
                                        <div className="col-md-12 text-right" style={{paddingRight: 0}}>
                                            <Button variant="contained" className="editorButton" component='label'>
                                                Upload Image
                                                <input
                                                    style={{display: 'none'}}
                                                    type="file" name="photo"
                                                    onChange={this.uploadPhoto}
                                                    accept="image/*"
                                                />
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-sm-7 mt-3"}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend" className={"m-0"}>Link Type</FormLabel>
                                        <RadioGroup row aria-label="linkType" name="linkType" value={this.state.dialogValue.linkType} onChange={this.handleChange}>
                                            <FormControlLabel classes={{root: 'FormControlLabel'}} value="internal" control={<Radio classes={{root: 'radio'}}/>} label="Internal" />
                                            <FormControlLabel classes={{root: 'FormControlLabel'}} value="external" control={<Radio classes={{root: 'radio'}}/>} label="External" />
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                                <div className={"col-sm-12 pr-0"}>
                                    {this.state.dialogValue.linkType === "internal" ?
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel>Link</InputLabel>
                                            <Select
                                                name={"link"}
                                                className={"w-100 textField"}
                                                onChange={this.handleChange}
                                                label="Link"
                                                value={this.state.dialogValue.link}
                                            >
                                                {this.state.documents.map((doc) => <MenuItem value={doc.contentId}>{doc.title}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                        :
                                        <TextField
                                            fullWidth
                                            margin="dense"
                                            onFocus={(event) => event.target.select()}
                                            name={"link"}
                                            value={this.state.dialogValue.link}
                                            onChange={this.handleChange}
                                            label={"Link"}
                                            className="textField"
                                            variant="outlined"
                                        />
                                    }
                                </div>
                                <div className={"col-sm-7"}>
                                    <TextField
                                        fullWidth
                                        margin="dense"
                                        onFocus={(event) => event.target.select()}
                                        name={"achievement"}
                                        value={this.state.dialogValue.achievement}
                                        onChange={this.handleChange}
                                        label={"Achievement"}
                                        className="textField"
                                        variant="outlined"
                                    />
                                </div>
                                <div className={"col-sm-5 px-0"}>
                                    <TextField
                                        fullWidth
                                        margin="dense"
                                        onFocus={(event) => event.target.select()}
                                        name={"version"}
                                        value={this.state.dialogValue.version}
                                        onChange={this.handleChange}
                                        label={"Version"}
                                        className="textField"
                                        variant="outlined"
                                    />
                                </div>
                                <div className="col-md-12 py-3 multiple-autocomplete-dialog pr-0">
                                    <Autocomplete
                                        multiple
                                        fullWidth
                                        onChange={(event, newValue) => this.handleChange({target: {name: "prerequisites", value: newValue}})}
                                        name={"prerequisites"}
                                        value={this.state.dialogValue.prerequisites}
                                        onInputChange={(event, searchTerm) => this.searchPrerequisites(searchTerm, this.state.dialogValue.id)}
                                        options={this.state.prerequisites}
                                        getOptionLabel={(option) => option.name}
                                        filterSelectedOptions
                                        renderTags={(tagValue, getTagProps) =>
                                            tagValue.map((option, index) => (
                                                <Chip
                                                    label={option.name}
                                                    {...getTagProps({ index })}
                                                    onDelete={() => this.handleChange({target: {name: "prerequisites", value: this.state.dialogValue.prerequisites.filter((p) => p.id !== option.id)}}) }
                                                    className={"chip-item"}
                                                    variant={"outlined"}
                                                    avatar={<Avatar alt={option.name} src={option.imageType + ',' + option.photo} />}
                                                />
                                            ))
                                        }
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Prerequisites" />
                                        )}
                                    />
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions style={{borderTop : "1px solid rgba(169, 169, 169, 0.50)"}}>
                            <Button onClick={this.handleClose} variant="contained" className="editorButton">
                                Cancel
                            </Button>
                            <Button type="submit" variant="contained" className="editorButton">
                                Save
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </div>
        );
    }

    handlePrerequisiteDelete(trainingId, prerequisite) {
        this.setState((state) => ({
            training: {
                ...state.training,
                trainings: state.training.trainings.map((t) => t.id === trainingId ? t.prerequisites.filter((t) => t !== prerequisite) : t)
            }
        }), () => this.save())
    }

    handleDialogSubmit(event) {
        event.preventDefault()
        this.setState((state) => ({
            training: state.dialogValue,
            dialogOpen: false
        }), () => this.save())
    }

    uploadPhoto(e) {
        if(e.target.files && e.target.files[0]) {
            let file = e.target.files[0];
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) =>
                this.setState((state) => ({
                        filerError: e,
                        dialogValue: {
                            ...state.dialogValue,
                            photo: reader.result.split(',')[1],
                            imageType: reader.result.split(',')[0]
                        }
                    }
                ));
        }
    }

    handleClose() {
        this.setState({
            dialogOpen: false
        })
    }

    handleChange(event) {
        const {name, value} = event.target

        this.setState((state) => ({
            dialogValue: {
                ...state.dialogValue,
                [name]: value
            }
        }))
    }

    saveTraining(training) {
        Rest.authFetch(this.props.user, `/rest/trainings/${training.id}`, {
            method: "PUT",
            body: JSON.stringify(training)
        })
            .then(response => {
                if(response) {
                    this.setState((state) => ({
                        training: {
                            ...state.training,
                            trainings: training.id < 1 ? [...state.training.trainings, response] : state.training.trainings.map((t) => t.id === response.id ? response : t)
                        }
                    }), () => this.save());
                }
                else {
                    toast.error("Error saving Training");
                }
            });
    }

    searchPrerequisites(searchTerm, trainingId) {
        if (searchTerm.trim() === "") {
            this.setState({
                prerequisites: []
            });
        } else {
            Rest.authFetch(this.props.user, `/rest/searchPrerequisites/${searchTerm}?trainingId=${trainingId}`).then(response => {
                if(response.status === 401 || response.status === 403) {
                    toast.error("Unauthorized!")
                }
                else {
                    this.setState({
                        prerequisites: response
                    });
                }
            })
                .catch(err => {
                    console.log(err);
                })
        }
    }

    getDocuments() {
        Rest.authFetch(this.props.user, `/rest/content`).then(response => {
            if(response.status === 401 || response.status === 403) {
                toast.error("Unauthorized!")
            }
            else {
                this.setState({
                    documents: response
                });
            }
        })
            .catch(err => {
                console.log(err);
            })
    }

    save() {
        Rest.authFetch(this.props.user, `/rest/trainings/${this.state.training.id}`, {
            method: "PUT",
            body: JSON.stringify(this.state.training)
        })
        .then(response => {
            if(response) {
                this.setState({
                    training: response
                });
                this.props.onTrainingChanged(response)
            }
            else {
                toast.error("Error saving Training");
            }
        });
    }
}

export default withUser(Training);