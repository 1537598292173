import React, { useEffect, useState } from 'react';
import { withUser } from "../../context/UserContext";
import { Menu, MenuItem, ProSidebar, SidebarContent, SidebarFooter, SidebarHeader, SubMenu } from "react-pro-sidebar";
import impactivo from "../../images/logoimpactivo.png";
import 'react-pro-sidebar/dist/css/styles.css';
import './Menu.css';
import { Link } from "react-router-dom";
import Icon, { SettingOutlined, DoubleLeftOutlined, TeamOutlined, DoubleRightOutlined } from "@ant-design/icons";
import { ReactComponent as AssessmentSvg } from "../../images/assessments.svg";
import { ReactComponent as StaffSvg } from "../../images/doctor-patient-online.svg";
import { ReactComponent as PatientSvg } from "../../images/patient.svg";
import Profile from "../login/Profile";

function PCMHMenu(props) {

    const [collapsed, setCollapsed] = useState(false);
    const [collapsedIcon, setCollapsedIcon] = useState();


    useEffect(() => {
        setCollapsedIcon(() => {
            if (collapsed)
                return (<DoubleRightOutlined style={{ fontSize: '1.3em', cursor: 'pointer' }} />)
            else
                return (<DoubleLeftOutlined style={{ fontSize: '1.3em', cursor: 'pointer' }} />)
        });
    }, [collapsed])

    function logout() {

    }
    return (
        <ProSidebar collapsed={collapsed}>
            <SidebarHeader>
                <div className="container">
                    <div className="row">
                        <div className="col" onClick={() => setCollapsed(!collapsed)}>
                            {collapsedIcon}
                        </div>
                    </div>
                    <div className="row nav-row">
                        <div className="col-12 branding unSelectable">
                            <img id="logoImpactivo" src={impactivo}
                                style={{ height: 'auto', width: collapsed ? '50px' : '150px' }}
                                alt="Impactivo" />
                        </div>
                    </div>
                    {props.user &&
                        <Profile logout={logout()} collapsed={collapsed} />
                    }
                </div>
            </SidebarHeader>
            <SidebarContent>
                <Menu>
                    <MenuItem icon={<TeamOutlined style={{ fontSize: '2.0em' }} />}>
                        Team Daily Huddle
                        <Link to={"/huddle"} />
                    </MenuItem>
                    <MenuItem icon={<Icon component={StaffSvg} style={{ fontSize: '2.0em' }} />}>
                        Staff Support
                        <Link to={"/staffsupport"} />
                    </MenuItem>
                    <SubMenu title="Patient Support"
                        icon={<Icon component={PatientSvg} style={{ fontSize: '2.0em' }} />}>
                        <MenuItem>&#8227;&nbsp;Patient Lab Results<Link to={"/patient-graph"} /></MenuItem>
                        <MenuItem>&#8227;&nbsp;Patients Views<Link to="patientsupport/patients" /></MenuItem>
                        <MenuItem>&#8227;&nbsp;Patient<Link to="patientsupport/patient" /></MenuItem>
                        <MenuItem>&#8227;&nbsp;Assessment<Link to="patientsupport/assessment" /></MenuItem>
                        <MenuItem>&#8227;&nbsp;Data Set Admin<Link to="patientsupport/datasetadmin" /></MenuItem>
                        <MenuItem>&#8227;&nbsp;Population Set<Link to="patientsupport/populationset" /></MenuItem>
                    </SubMenu>
                    <SubMenu title={"Assessments"}
                        icon={<Icon component={AssessmentSvg} style={{ fontSize: '2.0em' }} />}>
                        <MenuItem>&#8227;&nbsp;Templates<Link to="assessments/templates" /></MenuItem>
                        <MenuItem>&#8227;&nbsp;Batches<Link to="assessments/batches" /></MenuItem>
                        {/*<Link to={"/assessments"}/>*/}
                    </SubMenu>
                    <div className={"separator"} />
                    <SubMenu title={"Admin"} icon={<SettingOutlined style={{ fontSize: '2.0em' }} />}>
                        <MenuItem>&#8227;&nbsp;Staff<Link to={"/admin/staff"} /></MenuItem>
                        <MenuItem>&#8227;&nbsp;Teams<Link to={"/admin/teams"} /></MenuItem>
                        <MenuItem>&#8227;&nbsp;Agenda<Link to={"/admin/agenda"} /></MenuItem>
                        <MenuItem>&#8227;&nbsp;Users<Link to={"/admin/users"} /></MenuItem>
                        <MenuItem>&#8227;&nbsp;Content<Link to={"/admin/content"} /></MenuItem>
                        <MenuItem>&#8227;&nbsp;Curriculums<Link to={"/admin/curriculums"} /></MenuItem>
                        <MenuItem>&#8227;&nbsp;Trainings<Link to={"/admin/trainings"} /></MenuItem>
                        <MenuItem>&#8227;&nbsp;Health Elements<Link to={"/health-elements"} /></MenuItem>
                    </SubMenu>
                    
                </Menu>
            </SidebarContent>
            <SidebarFooter>

            </SidebarFooter>
        </ProSidebar>

    );
}

export default withUser(PCMHMenu);