import React, {Component} from 'react';

class ImageViewer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blob: props.blob,
            type: props.type,
            height: props.height,
            width: props.width
        };
    }

    componentDidMount() {
        this.drawImage();
    }

    render() {
        return <canvas ref={(canvas) => { this.canvas = canvas; }}/>;
    }

    drawImage() {
        const {canvas} = this;
        let img = new Image();
        let maxW = this.state.width;
        let maxH = this.state.height;
        img.onload = function () {
            let iw = img.width;
            let ih = img.height;
            let scale = Math.min((maxW / iw), (maxH / ih));
            let iwScaled = iw * scale;
            let ihScaled = ih * scale;
            canvas.width = iwScaled;
            canvas.height = ihScaled;
            let ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
        };
        img.src = "data:" + this.props.type + ";base64, " + this.state.blob;
    }

}

export default ImageViewer;