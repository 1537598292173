import React, {Component} from 'react';
import Curriculum from "./Curriculum";
import toast from "../../../../util/toast";
import {Rest} from "../../../../util/rest";
import {withUser} from "../../../../context/UserContext";
import "./CurriculumAdmin.css"
import {FolderAddOutlined} from "@ant-design/icons";
import AdminHeader from "../AdminHeader";

class CurriculumAdmin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            curriculums: [],
            isLoading: true
        };
        this.addNewCurriculum = this.addNewCurriculum.bind(this);
        this.deleteCurriculum = this.deleteCurriculum.bind(this);
        this.handleCurriculumUpdated = this.handleCurriculumUpdated.bind(this);
    }

    componentDidMount() {
        this.getCurriculums("");
    }

    render() {
        return (
            <div className={"curriculums"}>
                <div>
                    <AdminHeader
                            label={"Curriculums"}
                            onChange={(event) => this.getCurriculums(event.target.value)}
                            onClickIcon={this.addNewCurriculum}
                            icon={<FolderAddOutlined title="Add Curriculum"/>}
                    />
                    <div>
                        {this.state.isLoading &&
                            <div className="text-center my-5">
                                <button className="btn btn-primary-color btn-lg rounded shadow-sm" type="button" disabled>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"/>
                                    Loading...
                                </button>
                            </div>
                        }
                        {this.state.curriculums.map((curriculum) =>
                            <Curriculum
                                key={curriculum.id}
                                curriculum={curriculum}
                                onDelete={this.deleteCurriculum}
                                onCurriculumChanged={this.handleCurriculumUpdated}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }

    addNewCurriculum() {
        this.setState((state) => ({
            curriculums: [...state.curriculums, {
                id: 0,
                name: "New Curriculum",
                order: state.curriculums.length,
                trainings: []
            }]
        }))
    }

    handleCurriculumUpdated(curriculum) {
        this.setState((state) => ({
            curriculums: state.curriculums.map(c => c.id === curriculum.id ? curriculum : c)
        }), () => this.getCurriculums(""))
    }

    getCurriculums(searchTerm) {
        Rest.authFetch(this.props.user, `/rest/curriculums/searchCurriculums/${searchTerm}`).then(response => {
            if(response.status === 401 || response.status === 403) {
                toast.error("Unauthorized!")
            }
            else {
                this.setState({
                    curriculums: response.content,
                    isLoading: false
                });
            }
        })
        .catch(err => {
            console.log(err);
            this.setState({
                isLoading: false
            });
        })
    }

    deleteCurriculum(id) {
        Rest.authFetch(this.props.user, "/rest/curriculums/" + id,{
            method:'DELETE'
        })
        .then(response => {
            if(response.status === 401 || response.status === 403) {
                toast.error("Unauthorized!")
            }
            else {
                this.getCurriculums("");
            }
        })
        .catch(err => {
            console.log(err);
        })
    }
}

export default withUser(CurriculumAdmin);