import React, {useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {Rest} from "../../../../util/rest";
import toast from "../../../../util/toast";
import {withUser} from "../../../../context/UserContext";
import moment from 'moment';
import {CaretDownOutlined, CaretUpOutlined, HistoryOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import AssignableEditorDialog from "./AssignableEditorDialog";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {useLocation} from "react-router";

const columns = [
    { id: 'name', label: 'Name', width: "42%", align: "left" },
    { id: 'status', label: 'Status', width: "16%", align: "center" },
    { id: 'dateDue', label: 'Due Date', width: "16%", align: "center" },
    { id: 'lastUpdate', label: 'Last Update', width: "16%", align: "center" },
    { id: 'edit', label: 'Edit', width: "10%", align: "center" }
];

const useStyles = makeStyles({
                                 root: {
                                     width: '100%',
                                     maxHeight: 350
                                 },
                                 container: {
                                     maxHeight: 350,
                                 },
                             });

const caretStyle = {
    outline: 0,
    cursor: "pointer"
}

function AssignmentsTable(props) {
    const [rows, setRows] = useState([]);
    const [openSection, toggleOpenSection] = useState(true);
    const [openParent, setOpenParent] = useState([]);
    const location = useLocation();

    useEffect(() => {
        getAssignments()
    }, [props.refresh, props.personId])

    const getAssignments = (completed = false) => {
        Rest.authFetch(props.user, `/rest/assignments/${props.parentName}/${props.personId}?completed=${completed}`)
            .then(response => {
                if(response.status === 401 || response.status === 403) {
                    toast.error("Unauthorized!")
                }
                else {
                    handleResponse(response)
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const getAssignmentStatus = (assignment) => {
        let status
        let style
        if (assignment.dateEnded) {
            status = "Completed"
            style = "completed"
        } else if(assignment.dateStarted) {
            status = "In Progress"
            style = "in-progress"
        } else {
            status = "Not Started"
            style = "not-started"
        }

        return {
            status: status,
            style: style
        }
    }

    const handleResponse = (response) => {
        let parents = []
        setRows(response.map(assignment => {
            let {status, style} = getAssignmentStatus(assignment)
            parents.push(true)
            return {
                assignment: assignment,
                id: assignment.id,
                name: assignment.assignable.name,
                dateDue: assignment.dateDue ? moment(assignment.dateDue).format("MM/DD/YYYY") : "",
                lastUpdate: assignment.lastUpdate ? moment(assignment.lastUpdate).format("MM/DD/YYYY h:mma") : "",
                status: status,
                styleName: style,
                children: assignment.children.map(child => {
                    let {status, style} = getAssignmentStatus(child)
                    return {
                        assignment: child,
                        id: child.id,
                        name: child.assignable.name,
                        dateDue: child.dateDue ? moment(child.dateDue).format("MM/DD/YYYY") : "",
                        lastUpdate: child.lastUpdate ? moment(child.lastUpdate).format("MM/DD/YYYY h:mma") : "",
                        status: status,
                        styleName: style
                    }
                })
            }
        }))

        setOpenParent(parents)
    }

    function handleToggle() {
        if (openSection)
            getAssignments()
        toggleOpenSection(!openSection)
    }

    function toggleParent(index) {
        const sections = [...openParent]
        sections[index] = !sections[index]
        setOpenParent(sections)
    }

    function showCompleted() {
        getAssignments(true)
    }

    function handleDiscard(assignment) {
        Rest.authFetch(props.user, `/rest/assignment/${assignment.id}`, {
            method: 'DELETE'
        })
        .then(response => {
            getAssignments()
        })
        .catch(err => {
            console.log(err);
        })
    }

    const classes = useStyles();
    const assignmentType = props.assignmentType[0].toUpperCase() + props.assignmentType.slice(1);

    return (
        <>
            <div className={"py-2 px-4 assessments-label unSelectable row"}>
                <div className={"col-sm-10"}>{assignmentType + "s"}</div>
                <div className={"col-sm-1"}>
                    <HistoryOutlined style={caretStyle} title={"Show all " + assignmentType + "s"}
                                     onClick={showCompleted}/>
                </div>
                {/*<div className={"col-sm-1"}>*/}
                {/*    <PlusCircleOutlined type="plus-circle" title={"Add " + props.parentName}*/}
                {/*                        style={caretStyle} onClick={() => props.onClickAdd(props.parentName)}/>*/}
                {/*</div>*/}
                <div className={"col-sm-1"}>
                    {openSection ?
                     <CaretDownOutlined style={caretStyle} onClick={() => handleToggle()} title={"Collapse table"}/> :
                     <CaretUpOutlined style={caretStyle} onClick={() => handleToggle()} title={"Expand table"}/>
                    }
                </div>
            </div>
            {openSection &&
            <Paper className={classes.root + " assignments-table"}>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell key={column.id} align={column.align}
                                               style={{width: column.width, fontWeight: "bold"}} className={"py-2"}>
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.filter((r) => r.status !== "Completed").map((row, index) => {
                                return (
                                    <>
                                        <TableRow
                                            tabIndex={-1}
                                            key={row.id}
                                            className={`unSelectable`}
                                            style={{backgroundColor: "#eeeeee"}}
                                        >
                                            <TableCell colSpan={5} className={"py-2 pl-1"}>
                                                <div className={"d-flex justify-content-between"}>
                                                    {row.name}
                                                    <FontAwesomeIcon icon={faTrash} className={"discard"} title={"Discard"} onClick={() => handleDiscard(row.assignment)} />
                                                    {openParent[index] ?
                                                        <CaretDownOutlined style={caretStyle}
                                                                           onClick={() => toggleParent(index)}
                                                                           title={"Collapse"}/> :
                                                        <CaretUpOutlined style={caretStyle}
                                                                         onClick={() => toggleParent(index)}
                                                                         title={"Expand"}/>
                                                    }
                                                </div>
                                            </TableCell>
                                        </TableRow>

                                        {openParent[index] && row.children.map((child) =>
                                            <TableRow
                                                    hover
                                                    tabIndex={-1}
                                                    key={child.id}
                                                    className={`${child.styleName} unSelectable`}
                                            >
                                                {columns.map((column) => {
                                                    const value = column.id === "edit" ? <AssignableEditorDialog assignment={child.assignment} onDialogSubmitted={getAssignments}/>  : child[column.id];
                                                    return (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            className={"py-2"}
                                                        >
                                                            {column.id === "name" ?
                                                                <span>{child.assignment.assignable.linkType === "internal" ?
                                                                       <Link to={{
                                                                           pathname: `/document/${child.assignment.assignable.link}`,
                                                                           state: {
                                                                               personId: props.personId,
                                                                               from: location
                                                                           }
                                                                       }}>{value}</Link> :
                                                                       <a href={child.assignment.assignable.link}
                                                                          target={"_blank"}>{value}</a>}
                                                                </span>
                                                                : <span>{value}</span>
                                                            }
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        )}
                                    </>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            }
        </>
    );
}

export default withUser(AssignmentsTable)