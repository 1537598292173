import React, {useEffect, useState} from 'react';
import {withUser} from "../../../../context/UserContext";
import './HealthData.css'
import {
    BarsOutlined, CloseOutlined,
    DeleteOutlined, DownCircleOutlined,
    EditOutlined,
    PlusOutlined,
    SaveOutlined,
} from "@ant-design/icons";
import {Rest} from "../../../../util/rest";
import {toast} from "react-toastify";
import HealthDataElement from "./HealthDataElement";
import HealthElements from "../../../../components/section/HealthData/HealthElements/HealthElements";
import TextField from "@mui/material/TextField";
import HealthDataElementAttributeEditor from "./HealthDataElementAttributeEditor";


function TypeClass({typeClass, reload, user}) {
    const [isEditing, setEditing] = useState(false);
    const [showElements, setShowElements] = useState(false);
    const [dataElement, setDataElement] = useState([]);
    const [editDataElement, setEditDataElement] = useState([]);
    const [editAttributes, setEditAttributes] = useState(false);
    useEffect(() => {
        setDataElement(typeClass);
        setEditDataElement(typeClass);
    }, []);

    useEffect(() => {
        setDataElement(typeClass);
        setEditDataElement(typeClass);
        if(typeClass.id === 0)
            setEditing(true);
    }, [typeClass]);

    useEffect(() => {
        setEditDataElement(typeClass);
    }, [isEditing]);

    const deleteHealthElementTypeClass = async () => {
        const response = await Rest.authFetch(
                user,
                `/rest/health/element/type-class/${typeClass.id}`,
                {method: "DELETE"}
        );
        if(response.status === 401 || response.status === 403) toast.error("Unauthorized!")
        else if(response.status === 500)
            toast.error("Error deleting Type Class")
        else if(reload) {
            reload();
        }
    }

    const saveHealthElementTypeClass = async () => {
        if(!editDataElement || !editDataElement.name || editDataElement.name.length === 0 ||
           !editDataElement.healthElementClass || editDataElement.healthElementClass.length === 0 ||
           !editDataElement.type || editDataElement.type.length === 0) {
            toast.error("Cannot save, missing fields!")
            return;
        }
        const response = await Rest.authFetch(
                user,
                `/rest/health/element/type-class`,
                {
                    method: "PUT",
                    body: JSON.stringify(editDataElement)
                }
        );
        if(response.status === 401 || response.status === 403) toast.error("Unauthorized!")
        else if(response.status === 500)
            toast.error("Error saving Type Class")
        else {
            setEditing(false);
            if(reload)
                reload();
        }
    }


    return (
            <>
                <div className="type-class-box container-fluid">
                    <div className="row">
                        <div className="col-9 col-lg-8">
                            {/*{!showElements ?*/}
                            {/*    <div className="col-md-2 col-lg-1 edit my-auto align-middle" title="Edit"*/}
                            {/*        onClick={() => setShowElements(true)}>*/}
                            {/*        <DownOutlined />*/}
                            {/*    </div>*/}
                            {/*    :*/}
                            {/*    <div className="col-md-2 col-lg-1 edit my-auto" title="Edit"*/}
                            {/*        onClick={() => setShowElements(false)}>*/}
                            {/*        <UpOutlined />*/}
                            {/*    </div>}*/}
                            {!isEditing &&
                             <>
                                 <div className="row">
                                     <div className="col-4 my-auto header" >
                                         Type
                                     </div>
                                     <div className="col-4 my-auto header" >
                                         Class
                                     </div>
                                     <div className="col-4 my-auto header">
                                         Name
                                     </div>
                                 </div>
                                 <div className="row">
                                     <div className="col-4 my-auto">
                                         {typeClass &&
                                          <div className=" type-class-text">{typeClass.type}</div>}
                                     </div>
                                     <div className="col-4 my-auto">
                                         {typeClass &&
                                          <div className=" type-class-text">{typeClass.healthElementClass}</div>}
                                     </div>
                                     <div className="col-4 my-auto">
                                         {typeClass &&
                                          <div className=" type-class-text">{typeClass.name}</div>}
                                     </div>
                                 </div>
                             </>
                            }
                            {isEditing &&
                             <div className="row">
                                 <div className="col-4 my-auto">
                                     <TextField
                                             error={!editDataElement.type || editDataElement.type.length === 0}
                                             autoComplete="off"
                                             autoFocus
                                             fullWidth
                                             onFocus={(event) => event.target.select()}
                                             margin="dense"
                                             name="type"
                                             defaultValue={editDataElement.type}
                                             onChange={e => setEditDataElement({
                                                                                   ...editDataElement,
                                                                                   type: e.target.value
                                                                               })}
                                             label="Type"
                                             type="text"
                                             className="textField textBox"
                                             variant="outlined"
                                             required
                                             size="small"
                                     />
                                 </div>
                                 <div className="col-4 my-auto">
                                     <TextField
                                             error={!editDataElement.healthElementClass || editDataElement.healthElementClass.length === 0}

                                             autoComplete="off"
                                             fullWidth
                                             onFocus={(event) => event.target.select()}
                                             margin="dense"
                                             name="class"
                                             defaultValue={editDataElement.healthElementClass}
                                             onChange={e => setEditDataElement({
                                                                                   ...editDataElement,
                                                                                   healthElementClass: e.target.value
                                                                               })}
                                             label="Class"
                                             type="text"
                                             className="textField textBox"
                                             variant="outlined"
                                             required
                                             size="small"
                                     />

                                 </div>
                                 <div className="col-4 my-auto">
                                     <TextField
                                             error={!editDataElement.name || editDataElement.name.length === 0}
                                             autoComplete="off"
                                             fullWidth
                                             onFocus={(event) => event.target.select()}
                                             margin="dense"
                                             name="name"
                                             defaultValue={editDataElement.name}
                                             onChange={e => setEditDataElement({
                                                                                   ...editDataElement,
                                                                                   name: e.target.value
                                                                               })}
                                             label="Name"
                                             type="text"
                                             className="textField textBox"
                                             variant="outlined"
                                             required
                                             size="small"
                                     />
                                 </div>
                             </div>
                            }

                        </div>
                        <div className="col-3 col-lg-4">
                            <div className="row justify-content-end my-auto">
                                {!isEditing &&
                                 <div className="col-2 edit my-auto" title="Edit"
                                      onClick={() => setEditing(true)}>
                                     <EditOutlined title="Edit Class Type"/>
                                 </div>
                                }
                                {isEditing &&
                                 <>
                                     <div className="col-2 edit my-auto" title="Edit"
                                          onClick={() => saveHealthElementTypeClass()}>
                                         <SaveOutlined title="Edit Class Type"/>
                                     </div>
                                     <div className="col-2 edit my-auto" title="Cancel"
                                          onClick={() => {
                                              setEditing(false);
                                              reload()
                                          }}>
                                         <CloseOutlined title="Edit Class Type"/>
                                     </div>
                                 </>
                                }
                                {typeClass && typeClass.id > 0 &&
                                 <>
                                     <div className="col-2 edit my-auto">
                                         <BarsOutlined title="Attributes"
                                                       onClick={() => setEditAttributes(!editAttributes)}/>
                                     </div>
                                     <div className="col-2 edit my-auto">
                                         <DownCircleOutlined title="Attributes"
                                                             onClick={() => setShowElements(!editAttributes)}/>
                                     </div>
                                     <div className="col-2 edit my-auto" title="Delete"
                                          onClick={deleteHealthElementTypeClass}>
                                         <DeleteOutlined title="Delete Class Type"/>
                                     </div>
                                 </>
                                }
                            </div>
                        </div>
                        {showElements && (
                                <HealthElements
                                        healthElements={typeClass}
                                        healthAttributes={dataElements}
                                        handleClose={handleClose}
                                        reloadHealthElements={reload}
                                        reloadDataElements={loadDataElements}
                                        user={user}/>
                        )}
                        {/*<TypeClassDialog open={isEditing} typeClass={typeClass} handleClose={handleClose} /> */}

                    </div>
                </div>
                {editAttributes &&
                 <HealthDataElementAttributeEditor open={editAttributes} typeClass={typeClass} user={user}
                                                   close={() => setEditAttributes(false)}/>
                }
                {showElements &&
                 <div className="type-class-elements">
                     <div className="row attributes">
                         <div className="col-11">Attributes</div>
                         <div className="col-1 icon text-right" title={"Add Attribute"}><PlusOutlined/></div>
                     </div>
                     <div className="row header">
                         <div className="col-4 header-col col-first">
                             Name
                         </div>
                         <div className="col-3 header-col col-middle">
                             Data Type
                         </div>
                         <div className="col-3 header-col col-middle">
                             Options
                         </div>
                         <div className="col-1 header-col col-middle">
                             Required
                         </div>
                         <div className="col-1 header-col col-last">

                         </div>
                     </div>
                     {dataElement && dataElement.length > 0 && dataElement.map((row) => {
                         return (
                                 <HealthDataElement healthElement={row} key={row.id}/>
                         )
                     })}

                 </div>
                }
            </>
    )
}
;

export default withUser(TypeClass);