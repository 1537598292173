import React, {Component} from 'react';
import './TextArea.css'

class TextArea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.id || this.props.name,
            value: this.props.value
        }
    }

    render() {
        return (
            <div className={"form-group border shadow-sm rounded"}>
                <label className={"mx-3 mb-0"} htmlFor={this.state.id}>{this.props.label}</label>
                <hr className={"p-0 m-0 bg-dark"}/>
                <textarea
                        autoFocus={!!this.props.autofocus}
                        className={"form-textarea"}
                        name={this.props.name}
                        value={this.props.value}
                        id={this.state.id}
                        rows={this.props.rows}
                        onChange={this.props.onChange}
                        ref={this.input}
                        onBlur={this.props.onBlur}
                        spellCheck={true}
                        defaultValue={this.props.defaultValue}
                        readOnly={this.props.readOnly}
                />
            </div>
        );
    }
}

export default TextArea;