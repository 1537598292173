import React, {useEffect, useState} from 'react';

import TextField from "@mui/material/TextField";
import {Quill} from "react-quill";
import {useQuill} from 'react-quilljs';
import ImageResize from "quill-image-resize-module-react";
import {ImageDrop} from "quill-image-drop-module";
import 'quill/dist/quill.snow.css';

import {Rest} from "../../../../util/rest";
import toast from "../../../../util/toast";
import {withUser} from "../../../../context/UserContext";


const inputLabelProps = {
    formlabelclasses: {
        root: 'textField',
        focused: 'focused'
    },
};

var BaseImageFormat = Quill.import('formats/image');
const ImageFormatAttributesList = [
    'alt',
    'height',
    'width',
    'style'
];

class ImageFormat extends BaseImageFormat {
    static formats(domNode) {
        return ImageFormatAttributesList.reduce(function (formats, attribute) {
            if (domNode.hasAttribute(attribute)) {
                formats[attribute] = domNode.getAttribute(attribute);
            }
            return formats;
        }, {});
    }
    format(name, value) {
        if (ImageFormatAttributesList.indexOf(name) > -1) {
            if (value) {
                this.domNode.setAttribute(name, value);
            } else {
                this.domNode.removeAttribute(name);
            }
        } else {
            super.format(name, value);
        }
    }
}

Quill.register(ImageFormat, true);
Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageDrop', ImageDrop);


function MailTemplateEditor(props) {
    const placeholderText = 'Write your template here...';
    const [templateName, setTemplateName] = useState();
    const [template, setTemplate] = useState({ id: 0, active: true, name: "", template: "" });
    const theme = 'snow';
    const modules = {
        toolbar: {
            container: [
                [{ 'font': [] }, { 'header': [false, 1, 2, 3, 4, 5] }],
                [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                [{ 'align': [] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                ['link', 'image'],
                ['clean'],
                [{ 'placeholder': [false, ['Email'], ['Name'], ['Assessment URL']] }]
            ],
            handlers: {
                "placeholder": addTag
            }
        },
        imageResize: {
            parchment: Quill.import('parchment')
        },
        imageDrop: true
    };
    const { quill, quillRef } = useQuill({ theme, modules, placeholderText });

    function addTag(value) {
        if (value && this.quill) {
            const { index } = this.quill.getSelection();
            this.quill.insertText(index, '${' + value + '}');
            // if(value==='Email' || value === 'Name')
            //     this.quill.insertEmbed(index,'block', '<span th:text="${' + value + '}">${' + value + '}</span>','API');
            //     // this.quill.clipboard.dangerouslyPasteHTML(index, '<span th:text="${' + value + '}">${' + value + '}</span>');
            // else if(value==='URL')
            //     this.quill.insertEmbed(index, 'block','<a href="assessment" th:href="@{https://assessment.impactivo.com/${UUID})}">${URL}</a>','API');
            //     // this.quill.clipboard.dangerouslyPasteHTML(index, '<a href="assessment" th:href="@{https://assessment.impactivo.com/${UUID})}">${URL}</a>');
        }
    }

    useEffect(() => {
        const placeholderPickerItems = Array.prototype.slice.call(document.querySelectorAll('.ql-placeholder .ql-picker-item'));
        placeholderPickerItems.forEach(item => item.textContent = item.dataset.value);
        if (document.querySelector('.ql-placeholder .ql-picker-label') &&
            !document.querySelector('.ql-placeholder .ql-picker-label').innerHTML.includes('<span style="margin-right: 20px;">Insert Data Field</span>')) {
            document.querySelector('.ql-placeholder .ql-picker-label').innerHTML
                = '<span style="margin-right: 20px;">Insert Data Field</span>' + document.querySelector('.ql-placeholder .ql-picker-label').innerHTML;
        }


    }); //Set Data Insert dropdown

    useEffect(() => {
        if (props.template) {
            setTemplate(props.template);
            setTemplateName(props.template.name);
        }
    }, [])

    useEffect(() => {
        if (quill) {
            quill.on('editor-change', () => {
                if (template)
                    template.template = quill.root.innerHTML;
            });
        }
    }, [quill]);

    useEffect(() => {
        if (quill && template) {
            quillRef.current.firstChild.innerHTML = template.template;

        }
    }, [quill, template]);

    useEffect(() => {

        if (props.shouldSave) {

            if (!template || templateName === '') {
                console.log("Empty Name")
            }
            else {
                template.name = templateName;
                props.onTemplateUpdate(template)
                Rest.authFetch(props.user, "/rest/mailtemplate", {
                    method: "PUT",
                    body: JSON.stringify(template),
                    accepts: 'application/json'
                })
                    .then(response => {
                        if (response && response.id > 0) {
                            props.onTemplateUpdate(response);
                            props.onClose(false);
                        }
                        else {
                            toast.error("Error saving Template");
                        }
                    });
            }
            props.onSave(false);
        }
    }, [props.shouldSave]);


    return (
        <>
            <div className="row">
                <div className="col">
                    <div className="row">
                        <div className="col-3">
                            <TextField
                                InputProps={inputLabelProps}
                                label="Template Name"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                onChange={(e) => setTemplateName(e.target.value)}
                                value={templateName}
                            />

                        </div>
                    </div>
                    <div className="row section" />
                </div>
            </div>
            <div className="row flex-grow-1">
                <div className="col">
                    <div ref={quillRef} />
                </div>
            </div>
        </>
    );
}

export default withUser(MailTemplateEditor);
