import React, {Component} from 'react';
import {CloseCircleOutlined} from "@ant-design/icons";
import './Recipient.css'

class Recipient extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recipient: props.recipient,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.recipient !== this.props.recipient)
            this.setState({recipient: this.state.recipient})
    }

    render() {
        const recipient = this.state.recipient;
        const name = recipient.name;
        return (
                <div className="recipient">
                    <div className="removeRecipient" name={this.props.key} onClick={() => this.deleteRecipient()}>
                        <CloseCircleOutlined title="Remove Recipient"/>
                    </div>
                    <div className="recipientName">
                        {name}
                    </div>
                </div>
        );
    }

    deleteRecipient(e) {
        if(this.props.deleteHandler)
            this.props.deleteHandler(this.state.recipient);
    }
}

export default Recipient;