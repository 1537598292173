/* eslint no-eval: 0 */
import moment from 'moment';
import 'moment/locale/es';

moment.locale('en');

export function formatTaxonomy(taxonomy, classification, specialization) {

    if(!taxonomy) {
        return 'Sin especificar'
    }

    let taxStr = `(${taxonomy}) `;
    taxStr += [classification, specialization].filter(it => it !== null && it.length > 0).join(', ');
    return taxStr;
}

export function formatPhone(digits) {

    //normalize string and remove all unnecessary characters
    let strippedDigits = ('' + digits).replace(/[^\d]/g, "");

    //check if number length equals to 10
    if (strippedDigits.length !== 10) {
        return '';
    }

    //reformat and return phone number
    return strippedDigits.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");

}

export function formatZipCode(digits) {

    //normalize string and remove all unnecessary characters
    let strippedDigits = ('' + digits).replace(/[^\d]/g, "");

    //check if number length equals to 10
    if (strippedDigits.length !== 9) {
        return '';
    }

    //reformat and return zip code
    return strippedDigits.replace(/(\d{5})(\d{4})/, "$1-$2");

}

export function formatDate(isoDate, format) {
    if(!format) {
        format = 'D MMM YYYY';
    }
    if(!/\d{4}-\d{2}-\d{2}/.test(isoDate)) {
        return '';
    }

    return moment(isoDate).format(format);

}

export function date2str(x, y) {
    // noinspection JSUnusedLocalSymbols
    /* eslint no-unused-vars: 0 */
    let z = {
        M: x.getMonth() + 1,
        d: x.getDate(),
        h: x.getHours(),
        m: x.getMinutes(),
        s: x.getSeconds()
    };
    y = y.replace(/(M+|d+|h+|m+|s+)/g, function (v) {
        return ((v.length > 1 ? "0" : "") + eval('z.' + v.slice(-1))).slice(-2)
    });

    return y.replace(/(y+)/g, function (v) {
        return x.getFullYear().toString().slice(-v.length)
    });
}

export function findLabel(types, val) {
    const useObj = types.find(it => it.value === val);
    return useObj ? useObj.labelEs : val;
}

export function tConvert(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if(time.length > 1) { // If time format correct
        time = time.slice(1);  // Remove full string match value
        time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
}
