import React, {Component} from 'react';
import {CheckOutlined, DeleteOutlined, EditOutlined, HistoryOutlined, UndoOutlined} from "@ant-design/icons";
import moment from "moment";
import {Rest} from "../../../../util/rest";
import {withUser} from "../../../../context/UserContext";


const MAX_CHARS = 300;

class IssueItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            issue: props.issue,
            staffMember: null,
            editMode: false,
            showHistory: false
        };
        this.issueStatusClicked = this.issueStatusClicked.bind(this);
    }

    componentDidMount() {
        console.log(moment.locale())
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if(JSON.stringify(prevProps.issue) !== JSON.stringify(this.props.issue) && !this.state.editMode)
            this.setState({issue: this.props.issue})
    }

    render() {
        const issue = this.state.issue;
        return (
                <div className="row box">
                    {this.state.editMode ?
                     <div className="col-12">
                         <div className="row">
                             <div className="col-12">    
                                <textarea name="issueEntry" id="issueEntry"
                                          className="noteEntry"
                                          autoFocus
                                          defaultValue={this.state.issue.issueText}
                                          onChange={(e) => this.handleInputChange(e)}/>
                             </div>
                         </div>
                         <div className="row">
                             <div className="col-8 counter">
                                 {this.state.chars}/{MAX_CHARS}
                             </div>
                             <div className="col-2 text-right note-edit undo">
                                 <UndoOutlined className="undo" title="Undo"
                                               onClick={() => this.handleUndo()}/>
                             </div>
                             <div className="col-2 text-right note-edit save">
                                 <CheckOutlined className="note-edit" title="Save Changes"
                                                onClick={() => this.handleSave()}/>
                             </div>
                         </div>
                     </div>
                                         :
                     <div className="col-12">
                         {issue.closed ? null :
                          <div className="row">
                              <div className="offset-md-7 offset-lg-8 col-md-5 col-lg-4 ">
                                  <div className="row justify-content-end" style={{overflowY: "auto"}}>
                                      <div className="col-3 text-right">
                                          <HistoryOutlined className="note-edit" title="Show history"
                                                           onClick={() => this.showHistory()}/>
                                      </div>
                                      <div className="col-3 text-right">
                                          <EditOutlined className="note-edit" title="Edit issue"
                                                        onClick={() => this.edit()}/>
                                      </div>
                                      <div className="col-3 text-right">
                                          <DeleteOutlined className="note-edit" title="Delete issue"
                                                          onClick={() => this.delete()}/>
                                      </div>
                                  </div>
                              </div>
                          </div>
                         }
                         <div className="row">
                             <div className="col-10">
                                 {this.state.showHistory && this.state.history && this.state.history.length > 0 ?
                                  <>
                                      <div className="row history-block"/>
                                      {this.state.history.map((history, index) =>
                                                                      <div className="row  history-block" key={index}>
                                                                          <div className="col-12">
                                                                              <div className="row">
                                                                                  <div className="col-12 history-date"
                                                                                       style={{padding: 0}}>
                                                                                      Original
                                                                                      Date:{moment(history.createdDate).format("MM/DD/YYYY h:mm:ss a")}
                                                                                  </div>
                                                                              </div>
                                                                              <div className="row">
                                                                                  <div className="col-12 history"
                                                                                       style={{padding: 0}}>
                                                                                      {history.issueText}
                                                                                  </div>
                                                                              </div>
                                                                          </div>
                                                                      </div>)}
                                  </> : null}
                                 <div className="row">
                                     {issue.issueText}
                                 </div>
                                 <div className="row">
                                     <div className="col-3 dateEntered-label">
                                         Created:
                                     </div>
                                     <div className="col-9 dateEntered">
                                         {moment(issue.createdDate).format("MMM DD, YYYY h:mm:ss a")}
                                     </div>
                                 </div>
                                 {issue.closed ?
                                  <div className="row">
                                      <div className="col-3 dateEntered-label">
                                          Resolved:
                                      </div>
                                      <div className="col-9 dateEntered">
                                          {moment(issue.closedDate).format("MMM DD, YYYY h:mm:ss a")}
                                      </div>
                                  </div> : null
                                 }
                             </div>
                             <div className="col-2 my-auto">
                                 <div className="row completedBox float-right" onClick={this.issueStatusClicked}>
                                     {issue.closed ?
                                      <svg width="10" height="10" viewBox="0 0 10 10" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                          <path d="M9.9 1.414L8.486 0L4.95 3.536L1.414 0L0 1.414L3.536 4.95L0 8.486L1.414 9.9L4.95 6.364L8.486 9.9L9.9 8.486L6.364 4.95L9.9 1.414Z"/>
                                      </svg> :

                                      <svg width="16" height="13" viewBox="0 0 16 13"
                                           xmlns="http://www.w3.org/2000/svg">
                                          <path d="M5.558 12.0708C5.14123 12.0708 4.72528 11.9121 4.407 11.5938L0 7.18681L2.30199 4.88401L5.558 8.14001L13.698 0L16 2.30281L6.709 11.5938C6.39072 11.9121 5.97477 12.0708 5.558 12.0708Z"/>
                                      </svg>}
                                 </div>
                             </div>
                         </div>
                     </div>}
                </div>
        );


    }

    edit() {
        const length = this.state.issue.issueText.length;
        this.setState({
                          editMode: true,
                          showHistory: false,
                          chars: length,
                          oldValue: this.state.issue.issueText
                      });


    }

    delete() {
        if(this.props.onDelete)
            this.props.onDelete(this.state.issue);
    }

    showHistory() {
        const showHistory = !this.state.showHistory;
        this.setState({showHistory: showHistory});
        if(showHistory && this.state.issue && this.state.issue.id > 0) {
            let path = "/rest/agenda/issue/history/" + this.state.issue.id;
            Rest.authFetch(this.props.user, path).then(response => {
                this.setState({history: response});
            })
                    .catch(err => {
                        if(err === 204) {
                            this.setState({history: []})
                        }
                        else
                            console.log(err);
                    })
        }
    }

    issueStatusClicked() {
        if(this.props.onChange)
            this.props.onChange(this.state.issue);
    }

    handleSave() {
        if(this.state.oldValue !== this.state.issue.issueText) {
            const issue = this.state.issue;
            Rest.authFetch(this.props.user, "/rest/agenda/addIssue/history", {
                method: "PUT",
                body: JSON.stringify({
                                         createdDate: issue.createdDate,
                                         issueId: issue.id,
                                         issueText: this.state.oldValue,
                                     })
            })
                    .then(() => {
                        issue.createdDate = new Date();
                        Rest.authFetch(this.props.user, "/rest/agenda/addIssue", {
                            method: "PUT",
                            body: JSON.stringify(issue)
                        }).then(this.setState({editMode: false}))
                    });
        }

    }

    handleUndo() {
        this.setState((state) => ({
            editMode: false,
            issue: {
                ...state.issue,
                issueText: state.oldValue
            }
        }));
    }

    handleInputChange(e) {
        const target = e.target;
        const value = target.value;
        this.setState((state) => ({
            issue: {
                ...state.issue,
                issueText: value
            },
            chars: value.length
        }));
    }

}

export default withUser(IssueItem);