import React, {useEffect, useRef, useState} from 'react';
import {Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select} from "@mui/material";
import {withUser} from "../../../../context/UserContext";
import {BarsOutlined, DeleteOutlined, PlusCircleOutlined} from "@ant-design/icons";
import DataGrid, {textEditor} from "react-data-grid";
import {Rest} from "../../../../util/rest";
import {toast} from "react-toastify";


function DataTypeOptionsEditor({open, optionsRow, close, user}) {
    const dataGridRef = useRef();
    const [rows, setRows] = useState([]);

    const columns = [
        {key: 'name', name: 'Name', width: 200, editor: textEditor},
        {key: 'value', name: 'Value', width: 200, editor: textEditor},
        {
            key: 'remove', name: '', width: 20, formatter: (p) => (
                    <div style={{textAlign: "center"}}>
                        <DeleteOutlined title="Delete Attribute" className={"attributes-delete-icon"}
                                        onClick={() => { deleteOption(p.row.id); }}/>
                    </div>
            )
        }

    ];

    useEffect(() => {
        if(optionsRow && optionsRow.id > 0)
            loadOptions();
    }, [])

    useEffect(() => {
        const newRow = rows.findIndex((a) => a.hasOwnProperty("newAttribute") && a.newAttribute)
        if(newRow >= 0) {
            dataGridRef.current.selectCell({idx: 0, rowIdx: newRow}, true);
        }
    }, [rows]);

    function loadOptions() {
        Rest.authFetch(user, `/rest/health/element/type-class/health-data-element/attribute/${optionsRow.id}/options`)
                .then(response => {
                    if(response.status === 401 || response.status === 403) {
                        toast.error("Unauthorized!")
                    }
                    else {
                        setRows(response);
                    }
                })
                .catch(err => {
                    console.log(err);
                })
    }

    function saveOption(option) {
        Rest.authFetch(user, `/rest/health/element/type-class/health-data-element/attribute/option`, {
            method: "PUT",
            body: JSON.stringify(option)
        })
                .then(response => {
                    if(response.status === 401 || response.status === 403) {
                        toast.error("Unauthorized!")
                    }
                    else {
                        loadOptions();
                    }
                })
                .catch(err => {
                    console.log(err);
                })
    }

    function deleteOption(optionId) {
        Rest.authFetch(user, `/rest/health/element/type-class/health-data-element/attribute/option/${optionId}`, {
            method: "DELETE"
            
        })
                .then(response => {
                    if(response.status === 401 || response.status === 403) {
                        toast.error("Unauthorized!")
                    }
                    else {
                        loadOptions();
                    }
                })
                .catch(err => {
                    console.log(err);
                })
    }

    function handleSetRows(rows, index) {
        console.log(rows, index);
        index.indexes.forEach((r) => {
            const row = rows[r];
            row.healthDataElementId = optionsRow.id;
            saveOption(row);
        })
    }

    function addOption() {
        if(rows.findIndex((a) => a.hasOwnProperty("newAttribute") && a.newAttribute) >= 0)
            return
        setRows(oldOptions => [...oldOptions, {
            id: 0,
            name: "New Option",
            value: "New Option",
            healthDataElementAttributeId:optionsRow.id,
            newAttribute: true
        }]);
    }

    return (
            <Dialog open={open}>
                <DialogTitle classes={{root: "editorHeader"}}>Options</DialogTitle>
                <DialogContent>
                    <div style={{padding: "3px 0"}}>
                        <span style={{fontWeight: "bold"}}>{optionsRow.name}</span>
                        <span style={{float: "right"}}>
                        <PlusCircleOutlined className={"attributes-delete-icon"} title={"Add Option"}
                                            onClick={() => addOption()}/>
                            </span>
                    </div>
                    <DataGrid columns={columns} rows={rows} onRowsChange={handleSetRows} ref={dataGridRef} style={{width: "100%"}}/>

                </DialogContent>
                <DialogActions id="action-buttons">
                    <Button variant="contained" className={"editorButton"} onClick={() => close(false)}>
                        Close
                    </Button>
                </DialogActions>

            </Dialog>

    );
}

export default withUser(DataTypeOptionsEditor);