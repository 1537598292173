import React, {Component} from 'react';
import Pagination from "@mui/material/Pagination";
import "./AdminHeader.css";
import Checkbox from "@mui/material/Checkbox";
import {FormControlLabel} from "@mui/material";

class AdminHeader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filters: props.filters
        };
        this.filterChanged = this.filterChanged.bind(this);

    }

    filterChanged(e) {
        const filter = e.target.name;
        const filters = this.state.filters;
        const filterIndex = filters.findIndex(f => f.filter === filter);
        if(filterIndex >= 0) {
            filters[filterIndex] = {...filters[filterIndex], checked: e.target.checked}
            this.setState({filters: filters}, () => {
                if(this.props.onFilterChanged) {
                    this.props.onFilterChanged(this.state.filters);
                }
            })
        }
    }

    render() {
        return (
                <div className="container-fluid admin-header">
                    <div className="row">
                        <div className="col-12  col-md-7 col-lg-8 name">{this.props.label}</div>
                        <div className="col-10 col-md-4 col-lg-3 d-flex align-items-center">
                            <input
                                    name="searchTerm"
                                    type="text"
                                    placeholder="&#xf002; Search"
                                    title="Search"
                                    required={true}
                                    autoFocus={true}
                                    onFocus={(event) => event.target.select()}
                                    onKeyUp={this.props.onChange}
                                    charSet="utf-8"
                                    className={"search-term"}
                            />
                        </div>
                        <div className="col-2 col-md-1 icon d-flex align-items-center" onClick={this.props.onClickIcon}>
                            {this.props.icon}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-6 name pagination d-flex justify-content-center">
                            {this.props.onPageChange &&
                             <Pagination
                                     count={this.props.totalPages}
                                     page={this.props.pageNumber}
                                     onChange={this.props.onPageChange}
                             />
                            }
                        </div>
                        <div className="col-12 col-lg-6 filters d-flex justify-content-center">
                            {this.state.filters &&
                             <div className={"row"} style={{
                                 paddingLeft: "var(--bs-gutter-x)",
                                 paddingRight: "var(--bs-gutter-x)"
                             }}>
                                 {this.state.filters.map((f) =>
                                                                 <div className={"col"} key={f.filter}
                                                                      style={{margin: "auto"}}>
                                                                     <FormControlLabel control={<Checkbox
                                                                             key={f.filter}
                                                                             checked={f.checked}
                                                                             name={f.filter}
                                                                             onChange={(e) => this.filterChanged(e)}
                                                                             classes={{
                                                                                 root: 'Checkbox-alt',
                                                                                 checked: "checked"
                                                                             }}
                                                                     />
                                                                     }
                                                                                       classes={{label: "checkbox-label"}}
                                                                                       label={f.filterTitle}/>
                                                                 </div>
                                 )
                                 }
                             </div>
                            }
                        </div>
                    </div>
                </div>
        );
    }
}

export default AdminHeader;