import React, {Component} from 'react';
import MultipleAutocompleteDialog from "../MultipleAutocompleteDialog"
import {withUser} from "../../../../context/UserContext";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import {FormLabel} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {Rest} from "../../../../util/rest";
import toast from "../../../../util/toast";

class TrainingEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            trainings: this.props.trainings,
            options: [],
            documents: [],
            prerequisites: []
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.searchOptions = this.searchOptions.bind(this);
        this.handleTrainingRemove = this.handleTrainingRemove.bind(this);
        this.handleOptionSelected = this.handleOptionSelected.bind(this);
        this.getDocuments = this.getDocuments.bind(this);
    }

    componentDidMount() {
        this.getDocuments()
    }

    render() {
        return (
            <MultipleAutocompleteDialog
                value={this.state.trainings}
                onDialogSubmit={(training) => this.saveTraining(training)}
                onOptionSelected={this.handleOptionSelected}
                onInputChange={this.handleInputChange}
                onChipDelete={this.handleTrainingRemove}
                defaultEmptyValues={{version: "1.0", linkType: "internal"}}
                options={this.state.options}
                optionName={"Training"}
                optionDisplayProp={"name"}
                dialogContent={(dialogValue, getProps, uploadPhoto) =>
                    <div className={"row"}>
                        <div className={"col-sm-7"}>
                            <div className={"row"}>
                                <div className={"col-sm-12"}>
                                    <TextField
                                        fullWidth
                                        autoFocus={true}
                                        margin="dense"
                                        onFocus={(event) => event.target.select()}
                                        name={"name"}
                                        value={dialogValue.name}
                                        label="Name"
                                        {...getProps}
                                        className="textField"
                                        variant="outlined"
                                    />
                                </div>
                                <div className={"col-sm-12"}>
                                    <TextField
                                        fullWidth
                                        margin="dense"
                                        onFocus={(event) => event.target.select()}
                                        name={"keywords"}
                                        value={dialogValue.keywords}
                                        label={"Keywords"}
                                        {...getProps}
                                        className="textField"
                                        variant="outlined"
                                    />
                                </div>
                                <div className={"col-sm-12"}>
                                    <TextField
                                        fullWidth
                                        margin="dense"
                                        onFocus={(event) => event.target.select()}
                                        multiline={true}
                                        rows={4}
                                        name={"description"}
                                        value={dialogValue.description}
                                        {...getProps}
                                        label={"Description"}
                                        className="textField"
                                        variant="outlined"
                                    />
                                </div>
                                <div className={"col-sm-12"}>
                                    <TextField
                                        fullWidth
                                        margin="dense"
                                        onFocus={(event) => event.target.select()}
                                        name={"header_text"}
                                        value={dialogValue.headerText}
                                        {...getProps}
                                        label={"Header Text"}
                                        className="textField"
                                        variant="outlined"
                                    />
                                </div>
                                <div className={"col-sm-12"}>
                                    <TextField
                                        fullWidth
                                        margin="dense"
                                        onFocus={(event) => event.target.select()}
                                        name={"footer_text"}
                                        value={dialogValue.footerText}
                                        {...getProps}
                                        label={"Footer Text"}
                                        className="textField"
                                        variant="outlined"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className={"row"}>
                                <div className="col-md-12 photo">
                                    {dialogValue.photo ?
                                        <img alt={dialogValue.name} src={dialogValue.imageType + ',' + dialogValue.photo} width={"100%"}/>
                                        : ""
                                    }
                                </div>
                                <div className="col-md-12 text-right" style={{paddingRight: 0}}>
                                    <Button variant="contained" className="editorButton" component='label'>
                                        Upload Image
                                        <input
                                            style={{display: 'none'}}
                                            type="file" name="photo"
                                            onChange={uploadPhoto}
                                            accept="image/*"
                                        />
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className={"col-sm-7 mt-3"}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend" className={"m-0"}>Link Type</FormLabel>
                                <RadioGroup row aria-label="linkType" name="linkType" value={dialogValue.linkType} {...getProps}>
                                    <FormControlLabel classes={{root: 'FormControlLabel'}} value="internal" control={<Radio classes={{root: 'radio'}}/>} label="Internal" />
                                    <FormControlLabel classes={{root: 'FormControlLabel'}} value="external" control={<Radio classes={{root: 'radio'}}/>} label="External" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div className={"col-sm-12 pr-0"}>
                            {dialogValue.linkType === "internal" ?
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel>Link</InputLabel>
                                    <Select
                                        name={"link"}
                                        className={"w-100 textField"}
                                        {...getProps}
                                        label="Link"
                                        value={dialogValue.link}
                                    >
                                        {this.state.documents.map((doc) => <MenuItem value={doc.contentId}>{doc.title}</MenuItem>)}
                                    </Select>
                                </FormControl>
                                :
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    onFocus={(event) => event.target.select()}
                                    name={"link"}
                                    value={dialogValue.link}
                                    {...getProps}
                                    label={"Link"}
                                    className="textField"
                                    variant="outlined"
                                />
                            }
                        </div>
                        <div className={"col-sm-7"}>
                            <TextField
                                fullWidth
                                margin="dense"
                                onFocus={(event) => event.target.select()}
                                name={"achievement"}
                                value={dialogValue.achievement}
                                {...getProps}
                                label={"Achievement"}
                                className="textField"
                                variant="outlined"
                            />
                        </div>
                        <div className={"col-sm-5 px-0"}>
                            <TextField
                                fullWidth
                                margin="dense"
                                onFocus={(event) => event.target.select()}
                                name={"version"}
                                value={dialogValue.version}
                                {...getProps}
                                label={"Version"}
                                className="textField"
                                variant="outlined"
                            />
                        </div>
                        <div className="col-md-12 py-3 multiple-autocomplete-dialog pr-0">
                            <Autocomplete
                                multiple
                                fullWidth
                                onChange={(event, newValue) => getProps.onChange({target: {name: "prerequisites", value: newValue}}) }
                                name={"prerequisites"}
                                value={dialogValue.prerequisites}
                                onInputChange={(event, searchTerm) => this.searchPrerequisites(searchTerm, dialogValue.id)}
                                options={this.state.prerequisites}
                                getOptionLabel={(option) => option.name}
                                filterSelectedOptions
                                renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option, index) => (
                                        <Chip
                                            label={option.name}
                                            {...getTagProps({ index })}
                                            onDelete={() => getProps.onChange({target: {name: "prerequisites", value: dialogValue.prerequisites.filter((p) => p.id !== option.id)}}) }
                                            className={"chip-item"}
                                            variant={"outlined"}
                                            avatar={<Avatar alt={option.name} src={option.imageType + ',' + option.photo} />}
                                        />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Prerequisites" />
                                )}
                            />
                        </div>
                    </div>
                }
            />
        );
    }

    handleInputChange(event, searchTerm) {
        this.searchOptions(searchTerm)
    }

    searchOptions(searchTerm) {
        if (searchTerm.trim() === "") {
            this.setState({
                options: []
            });
        } else {
            Rest.authFetch(this.props.user, `/rest/searchTrainings/${searchTerm}`).then(response => {
                if(response.status === 401 || response.status === 403) {
                    toast.error("Unauthorized!")
                }
                else {
                    this.setState((state) => ({
                        options: response.filter((t) => !state.trainings.map(t => t.id).includes(t.id))
                    }));
                }
            })
            .catch(err => {
                console.log(err);
            })
        }
    }

    handleTrainingRemove(training) {
        this.setState((state) => ({
            trainings: state.trainings.filter((t) => t.id !== training.id)
        }), () => this.props.onTrainingsChange(this.state.trainings))
    }

    handleOptionSelected(trainings) {
        this.setState({
            trainings: trainings
        }, () => this.props.onTrainingsChange(this.state.trainings))
    }

    getDocuments() {
        Rest.authFetch(this.props.user, `/rest/content`).then(response => {
            if(response.status === 401 || response.status === 403) {
                toast.error("Unauthorized!")
            }
            else {
                this.setState({
                    documents: response
                });
            }
        })
        .catch(err => {
            console.log(err);
        })
    }

    searchPrerequisites(searchTerm, trainingId) {
        if (searchTerm.trim() === "") {
            this.setState({
                prerequisites: []
            });
        } else {
            Rest.authFetch(this.props.user, `/rest/searchPrerequisites/${searchTerm}?trainingId=${trainingId}`).then(response => {
                if(response.status === 401 || response.status === 403) {
                    toast.error("Unauthorized!")
                }
                else {
                    this.setState({
                        prerequisites: response
                    });
                }
            })
                .catch(err => {
                    console.log(err);
                })
        }
    }

    saveTraining(training) {
        Rest.authFetch(this.props.user, `/rest/trainings/${training.id}`, {
            method: "PUT",
            body: JSON.stringify(training)
        })
        .then(response => {
            if(response) {
                this.setState((state) => ({
                    trainings: training.id < 1 ? [...state.trainings, response] : state.trainings.map((t) => t.id === response.id ? response : t)
                }), () => this.props.onTrainingsChange(this.state.trainings));
            }
            else {
                toast.error("Error saving Training");
            }
        });
    }
}

export default withUser(TrainingEditor);