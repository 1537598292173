import React, {Component} from 'react';
import {Rest} from "../../../../util/rest";
import {withUser} from "../../../../context/UserContext";
import TeamItem from "./TeamItem";
import AdminHeader from "../AdminHeader";
import {UsergroupAddOutlined} from "@ant-design/icons";
import {toast} from "react-toastify";

class TeamAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teams: [],
            isLoading: true
        };

        this.newTeam = this.newTeam.bind(this);
        this.endEdit = this.endEdit.bind(this);
        this.teamDeleted = this.teamDeleted.bind(this);
    }

    componentDidMount() {
        this.getTeams("");
    }

    render() {
        const emptyStyle = this.state.empty;
        const teams = this.state.teams;
        return (
                <div>
                    <AdminHeader
                            empty={emptyStyle}
                            label={"Teams"}
                            onChange={(event) => this.getTeams(event.target.value)}
                            onClickIcon={this.newTeam}
                            icon={<UsergroupAddOutlined title="Add Team"/>}
                    />
                    <div>
                        {this.state.isLoading &&
                         <div className="text-center my-5">
                             <button className="btn btn-primary-color btn-lg rounded shadow-sm" type="button" disabled>
                                 <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"/>
                                 Loading...
                             </button>
                         </div>}
                        {
                            teams.map(team => {
                                return <TeamItem key={team.id} team={team} onEditMode={team.new}
                                                 onEndEdit={this.endEdit} onDelete={this.teamDeleted}/>
                            })
                        }
                    </div>
                </div>
        );
    }

    newTeam() {
        this.setState({
                          teams: [{id: 0, name: "New Team", members: [], new: true}],
                          empty: "",
                          onEdit: true
                      })
    }

    endEdit(team) {
        if(!team || team.id === 0)
            this.setState({teams: [], onEdit: false});
    }

    teamDeleted() {
        this.getTeams("");
    }

    getTeams(searchTerm) {
        this.setState({isLoading: true})
        Rest.authFetch(this.props.user, `/rest/staff/searchTeam/${searchTerm}`).then(response => {
            if(response.status === 401 || response.status === 403) {
                this.setState({teams: []});
                toast.error("Unauthorized!")
                this.setState({isLoading: false})

            }
            else {
                this.setState({
                                  teams: response.content,
                                  isLoading: false
                              });
            }
        })
                .catch(err => {
                    console.log(err);
                })
    }
}


export default withUser(TeamAdmin);