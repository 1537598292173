import styled from 'styled-components';

const ResetPasswordStyle = styled.div`

    .form-header {
        text-align: center;
    }

    .otp-form {
        width: 300px;
	    margin: 0 auto;

        .divider {
            height: 5px;
        }

        /* Add a green text color and a checkmark when the requirements are right */
        .valid {
            color: green;
        }

        .valid:before {
            position: relative;
            left: -35px;
            content: "✔";
        }

        /* Add a red text color and an "x" when the requirements are wrong */
        .invalid {
            color: red;
        }

        .invalid:before {
            position: relative;
            left: -35px;
            content: "✖";
        }
        .action-buttons {
            display: flex;
            justify-content: space-between;

            button {
                flex-basis: 48%;
            }

            .resend-otp-btn {

            }
            .submit-btn {
                text-align: right;
                color: #319946;
                border: 1px solid #319946;
            }

            .disable-submit-btn {
                cursor: not-allowed;
            }
        }

    }

        /* Add a green text color and a checkmark when the requirements are right */
            .valid {
            color: green;
        }

        .valid:before {
            position: relative;
            left: -35px;
            content: "✔";
        }

        /* Add a red text color and an "x" when the requirements are wrong */
        .invalid {
            color: red;
        }

        .invalid:before {
            position: relative;
            left: -35px;
            content: "✖";
        }
`;

export { ResetPasswordStyle };
