import React, {Component} from 'react';
import {flow as compose} from 'lodash';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import {Auth} from "../../util/auth";
import {withUser} from "../../context/UserContext";
import {withNavigate} from "../../util/NavigateHO"

const inputLabelProps = {
    formlabelclasses: {
        root: 'textField',
        focused: 'focused'
    }
};

class NewLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true
        };
        this.login = this.login.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleKeyDown = this.handleKeyDown.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    render() {
        return (
                <Dialog open={this.state.open}
                        fullWidth={true}
                        maxWidth={"xs"}
                        onClose={this.handleClose}
                        aria-labelledby="form-dialog-title"
                        className={"login-dialog"}
                >
                    <DialogTitle classes={{root: "editorHeader"}} id="form-dialog-title">Smart PCMH</DialogTitle>
                    <DialogContent className="container-fluid pcmh-profile-editor">
                        <fieldset>
                            <div className="row">
                                <div className="col-md-12">
                                    <TextField
                                            name="username"
                                            type="text"
                                            placeholder="Username"
                                            title="Username"
                                            required={true}
                                            autoFocus={true}
                                            autoComplete="username"
                                            formEncType="application/json"
                                            onKeyUp={this.handleInputChange}
                                            margin="dense"
                                            label="Username"
                                            variant="outlined" InputLabelProps={inputLabelProps}
                                            className="textField" fullWidth
                                            InputProps={{
                                                startAdornment: (
                                                        <InputAdornment position="start">
                                                            <UserOutlined/>
                                                        </InputAdornment>
                                                ),
                                            }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <TextField name="password"
                                               required={true}
                                               placeholder="Password"
                                               title="Password"
                                               type="password"
                                               formEncType="application/json"
                                               onKeyUp={this.handleInputChange}
                                               onKeyDown={this.handleKeyDown}
                                               margin="dense" label="Password" variant="outlined"
                                               InputLabelProps={inputLabelProps} className="textField" fullWidth
                                               InputProps={{
                                                   startAdornment: (
                                                           <InputAdornment position="start">
                                                               <LockOutlined/>
                                                           </InputAdornment>
                                                   ),
                                               }}
                                    />

                                </div>
                            </div>
                            <div className="row">
                            </div>

                        </fieldset>

                    </DialogContent>
                    <div className="editorFooter"
                         style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Button
                                style={{color: 'forestgreen'}}
                                onClick={() => <Navigate to="/reset-password"/>}
                        >Forgot Password?
                        </Button>
                        <DialogActions>

                            <Button onClick={this.login} className="editorButton" variant="contained">Login</Button>

                        </DialogActions>
                    </div>
                </Dialog>
        );
    }

    login(event) {
        event.preventDefault();
        Auth.login(this.state.username, this.state.password)
                .then((authResult) => {
                    this.setState({
                                      authenticationError: !authResult.authenticated
                                  });
                    if(authResult.authenticated) {
                        this.setState({
                                          authenticated: true,
                                          open: false
                                      });
                        this.props.setUser(authResult.user);
                        this.props.navigate(-1);
                    }
                });
    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        this.setState({
                          [name]: value,
                          authenticationError: false
                      });
    }

    handleKeyDown(event) {
        if(event.key === "Enter") {
            this.login(event)
        }
    }

    handleClose(event, reason) {
        if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            // Set 'open' to false, however you would do that with your particular code.
            this.setState({open: false});
        }
    }
}

export default compose(
        withUser, withNavigate
)(NewLogin);