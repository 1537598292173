import React, {useEffect, useState} from 'react';
import {withUser} from "../../../context/UserContext";
import ReactDataGrid from "react-data-grid";
import {Rest} from "../../../util/rest";

function AssessmentsBatches(props) {
    const dateFormatter = new Intl.DateTimeFormat(navigator.language);

    function TimestampFormatter({timestamp}: { timestamp: number }) {
        return <span style={{width: "100%", textAlign: "center"}}>{dateFormatter.format(timestamp)}</span>;
    }

    const columns = [
        {key: "personName", name: "Assigned to", editable: false, width: 180},
        {key: "assignableName", name: "Assessment", editable: false, width: 400},
        {
            key: "dateCreated", name: "Assigned Date", editable: false, width: 120,
            formatter(props) {
                return <TimestampFormatter timestamp={new Date(props.row.dateCreated)}/>;
            }
        },
        {
            key: "dateExpires", name: "Expiration Date", editable: false, width: 120,
            formatter(props) {
                return <TimestampFormatter timestamp={new Date(props.row.dateExpires)}/>;
            }
        },
        {
            key: "dateStarted", name: "Date Started", editable: false, width: 120,
            formatter(props) {
                return props.row.dateStarted === null ? <span>N/A</span> :
                       <TimestampFormatter timestamp={new Date(props.row.dateExpires)}/>;
            }
        }
    ];

    const [rows, setRows] = useState([])


    useEffect(() => {
        loadAssessments();
    }, []);

    function loadAssessments() {
        Rest.authFetch(props.user, "/rest/assignment/assessments")
                .then(response => {
                    if(response) {
                        setRows(response.content);
                    }
                });

    }


    return (
            <div>
                <ReactDataGrid
                        columns={columns}
                        rows={rows}

                        //rowKeyGetter={key => }
                        // rowGetter={rowIdx =>{
                        //     let data =  rows[rowIdx];
                        //     data.get = (key) => {
                        //         let splittedKey = key.split(".");
                        //         if(data && key.includes('.')) {
                        //             let current = data;
                        //             splittedKey.forEach((splitKey) => {
                        //                 if(current) {
                        //                     current = current[splitKey];
                        //                 }
                        //             });
                        //             return current;
                        //         } else {
                        //             return data[key]
                        //         }
                        //     };
                        //     return data;
                        // }}

                        rowsCount={1}
                        // onGridRowsUpdated={this.onGridRowsUpdated}
                        enableCellSelect={false}
                />
            </div>

    );
}

export default withUser(AssessmentsBatches);