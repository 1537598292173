import React, {Component} from 'react';
import {StarFilled, StarOutlined} from "@ant-design/icons";
import './Login.css'

const TOTAL_STARS=5;
class Achievements extends Component{
    
    render(){
        const achievements=this.props.stars;
        let i=0;
        let stars=[];
        for(i=0;i<TOTAL_STARS;i++){
             stars[i] = i < achievements;
        }
        return(
                <div className="pcmh-login achievements">
                    {stars.map((value, index) => {
                        return value ? <StarFilled key={index} className="achievement"/> :
                               <StarOutlined key={index} className="achievement"/>
                    })}
                </div>     
        );
    }        
}
export default Achievements;