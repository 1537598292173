import React from 'react'
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";


const DataSet = (props) => {

    const editing = () => {
        if(props.onEdit)
            props.onEdit(props.dataSet)
    }
    const deleting = () => {
        if(props.onDelete)
            props.onDelete(props.dataSet)
    }

    return (
            <div>
                {props.dataSet &&
                 <div className="dataset-box container-fluid">
                     <div className="row">
                         <div className="col-4 name">
                             {props.dataSet.name}
                         </div>
                         <div className="col-5 col-lg-4">
                             {props.dataSet.description}
                         </div>
                         <div className="col-3 col-lg-4">
                             <div className="row justify-content-end my-auto">
                                 {props.dataSet &&
                                  <>
                                      <div className="col-2 edit my-auto" onClick={() => editing()}>
                                          <EditOutlined title="Edit Data Set"/>
                                      </div>
                                      <div className="col-2 edit my-auto" title="Delete" onClick={() => deleting()}>
                                          <DeleteOutlined title="Delete Class Type"/>
                                      </div>
                                  </>
                                 }
                             </div>
                         </div>
                     </div>
                 </div>
                }
            </div>
    )

}

export default DataSet;