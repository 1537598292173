import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.css";
import "../../../SmartPcmhClientApp.css";

import Team from "./Team";
import Section from "../common/Section";
import Tab from "../common/Tab";
import ContentAdmin from "../admin/content/ContentAdmin";
class StaffSupport extends Component {

    render() {
        return (
            <div className={"staff-support"}>
                <Section title="Staff Support" className={"section"}>
                    <Tab text="Team" content={Team} active />
                    <Tab text="Library" content={ContentAdmin} />
                </Section>
            </div>
        )
    }
}

export default StaffSupport;