import React, {Component} from 'react';
import './AgendaTemplate.css'
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";

class AgendaTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            template: props.template
        };

        this.onEdit = this.onEdit.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    render() {
        const template = this.state.template;
        return (
            <div className="agendaItem container-fluid">
                <div className="row">
                    <div className="col-md-6 col-lg-8 col-xl-9 name my-auto">
                        {template.name}
                    </div>
                    <div className="col-md-2 col-xl-1 my-auto">
                        <span className="items" title="Items">{this.state.template.items.length}</span>
                    </div>
                    <div className="col-md-2 col-lg-1 edit my-auto" onClick={this.onEdit} title="Edit">
                        <EditOutlined title="Edit"/>
                    </div>
                    <div className="col-md-2 col-lg-1 edit my-auto" onClick={this.onDelete}>
                        <DeleteOutlined title="Delete"/>
                    </div>
                </div>
            </div>
        );
    }

    onEdit() {
        if(this.props.onEdit)
            this.props.onEdit(this.state.template)
    }

    onDelete() {
        if(this.props.onDelete)
            this.props.onDelete(this.state.template.id)
    }

}

export default AgendaTemplate;