import React, {useState} from 'react';
import AssignmentsTable from './AssignmentsTable';
import SubAssignmentsTable from "./SubAssignmentsTable";
import "./AssignmentsDialog.css";
import PersonAssignDialog from "./PersonAssignDialog";
// import useNavigate from "react-router-dom";
import {PlusCircleOutlined} from "@ant-design/icons";
import PersonAssignDialog2 from "./PersonAssignDialog2";
import {useNavigate} from "react-router-dom";

export default function AssignmentsListTables(props) {
    const [openDialog, setOpenDialog] = useState(false);
    const [assignmentType, setAssignmentType] = useState("");
    const history = useNavigate();

    const handleClose = () => {
        setOpenDialog(false)
    }
    const caretStyle = {
        outline: 0,
        cursor: "pointer"
    }
    const handleOpenDialog = (assignmentType) => {
        // setAssignmentType(assignmentType)
        setOpenDialog(true)
    }

    return (
            <div id="assignments-dialog" className="card pcmh-profile-card"
                 style={{overflowY: "auto", overflowX: "hidden"}}>
                <div className={"card-header pcmh-profile-card-header"}>
                    <div className={"row"}>
                        <div className={"col-11"}>{"Assignments"}</div>
                        <div className={"col-1 text-right center middle"}>
                            <PlusCircleOutlined type="plus-circle" title={"Add Assignment"}
                                                style={caretStyle}
                                                onClick={() => handleOpenDialog()}
                            />
                        </div>
                    </div>
                </div>
                <AssignmentsTable refresh={openDialog} personType={props.personType} personId={props.person.id}
                                  assignmentType={"assessment"} onClickAdd={handleOpenDialog}/>
                <AssignmentsTable refresh={openDialog} personType={props.personType} personId={props.person.id}
                                  assignmentType={"external"} onClickAdd={handleOpenDialog}/>
                {/*<SubAssignmentsTable refresh={openDialog} personType={props.personType} personId={props.person.id}*/}
                {/*                     parentName={"curriculum"} assignmentType={"training"}*/}
                {/*                     onClickAdd={handleOpenDialog}/>*/}

                <PersonAssignDialog2
                        open={openDialog}
                        url={"/rest/patients/searchPatient"}
                        onClose={handleClose}
                        personType={props.personType}
                        person={props.person}
                        assignmentType={assignmentType}
                        history={history}
                />
            </div>
    );
}