import React, {Component} from 'react'
import {withUser} from "../../../context/UserContext";
import SectionHeader from "./SectionHeader";
import PropTypes from 'prop-types';

class Section extends Component {

    constructor(props) {
        super(props);
        this.state = {
            content: null,
            activeTab: ""
        };
        this.onClickHandler = this.onClickHandler.bind(this);
    }

    componentDidMount() {
        this.setChildren(false);
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if(prevProps.children !== this.props.children) {
            this.setChildren(true);
        }
    }

    setChildren(contentOnly) {
        let active = null;
        React.Children.map(this.props.children, tab => {
            if(contentOnly) {
                if(tab.props.text === this.state.activeTab) {
                    this.setState({
                                      content: tab.props.content
                                  });
                }
            }
            else if(tab.props.active === true) {
                this.setState({
                                  activeTab: tab.props.text,
                                  content: tab.props.content
                              });
                active = tab.props.text;
            }
        });
        if(!contentOnly && active === null && this.props.children && this.props.children.length > 0) {
            const tab = this.props.children[0];
            this.setState({
                              activeTab: tab.props.text,
                              content: tab.props.content
                          });
        }
    }

    onClickHandler(event, text, content) {
        this.setState({
                          content: content,
                          activeTab: text
                      });
        event.preventDefault();
    }

    render() {
        const activeTab = this.state.activeTab;
        return (

                <div className="section">
                    <div>
                        <SectionHeader title={this.props.title}>
                            {React.Children.map(this.props.children, tab => {
                                const {active, ...rest} = tab.props;
                                if(tab.props.text === activeTab) {
                                    return React.createElement(tab.type, {
                                        active: true,
                                        onClick: this.onClickHandler, ...rest
                                    });
                                }
                                else
                                    return React.createElement(tab.type, {onClick: this.onClickHandler, ...rest});
                            })}
                        </SectionHeader>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div id="tab-contents" className="tab-contents">
                                {
                                    this.state.content ? typeof this.state.content === "function" ?
                                                         React.createElement(this.state.content, this.state.content.props) :
                                                         this.state.content : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

Section.propTypes = {
    title: PropTypes.string
};
export default withUser(Section);