import React, {useEffect, useState} from 'react';
import './Today.css'
import TodayDataElementHeader from "./TodayDataElementHeader";
import {tConvert} from "../../../../util/format";
import AppointmentPatient from "./AppointmentPatient";
import PatientItem from "./PatientItem";
import {withUser} from "../../../../context/UserContext";
import {Rest} from "../../../../util/rest";
import {toast} from "react-toastify";
import moment from "moment/moment";


function PatientData(props) {
    const [dataElements, setDataElements] = useState([]);
    const [records, setRecords] = useState([])

    useEffect(() => {
        loadDataElements();
        loadRecords();
    }, []);

    function loadDataElements() {
        let path = "/rest/huddle/dataelements";
        Rest.authFetch(props.user, path)
                .then(response => {
                          if(response.status === 401 || response.status === 403)
                              toast.error("Unauthorized!")
                          else if(response.status === 500)
                              toast.error("Error loading templates");
                          else {
                              setDataElements(response);
                          }
                      }
                )
                .catch(err => {
                    toast.error("Error loading templates");
                    console.log(err);
                });
    }

    function loadRecords() {

        if(props.listDate) {
            const searchDate = moment(props.listDate).format("yyyyMMDD");
            const path = "/rest/huddle/appointments/" + searchDate;
            Rest.authFetch(props.user, path).then(response => {
                if(response.status === 401 || response.status === 403)
                    toast.error("Unauthorized!")
                else if(response.status === 500)
                    toast.error("Error loading patient records");
                else {
                    setRecords(response);
                }
            })
                    .catch(err => {
                        toast.error("Error loading patient records");
                        console.log(err);
                    });
        }
    }


    return (
            <div className="pcmh-today-container">
                <table>
                    <thead>
                    <tr>
                        {dataElements && dataElements.length > 0 &&
                         dataElements.sort((e1, e2) => e1.position - e2.position)
                                 .map((element) =>
                                              <TodayDataElementHeader
                                                      key={element.id}
                                                      header={element.name}/>)
                        }
                    </tr>
                    </thead>
                    <tbody>

                    {records && records.length > 0 &&
                     records.map((appointment, index) =>
                                         <tr key={index}>
                                             <th><span className="time">{tConvert(appointment.time)}</span>
                                                 <AppointmentPatient
                                                         patient={appointment.patient}/>
                                             </th>
                                             {
                                                     dataElements && dataElements.length > 0 &&
                                                     dataElements.sort((e1, e2) => e1.position - e2.position)
                                                             .map((itemHeader, index) =>
                                                                          <PatientItem key={index}
                                                                                       appointment={appointment}
                                                                                       column={itemHeader}/>
                                                             )}
                                         </tr>
                     )}

                    </tbody>

                </table>
            </div>
    )
}

export default withUser(PatientData);