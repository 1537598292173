import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import './Messaging.css'
import {DeleteOutlined} from "@ant-design/icons";
// import Message from "./Message";
import {withUser} from "../../context/UserContext";
import {Rest} from "../../util/rest";
import toast from "../../util/toast";

const options = {
    year: 'numeric', month: 'numeric', day: 'numeric',
    hour: 'numeric', minute: 'numeric', second: 'numeric',
    hour12: true,
    timeZone: 'America/Puerto_Rico'
};

function MessageItem(props) {

    const [message, setMessage] = useState(props.message);


    useEffect(() => {
        if(props.message !== message)
            setMessage(props.message)
    }, [props.message]);


    return (
            <div>
                <div className={"message-item unSelectable"}>
                    <div className={"row"}>
                        <div className={"col-md-11"}>
                            <div className={"row"}>
                                <div className={"col-8 subject" + (message.readDateTime ? "" : " read")}>
                                    {message.announcement.subject}
                                </div>
                                <div className={"col-4 date"}>
                                    {Intl.DateTimeFormat('en-US', options).format(new Date(message.announcement.sentDateTime))}
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col-md-12 text"}>
                                    {message.announcement.message}
                                </div>
                            </div>
                        </div>
                        <div className={"col-md-1"} style={{margin: "auto"}}>
                            <div className={"row"}>
                                <div className={"col-md-12"}>
                                    <DeleteOutlined className={"delete-icon"}/>
                                    {/*<DeleteOutlined className={"delete-icon"} onClick={(e) => this.deleteMe(e)}*/}
                                    {/*                type="delete" title="Delete Message"/>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<Message open={reading} message={message} anchorEl={anchorEl} onClose={() => this.unShowMessage()}/>*/}
            </div>
    );

    deleteMe(e)
    {
        e.preventDefault();
        e.stopPropagation();
        if(this.props.deleteHandler) {
            this.props.deleteHandler(this.props.message, this.props.index)
        }
    }

    markAsRead(id)
    {
        Rest.authFetch(this.props.user, "/rest/messaging/read/" + id,
                       {
                           method: "PUT"
                       })
                .then(response => {
                    if(response.status === 401 || response.status === 403) {
                        this.setState({
                                          messageList: [],
                                      });
                        toast.error('Unauthorized!')
                    }
                    else {
                        this.getUnreadCount();
                    }
                })
                .catch(err => {
                    console.log(err);
                })

    }

    unShowMessage = () => {
        this.setState({reading: false})
    }

    function showMessage(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
                          reading: true,
                          anchorEl: e.currentTarget
                      })
        if(this.state.message.readDateTime == null) {
            this.markAsRead(this.state.message.id);
            this.setState((state) => ({
                message: {
                    ...state.message,
                    readDateTime: new Date().toISOString()
                }
            }));
        }
    }
}

MessageItem.protoTypes = {

    message: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    onRead: PropTypes.func
}
export default withUser(MessageItem);