import React, { Component } from 'react';
import Patient from "./Patient";
import { Rest } from "../../../util/rest";
import toast from "../../../util/toast";
import { withUser } from "../../../context/UserContext";
import { useLocation } from "react-router";

class PatientSupport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			patient: null
		}
		this.setPatient = this.setPatient.bind(this);

	}

	componentDidMount() {
		if (this.props.location.state && this.props.location.state.patientId && this.props.location.state.patientId > 0) {
			const patientId = this.props.location && this.props.location.state && this.props.location.state.patientId ? this.props.location.state.patientId : null;
			this.props.location.state = {};

			if (patientId) { this.getPatient(patientId); }
		}

	}

	render() {
		const active = this.patient != null;
		return (
			<div className={"patient-support"}>
				<Patient patient={this.state.patient}
					onPatientChanged={this.setPatient}
					active={active} />
			</div>
		);
	}

	setPatient(patient) {
		this.setState({ patient: patient })
	}

	getPatient(patientId) {
		if (patientId > 0) {
			Rest.authFetch(this.props.user, "/rest/patient/" + patientId)
				.then(response => {
					if (response.status === 401 || response.status === 403) {
						toast.error("Unauthorized!")
					}
					else {
						this.setState({
							patient: response
						});
					}
				})
				.catch(err => {
					console.log(err);
				})
		}

	}

}


function withMyHook(Component) {
	return function WrappedComponent(props) {
		const location = useLocation();
		return <Component {...props} location={location} />;
	}
}

export default withMyHook(withUser(PatientSupport));