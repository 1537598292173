import React, {Component} from 'react';
import {Rest} from "../../../../util/rest";
import {withUser} from "../../../../context/UserContext";
import './ProfileAdmin.css'
import ProfileItem from "./ProfileItem";
import ProfileEditor from "./ProfileEditor";
import {UserAddOutlined} from "@ant-design/icons";
import AdminHeader from "../AdminHeader";
import {toast} from "react-toastify";

class ProfileAdmin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            profiles: [],
            editing: false,
            pageNumber: 1,
            totalPages: 0,
            searchTerm: "",
            isLoading: true
        };
        this.addNewProfile = this.addNewProfile.bind(this);
        this.editProfile = this.editProfile.bind(this);
        this.editorCloseCallBack = this.editorCloseCallBack.bind(this);
        this.getProfiles = this.getProfiles.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
    }

    componentDidMount() {
        this.getProfiles("");
    }

    render() {
        const profiles = this.state.profiles ? this.state.profiles.sort((p1, p2) => {
            if(p1.firstName.toLocaleLowerCase() > p2.firstName.toLocaleLowerCase()) return 1;
            if(p1.firstName.toLocaleLowerCase() < p2.firstName.toLocaleLowerCase()) return -1;
            if(p1.firstLastName.toLocaleLowerCase() > p2.firstLastName.toLocaleLowerCase()) return 1;
            if(p1.firstLastName.toLocaleLowerCase() < p2.firstLastName.toLocaleLowerCase()) return -1;
            if(p1.secondLastName.toLocaleLowerCase() > p2.secondLastName.toLocaleLowerCase()) return 1;
            if(p1.secondLastName.toLocaleLowerCase() < p2.secondLastName.toLocaleLowerCase()) return -1;
            return 0;
        }) : [];
        const editing = this.state.editing;
        return (
                <div className={"profile-admin"}>
                    <AdminHeader
                            label={"Staff"}
                            onChange={(event) => this.getProfiles(event.target.value)}
                            onClickIcon={this.addNewProfile}
                            onPageChange={this.handlePageChange}
                            pageNumber={this.state.pageNumber}
                            totalPages={this.state.totalPages}
                            icon={<UserAddOutlined title="Add Member"/>}
                    />
                    {this.state.isLoading &&
                     <div className="text-center my-5">
                         <button className="btn btn-primary-color btn-lg rounded shadow-sm" type="button" disabled>
                             <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"/>
                             Loading...
                         </button>
                     </div>}
                    <div className={"profiles-container"}>
                        {
                            profiles.map((profile) => {
                                return <ProfileItem key={profile.id} profile={profile} onEdit={this.editProfile}/>
                            })
                        }
                    </div>
                    <ProfileEditor open={editing} profile={this.state.profile} editorClose={this.editorCloseCallBack}/>
                </div>

        )
    }

    editProfile(profile) {
        this.setState({
                          profile: profile,
                          editing: true
                      })
    }

    addNewProfile() {
        this.setState({
                          editing: true,
                          profile: getNewProfile()
                      });
    }

    handlePageChange(event, value) {
        this.setState({
            pageNumber: value
        }, () => this.getProfiles(this.state.searchTerm))
    };

    getProfiles(searchTerm) {
        this.setState({isLoading: true})
        Rest.authFetch(this.props.user, `/rest/staff/searchStaff/${searchTerm}?page=${this.state.pageNumber - 1}`).then(response => {
            if(response.status === 401 || response.status === 403) {
                this.setState({profiles: []});
                toast.error("Unauthorized!")
                this.setState({isLoading: false})
            }
            else {
                this.setState({
                                  profiles: response.content,
                                  pageNumber: response.number + 1,
                                  totalPages: response.totalPages,
                                  searchTerm: searchTerm,
                                  isLoading: false
                              });
            }
        })
        .catch(err => {
            console.log(err);
        })
    }

    editorCloseCallBack(profile) {
        this.setState({editing: false});
        if(profile) {
            let index = this.state.profiles.findIndex((sprofile => sprofile.id === profile.id));
            if(index >= 0) {
                let profiles = this.state.profiles;
                profiles[index] = profile;
                this.setState({profiles: profiles});
            }
        }
    }

}

export function getNewProfile() {
    return {
        id: 0,
        title: "",
        firstName: "",
        middleInitial: "",
        firstLastName: "",
        secondLastName: "",
        suffix: null,
        phone: "",
        fax: "",
        email: "",
        website: "",
        photo: "",
        fullName: "",
        imageType: "",
        typeOfPractice: ""
    }
}

export function flattenCommunications(comms) {
    const comm = {
        phone: "",
        fax: "",
        email: "",
        website: "",
        video: "",
        social: ""
    }
    if(comms) {
        //Search for phones that are not fax and get the preferred one if no preferred found get the first one.
        const phones = comms.filter((com) => com.communicationType === 'PHONE' && com.communicationSubType !== 'FAX')
        if(phones.length > 0) {
            let phone = phones.find((phone) => phone.preferred)
            if(phone === undefined)
                phone = phones[0];
            comm.phone = phone.communicationAddress;
        }
        const faxes = comms.filter((com) => com.communicationType === 'PHONE' && com.communicationSubType === 'FAX')
        if(faxes.length > 0) {
            let fax = faxes.find((fax) => fax.preferred)
            if(fax === undefined)
                fax = faxes[0];
            comm.fax = fax.communicationAddress;
        }
        const emails = comms.filter((com) => com.communicationType === 'EMAIL')
        if(emails.length > 0) {
            let email = emails.find((email) => email.preferred)
            if(email === undefined)
                email = emails[0];
            comm.email = email.communicationAddress;
        }
        const websites = comms.filter((com) => com.communicationType === 'WEBSITE')
        if(websites.length > 0) {
            let website = websites.find((website) => website.preferred)
            if(website === undefined)
                website = websites[0];
            comm.website = website.communicationAddress;
        }

        const videos = comms.filter((com) => com.communicationType === 'VIDEOCONFERENCE')
        if(videos.length > 0) {
            let video = videos.find((video) => video.preferred)
            if(video === undefined)
                video = videos[0];
            comm.video = video.communicationAddress;
        }

        const socials = comms.filter((com) => com.communicationType === 'SOCIALMEDIA')
        if(socials.length > 0) {
            let social = socials.find((social) => social.preferred)
            if(social === undefined)
                social = socials[0];
            comm.social = social.communicationAddress;
        }
    }
    return comm;
}
export default withUser(ProfileAdmin);