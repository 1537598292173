import React, {Component} from 'react';
import Button from "@mui/material/Button";
import './Assessment.css';
import Announcement from "../../announcements/Announcement";
import moment from "moment";
import {withUser} from "../../../context/UserContext";
import {Rest} from "../../../util/rest";

class AssessmentHeader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            assessment: props.assessment,
            readOnly: props.readOnly,
            validationErrors: props.validationErrors,
            person: null
        };
    }

    componentDidMount() {
        if(this.state.assessment)
            this.getPerson(this.state.assessment.personId);
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if(prevProps.readOnly !== this.props.readOnly)
            this.setState({readOnly: this.props.readOnly})
        if(prevProps.validationErrors !== this.props.validationErrors)
            this.setState({validationErrors: this.props.validationErrors})
        if(prevProps.assessment !== this.props.assessment) {
            this.setState({assessment: this.props.assessment})
            if(this.props.assessment)
                this.getPerson(this.props.assessment.personId)
        }
    }

    render() {
        const assigned = this.state.assessment && moment(this.state.assessment.dateCreated).format("MM/DD/YYYY") !== "Fecha invalida" ? moment(this.state.assessment.dateCreated).format("MM/DD/YYYY") : null
        const due = this.state.assessment && moment(this.state.assessment.dateDue).format("MM/DD/YYYY") !== "Fecha invalida" ? moment(this.state.assessment.dateDue).format("MM/DD/YYYY") : null
        const lastUpdate = this.state.assessment && moment(this.state.assessment.lastUpdate).format("MM/DD/YYYY") !== "Fecha invalida" ? moment(this.state.assessment.lastUpdate).format("MM/DD/YYYY") : null
        const completed = this.state.assessment && this.state.assessment.dateEnded && moment(this.state.assessment.dateEnded).format("MM/DD/YYYY") !== "Fecha invalida" ? moment(this.state.assessment.dateEnded).format("MM/DD/YYYY") : null
        const fullName = this.state.person ? this.state.person.fullName : "";
        const templateName = this.state.assessment && this.state.assessment.template ? this.state.assessment.template.name : ""
        return (
                <div className="header">
                    <div className={"p-3 shadow-sm border-bottom row"}>
                        <div className={"col-md-3 col-sm-3"}>
                            <div className="row">
                                <div className="col-md-12 person-name">
                                    {fullName}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 assessment-name">
                                    {templateName}
                                </div>
                            </div>
                        </div>
                        <div className={"col-md-3 col-sm-3 dates"}>
                            <div className="row">
                                <div className="col-md-6 text-right">
                                    {assigned ? <span className="date-header">Assigned on:</span> : null}
                                </div>
                                <div className="col-md-6">
                                    {assigned ? <span className="date-value">{assigned}</span> : null}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 text-right">
                                    {due ? <span className="date-header">Due on:</span> : null}
                                </div>
                                <div className="col-md-6">
                                    {due ? <span className="date-value">{due}</span> : null}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 text-right">
                                    {completed ? <span className="date-header completed">Completed:</span> :
                                     lastUpdate ? <span className="date-header">Last update:</span> : null}
                                </div>
                                <div className="col-md-6">
                                    {(completed || lastUpdate) ?
                                     <span className="date-value">{completed ? completed : lastUpdate ? lastUpdate : ""}</span> : null}
                                </div>
                            </div>
                        </div>

                        <div className={"col-md-4 col-sm-4"}>
                            <div className="row">
                                <div className="col-md-5">
                                    {!this.state.readOnly ?
                                     <Button onClick={(e) => this.handleClose(e)} variant="contained"
                                             className="editorButton">
                                         Save & Exit
                                     </Button> : null}
                                </div>
                                <div className="col-md-4">
                                    {!this.state.readOnly ?
                                     <Button onClick={(e) => this.handleSubmit(e)} variant="contained"
                                             className="editorButton">
                                         Submit
                                     </Button> : null}
                                </div>
                                <div className="col-md-3">
                                    {!this.state.readOnly ?
                                     <Button onClick={() => this.handleDiscard()} variant="contained"
                                             className="editorButton">
                                         Discard
                                     </Button> :
                                     <Button onClick={(e) => this.handleClose(e)} variant="contained"
                                             className="editorButton">
                                         Exit
                                     </Button>}
                                </div>
                            </div>

                        </div>

                        <div className="col-md-2 col-sm-2 float-right">
                            <Announcement/>
                        </div>
                    </div>
                    {this.state.validationErrors ?
                     <div className="row errors">
                         <div className="col-md-12">There are some errors in this assessment!</div>
                     </div> : null
                    }
                </div>
        );
    }

    getPerson(id) {
        Rest.authFetch(this.props.user, "/rest/person/" + id)
                .then(response => {
                    this.setState({person: response});
                })
                .catch(err => {
                    console.log(err);
                })
    }


    handleSubmit(e) {
        if(this.props.handleSubmit)
            this.props.handleSubmit(e);
    }

    handleClose() {
        if(this.props.history) {
            const path = (this.props.location.state && this.props.location.state.from && this.props.location.state.from.pathname) ? this.props.location.state.from.pathname : "/"
            this.props.history.push({
                                        pathname: path,
                                        state: {patientId: this.state.person.id}
                                    });
        }
    }

    handleDiscard() {
        if(this.state.assessment) {
            Rest.authFetch(this.props.user, "/rest/assessment/" + this.state.assessment.id, {
                method: 'DELETE'
            })
                    .then(() => {
                        this.handleClose();
                    })
                    .catch(err => {
                        console.log(err);
                    })
        }
    }
}

export default withUser(AssessmentHeader);