import React, {Component} from 'react';
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {Rest} from "../../../../util/rest";
import toast from "../../../../util/toast";
import {withUser} from "../../../../context/UserContext";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Avatar from "@mui/material/Avatar";
import DialogActions from "@mui/material/DialogActions";
import './CurriculumAdmin.css';
import TrainingEditor from "./TrainingEditor";

class Curriculum extends Component {
    constructor(props) {
        super(props);
        this.state = {
            curriculum: props.curriculum,
            trainings: [],
            dialogOpen: false,
            dialogValue: {}
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.searchTrainings = this.searchTrainings.bind(this);
        this.save = this.save.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.uploadPhoto = this.uploadPhoto.bind(this);
        this.handleDialogSubmit = this.handleDialogSubmit.bind(this);
        this.handlePrerequisiteDelete = this.handlePrerequisiteDelete.bind(this);
        this.handleTrainingsChange = this.handleTrainingsChange.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.curriculum !== this.props.curriculum) {
            this.setState({curriculum: this.props.curriculum})
        }
    }

    render() {
        return (
            <div className="curriculum-box container-fluid">
                <div className="row">
                    <div className="col-md-12 col-lg-5 col-xl-4 name my-auto d-flex justify-content-start" onClick={() => this.setState({dialogOpen: true, dialogValue: this.state.curriculum })} title={"Edit Curriculum"}>
                        <Avatar alt={this.state.curriculum.name} src={this.state.curriculum.imageType + "," + this.state.curriculum.photo} />
                        <span className={"ml-2"}>{this.state.curriculum.name}</span>
                    </div>
                    <div className={"col-md-8 col-lg-5 col-xl-6"}>
                        <TrainingEditor
                            trainings={this.state.curriculum.trainings}
                            onTrainingsChange={this.handleTrainingsChange}
                        />
                    </div>
                    <div className="col-md-2 col-lg-1 edit my-auto" onClick={() => this.setState({dialogOpen: true, dialogValue: this.state.curriculum})} title="Edit">
                        <EditOutlined title="Edit Curriculum"/>
                    </div>
                    <div className="col-md-2 col-lg-1 edit my-auto" onClick={() => this.props.onDelete(this.state.curriculum.id)}>
                        <DeleteOutlined title="Delete Curriculum"/>
                    </div>
                </div>

                <Dialog open={this.state.dialogOpen} fullWidth={true} onClose={this.handleClose} className={"curriculum-dialog"}>
                    <form onSubmit={this.handleDialogSubmit}>
                        <DialogTitle classes={{root: "editorHeader"}}>Edit Curriculum</DialogTitle>
                        <DialogContent>
                            <div className={"row"}>
                                <div className={"col-sm-7"}>
                                    <div className={"row"}>
                                        <div className={"col-sm-12"}>
                                            <TextField
                                                autoComplete="off"
                                                fullWidth
                                                autoFocus
                                                margin="dense"
                                                name="name"
                                                value={this.state.dialogValue.name}
                                                onChange={this.handleChange}
                                                onFocus={(event) => event.target.select()}
                                                label="Name"
                                                type="text"
                                                className="textField"
                                                variant="outlined"
                                            />
                                        </div>
                                        <div className={"col-sm-12"}>
                                            <TextField
                                                autoComplete="off"
                                                fullWidth
                                                onFocus={(event) => event.target.select()}
                                                margin="dense"
                                                name="achievement"
                                                value={this.state.dialogValue.achievement}
                                                onChange={this.handleChange}
                                                label="Achievement"
                                                type="text"
                                                className="textField textBox"
                                                variant="outlined"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className={"row"}>
                                        <div className="col-md-12 photo">
                                            {this.state.dialogValue.photo &&
                                                <img
                                                    alt={this.state.dialogValue.name}
                                                    src={this.state.dialogValue.imageType + ',' + this.state.dialogValue.photo}
                                                    width={"100%"}
                                                />
                                            }
                                        </div>
                                        <div className="col-md-12 text-right" style={{paddingRight: 0}}>
                                            <Button variant="contained" className="editorButton" component='label'>
                                                Upload Image
                                                <input
                                                    style={{display: 'none'}}
                                                    type="file" name="photo"
                                                    onChange={this.uploadPhoto}
                                                    accept="image/*"
                                                />
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-sm-12 py-3 multiple-autocomplete-dialog"}>
                                    {this.state.dialogValue.trainings &&
                                        <TrainingEditor
                                            trainings={this.state.dialogValue.trainings}
                                            onTrainingsChange={(trainings) => this.handleChange({target: {name: "trainings", value: trainings}})}
                                        />
                                    }
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions style={{borderTop : "1px solid rgba(169, 169, 169, 0.50)"}}>
                            <Button onClick={this.handleClose} variant="contained" className="editorButton">
                                Cancel
                            </Button>
                            <Button type="submit" variant="contained" className="editorButton">
                                Save
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </div>
        );
    }

    handlePrerequisiteDelete(trainingId, prerequisite) {
        this.setState((state) => ({
            curriculum: {
                ...state.curriculum,
                trainings: state.curriculum.trainings.map((t) => t.id === trainingId ? t.prerequisites.filter((t) => t !== prerequisite) : t)
            }
        }), () => this.save())
    }

    handleDialogSubmit(event) {
        event.preventDefault()
        this.setState((state) => ({
            curriculum: state.dialogValue,
            dialogOpen: false,
            dialogValue: {}
        }), () => this.save())
    }

    handleTrainingsChange(trainings) {
        this.setState((state) => ({
            curriculum: {
                ...state.curriculum,
                trainings: trainings
            }
        }), () => this.save())
    }

    uploadPhoto(e) {
        if(e.target.files && e.target.files[0]) {
            let file = e.target.files[0];
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) =>
                this.setState((state) => ({
                    filerError: e,
                    dialogValue: {
                        ...state.dialogValue,
                        photo: reader.result.split(',')[1],
                        imageType: reader.result.split(',')[0]
                    }
                }
            ));
        }
    }

    handleClose() {
        this.setState({
            dialogOpen: false,
            dialogValue: {}
        })
    }

    handleChange(event) {
        const {name, value} = event.target

        this.setState((state) => ({
            dialogValue: {
                ...state.dialogValue,
                [name]: value
            }
        }))
    }

    handleInputChange(event, searchTerm) {
        this.searchTrainings(searchTerm)
    }

    searchTrainings(searchTerm) {
        if (searchTerm.trim() === "") {
            this.setState({
                trainings: []
            });
        } else {
            Rest.authFetch(this.props.user, `/rest/searchTrainings/${searchTerm}`).then(response => {
                if(response.status === 401 || response.status === 403) {
                    toast.error("Unauthorized!")
                }
                else {
                    this.setState((state) => ({
                        trainings: response.filter((t) => !state.curriculum.trainings.map(t => t.id).includes(t.id))
                    }));
                }
            })
                    .catch(err => {
                console.log(err);
            })
        }
    }

    save() {
        Rest.authFetch(this.props.user, `/rest/curriculums/${this.state.curriculum.id}`, {
            method: "PUT",
            body: JSON.stringify(this.state.curriculum)
        })
            .then(response => {
                if(response) {
                    this.setState({
                        curriculum: response
                    });
                    this.props.onCurriculumChanged(response)
                }
                else {
                    toast.error("Error saving Curriculum");
                }
            });
    }
}

export default withUser(Curriculum);