import React, {useEffect, useState} from 'react'
import {withUser} from "../../../../context/UserContext";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import AttributeFilter from "./AttributeFilter";
import {reorder} from "../../../../util/reorder";
import FilterGroup from "./FilterGroup";

function FilterElementsView(props) {

    const [selectedFilters, setSelectedFilters] = useState([])
    const reorderFilters = ({source, destination}) => {
        console.log("reorderFilters Called", source, destination)
        if(!destination) {
            return;
        }

        // const filterGroups = props.filterGroups;
        // const filters = props.filters;
        // const reorderedAttributes = reorder(
        //         filters,
        //         source.index,
        //         destination.index
        // );
    };


    useEffect(() => {
        console.log("props.filterGroups->", props.filterGroups)
    }, [props]);


    return (
            <>
                <DragDropContext onDragEnd={reorderFilters}>
                    <Droppable droppableId="droppable-filter" direction="vertical">
                        {(droppableProvided) => (
                                <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                                    {props.filterGroups && props.filterGroups.sort((a, b) => a.order - b.order).map((filterGroup, index) => (
                                            <>
                                                <FilterGroup
                                                        key={index}
                                                        index={index}
                                                        filterGroup={filterGroup}
                                                        // onGroupUpdate={groupUpdate}
                                                />
                                            </>
                                    ))
                                    }
                                    {droppableProvided.placeholder}
                                </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </>
    )
}

export default withUser(FilterElementsView);