import React, {useEffect, useState} from 'react'
import TextField from "@mui/material/TextField/TextField";
import {withUser} from "../../../../context/UserContext";
import {Rest} from "../../../../util/rest";
import {toast} from "react-toastify";
import {Select} from "antd";


function CreateAgenda(props) {

    const [name, setName] = useState(null);
    const [createAgenda, setCreateAgenda] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    useEffect(() => {
        loadTemplates();
    }, []);

   
    function loadTemplates() {
        Rest.authFetch(props.user, "/rest/agenda/templates").then(response => {
            if(response.status === 401 || response.status === 403) toast.error("Unauthorized!")
            else if(response.status === 500)
                toast.error("Error loading templates");
            else {
                let selectTemplates=[]
                // let selectTemplates=[{value:0,label:"Select a template"}]
                response.map((template)=>selectTemplates.push({label:template.name,value:template.id,items:template.items}))
                setTemplates(selectTemplates);
            }
        })
    }

    function createAgendaItem() {
        if(!props.team) {
            toast.error('The user is not in a team');
            return;
        }
        console.log("templates->",templates);
        console.log("selectedTemplate->",selectedTemplate);
        if(name && name.length > 1 && selectedTemplate && selectedTemplate>0) {
            Rest.authFetch(props.user, "/rest/agenda", {
                method: "PUT",
                body: JSON.stringify({
                                         id: 0,
                                         name: name,
                                         meetingDate: props.date,
                                         items: templates.find((template)=> template.value===selectedTemplate).items,
                                         team: props.team
                                     })
            })
                    .then(response => {
                        if(response && response.id > 0) {
                            toast.success("Agenda created!");
                            if(props.onAgendaCreated)
                                props.onAgendaCreated(response)

                        }
                        else {
                            toast.error("Error creating agenda");
                        }
                    });
        }
    }

    return (
            <div className="card newAgendaBox">
                {!createAgenda &&
                 <div>
                     <div className="card-header text-center">
                         <div className="unSelectable">No agenda found for selected Date</div>
                     </div>
                     <div className="card-body align-middle">
                         <button className="smartpcmh-button unSelectable"
                                 onClick={() => setCreateAgenda(true)}>Create
                             Agenda?
                         </button>
                     </div>
                 </div>}
                {createAgenda &&
                 <div>
                     <div className="card-header text-center">New Agenda</div>
                     <div className="card-body smartpcmh-editor">
                         <div className="row">
                             <div className="col-md-10 offset-1">
                                 <TextField margin="dense" name="name" id="name"
                                            label="Agenda Name" type="text"
                                            variant="outlined"
                                            InputLabelProps={{classes: {root: 'textField', focused: 'focused'}}}
                                            className="textField" fullWidth
                                            onChange={(event) => setName(event.target.value)}
                                            autoComplete="off"
                                            size="small"
                                 />
                             </div>
                         </div>
                         <div className="row">
                             <div className="col-md-10 offset-1">
                                 <Select label="Select Template" 
                                         notFoundContent="Select Template"
                                         placeholder="Select Template"
                                         options={templates} 
                                         style={{width: "100%",margin:"10px 0 0 0"}}
                                         value={selectedTemplate} 
                                         onChange={(e, newValue) => setSelectedTemplate(newValue.value)}/>
                      
        
                             </div>
                         </div>
                         <div className="row align-bottom">
                             <div className="col-md-4 offset-1">
                                 <button className="smartpcmh-button" onClick={() => setCreateAgenda(false)}>Cancel</button>
                             </div>
                             <div className="col-md-6">
                                 <button className="smartpcmh-button" onClick={createAgendaItem}
                                         disabled={!selectedTemplate}>Create Agenda
                                 </button>
                             </div>
                         </div>
                     </div>
                 </div>
                }
            </div>
    );
}

export default withUser(CreateAgenda);