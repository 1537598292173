import React, {useEffect, useState} from 'react'
import {map} from "lodash";
import {Autocomplete, List, ListItemButton, ListItemText, TextField} from "@mui/material";
import {Rest} from "../../../../util/rest";
import {toast} from "react-toastify";
import {withUser} from "../../../../context/UserContext";
import {RightCircleOutlined} from "@ant-design/icons";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import AttributeDisplay from "./AttributeDisplay";


function HealthDataElementSelector(props) {
    const [healthElements, setHealthElements] = useState([]);
    const [availableAttributes, setAvailableAttributes] = useState([]);
    const [selectedAttribute, setSelectedAttribute] = useState(null);

    function ResultsWindow() { return props.ResultsWindows}


    const searchDataElements = async (searchTerm) => {
        if(!searchTerm) searchTerm = '';
        const response = await Rest.authFetch(
                props.user,
                `/rest/health/element/search-type-class/${searchTerm}?size=1000`,
                {
                    method: "GET"
                }
        );
        if(response.status === 401 || response.status === 403) toast.error("Unauthorized!")
        else if(response.status === 500)
            toast.error("Error Searching Health Elements")
        else {
            setHealthElements(response.content || []);
        }

    }

    const getAttributes = async (healthElementId) => {
        const response = await Rest.authFetch(
                props.user,
                `/rest/health/element/type-class/health-data-element/${healthElementId}/attributes`,
                {
                    method: "GET"
                }
        );
        if(response.status === 401 || response.status === 403) toast.error("Unauthorized!")
        else if(response.status === 500)
            toast.error("Error Getting Health Element Attributes")
        else {
            setAvailableAttributes(response);
        }
    };

    const healthElementHandler = (e, selectedValue) => {
        if(selectedValue) {
            getAttributes(selectedValue.value.id).then();
            // setEditElementsMode(false);
        }
        else {
            setAvailableAttributes([]);

        }
    }

    const addAttribute = () => {
        if(selectedAttribute && props.addAttribute)
            props.addAttribute(availableAttributes[selectedAttribute-1]);
    };

    return (
            <div style={{marginBottom: "10px"}}>
                <Autocomplete
                        fullWidth
                        size="small"
                        freeSolo
                        onChange={healthElementHandler}
                        filterOptions={(x) => x}
                        options={map(healthElements, (healthElement) =>
                                ({
                                    value: healthElement,
                                    label: `${healthElement.type}:${healthElement.healthElementClass}:${healthElement.name}`,
                                }))}
                        renderInput={(params) => <TextField {...params} label="Search Health Data"
                                                            onChange={(e) => searchDataElements(e.target.value)}/>}/>
                <div className="col-12 outer-box">
                    <div className="row">

                        <div className="col-md-5 col-xl-3">
                            Available Attributes
                        </div>
                        <div className="col-auto">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                        <div className="col-md-6 col-xl-8">
                            Attributes Display
                        </div>
                    </div>
                    <div className="row inner-box">

                        <div className="col-md-5 col-xl-3"
                             style={{overflowY: "scroll", height: "100%"}}>
                            <List dense>
                                {availableAttributes && availableAttributes.length > 0 && availableAttributes.map((attribute, index) => (
                                        <ListItemButton selected={index + 1 === selectedAttribute}
                                                        onClick={() => setSelectedAttribute(index + 1)}
                                                        key={index}>
                                            <ListItemText primary={attribute.name}/>
                                        </ListItemButton>
                                ))}
                            </List>
                        </div>
                        <div className="col-auto">
                            <div className="row transfer">
                                <div className="col-12 edit"
                                     onClick={() => addAttribute()}>
                                    <RightCircleOutlined/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-8 container-fluid"
                             style={{
                                 overflowY: "scroll",
                                 height: "100%"
                             }}>
                            {ResultsWindow && <ResultsWindow/>}
                        </div>
                    </div>
                </div>
            </div>


    )
}

export default withUser(HealthDataElementSelector)