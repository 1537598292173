import React, {useEffect, useState} from 'react';
import {withUser} from "../../../../context/UserContext";
import AdminHeader from "../AdminHeader";
import {FolderAddOutlined} from "@ant-design/icons";
import {Rest} from "../../../../util/rest";
import TypeClass from "./TypeClass";
import './HealthData.css'
import {toast} from "react-toastify";

function HealthDataAdmin(props) {

    const [rows, setRows] = useState([]);
    const [addNew, setAddNew] = useState(false);
    const [isLoading,setLoading] = useState(false);
    const [page,setPage] = useState(1);
    const [totalPages,setTotalPages] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    
    useEffect(() => {
        searchTypeClass();
    }, []);

    useEffect(() => {
        if(addNew) {
            const newRow = {id: 0, type: "", healthElementClass: "", name: ""}
            if(rows && rows.length > 0)
                setRows(existingRows => [...existingRows, newRow]);
            else
                setRows([newRow]);
        }
    }, [addNew]);

    useEffect(() => {
        searchTypeClass(searchTerm)
    }, [searchTerm]);

    
    function searchTypeClass() {
        setLoading(true);
        Rest.authFetch(props.user, `/rest/health/element/search-type-class/${searchTerm}?page=${page - 1}`)
                .then(response => {
                    if(response.status === 401 || response.status === 403) {
                        toast.error("Unauthorized!")
                    }
                    else {
                        setPage(response.number+1);
                        setTotalPages(response.totalPages);
                        setRows(response.content);
                        setAddNew(false);
                    }
                    setLoading(false);
                }).catch(err => {
            console.log(err);
        })

    }

    function handlePageChange(event, value)
    {
        setPage(value);
    }
    
    return (
            <div className={"health-data"}>
                <AdminHeader
                        label={"Health Data Elements"}
                        onChange={(event) => setSearchTerm(event.target.value)}
                        onClickIcon={() => setAddNew(true)}
                        icon={<FolderAddOutlined title="Add Type Class"/>}
                        onPageChange={handlePageChange}
                        pageNumber={page}
                        totalPages={totalPages}
                />
                <div>
                    {rows && rows.length > 0 && rows.map((row) => {
                        return (
                                <TypeClass typeClass={row} key={row.id} reload={searchTypeClass}/>
                        )
                    })}
                </div>
                {   isLoading &&
                    <div className="text-center my-5">
                        <button className="btn btn-primary-color btn-lg rounded shadow-sm" type="button" disabled>
                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"/>
                            Loading...
                        </button>
                    </div>
                }
            </div>
    );
}

export default withUser(HealthDataAdmin);