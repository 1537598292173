import React, {Component} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import "../SmartPcmhClientApp.css";
import {withUser} from "../context/UserContext";
import StompClient from "react-stomp-client";

class Dashboard extends Component {
    // static contextPath = '/server';

    componentDidMount() {
        this.register();
    }

    render() {
        const staffMemberId = this.props.user ? this.props.user.staffMemberId : "";
        const topic = 'topic/announcement/' + staffMemberId + '/count';
        // console.log(topic);
        return (
                <div className={"mx-2"}>Dashboard component
                    <div>

                    </div>
                    {/*{this.props.user ?*/}
                    {/* <StompClient*/}
                    {/*         endpoint={'ws://localhost:8080' + Dashboard.contextPath + '/pcmh-messaging/websocket'}*/}
                    {/*         topic={topic}*/}
                    {/*         onMessage={(msg) => {*/}
                    {/*             console.log(msg);*/}
                    {/*         }}*/}
                    {/* >*/}
                    {/*     Some text*/}
                    {/* </StompClient>*/}
                    {/*                 : null}*/}
                </div>
        );
    }

    register() {

    }

}

export default withUser(Dashboard);