import React, {Component} from 'react';
import {Rest} from "../../util/rest";
import toast from "../../util/toast";
import {withUser} from "../../context/UserContext";
import "../../SmartPcmhClientApp.css";
import "./MessageList.css";
import MessageItem from "./MessageItem";
import Popper from "@mui/material/Popper";

class MessageList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: props.open,
            anchorEl: this.props.anchorEl,
            readMore: false,
            refId: this.props.refId,
            messageList: []
        };

        this.deleteMessage = this.deleteMessage.bind(this);

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.open !== this.props.open) {
            this.setState({open: this.props.open})
            if(this.props.open)
                this.getMessageList();
        }
        if(prevProps.anchorEl !== this.props.anchorEl)
            this.setState({anchorEl: this.props.anchorEl})
        if(prevProps.refId !== this.props.refId)
            this.setState({refId: this.props.refId})
    }

    componentDidMount() {
        this.getMessageList();
    }

    render() {
        const messages = this.state.messageList;
        const anchorEl = this.state.anchorEl;
        return (
                <Popper id={this.state.refId} open={this.state.open} className={"message-list"}

                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        anchorEl={anchorEl}
                        modifiers={[{

                            name: 'preventOverflow',
                            options: {
                                altAxis: true,
                                padding: 40
                            },
                        }]}
                >
                    {(messages && messages.length > 0) ?
                     messages.map((message, index) => <><MessageItem index={message.id} key={message.id}
                                                                     message={message}
                                                                     deleteHandler={this.deleteMessage}/>
                         <div key={'sep' + index} className={"separator"}/>
                     </>)
                                                       :
                     <span>No Messages</span>
                    }
                </Popper>
        );
    }


    deleteMessage(message, index) {
        let messages = [...this.state.messageList];
        let messageId = messages[index].id;
        Rest.authFetch(this.props.user, "/rest/messaging/delete/" + messageId,
                       {
                           method: "DELETE"
                       })
                .then(response => {
                    if(response.status === 401 || response.status === 403) {
                        this.setState({
                                          messageList: [],
                                      });
                        toast.error('Unauthorized!')
                    }
                    else {
                        messages.splice(index, 1);
                        this.setState({messageList: messages});
                    }
                })
                .catch(err => {
                    console.log(err);
                });
    }

    getMessageList() {
        Rest.authFetch(this.props.user, "/rest/messaging/" + this.props.user.staffMemberId)
                .then(response => {
                    if(response.status === 401 || response.status === 403) {
                        this.setState({
                                          messageList: [],
                                      });
                        toast.error('Unauthorized!')
                    }
                    else {
                        this.setState({
                                          messageList: response,
                                      });
                    }
                })
                .catch(err => {
                    console.log(err);
                });
    }


}


export default withUser(MessageList);