import {
	List,
	ListItemText,
	ListItemButton,
	Button
} from "@mui/material";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { RightCircleOutlined } from "@ant-design/icons";
import { cloneDeep, filter, find, forEach, isEqual, isNull, keys, map, reduce, sortBy } from 'lodash';
import AttributeFilter from "./AttributeFilter";
import { useEffect, useState } from "react";

const FiltersTab = (props) => {	
	const {
		availableAttributes,
		column,
		removeFilter,
		reorderFilters,
		selectedAttribute,
		setSelectedAttribute,
		transferAttributeToFilter,
		updateFilter
	} = props;

	const [tempColums, setTempColums] = useState([]);
	const [groupStyles, setGroupStyles] = useState([]);
	const [activeFilterChecked, setActiveFilterChecked] = useState(null);
	const [activeGroup, setActiveGroup] = useState(undefined);
	const [filterGroup, setFilterGroup] = useState([]);

	const onCheckHandler = (event, seletecFilter) => {
		if (event.target.checked && (seletecFilter.groupId === null)) {
			setActiveFilterChecked(seletecFilter.id);
			setFilterGroup([seletecFilter.id]);
			setActiveGroup(undefined);
		}
		else if (!event.target.checked && (seletecFilter.groupId === null)) {
			setActiveFilterChecked(null);
			setFilterGroup([]);
		}
		else if (event.target.checked) {
			setActiveFilterChecked(null);
			setActiveGroup(seletecFilter.groupId);
			if (seletecFilter.groupId) {
				const filtered = filter(tempColums, ({ groupId }) => groupId === seletecFilter.groupId);
				setFilterGroup(map(filtered, 'id'))
			};
		}
		else {
			setActiveGroup(undefined);
			setFilterGroup([]);
		}
	}

	const makeGroupHandler = () => {
		const clonedColumnFilters = cloneDeep(tempColums);
		const filterObj = find(clonedColumnFilters, ({ id }) => id === activeFilterChecked);
		const newGroupId = Math.max.apply(null, map(clonedColumnFilters, 'groupId')) + 1;
		console.log('newGroupId-->', newGroupId);
		filterObj.groupId = newGroupId;
		setTempColums(clonedColumnFilters);
		setActiveFilterChecked(null)
		setActiveGroup(newGroupId);
	};

	const linkHandler = (filterId) => {
		const clonedColumnFilters = cloneDeep(tempColums);
		const filterObj = find(clonedColumnFilters, ({ id }) => id === filterId);
		filterObj.groupId = activeGroup;
		setTempColums(clonedColumnFilters)
		setFilterGroup((prevState) => ([
			...prevState,
			...[filterObj.id]
		]))
	};

	const unLinkHandler = (filterId) => {
		const clonedColumnFilters = cloneDeep(tempColums);		
		const filterObj = find(clonedColumnFilters, ({ id }) => id === filterId);
		filterObj.groupId = null;
		const newFilteredList = filter(filterGroup, (id) => id !== filterId)

		setFilterGroup(newFilteredList)
		setTempColums(clonedColumnFilters)
	};

	useEffect(() => {
		setTempColums([
			{
					"id": 9,
					"attributeId": 232,
					"attributeName": "Value",
					"attributeDataType": "STRING",
					"filterValue1": "test",
					"filterValue2": null,
					"grouping": null,
					"order": 0,
					"operand": "STARTS_WITH",
					"groupId": null,
			},
			{
					"id": 3,
					"attributeId": 232,
					"attributeName": "Value",
					"attributeDataType": "STRING",
					"filterValue1": "hello world",
					"filterValue2": null,
					"grouping": "AND",
					"order": 3,
					"operand": "STARTS_WITH",
					"groupId": null,
			},
			{
					"id": 1,
					"attributeId": 232,
					"attributeName": "Value",
					"attributeDataType": "STRING",
					"filterValue1": "ever",
					"filterValue2": null,
					"grouping": "AND",
					"order": 1,
					"operand": "STARTS_WITH",
					"groupId": 3,
			},
			{
					"id": 2,
					"attributeId": 232,
					"attributeName": "Value",
					"attributeDataType": "STRING",
					"filterValue1": "hello",
					"filterValue2": null,
					"grouping": "OR",
					"order": 2,
					"operand": "STARTS_WITH",
					"groupId": 3,
			},
			{
				"id": 45,
				"attributeId": 232,
				"attributeName": "Value",
				"attributeDataType": "STRING",
				"filterValue1": "SMART",
				"filterValue2": null,
				"grouping": "OR",
				"order": 5,
				"operand": "STARTS_WITH",
				"groupId": 3,
			},
			{
				"id": 6,
				"attributeId": 232,
				"attributeName": "Value",
				"attributeDataType": "STRING",
				"filterValue1": "world",
				"filterValue2": null,
				"grouping": "OR",
				"order": 4,
				"operand": "STARTS_WITH",
				"groupId": 5,
		},
		{
			"id": 22,
			"attributeId": 232,
			"attributeName": "Value",
			"attributeDataType": "STRING",
			"filterValue1": "TEST TWICE",
			"filterValue2": null,
			"grouping": "OR",
			"order": 12,
			"operand": "STARTS_WITH",
			"groupId": 6,
	},
	{
		"id": 8,
		"attributeId": 232,
		"attributeName": "Value",
		"attributeDataType": "STRING",
		"filterValue1": "TEST TWICE .2",
		"filterValue2": null,
		"grouping": "OR",
		"order": 15,
		"operand": "STARTS_WITH",
		"groupId": 6,
	}
	])
	}, [])
	
	const sortingFilters = () => {
		const groupSort = sortBy(tempColums, ['groupId']).reverse();
		const groupings = {};
		let currentGroupId = null;
		forEach(groupSort, (filterObj) => {
			if (isNull(filterObj.groupId)) filterObj.borderStyle = 'NONE'
			else {
				if (!isEqual(filterObj.groupId, currentGroupId)) {
					groupings[filterObj.groupId] = filter(groupSort, ({ groupId }) => isEqual(groupId, filterObj.groupId));
					currentGroupId = filterObj.groupId;
				}
			}
		})

		forEach(groupings, (value) => {
			if (value.length > 2) {
				forEach(value, (list, index) => {
					if (isEqual(index, 0)) list.borderStyle = "TOP";
					else if (isEqual(index + 1, value.length)) list.borderStyle = "BOTTOM";
					else list.borderStyle = "BETWEEN";
				})
			}
			else if (isEqual(value.length, 2)) {
				forEach(value, (list, index) => {
					if (isEqual(index, 0)) list.borderStyle = "TOP";
					else if (isEqual(index, value.length-1)) list.borderStyle = "BOTTOM";
				})
			}
			else if (isEqual(value.length, 1)) {
				forEach(value, (list) => {
					list.borderStyle = "ALL";
				})
			}
		});

		const results = reduce(keys(groupings).reverse(), (result, groupId) =>
			[...result, ...groupings[groupId]], []
		);

		return [...results, ...filter(groupSort, ({ groupId }) => isEqual(groupId, null))];
	};

	const borderStyling = (filter) => {
		if (isEqual(filter.borderStyle, 'TOP'))
			return { borderLeft: '1px solid red', borderRight: '1px solid red', borderTop: '1px solid red'}
		if (isEqual(filter.borderStyle, 'BOTTOM'))
			return { borderLeft: '1px solid red', borderRight: '1px solid red', borderBottom: '1px solid red'}
		if (isEqual(filter.borderStyle, 'BETWEEN'))
			return { borderLeft: '1px solid red', borderRight: '1px solid red'}
		if (isEqual(filter.borderStyle, 'ALL'))
			return { border: '1px solid red'}
	};

	return (
		<div className="col-12 outer-box">
			<div className="row">
				<div className="col-md-5 col-xl-3">Available Attributes</div>
				<div className="col-auto">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
				<div className="col-md-6 col-xl-8" style={{ display: 'flex', justifyContent: 'space-between' }}>
					Attributes Filters
					<span>
						{!isNull(activeFilterChecked) && (
							<Button onClick={makeGroupHandler} size="small" variant="contained" color="success">
								Make Group
							</Button>
						)}
					</span>
				</div>
			</div>
			<div className="row inner-box">
				<div className="col-md-5 col-xl-3" style={{overflowY: "scroll", height: "100%"}}>
					<List dense>
						{availableAttributes
						&& availableAttributes.length > 0
						&& map(availableAttributes, (attribute, index) => (
							<ListItemButton
								selected={isEqual(index + 1, selectedAttribute)}
								onClick={() => setSelectedAttribute(index + 1)}
								key={index}>
								<ListItemText primary={attribute.name}/>
							</ListItemButton>
						))}
					</List>
				</div>
			
				<div className="col-auto">
					<div className="row transfer">
						<div
							className="col-12 edit"
							onClick={() => transferAttributeToFilter()}>
								<RightCircleOutlined/>
						</div>
					</div>
				</div>
				
				<div
					className="col-md-6 col-xl-8 container-fluid"
					style={{overflowY: "scroll", height: "100%", padding: '0'}}>
					<DragDropContext onDragEnd={reorderFilters}>
						<Droppable droppableId="droppable-filters" direction="vertical">
							{(droppableProvided) => (
								<div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
									{column
										&& column.attributes
										&& tempColums.length > 0
										&& map(sortingFilters(), (filter, index) => (
											<div style={borderStyling(filter)}>
												<AttributeFilter
													key={filter.order + 1}
													index={filter.order}
													filter={filter}
													onDelete={removeFilter}
													onUpdate={updateFilter}
													onCheckHandler={onCheckHandler}
													activeFilterChecked={activeFilterChecked}
													activeGroup={activeGroup}
													filterGroup={filterGroup}
													linkHandler={linkHandler}
													unLinkHandler={unLinkHandler} />
													{(index+1 !== tempColums.length) && <div style={{ 'height': '10px' }}/>}
											</div>))}
												{droppableProvided.placeholder}
											</div>
										)}
						</Droppable>
					</DragDropContext>
				</div>
			</div>
		</div>
	);
}

FiltersTab.defaultProps = {
	availableAttributes: [],
	column: {
		attributes: [],
		dataSetId: 0,
		filters: [],
		id: 0,
		order: 0,
		title: "New Column"
	},
	selectedAttribute: null,
}

export default FiltersTab;