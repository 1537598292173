import React, {Component} from 'react';
import {CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis} from "recharts";
import './TeamPerformance.css';

const data = [
    {name: 'W1', Team1: 36, AllTeams: 24, Team3: 44},
    {name: 'W2', Team1: 32, AllTeams: 26, Team3: 46},
    {name: 'W3', Team1: 30, AllTeams: 33, Team3: 48},
    {name: 'W4', Team1: 25, AllTeams: 39, Team3: 60},
    {name: 'W5', Team1: 48, AllTeams: 48, Team3: 72},
    {name: 'W6', Team1: 56, AllTeams: 54, Team3: 68},
    {name: 'W8', Team1: 70, AllTeams: 62, Team3: 66},
    {name: 'W9', Team1: 76, AllTeams: 66, Team3: 64}
];

class TeamPerformance extends Component {
    render() {
        return (
                <div className="card pcmh-profile-card">
                    <div className="card-header pcmh-profile-card-header">
                        Team Performance 
                        <div style={{fontSize:".8em"}}>% Of Patients with controlled diabetes (* Risk Adjusted)</div>
                    </div>
                    <div className="card-body">
                        <LineChart width={500} height={275} data={data}>
                            <XAxis dataKey="name"/>
                            <YAxis type="number" name='score'/>
                            <Tooltip/>
                            <Legend/>
                            <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                            <Line type="monotone" dataKey="Team1" stroke="#89bf40"/>
                            <Line type="monotone" dataKey="AllTeams" stroke="#8884d8"/>
                        </LineChart>
                    </div>
                </div>
        )
    }
}

export default TeamPerformance;