import React, {Component} from 'react';
import {isEqual, isNil, isNull, map, reverse} from 'lodash';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {withUser} from "../../../../context/UserContext";


import './ContentAdmin.css';
import ContentUploader from "./ContentUploader/ContentUploader";
import {Rest} from "../../../../util/rest";
import toast from '../../../../util/toast';
import FileDisplay from "./FileDisplay";
import FileReader from "../../../common/fileviewer/FileReader";
import {FileAddOutlined} from "@ant-design/icons";
import AdminHeader from "../AdminHeader";


class ContentAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeContent: null,
            edit: false,
            showFile: false,
            files: [],
            searchTerm: ""
        };
        this.deleteContent = this.deleteContent.bind(this);
        this.newContentHandler = this.newContentHandler.bind(this);
        this.documentClicked = this.documentClicked.bind(this);
        this.documentClosed = this.documentClosed.bind(this);
        this.toggleActiveContent = this.toggleActiveContent.bind(this);
        this.searchFiles = this.searchFiles.bind(this);
        this.submitContent = this.submitContent.bind(this);
    }

    componentDidMount() {
        this.loadFiles();
    }

    render() {
        const files = this.state.files;
        const show = this.state.showFile;
        const currentFile = this.state.currentFile;
        const { activeContent } = this.state;
        return (
            <div className="pcmh-content-admin">
                <AdminHeader
                    label={"Content"}
                    onChange={(event) => this.searchFiles(event.target.value)}
                    onClickIcon={this.newContentHandler}
                    icon={<FileAddOutlined title="Add Content" />}
                />
                {!isNull(activeContent) && isEqual(activeContent.modalType, 'CREATE') && (
                    <ContentUploader
                        closeModal={this.toggleActiveContent}
                        submitContent={this.submitContent} />
                )}
                <div className="card-columns">
                    {map(files, (file) => (
                        <FileDisplay
                            key={file.id}
                            file={file}
                            toggleActiveContent={this.toggleActiveContent}
                            clickHandler={this.documentClicked} />
                    ))}
                </div>
                <div className="static-modal">
                    <FileReader show={show} file={currentFile}
                        onClose={this.documentClosed} />
                </div>

                {/* Modal for confirmation on content deletion */}
                {!isNull(activeContent) && isEqual(activeContent.modalType, 'DELETE') && (
                    <Dialog open fullWidth onClose={this.toggleActiveContent}>
                        <DialogTitle>Delete Content</DialogTitle>
                        <DialogContent>
                            Are you sure you want to delete <strong>{activeContent.title}</strong>?
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" onClick={this.toggleActiveContent}>
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                className="contentDeleteButton"
                                onClick={this.deleteContent}>
                                Delete
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}

                {!isNull(activeContent) && isEqual(activeContent.modalType, 'EDIT') && (
                    <ContentUploader
                        activeContent={activeContent}
                        closeModal={this.toggleActiveContent}
                        submitContent={this.submitContent} />
                )}
            </div>
        );
    }

    newContentHandler() {
        this.setState({ activeContent: { modalType: 'CREATE' } });
    }

    reshapeContent(documents) {
        return map(documents, (document) => ({
            ...document,
            authors: isEqual(document.authors, ['']) ? [] : document.authors,
            keywords: isEqual(document.keywords, ['']) ? [] : document.keywords,
        }));
    }

    loadFiles() {
        this.setState({ files: [] }, () => (
            Rest.authFetch(this.props.user, "/rest/content").then(response => {
                if (response.status === 401 || response.status === 403) {
                    this.setState({ files: [] });
                    toast.error("Unauthorized!")
                }
                else
                    this.setState({ files: this.reshapeContent(reverse(response)) });
            })
                .catch(err => {
                    console.log(err);
                })
        ));
    }

    searchFiles(searchTerm) {
        Rest.authFetch(this.props.user, `/rest/content/searchContent/${searchTerm}`).then(response => {
            if (response.status === 401 || response.status === 403) {
                this.setState({ files: [] });
                toast.error("Unauthorized!")
            }
            else this.setState({ files: this.reshapeContent(response.content) });
        })
            .catch(err => {
                console.log(err);
            })
    }

    documentClicked(file) {
        this.setState({
            showFile: true,
            currentFile: file
        })
    }

    documentClosed() {
        this.setState({
            showFile: false,
            currentFile: null
        });
    }

    toggleActiveContent(file) {
        if (isNull(this.state.activeContent)) this.setState({ activeContent: file })
        else this.setState({ activeContent: null })
    }

    async deleteContent() {
        const { user } = this.props;
        const url = `/rest/content/${this.state.activeContent.id}`;
        const data = { method: 'DELETE' }
        await Rest.authFetch(user, url, data).catch((err) => console.log(err));
        this.loadFiles();
        this.toggleActiveContent();
    }

    async submitContent(content) {
        const { user } = this.props;
        const url = isEqual(content.id, 0) ? '/rest/content/add' : '/rest/content';
        const data = {
            method: 'PUT',
            body: JSON.stringify(content)
        }
        const response = await Rest.authFetch(user, url, data).catch((err) => console.log(err));
        if (!isNil(response) && response.id) {
            toast.success("Document saved");
            this.loadFiles();
            this.toggleActiveContent();
        }
        else toast.error("Error saving Document");
    }
}

export default withUser(ContentAdmin);