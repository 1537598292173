import React, {Component} from 'react';

class AssessmentReview extends Component {

    render() {
        return (
                <div>AssessmentReview</div>
        );
    }
}

export default AssessmentReview;