import React, {Component} from 'react';
import {Button, Divider} from "@mui/material";
import './ContentAdmin.css'
import {FileTextOutlined} from "@ant-design/icons";
import FileViewer from "../../../common/fileviewer/FileViewer";

class FileDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: props.file
        };
        this.deleteHandler = this.deleteHandler.bind(this);
        this.editHandler = this.editHandler.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    render() {
        const file = this.state.file;
        const blob = this.state.file.content;
        const type = this.state.file.contentType;
        const l1 = file.authors.length;
        const l2 = file.keywords.length;
        return (
            <div className="card content-display" onClick={this.handleClick}>
                <div className="card-header pcmh-file-display-header">
                    <h5 className="card-title content-title">{file.title}</h5>
                </div>
                <div className="card-body text-center">
                    {blob ?
                        <FileViewer blob={blob} type={type} className="content-viewer" /> :
                        <FileTextOutlined className="empty-document my-auto" />
                    }
                </div>
                <div className="card-footer content-footer">
                    <div className="content-metadata-title">Author(s):</div>
                    {
                        file.authors.map((author, i) => {
                            return <div key={"author" + i} className="content-metadata-item">{author}{i < l1 - 1 ?
                                <span>,&nbsp;</span> : null}</div>
                        })}
                    <div className="content-metadata-title">Keyword(s):</div>
                    {file.keywords.map((keyword, i) => {
                        return <div key={"keyword" + i} className="content-metadata-item">{keyword}{i < l2 - 1 ?
                            <span>,&nbsp;</span> : null}</div>
                    })
                    }

                    <Divider />

                    <div style={this.actionButtonStyles}>
                        <Button
                            variant="outlined"
                            style={this.actionEditButtonStyles}
                            onClick={this.editHandler}>
                            Edit
                        </Button>
                        <Button
                            variant="outlined"
                            style={this.actionDeleteButtonStyles}
                            onClick={this.deleteHandler}>
                            Delete
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    deleteHandler(event) {
        event.stopPropagation();
        this.props.toggleActiveContent({ ...this.props.file, modalType: 'DELETE' });
    }

    editHandler(event) {
        event.stopPropagation();
        this.props.toggleActiveContent({ ...this.props.file, modalType: 'EDIT' });
    }

    handleClick() {
        if (this.props.clickHandler) {
            this.props.clickHandler(this.state.file);
        }
    }

    actionButtonStyles = {
        display: 'flex',
        justifyContent: 'space-evenly',
        margin: '10px 0 10px 0'
    }

    actionEditButtonStyles = {
        background: '#329946',
        color: 'white',
        minWidth: '90px'
    }

    actionDeleteButtonStyles = {
        minWidth: '90px'
    }
}

export default FileDisplay;