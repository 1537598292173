import React, {Component} from 'react';
import {Rest} from "../../../../util/rest";
import {withUser} from "../../../../context/UserContext";
import {CheckOutlined, DeleteOutlined, EditOutlined, HistoryOutlined, UndoOutlined} from "@ant-design/icons";
import moment from "moment";

const MAX_CHARS = 300;

class AgendaNoteItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            note: props.note,
            staffMember: null,
            editMode: false,
            showHistory: false
        };

    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if(JSON.stringify(prevProps.note) !== JSON.stringify(this.props.note)) {
            this.setState({note: this.props.note});
            this.getStaffMember(this.props.note.authorId)
        }

    }

    componentDidMount() {
        if(this.props.note && this.props.note.authorId)
            this.getStaffMember(this.props.note.authorId);
    }

    render() {
        const note = this.state.note ? this.state.note : {text: ""};
        const staffMember = this.state.staffMember ? this.state.staffMember : null;
        const staffMemberName = staffMember ? (staffMember.title ? (staffMember.title + " ") : "") + staffMember.firstName + " " + staffMember.firstLastName : "";
        return (
                <div className="row box">
                    {this.state.editMode ?
                     <div className="col-12">
                         <div className="row">
                             <div className="col-12 noteText">
                                 <div className="row">
                                     <div className="col-12">
                                        
                                <textarea name="noteEntry" id="noteEntry"
                                          className="noteEntry"
                                          autoFocus
                                          defaultValue={this.state.note.text}
                                          value={this.state.note.text}
                                          onChange={(e) => this.handleInputChange(e)}/>
                                     </div>
                                 </div>
                             </div>
                         </div>
                         <div className="row">
                             <div className="col-10 counter">
                                 {this.state.chars}/{MAX_CHARS}
                             </div>
                             <div className="col-1 text-right note-edit undo">
                                 <UndoOutlined className="undo" title="Undo"
                                               onClick={() => this.handleUndo()}/>
                             </div>
                             <div className="col-1 text-right note-edit save">
                                 <CheckOutlined className="note-edit" title="Save Changes"
                                                onClick={() => this.handleSave()}/>
                             </div>
                         </div>
                     </div>
                                         :
                     <div className="col-12">
                         <div className="row">
                             <div className="offset-md-7 offset-lg-8 col-md-5 col-lg-4">
                                 <div className="row  justify-content-end" style={{overflowY: "auto"}}>
                                     <div className="col-3 text-right">
                                         <HistoryOutlined className="note-edit" title="Show history"
                                                          onClick={() => this.showHistory()}/>
                                     </div>
                                     <div className="col-3 text-right">
                                         <EditOutlined className="note-edit" title="Edit note"
                                                       onClick={() => this.edit()}/>
                                     </div>
                                     <div className="col-3 text-right">
                                         <DeleteOutlined className="note-edit" title="Delete note"
                                                         onClick={() => this.delete()}/>
                                     </div>
                                 </div>
                             </div>
                         </div>
                         {(this.state.showHistory && this.state.history && this.state.history.length > 0) ?
                          <>
                              <div className="row history-block"/>
                              {this.state.history.map((history, index) =>
                                                              <div className="row history-block" key={index}>
                                                                  <div className="col-12">
                                                                      <div className="row">
                                                                          <div className="col-12 history-date">
                                                                              {moment(history.dateEntered).format("MM/DD/YYYY HH:mm:ss")}
                                                                          </div>
                                                                      </div>
                                                                      <div className="row">
                                                                          <div className="col-12 history-author">
                                                                              ({history.authorFullName})
                                                                          </div>
                                                                      </div>
                                                                      <div className="row">
                                                                          <div className="col-12 history">
                                                                              {history.text}
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              </div>
                              )}</> : null}
                         <div className="row">
                             <div className="col-12 commenter">
                                 {staffMemberName}
                             </div>
                         </div>
                         <div className="row">
                             <div className="col-12 dateEntered">
                                 {moment(note.dateEntered).format("MM/DD/YYYY h:mm:ss a")}
                             </div>
                         </div>
                         <div className="row">
                             <div className="col-12">

                                 <div className="row">
                                     <div className="col-12 noteText">
                                         {note.text}
                                     </div>
                                 </div>
                             </div>
                         </div>
                     </div>}
                </div>)
    }

    getStaffMember(id) {
        if(id > 0) {
            let path = "/rest/staff/" + id;
            Rest.authFetch(this.props.user, path).then(response => {
                this.setState({staffMember: response});
            })
                    .catch(err => {
                        console.log(err);
                    })
        }
    }

    edit() {
        const length = this.state.note.text.length;
        this.setState({
                          editMode: true,
                          showHistory: false,
                          chars: length,
                          oldValue: this.state.note.text
                      });

    }

    delete() {
        if(this.props.onDelete)
            this.props.onDelete(this.state.note);
    }

    showHistory() {
        const showHistory = !this.state.showHistory;
        this.setState({showHistory: showHistory});
        if(showHistory && this.state.note && this.state.note.id > 0) {
            let path = "/rest/agenda/note/history/" + this.state.note.id;
            Rest.authFetch(this.props.user, path).then(response => {
                this.setState({history: response});
            })
                    .catch(err => {
                        if(err === 204) {
                            this.setState({history: []})
                        }
                        else
                            console.log(err);
                    })
        }
    }

    handleSave() {
        if(this.state.oldValue !== this.state.note.text) {
            const note = this.state.note;
            Rest.authFetch(this.props.user, "/rest/agenda/addNote/history", {
                method: "PUT",
                body: JSON.stringify({
                                         dateEntered: note.dateEntered,
                                         noteId: note.id,
                                         text: this.state.oldValue,
                                         authorId: note.authorId
                                     })
            })
                    .then(() => {
                        note.dateEntered = new Date();
                        note.authorId = this.props.user.staffMemberId;
                        Rest.authFetch(this.props.user, "/rest/agenda/addNote", {
                            method: "PUT",
                            body: JSON.stringify(note)
                        }).then(this.setState({editMode: false}))
                    });

        }
    }

    handleUndo() {
        this.setState((state) => ({
            editMode: false,
            note: {
                ...state.note,
                text: this.state.oldValue
            }
        }));
    }

    handleInputChange(e) {
        const target = e.target;
        const value = target.value;

        this.setState((state) => ({
            note: {
                ...state.note,
                text: value
            },
            chars: value.length
        }));
    }

}

export default withUser(AgendaNoteItem);