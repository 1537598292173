import React, { useEffect, useState } from 'react';
import { withUser } from "../../../../context/UserContext";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Button from "@mui/material/Button";


function HealthDataElementOptionsDialog(props) {
    const [isOpen, setIsOpen] = useState(props.open);
    const [options, setOptions] = useState(props.options);

    useEffect(() => {
        setIsOpen(props.open);
    }, [props]);

    function callSave() {
        // props.handleClose('save',{id:id,name:name,type:type,healthElementClass:healthElementClass});
    }

    return (

        <Dialog open={isOpen}>
            <DialogTitle classes={{ root: "editorHeader" }}>Options</DialogTitle>
            <DialogContent>
                <div className={"row"}>
                </div>
            </DialogContent>
            <DialogActions style={{ borderTop: "1px solid rgba(169, 169, 169, 0.50)" }}>
                <Button onClick={() => props.handleClose('cancel')} variant="contained" className="editorButton">
                    Cancel
                </Button>
                <Button onClick={() => callSave()} variant="contained" className="editorButton">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default withUser(HealthDataElementOptionsDialog);