import React, {Component} from 'react';

const MAX_CHARS = 300;

class AgendaNoteEntry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            noteEntry: "",
            chars: 0
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.addNote = this.addNote.bind(this);
    }

    render() {
        return (
                <div>
                    <div className="row">
                        <div className="col-12">
                        <textarea name="noteEntry" id="noteEntry"
                                  placeholder="Add your notes here"
                                  className="noteEntry"
                                  value={this.state.noteEntry}
                                  onChange={this.handleInputChange}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 counter">
                            {this.state.chars}/{MAX_CHARS}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <button onClick={this.addNote} disabled={this.state.chars === 0}>Add Note</button>
                        </div>
                    </div>

                </div>
        );
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;

        this.setState({
                          noteEntry: value,
                          chars: value.length
                      });
    }

    addNote() {

        const text = this.state.noteEntry;
        this.setState({
                          noteEntry: "",
                          chars: 0
                      });

        if(this.props.onNew) {
            this.props.onNew({
                                 id: 0,
                                 text: text,
                                 dateEntered: new Date()
                             });
        }
    }
}

export default AgendaNoteEntry;


