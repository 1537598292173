import React, {Component} from 'react';
import './Announcement.css'
import MessageList from "./MessageList";
import {withUser} from "../../context/UserContext";
import {Rest} from "../../util/rest";
import {toast} from "react-toastify";


class Announcement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            unread: 0,
            listOpen: false,
            anchorEl: null,
        }

    }

    componentDidMount() {
        this.getUnreadCount();
        this.timer = setInterval(this.getUnreadCount.bind(this), 300000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    render() {
        const unread = this.state.unread;
        const id = this.state.listOpen ? 'message-list-popper' : undefined;
        const anchor = this.state.anchorEl;
        return (
                <div aria-describedby={id} onClick={(e) => this.showList(e)}
                     className="announcementbutton unSelectable ml-auto">
                    Announcements
                    {unread > 0 ? <span className="notify-bubble">{unread}</span> : null}
                    <MessageList anchorEl={anchor}
                                 open={this.state.listOpen}
                                 refId={id}
                    />
                </div>
        )
    }

    showList(event) {
        event.preventDefault();
        if(this.state.selectedMessage) {
            this.setState({
                              selectedMessage: null,
                              listOpen: true
                          })

        }
        else
            this.setState({
                              listOpen: !this.state.listOpen,
                              anchorEl: event.currentTarget
                          });
    }

    getUnreadCount() {
        Rest.authFetch(this.props.user, "/rest/messaging/unread/count/" + this.props.user.staffMemberId)
                .then(response => {
                    if(response.status === 401 || response.status === 403) {
                        this.setState({
                                          messageList: [],
                                      });
                        toast.error('Unauthorized!')
                    }
                    else {
                        this.setState({
                                          unread: response
                                      });
                    }
                })
                .catch(err => {
                    console.log(err);
                })
    }


}

export default withUser(Announcement);