import React, {useEffect, useState} from 'react';
import './AssessmentTemplateEditor.css'
import {DragDropContext} from "react-beautiful-dnd";
import Icon, {CloseOutlined, EditOutlined} from "@ant-design/icons";
import toast from "../../../../util/toast";
import {Rest} from "../../../../util/rest";
import {withUser} from "../../../../context/UserContext";
import TextArea from "../../assessment/inputtypes/TextArea";
import AssessmentGroup from "./AssessmentGroup";
import Button from "@mui/material/Button";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";

const AddGroupSVG = () => (
        <svg width="50px" height="50px" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"
               fontFamily="Helvetica-Bold, Helvetica" fontSize="26" fontWeight="bold">
                <g id="Group-13" fill="#ffffff">
                    <text id="+">
                        <tspan x="26" y="36">+</tspan>
                    </text>
                    <text id="?">
                        <tspan x="0" y="27">?</tspan>
                    </text>
                    <text id="?-copy">
                        <tspan x="9" y="36">?</tspan>
                    </text>
                    <text id="?-copy-2">
                        <tspan x="18" y="25">?</tspan>
                    </text>
                </g>
            </g>
        </svg>
);


function AssessmentTemplateEditor(props) {


    const [template, setTemplate] = useState(props.template);
    const [lightTemplate, setLightTemplate] = useState(null)
    const [editMode, setEditMode] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [questionQty, setQuestionQty] = useState(0);
    const [save, setSave] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        if(template) {
            if(template.id === 0) {
                simpleSave();
            }
            else {
                getTemplate();
            }
        }
    }, []);

    useEffect(() => {
        const lightTemplate = {...template};
        delete lightTemplate.groups;
        setLightTemplate(lightTemplate);
        setQuestionQty(!template || !template.groups || template.groups.length === 0 ? 0 : template.groups.map((group) => group.questions).reduce((questions, q) => questions.concat(q)).length);
        if(props.onTemplateUpdated)
            props.onTemplateUpdated({
                                        ...lightTemplate,
                                        questionsQty: questionQty,
                                    });

    }, [template])

    useEffect(() => {
        if(save) {
            simpleSave();
            setSave(false);
        }
    }, [save])


    function addGroup() {
        const newGroup = {
            id: 0,
            templateId: template.id,
            name: "New Group",
            description: "",
            instructions: "",
            order: template.groups.length + 1,
            questions: [],
            weight: 0,
            displayInfo: false
        }
        const groups = template.groups;
        groups.push(newGroup);
        setTemplate({...template, groups: groups});
    }

    function cancel() {
        if(props.onEditorClose)
            props.onEditorClose();
    }

    function getTemplate() {
        setIsLoading(true);
        Rest.authFetch(props.user, `/rest/assessment/templates/${template.id}`).then(response => {
            if(response.status === 401 || response.status === 403) {
                toast.error("Unauthorized!")
            }
            else {
                setTemplate(response);
                setIsLoading(false);
                setIsSaving(false);
            }
        })
                .catch(err => {
                    console.log(err);
                    setIsLoading(false);
                })
    }

    function saveAssessmentTemplate() {
        setIsSaving(true);
        Rest.authFetch(props.user, "/rest/assessment/templates", {
            method: "PUT",
            body: JSON.stringify(template)
        })
                .then(response => {
                    if(response) {
                        setTemplate(response);
                        setIsSaving(false);
                        setIsLoading(false);
                    }
                    else {
                        toast.error("Error saving Template");
                    }
                });
    }

    function simpleSave() {
        setIsSaving(true);
        const groups = template.groups;
        Rest.authFetch(props.user, "/rest/assessment/templates/simple", {
            method: "PUT",
            body: JSON.stringify(template)
        })
                .then(response => {
                    if(response) {
                        setTemplate({...response, groups: groups});
                        setIsSaving(false);
                        setIsLoading(false);
                    }
                    else {
                        toast.error("Error saving Template");
                    }
                });
    }

    function onGroupChange(originalId, group) {
        const groups = template.groups;
        const groupIndex = groups.findIndex(g => g.id === originalId);
        if(groupIndex >= 0) {
            groups[groupIndex] = group;
            setTemplate({...template, groups: groups});
        }
    }

    function onGroupDelete(groupId) {
        const groups = template.groups;
        const groupIndex = groups.findIndex(q => q.id === groupId);
        if(groupIndex >= 0) {
            groups.splice(groupIndex, 1);
            setTemplate({...template, groups: groups})
        }
    }

    function onDragEnd(result) {
        if(!props.readOnly) {
            const {source, destination} = result;
            if(!destination) {
                return;
            }
            const groups = template.groups;
            if(source.droppableId === destination.droppableId) {
                const groupIndex = groups.findIndex(g => {return g.id === parseInt(destination.droppableId)});
                const group = groups[groupIndex];
                group.questions = reorder(
                        group.questions,
                        result.source.index,
                        result.destination.index
                );
                groups[groupIndex] = group;
            }
            else {

                const result = move(
                        getGroup(source.droppableId),
                        getGroup(destination.droppableId),
                        source,
                        destination
                );


                groups[getGroupIndex(source.droppableId)] = result.sourceGroup;
                groups[getGroupIndex(destination.droppableId)] = result.destinationGroup;
            }
            setTemplate({...template, groups: groups});
            saveAssessmentTemplate();
        }
    }

    function reorder(list, startIndex, endIndex) {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        result.map((question, index) => question.order = index + 1);
        return result;
    }

    function move(source, destination, droppableSource, droppableDestination) {
        const sourceClone = Array.from(source.questions);
        const destClone = Array.from(destination.questions);
        const [removed] = sourceClone.splice(droppableSource.index, 1);
        destClone.splice(droppableDestination.index, 0, removed);
        sourceClone.map((question) => question.groupId = source.id);
        destClone.map((question) => question.groupId = destination.id);
        source.questions = sourceClone;
        destination.questions = destClone
        return {sourceGroup: source, destinationGroup: destination};
    }

    function getGroup(groupId) {
        return template.groups.find(g => {return g.id === parseInt(groupId)});
    }

    function getGroupIndex(groupId) {
        return template.groups.findIndex(g => {return g.id === parseInt(groupId)});
    }

    function changeStatus() {
        if(template.status === "DRAFT" || template.status === "PUBLISHED") {
            const newStatus = template.status === "DRAFT" ? "PUBLISHED" : "INACTIVE";
            setTemplate({...template, status: newStatus});
            setLightTemplate({...lightTemplate, status: newStatus})
            setSave(true);
            setShowAlert(false);
        }
    }

    return (
            <>
                <div className="pcmh-content-header container-fluid">
                    <div className="row">
                        {editMode ?
                         <div className="col-md-9 name my-auto">
                             <input type="text"
                                    autoFocus={true}
                                    defaultValue={template.name}
                                    className="editing"
                                    onChange={(e) => setTemplate({...template, name: e.target.value})}
                                    onBlur={() => {simpleSave()}}
                                    onFocus={(e) => e.target.select()}
                             />
                         </div>
                                  :
                         <div className="col-md-8 name my-auto"
                              onClick={() => setEditMode(!props.readOnly)}>
                             {template.name}
                         </div>
                        }
                        <div className={"col-md-4"}>
                            <div className={"row"}>
                                {!props.readOnly &&
                                 <>
                                     <div className="col-md-3 icon my-auto"
                                          onClick={() => setEditMode(true)}>
                                         <EditOutlined title="Edit Name"/>
                                     </div>

                                     <div className="col-md-3 icon my-auto" onClick={() => {addGroup()}}>
                                         <Icon component={AddGroupSVG} title="Add Group"/>
                                     </div>
                                 </>
                                }
                                <div className={`col-md-3 ${props.readOnly ? "offset-md-5" : ""} icon my-auto`}
                                     onClick={() => {cancel()}}>
                                    <CloseOutlined title="Cancel"/>
                                </div>
                                <div className={"col-md-3 icon my-auto"} style={{padding: 0}}>
                                    <Button variant="contained"
                                            className="editorButton"
                                            style={{cursor: template.status === "INACTIVE" ? "not-allowed" : "pointer"}}
                                            onClick={() => {
                                                if(template.status !== "INACTIVE") setShowAlert(true)
                                            }}>
                                        {template.status === "DRAFT" ? "Publish" : template.status === "PUBLISHED" ? "Inactivate" : "Inactive"}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <TextArea
                                autofocus={false}
                                label={"Assessment Description"}
                                name={"description"}
                                defaultValue={template.description}
                                onChange={(e) => setTemplate({...template, description: e.target.value})}
                                onBlur={() => { simpleSave() }}
                                readOnly={props.readOnly}
                        />
                    </div>
                    <div className="col-md-6">
                        <TextArea
                                autofocus={false}
                                label={"Assessment Instructions"}
                                name={"instructions"}
                                defaultValue={template.instructions}
                                onChange={(e) => setTemplate({...template, instructions: e.target.value})}
                                onBlur={() => {simpleSave() }}
                                readOnly={props.readOnly}
                        />
                    </div>
                </div>

                <div className={"template-content"}>
                    {isLoading &&
                     <div className="text-center my-5">
                         <button className="btn btn-primary-color btn-lg rounded shadow-sm" type="button" disabled>
                             <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"/>
                             Loading...
                         </button>
                     </div>
                    }
                    {isSaving && <div className="text-center my-5 saving-notification">
                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"/>
                        Saving...
                    </div>}
                    <DragDropContext onDragEnd={onDragEnd}>
                        {template && template.groups && template.groups.sort((a, b) => (a.order > b.order) ? 1 : -1).map((group, index) => (
                                <AssessmentGroup key={group.id} group={group} index={index}
                                                 onStartSave={() => {setIsSaving(true)}}
                                                 onEndSave={() => {setIsSaving(false)}}
                                                 onGroupChange={onGroupChange}
                                                 template={lightTemplate}
                                                 onDelete={onGroupDelete}
                                                 readOnly={props.readOnly}
                                />
                        ))}
                    </DragDropContext>
                </div>
                <div>
                    <Dialog open={showAlert} aria-labelledby="form-dialog-title"
                            onClose={(event, reason) => {
                                if(reason !== 'backdropClick' && reason !== 'escapeKeyDown')
                                    setShowAlert(false);
                            }}
                            className="pcmh-content-uploader">
                        <DialogTitle title="Status Change" classes={{root: "editorHeader"}}>Change Status</DialogTitle>
                        <DialogContent>
                            <span className={"warning-text unSelectable"}>Warning! Changing state is not reversible</span>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {setShowAlert(false)}} variant="contained"
                                    className="editor-button">Cancel</Button>
                            <Button onClick={() => {changeStatus();}} variant="contained"
                                    className="editor-button">Confirm</Button>
                        </DialogActions>
                    </Dialog>
                </div>

            </>
    );
}

export default withUser(AssessmentTemplateEditor);