import React, {Component} from 'react';
import './ProfileEditor.css';

class TeamItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            team: props.team,
            selected: false
        };
        this.toggleSelected = this.toggleSelected.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if(prevProps.team !== this.props.team) {
            let team = this.props.team;
            let selected = !(typeof team.selected === "undefined" || !team.selected);
            team.selected = selected;
            this.setState({
                              team: team,
                              selected: selected
                          });
        }

    }

    render() {
        const name = this.state.team.name;
        const selected = this.state.team.selected;
        return (
                <div className="teamItem unSelectable container-fluid" style={{padding: "4px 16px"}}>
                    <div className="row">
                        <div className="col-md-8 my-auto">{name}</div>
                        <div className="col-md-4 my-auto">
                            <div className="completedBox" onClick={this.toggleSelected}>
                                {selected ?
                                 <svg width="16" height="13" viewBox="0 0 16 13"
                                      xmlns="http://www.w3.org/2000/svg">
                                     <path d="M5.558 12.0708C5.14123 12.0708 4.72528 11.9121 4.407 11.5938L0 7.18681L2.30199 4.88401L5.558 8.14001L13.698 0L16 2.30281L6.709 11.5938C6.39072 11.9121 5.97477 12.0708 5.558 12.0708Z"/>
                                 </svg> : null}
                            </div>
                        </div>
                    </div>
                </div>
        );
    }

    toggleSelected() {
        this.notifyChange(!this.state.selected);
        this.setState((state) => ({
            selected: !state.selected,
            team: {
                ...state.team,
                selected: !state.selected
            }
        }),);

    }
    
    notifyChange(newState){
        if(this.props.onChange) {
            let team=this.state.team;
            team.selected=newState;
            this.props.onChange(team);
        }
    }
}

export default TeamItem;