import React, {Component} from 'react';
import {Rest} from "../../util/rest";
import toast from "../../util/toast";
import {withUser} from "../../context/UserContext";
import FileReader from "../common/fileviewer/FileReader";

class Document extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: null,
            personId: 0
        };
    }

    componentDidMount() {
        this.loadDocument(this.props.match.params.id);
    }

    render() {
        const file = this.state.file

        return (
                <div>
                    {this.state.file &&
                     <div className="static-modal"><FileReader show file={file} className="content-viewer"
                                                               onClose={() => this.onClose()}/></div>
                    }
                </div>
        );
    }

    loadDocument(id) {
        this.setState({file: null}, () => (
                Rest.authFetch(this.props.user, "/rest/content/" + id).then(response => {
                    if(response.status === 401 || response.status === 403) {
                        this.setState({files: []});
                        toast.error("Unauthorized!")
                    }
                    else {
                        this.setState({file: response});
                    }
                })
                        .catch(err => {
                            console.log(err);
                        })
        ));

    }

    onClose() {
        if(this.props.history) {
            const path = (this.props.location.state && this.props.location.state.from && this.props.location.state.from.pathname) ? this.props.location.state.from.pathname : "/"
            this.props.history.push({
                                        pathname: path,
                                        state: {patientId: this.props.location && this.props.location.state && this.props.location.state.personId ? this.props.location.state.personId : null}
                                    });
        }

    }
}

export default withUser(Document);