import React, {createContext, useContext, useState} from 'react';


const DockedMenuContext = createContext(null);
const DockedMenuUpdateContext = createContext(null);

const MenuToggleProvider = ({ children }) => {
	const [docked, setDocked] = useState(true);

	const setDockedHandler = () => setDocked(!docked);

	return (
		<DockedMenuContext.Provider value={docked}>
			<DockedMenuUpdateContext.Provider value={setDockedHandler}>
				{children}
			</DockedMenuUpdateContext.Provider>
		</DockedMenuContext.Provider>
	);
};

const useDockedMenu = () => useContext(DockedMenuContext);
const useDockedMenuUpdate = () => useContext(DockedMenuUpdateContext);

export {
	MenuToggleProvider,
	useDockedMenu,
	useDockedMenuUpdate,
};
