import React, {Component} from 'react';

class PatientItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            appointment: props.appointment,
            column: props.column
        };
    }

    render() {
        const column = this.state.column ? this.state.column : {id: -1};
        let item = null;
        let data = [];
        let pending = "black";
        let element = "";
        let placeholder = this.state.appointment ? this.state.appointment.placeHolder : true;
        if(this.state.appointment && !this.state.appointment.placeHolder && this.state.appointment.items && this.state.appointment.items.length > 0) {
            item = this.state.appointment.items.find(findItem => findItem.type.id === column.id);
            if(item) {
                data = JSON.parse(item.data)[0]; //TODO: remove index when having real data
                if(column.array && data[column.dataSourceMapping]) {
                    element = data[column.dataSourceMapping].map(e => e.result).join("/");
                }
                else {
                    if(column.dataType === "TEXT") {
                        element = data[column.dataSourceMapping];
                    }
                    else if(column.dataType === "CHECK") {
                        element = data[column.dataSourceMapping] ? "✓" : "";
                    }
                    else {
                        element = "";
                    }
                }
            }
        }
        else if(column.name.startsWith("Labs") && this.state.appointment && !this.state.appointment.placeHolder && this.state.appointment.labs) {
            if(this.state.appointment.labs && this.state.appointment.labs[0]) {
                element = this.state.appointment.labs[0].order.description;
                if(this.state.appointment.labs[0].order.orderStatus === "PENDING")
                    pending = "red";
                else if(this.state.appointment.labs[0].order.orderStatus === "FILLED")
                    pending = "green";
                item = true;
            }
        }
        return (
                column.name !== 'Name' ?
                <td>
                    <div>
                        {
                            item ?
                            <div style={{color:pending}}>{element}</div>
                                 :
                            !placeholder ?
                            <div>N/A</div> : <div></div>
                        }
                    </div>
                </td> : null
        );
    }
}

export default PatientItem;