import React, {Component} from 'react';
import {Modal} from "react-bootstrap";
import './FileViewer.css';
import FileViewer from "./FileViewer";
import PageSelector from "./PageSelector";
import {CloseCircleOutlined, MinusCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";

const SCALE_FACTOR = .50;

class FileReader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: props.file,
            currentPage: 1,
            totalPages: 0,
            scale: 1
        };
        this.pageChanged = this.pageChanged.bind(this);
        this.documentLoaded = this.documentLoaded.bind(this);
        this.increaseScale = this.increaseScale.bind(this);
        this.decreaseScale = this.decreaseScale.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if(prevProps.file !== this.props.file)
            this.setState({file: this.props.file})

    }

    render() {
        const show = this.props.show;
        const onClose = this.props.onClose;
        const file = this.state.file;
        const title = file ? file.title : "";
        const blob = file ? file.content : null;
        const type = file ? file.contentType : null;
        const currentPage = this.state.currentPage;
        const totalPages = this.state.totalPages;
        const scale = this.state.scale;
        return (
                <div>
                    <Modal show={show} onHide={onClose} className={"show"} dialogClassName="pcmh-file-reader"
                           style={{height: "100%"}}>
                        <Modal.Header>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-4">
                                        <Modal.Title className="no-select">{title}</Modal.Title>
                                    </div>
                                    <div className="col-md-4 text-center">
                                        <PageSelector onPageChanged={this.pageChanged} currentPage={currentPage}
                                                      lastPage={totalPages}/>
                                    </div>
                                    <div className="col-md-3 text-center">
                                        <MinusCircleOutlined type="minus-circle" className="page-zoom" title="Zoom less"
                                                             onClick={this.decreaseScale}/>
                                        <PlusCircleOutlined type="plus-circle" className="page-zoom" title="Zoom more"
                                                            onClick={this.increaseScale}/>
                                    </div>
                                    <div className="col-md-1 text-right">
                                        <CloseCircleOutlined type="close-circle" className="page-exit" title="Exit"
                                                             onClick={onClose}/>
                                    </div>
                                </div>
                            </div>
                        </Modal.Header>
                        <Modal.Body className="text-center" styl>
                            <div className="file-contents">
                                <FileViewer blob={blob} type={type} page={currentPage} scale={scale}
                                            onDocumentComplete={this.documentLoaded}/>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
        );
    }

    increaseScale() {
        this.setState((prevState) => ({
            scale: prevState.scale + SCALE_FACTOR
        }));
    }

    decreaseScale() {
        if((this.state.scale - SCALE_FACTOR) > 0)
            this.setState((prevState) => ({
                scale: prevState.scale - SCALE_FACTOR
            }));
    }


    pageChanged(currentPage) {
        this.setState({currentPage: currentPage})
    }

    documentLoaded(pages) {
        this.setState({totalPages: pages});
    }
}

export default FileReader;