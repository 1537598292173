import React, {Component} from 'react'
import './Tab.css'
import PropTypes from 'prop-types';

class Tab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isActive: props.active
        }
    }

    render() {
        const activeClass = this.props.active ? "active" : "";
        return (
                <div className={activeClass+" tabLink"} onClick={(e) => this.props.onClick(e, this.props.text, this.props.content)}>
                    {this.props.text}
                </div>
        )
    }
}

Tab.propTypes = {
    text: PropTypes.string,
    onClick: PropTypes.func,
    active: PropTypes.bool,
    content: PropTypes.any
};

export default Tab;