import React, {useEffect, useRef, useState} from 'react';
import {withUser} from "../../../../context/UserContext";
import {Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select} from "@mui/material";
import 'react-data-grid/lib/styles.css';
import DataGrid, {SelectColumn, textEditor} from 'react-data-grid';
import {BarsOutlined, DeleteOutlined, PlusCircleOutlined} from "@ant-design/icons";
import './HealthData.css';
import DataTypeOptionsEditor from "./DataTypeOptionsEditor";
import {toast} from "react-toastify";
import {Rest} from "../../../../util/rest";


function HealthDataElementAttributeEditor({typeClass, open, close, user}) {

    const dataGridRef = useRef();
    const [rows, setRows] = useState([]);
    const [editOptions, setEditOptions] = useState(false);
    const [optionsRow, setOptionsRow] = useState(false);

    useEffect(() => {
        loadAttributes(typeClass.id)
    }, [])

    useEffect(() => {
        const newRow = rows.findIndex((a) => a.hasOwnProperty("newAttribute") && a.newAttribute)
        if(newRow >= 0) {
            dataGridRef.current.selectCell({idx: 0, rowIdx: newRow}, true);
        }
    }, [rows]);

    function handleSetRows(rows, index) {
        index.indexes.forEach((r) => {
            const row = rows[r];
            row.healthElementTypeClassId = typeClass.id;
            saveAttribute(row);
        })
    }

    function handleDelete(attributeId) {
        Rest.authFetch(user, `/rest/health/element/type-class/health-data-element/attribute/${attributeId}`, {
            method: 'DELETE'
        })
                .then(response => {
                    if(response.status === 401 || response.status === 403) {
                        toast.error("Unauthorized!")
                    }
                    else {
                        loadAttributes(typeClass.id);
                    }
                })
                .catch(err => {
                    console.log(err);
                })

    }

    const dataTypes = [
        {value: "", display: ""},
        {value: "STRING", display: "String"},
        {value: "TEXT", display: "Text"},
        {value: "INTEGER", display: "Integer"},
        {value: "DECIMAL", display: "Decimal"},
        {value: "BOOLEAN", display: "Boolean"},
        {value: "DATE", display: "Date"},
        {value: "TIME", display: "Time"},
        {value: "DATETIME", display: "Date/Time"},
        {value: "OPTIONS", display: "Options"},

    ];

    const columns = [
        {key: 'name', name: 'Name', width: 316, editor: textEditor},
        {
            key: 'dataType', name: 'Data Type', width: 100,
            formatter({row, onRowChange, isCellSelected}) {
                const dtd = dataTypes.find(dt => dt.value.toLowerCase() === row.dataType.toLowerCase());
                return <div>{dtd ? dtd.display : ""}
                    {dtd && dtd.display.toLowerCase() === "options" ?
                     <span style={{
                         fontSize: "1.3em",
                         cursor: "pointer",
                         color: "green",
                         paddingLeft: "6px"
                     }}><BarsOutlined style={{verticalAlign: "middle"}} onClick={() => {
                         setOptionsRow(row);
                         setEditOptions(true)
                     }}/></span> : null}</div>;
            },
            editor: (p) => (
                    <Select size="small"
                            autoFocus
                            defaultOpen
                            fullWidth
                            margin="dense"
                            label="Data Type"
                            value={p.row.dataType}
                            onChange={(e) => p.onRowChange({...p.row, dataType: e.target.value}, true)}
                    >
                        {dataTypes.map((dt) => (
                                <MenuItem value={dt.value}>{dt.display}</MenuItem>

                        ))}
                    </Select>

            )
        },
        {
            key: 'required', name: 'Required', width: 25, formatter({row, onRowChange, isCellSelected}) {

                return (
                        <div style={{textAlign: "center"}}>
                            <Checkbox classes={{root: 'Checkbox', checked: "checked"}} checked={row.required}
                                      onChange={() => {
                                          console.log("changed")
                                          onRowChange({...row, required: !row.required});
                                      }}
                            />
                        </div>
                )
            }
        },
        {
            key: 'remove', name: '', width: 20, formatter: (p) => (
                    <div style={{textAlign: "center"}}>
                        <DeleteOutlined title="Delete Attribute" className={"attributes-delete-icon"}
                                        onClick={() => {
                                            handleDelete(p.row.id);
                                        }}/>
                    </div>
            )
        }

    ];

    function AddAttribute() {
        if(rows.findIndex((a) => a.hasOwnProperty("newAttribute") && a.newAttribute) >= 0)
            return
        setRows(oldRows => [...oldRows, {
            id: 0,
            name: "New Attribute",
            dataType: "STRING",
            required: false,
            newAttribute: true
        }]);
    }

    async function loadAttributes(typeClassId) {
        const response = await Rest.authFetch(
                user,
                `/rest/health/element/type-class/health-data-element/${typeClassId}/attributes`,
                {
                    method: "GET"
                }
        );
        if(response.status === 401 || response.status === 403) toast.error("Unauthorized!")
        else if(response.status === 500)
            toast.error("Error loading Attributes")
        else {
            setRows(response);
        }
    }

    async function saveAttribute(attribute) {

        const response = await Rest.authFetch(
                user,
                `/rest/health/element/type-class/attribute`,
                {
                    method: "PUT",
                    body: JSON.stringify(attribute)
                }
        );
        if(response.status === 401 || response.status === 403) toast.error("Unauthorized!")
        else if(response.status === 500)
            toast.error("Error saving Attribute")
        else {
            await loadAttributes(typeClass.id);
        }
    }

    return (
            <div className={"attributes-editor"}>
                <Dialog open={open} fullWidth>
                    <DialogTitle classes={{root: "editorHeader"}}>Attributes</DialogTitle>
                    <DialogContent style={{padding: "0 12px 12px 10px"}}>
                        <div style={{padding: "3px 0"}}>
                            <span style={{fontWeight: "bold",fontSize:"1vw"}}>{typeClass.type}:{typeClass.healthElementClass}:{typeClass.name}</span>
                            <span style={{float:"right",padding:"3px 0"}}>
                            <PlusCircleOutlined className={"attributes-delete-icon"} title={"Add Attribute"}
                                                onClick={() => AddAttribute()}/>
                                </span>
                        </div>
                        <DataGrid columns={columns} rows={rows} onRowsChange={handleSetRows} ref={dataGridRef}
                                  style={{width: "100%"}}/>
                        <DataTypeOptionsEditor open={editOptions} close={setEditOptions} optionsRow={optionsRow}
                                               update={saveAttribute}/>
                    </DialogContent>
                    <DialogActions id="action-buttons">
                        <Button variant="contained" className={"editorButton"} onClick={() => close()}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
    )
}

export default withUser(HealthDataElementAttributeEditor);