import React from 'react'
import {withUser} from "../../../../context/UserContext";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import AttributeDisplay from "./AttributeDisplay";
import {reorder} from "../../../../util/reorder";


function DisplayElementsView(props) {

    const reorderDisplayAttributes = ({source, destination}) => {
        if(!destination) {
            return;
        }
        const columnAttributes = props.columnAttributes;
        const reorderedAttributes = reorder(
                columnAttributes,
                source.index,
                destination.index
        );
        if(props.onReorder)
            props.onReorder(reorderedAttributes);
        // setColumn({...column, attributes: reorderedAttributes});        //TODO: set Callback

    };

    return (
            <>
                <DragDropContext onDragEnd={reorderDisplayAttributes}>
                    <Droppable droppableId="droppable-attributes" direction="vertical">
                        {(droppableProvided) => (
                                <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}
                                     style={{width: "100%"}}>
                                    {props && props.columnAttributes && props.columnAttributes && props.columnAttributes.length > 0 &&
                                     props.columnAttributes.sort((a, b) => {return a.order - b.order}).map((attribute, index) => (
                                             <AttributeDisplay
                                                     key={index}
                                                     index={index}
                                                     attribute={attribute}
                                                     onDelete={props.onDelete}
                                                     onUpdate={props.onUpdate}
                                             />
                                     ))}
                                    {droppableProvided.placeholder}
                                </div>)}
                    </Droppable>
                </DragDropContext>
            </>
    )
}

export default withUser(DisplayElementsView);