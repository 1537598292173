import styled from 'styled-components';
import { colors } from '../../../../SmartPcmhClientAppConstantsStyle';

const TestStyle = styled.div`
    #title {
        background-color: ${colors['--smartpcmh-alt-lime-green']};
        color: white;
        text-align: center;
    }

    #content {
        #divider {
            height: 50px;
        }
    
        #attributes-table {
            table tbody {
                display: block;
                max-height: 25vh;
                overflow-y: scroll;
            }

            table thead, table tbody tr {
                display: table;
                width: 100%;
                table-layout: fixed;
            }

            #table-head {
                background-color: ${colors['--smartpcmh-alt-lime-green']};
                th {
                    color: white;
                }
            }

            #undo-btn {
                background-color: lightgrey;
            }

            #addOptionSwitch {
                .Mui-checked {
                    color: ${colors['--smartpcmh-alt-lime-green']};
                }
                .MuiSwitch-track {
                    background-color: ${colors['--smartpcmh-alt-lime-green']};
                }
            }

            #select-container {
                height: 130px;
                border: 1px solid lightgrey;
                display: flex;
                align-items: center;
                justify-content: space-between;
                
                #freeTextSelection {
                    width: 48%;
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                }

                #list-options {
                    overflow: scroll;
                    display: flex;
                    justify-content: center;
                    height: 130px;
                    width: 48%;
                }
            }

            #range-container {
                height: 130px;
                border: 1px solid lightgrey;
                display: flex;
                align-items: center;
                justify-content: space-evenly;
            }
                
            #delete-row-div {
                border: 1px solid lightgrey;
                height: 100px;
                padding: 10px;

                #delete-row-action-buttons {
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;

                    button {
                        background-color: lightgrey;
                        width: 25%;
                    }

                    #delete-row-btn {
                        background-color: red;
                        color: white;
                    }
                }
            }
        }
    }

    #action-buttons {
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        button {
            color: white;
            background-color: ${colors['--smartpcmh-alt-lime-green']};
            width: 25%;
        }
    }

    .ant-icon {
        font-size: 20px;
        cursor: pointer;
    }

    .ant-icon-button {
        padding-top: 0;
        padding-bottom: 0;
    }
`;

export default TestStyle;
