// noinspection DuplicatedCode

import React, {useEffect, useRef, useState} from 'react';
import {withUser} from "../../context/UserContext";
import Achievements from "./Achievements";
import {Rest} from "../../util/rest";
import {toast} from "react-toastify";
import SendAnnouncement from "../announcements/SendAnnouncement";
import GenericProfile from "../../images/profile.png";
import {CaretDownOutlined, LogoutOutlined, MessageOutlined, SendOutlined, UserOutlined} from "@ant-design/icons";
import {Menu, MenuItem, SubMenu} from "react-pro-sidebar";
import {useNavigate} from "react-router-dom";
import MessageList from "../announcements/MessageList";


function Profile(props) {
    const [user, setUser] = useState(props.user);
    const [staffMember, setStaffMember] = useState();
    const [sendMessage, setSendMessage] = useState(false);
    const [photoSrc, setPhotoSrc] = useState();
    const [alt, setAlt] = useState();
    const [team, setTeam] = useState();
    const [unread, setUnread] = useState(0);
    const [messageList, setMessageList] = useState([]);
    const [listOpen, setListOpen] = useState(false);
    const [refId, setRefId] = useState(undefined);
    const [anchorEl, setAnchorEl] = useState(null);
    let navigate = useNavigate();

    useEffect(() => {
        if(props.user && props.user.staffMemberId) {
            loadStaffMember(props.user.staffMemberId);
        }
        else
            setStaffMember(null)
        const interval = setInterval(() => {
            getUnreadCount();
        }, 10000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if(props.user !== user) {
            setUser(props.user);
            if(props.user.staffMemberId)
                loadStaffMember(props.user.staffMemberId);
            else
                setStaffMember(null)
        }
    }, [props.user]);

    useEffect(() => {
        getUnreadCount();

        if(staffMember && staffMember.photo)
            setPhotoSrc(staffMember.imageType + "," + staffMember.photo);
        else
            setPhotoSrc(GenericProfile);
        if(staffMember && staffMember.fullName)
            setAlt(staffMember.fullName);
        else
            setAlt(user.name);
        if(staffMember && staffMember.teams && staffMember.teams.length > 0)
            setTeam(staffMember.teams[0]);
        else
            setTeam(null);
    }, [staffMember])

    useEffect(() => {
        setRefId(listOpen ? 'message-list-popper' : undefined);
    }, [listOpen])

    function loadStaffMember(memberId) {
        Rest.authFetch(props.user, "/rest/staff/" + memberId).then(response => {
            if(response.status === 401 || response.status === 403) {
                toast.error("Unauthorized!")
            }
            else {
                setStaffMember(response);
            }
        })
                .catch(err => {
                    console.log(err);
                });
    }

    function getUnreadCount() {
        if(props.user && props.user.staffMemberId) {
            Rest.authFetch(props.user, "/rest/messaging/unread/count/" + props.user.staffMemberId)
                    .then(response => {
                        if(response.status === 401 || response.status === 403) {
                            setMessageList([]);
                            toast.error('Unauthorized!')
                        }
                        else {
                            setUnread(response);
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })
        }
        else {
            setUnread(0);
            setMessageList([]);
        }
    }

    return (
            <>
                {user &&
                 <div className="profile-wrapper" ref={setAnchorEl}>
                     <div className="profile">
                         <div className="container-fluid">
                             <div className="row">
                                 <div className="col-2" style={{padding: 0}}>
                                     <img alt={alt} src={photoSrc}/>
                                     {unread > 0 ? <span className="notify-bubble"
                                                         onClick={() => {navigate("/messages")}}>{unread}</span> : null}
                                 </div>

                                 {!props.collapsed &&
                                  <div className="col-10">
                                      <div className="row">
                                          <div className="col-12 name">
                                              {user.name}
                                          </div>
                                      </div>
                                      {team ?
                                       <div className="row">
                                           <div className="col-12 team">
                                               {team.name}
                                           </div>
                                       </div> : null

                                      }
                                  </div>}
                             </div>
                             {!props.collapsed &&
                              <div className="row">
                                  <div className="col-10">
                                      {staffMember ?
                                       <Achievements stars={staffMember.stars}/> : "Not a Staff Member"}
                                  </div>
                                  <div className="col-2 open-profile">
                                      <CaretDownOutlined/>
                                  </div>
                              </div>
                             }
                         </div>
                         <div className="profileNav">
                             <Menu>
                                 <MenuItem onClick={() => navigate("/profile")}
                                           icon={<UserOutlined style={{fontSize: '1.5em'}}/>}>
                                     Profile
                                 </MenuItem>
                                 <MenuItem onClick={() => {
                                     props.setUser(null);
                                     navigate("/")
                                 }} icon={<LogoutOutlined style={{fontSize: '1.5em'}}/>}>
                                     Log out
                                 </MenuItem>
                                 <MenuItem onClick={() => navigate("/messages")}
                                           icon={<MessageOutlined style={{fontSize: '1.5em'}}/>}>
                                     Announcements
                                 </MenuItem>
                                 <MenuItem onClick={() => setSendMessage(true)}
                                           icon={<SendOutlined style={{fontSize: '1.5em'}}/>}>
                                     Send Announcement
                                 </MenuItem>
                             </Menu>
                         </div>
                     </div>
                     <SendAnnouncement open={sendMessage} onClose={() => setSendMessage(false)}/>
                     <MessageList anchorEl={anchorEl}
                                  open={listOpen}
                                  refId={refId}
                     />
                 </div>
                }
            </>
    )


}

export default withUser(Profile);