import React, {Component} from 'react';
import './AgendaItem.css';
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";

class AgendaItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            item: props.item,
            editMode: this.props.item.new
        };
        console.log("I'm index: " + props);
        this.edit = this.edit.bind(this);
        this.delete = this.delete.bind(this);
        this.handleEnterKey = this.handleEnterKey.bind(this);
        this.exitEditMode = this.exitEditMode.bind(this);
    };

    render() {

        const item = this.state.item;
        const editMode = this.state.editMode;
        return (
                <div className="container-fluid agendaItem">
                    <div className="row">


                        {editMode ?
                         <div className="col-md-10 my-auto">
                             <input type="text"
                                    autoFocus
                                    defaultValue={item.name}
                                    className="editing"
                                    onKeyUp={this.handleEnterKey}
                                    onBlur={this.exitEditMode}
                                    onFocus={(e) => this.props.item.new ? e.target.select() : null}
                                    ref={this.input}/>
                         </div> :
                         <div className="col-md-10 my-auto" onClick={this.edit}>
                             {item.name}
                         </div>}
                        <div className="col-md-1 my-auto edit" onClick={this.edit}>
                            <EditOutlined title="Edit"/>
                        </div>
                        <div className="col-md-1 my-auto edit" onClick={this.delete}>
                            <DeleteOutlined title="Delete"/>
                        </div>
                    </div>
                </div>
        );
    }

    handleEnterKey(e) {
        if(e.keyCode === 13 || e.charCode === 13) {
            this.setState((state) => ({
                editMode: false
            }), () => {this.exitEditMode(e)});
        }
        else if(e.keyCode === 27 || e.charCode === 27) {
            const name = this.props.item.name;
            this.setState((state) => ({
                editMode: false,
                item: {
                    ...state.item,
                    name: name
                }
            }));

        }
        else {
            const name = e.target.value;
            this.setState((state) => ({
                item: {
                    ...state.item,
                    name: name
                }
            }));
        }
    }

    exitEditMode(event) {
        this.setState({editMode: false});
        if(this.props.onChange && this.state.item.name !== this.props.item.name) {
            this.props.onChange(this.state.item);
        }
    }

    edit() {
        this.setState({editMode: true})
    }

    delete() {
        if(this.props.onRemove)
            this.props.onRemove(this.state.item)
    }
}

export default AgendaItem;