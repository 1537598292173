import React, {useEffect, useState} from 'react'
import {withUser} from "../../../../context/UserContext";
import './DataSet.css'
import {Button, TextareaAutosize, TextField} from "@mui/material";
import {Rest} from "../../../../util/rest";
import {toast} from "react-toastify";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {DeleteOutline, EditOutlined, ReorderOutlined} from "@mui/icons-material";
import DataSetColumnEditor from "./DataSetColumnEditor";
import {reorder} from "../../../../util/reorder";
import ResultsTable from '../PopulationSet/ResultsTable';
import {isEqual} from 'lodash';

const operands = {
    "EQUALS": "equals",
    "LESS_THAN": "less than",
    "LESS_THAN_OR_EQUAL_TO": "less than or equal to",
    "GREATER_THAN": "greater than",
    "GREATER_THAN_OR_EQUAL_TO": "greater than or equal to",
    "CONTAINS": "contains",
    "BETWEEN": "between",
    "LAST_N_MONTHS": "last months",
    "STARTS_WITH": "starts with",
    "ENDS_WITH": "ends with",
    "IS_TRUE": "is true",
    "IS_FALSE": "is false"
}

function DataSetEditor(props) {

    const [dataSet, setDataSet] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [activeColumn, setActiveColumn] = useState();
    const [dataSetType, setDataSetType] = useState("");

    useEffect(() => {
        if(props.dataSet.id===0)
            saveDataSet(props.dataSet).then();
        else
            getActiveDataSet(props.dataSet.id).then();
    }, [])

    useEffect(() => {
        if(dataSet) {
            switch(dataSet.dataSetType) {
                case "DATASET":
                    setDataSetType("Data Set");
                    break;
                case "POPULATIONSET":
                    setDataSetType("Population Set");
                    break;
                default:
                    setDataSetType("");
            }
        }
    }, [dataSet])

    const getActiveDataSet = async (id) => {
        if(id > 0) {
            setLoading(true);

            const response = await Rest.authFetch(
                    props.user,
                    `/rest/dataset/${id}`,
                    {
                        method: "GET"
                    }
            );
            if(response.status === 401 || response.status === 403)
                toast.error("Unauthorized!")
            else if(response.status === 500)
                toast.error("Error Getting Dataset")
            else {
                setDataSet(response);
            }
            setLoading(false);
        }
        else {
            setDataSet(props.dataSet);
        }
    };

    const reorderElements = ({source, destination}) => {
        if(!destination) {
            return;
        }
        const columns = dataSet.columns;
        const reOrderedColumns = reorder(
                columns,
                source.index,
                destination.index
        );
        setDataSet({...dataSet, columns: reOrderedColumns});
        reOrderedColumns.forEach((c, i) => {saveColumn(c, i).then()})
    };

    const generateColumnDescription = (column) => {
        if(!column) return "";
        let retVal = `${column.title}`;
        if(column.attributes && column.attributes.length > 0) {
            let attributes = column.attributes.map((attribute) => {
                return `${attribute.attributeName}`;
            })
            retVal = `${retVal} will display ${attributes.join(',')}`
        }
        if(column.filters && column.filters.length > 0) {
            let filters = column.filters.sort((a, b) => a.order - b.order).map((filter) => {
                console.log("filter.operand->", filter.operand);
                return `<b>${filter.attributeName}</b> ${operands[filter.operand]} ${filter.filterValue1}${filter.filterValue2 ? " and " + filter.filterValue2 : ""} ${filter.grouping ? filter.grouping : ""}`;

            })
            retVal = `${retVal} when ${filters.join(' ')}`
        }
        return retVal;
    };

    const newColumn = () => {
        const column = {
            id: 0,
            dataSetId: dataSet.id,
            attributes: [],
            filterGroups: [],
            order: dataSet.columns.length,
            title: "New Column"
        };
        const columns = dataSet.columns;
        columns.push(column);
        saveColumn(column, columns.length - 1).then(() => {
            getActiveDataSet(props.dataSet.id).then(() => {
                setActiveColumn(dataSet.columns[dataSet.columns.length - 1]);
            });
        });
    };

    const cancelColumnEdit = () => {
        setActiveColumn(null);
    };

    const updateColumn = (column) => {
        saveColumn(column).then(()=>{
            let columns=dataSet.columns;
            const columnIndex=findColumnIndex(columns,column)
            if(columnIndex>=0) {
                columns[columnIndex] = column;
                setDataSet({...dataSet,columns:columns})
            }
        });
    };

    const removeColumn = (column) => {
        const columns = dataSet.columns;
        const index = findColumnIndex(columns, column);
        if(index >= 0) {
            columns.splice(index, 1);
            setDataSet({...dataSet, columns: columns});
            setActiveColumn(null);
        }
        if(column.id > 0) {
            deleteColumn(column.id).then();
        }
    }

    const findColumnIndex = (columns, column) => {
        return columns.findIndex((c) => c.id === column.id);
    }

    async function saveDataSet(dataSet) {

        const response = await Rest.authFetch(
                props.user,
                `/rest/dataset/`,
                {
                    method: "PUT",
                    body: JSON.stringify(dataSet)
                }
        );

        if(response.status === 401 || response.status === 403) toast.error("Unauthorized!")
        else if(response.status === 500)  toast.error("Error Saving Dataset")
        else setDataSet(response);
    };

    const saveColumn = async (column, index) => {
        const response = await Rest.authFetch(
                props.user,
                `/rest/dataset/column`,
                {
                    method: "PUT",
                    body: JSON.stringify(column)
                }
        );

        if(response.status === 401 || response.status === 403) toast.error("Unauthorized!")
        else if(response.status === 500)
            toast.error("Error Saving Column")

        else {
            if(index) {
                const columns = dataSet.columns;
                columns[index] = column;
                setDataSet({...dataSet, columns: columns});
                setActiveColumn(null);
            }
        }

    }

    const deleteColumn = async (columnId) => {
        const response = await Rest.authFetch(
                props.user,
                `/rest/dataset/column/${columnId}`,
                {
                    method: "DELETE"
                }
        );
        if(response.status === 401 || response.status === 403) toast.error("Unauthorized!")
        else if(response.status === 500)
            toast.error("Error Deleting Column")
    }

    return (
            <div className="dataset-editor container-fluid">
                <h4>{`${dataSetType}`}</h4>
                {dataSet &&
                 <>
                     <div className="row">
                         <div className="col-5">
                             <TextField
                                     fullWidth
                                     margin="dense"
                                     label={`${dataSetType} Name`}
                                     name="name"
                                     variant="outlined"
                                     autoComplete="off"
                                     size="small"
                                     onChange={(e) => setDataSet({...dataSet, name: e.target.value})}
                                     value={dataSet.name}
                                     maxLength="100"
                                     autoFocus
                                     onFocus={event => { event.target.select(); }}
                                     onBlur={() => saveDataSet(dataSet)}
                             />
                         </div>
                         <div className="col-6">
                             <TextField
                                     multiline
                                     maxRows={2}
                                     fullWidth
                                     margin="dense"
                                     label="Description"
                                     name="description"
                                     variant="outlined"
                                     autoComplete="off"
                                     size="small"
                                     value={dataSet.description}
                                     onChange={(e) => setDataSet({...dataSet, description: e.target.value})}
                                     maxLength="500"
                                     onBlur={() => saveDataSet(dataSet)}
                             />
                         </div>
                         <div className="col-1">
                             <Button className="editorButton" size="small"
                                     onClick={() => {if(props.cancelEdit) props.cancelEdit()}}>Exit</Button>
                         </div>
                     </div>
                     <div className="row divider"/>
                     <>
                         <div className="row align-content-center" style={{marginBottom: "5px"}}>
                             <div className="col-10">
                                 <h5>Columns</h5>
                             </div>
                             <div className="col-2 edit align-content-end">
                                 <Button className="editorButton" size="small" onClick={() => newColumn()}>
                                     New Column
                                 </Button>

                             </div>
                         </div>
                         <div id="elements-list">
                             <DragDropContext onDragEnd={reorderElements}>
                                 <Droppable droppableId="droppable" direction="vertical">
                                     {(droppableProvided) => (
                                             <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps} >
                                                 {dataSet && dataSet.columns && dataSet.columns.map((column, index) => (
                                                         <Draggable key={index + 1} draggableId={String(index)}
                                                                    index={index}>
                                                             {(draggableProvided, snapshot) => (
                                                                     <div className={"row column" + (activeColumn && column.id === activeColumn.id ? " highlighted" : "")}
                                                                          key={index + 1}
                                                                          ref={draggableProvided.innerRef} {...draggableProvided.draggableProps}>
                                                                         <div className="element-reorder col-auto align-self-center" {...draggableProvided.dragHandleProps}>
                                                                             <ReorderOutlined/>
                                                                         </div>
                                                                         <div className="col-10 align-self-center element-text">
                                                                             {generateColumnDescription(column)}
                                                                         </div>
                                                                         <div className="element-actions col-1 align-self-center">
                                                                             <div className="row justify-content-end">
                                                                                 <div className="col-1 offset-7">
                                                                                     <EditOutlined
                                                                                             className="edit"
                                                                                             onClick={() => setActiveColumn(column)}/>
                                                                                 </div>
                                                                                 <div className="col-1">
                                                                                     <DeleteOutline
                                                                                             className="edit"
                                                                                             onClick={() => removeColumn(column)}/>
                                                                                 </div>
                                                                             </div>
                                                                         </div>
                                                                     </div>
                                                             )}
                                                         </Draggable>
                                                 ))}
                                                 {droppableProvided.placeholder}
                                             </div>
                                     )}
                                 </Droppable>
                             </DragDropContext>
                         </div>

                     </>
                     <div className="row divider"/>
                     {activeColumn &&
                      <div style={{height: "100%", overflowY: "scroll"}}>
                          <DataSetColumnEditor column={activeColumn} cancelColumnEdit={cancelColumnEdit}
                                               onUpdate={updateColumn}/>
                      </div>
                     }
                 </>
                }
                {isLoading &&
                 <div className="text-center my-5">
                     <button className="btn btn-primary-color btn-lg rounded shadow-sm" type="button" disabled>
                         <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"/>
                         Loading...
                     </button>
                 </div>
                }

                {/* <ResultsTable data={patientSet} /> */}
                <br/>
                {dataSet && isEqual(dataSet.dataSetType, 'POPULATIONSET') && (
                        <ResultsTable data={dataTest}/>
                )}

            </div>
    )


}

const dataTest = {
    "population_set": [
        {
            "row_id": 1,
            "row_order": 1,
            "patient": {
                "patient_id": "123456789"
            },
            "columns": [
                {
                    "column_id": 1,
                    "column_name": "Blood Test",
                    "values": [
                        {
                            "health_element_attribute_id": 268,
                            "health_element_datatype": "STRING",
                            "health_element_attribute_name": "Test Name",
                            "display": "MOUSEOVER",
                            "value": "x10E3/ul",
                            "instance_id": "2"
                        },
                        {
                            "health_element_attribute_id": 263,
                            "health_element_datatype": "STRING",
                            "health_element_attribute_name": "Testing Still",
                            "display": "IN_COLUMN",
                            "value": "WBC",
                            "patient_id": "123456789",
                            "instance_id": "2"
                        }
                    ]
                },
                {
                    "column_id": 2,
                    "column_name": "Temperature",
                    "values": [
                        {
                            "health_element_attribute_id": 138,
                            "health_element_datatype": "DATETIME",
                            "health_element_attribute_name": "Date Taken",
                            "display": "IN_COLUMN",
                            "value": "10-05-2022 10:49 AM",
                            "patient_id": "123456789",
                            "instance_id": "2"
                        },
                        {
                            "health_element_attribute_id": 139,
                            "health_element_datatype": "DECIMAL",
                            "health_element_attribute_name": "Value",
                            "display": "IN_COLUMN",
                            "value": "98.9",
                            "patient_id": "123456789",
                            "instance_id": "2"
                        },
                        {
                            "health_element_attribute_id": 140,
                            "health_element_datatype": "STRING",
                            "health_element_attribute_name": "Unit",
                            "display": "IN_COLUMN",
                            "value": "F",
                            "patient_id": "123456789",
                            "instance_id": "2"
                        }
                    ]
                }
            ]
        }
    ]
}

export default withUser(DataSetEditor)