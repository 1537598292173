import React, {Component} from 'react';
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import "./SendAnnouncement.css";
import Recipient from "./Recipient";
import SelectPersonAndTeams from "./SelectPersonAndTeams";
import {withUser} from "../../context/UserContext";
import {Rest} from "../../util/rest";
import {toast} from "react-toastify";
// import toast from "../../util/toast";

const inputLabelProps = {
    FormLabelClasses: {
        root: 'textField',
        focused: 'focused'
    },
};

class SendAnnouncement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            showSearchResults: false,
            searchTerm: "",
            anchorEl: null,
            announcement: {
                sender: {id: props.user.staffMemberId},
                recipients: [],
                subject: "",
                message: "",
                teams: [],
            },
        };

        this.searchRef = React.createRef('');
        this.addRecipients = this.addRecipients.bind(this);
        this.removeRecipients = this.removeRecipients.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.open !== this.props.open) {
            this.setState({open: this.props.open});
            if(!this.props.open)
                this.setState({
                                  searchTerm: "",
                                  announcement: {
                                      sender: {id: this.props.user.staffMemberId},
                                      recipients: [],
                                      subject: "",
                                      message: "",
                                      teams: [],
                                  }
                              })
        }
    }

    render() {

        const selectedRecipients = [];
        const showSearchResults = this.state.showSearchResults;

        this.state.announcement.recipients.map(recipient => {
            selectedRecipients.push({
                                        name: recipient.fullName,
                                        type: "recipients",
                                        id: recipient.id
                                    });
            return null;
        });
        this.state.announcement.teams.map(team => {
            selectedRecipients.push({
                                        name: team.name,
                                        type: "team",
                                        id: team.id
                                    });
            return null;
        });

        return (

                <Dialog
                        open={this.state.open}
                        onClose={this.handleClose}
                        fullWidth={true} maxWidth={"sm"}
                        aria-labelledby="sendMessage-dialog-title">

                    <DialogTitle classes={{root: "editorHeader"}} id={"sendMessage-dialog-title"}>
                        <span className="unSelectable">Send Announcement</span>
                    </DialogTitle>
                    <DialogContent>
                        <div className={"send-announcement"}>
                            <TextField name="searchTerm"
                                       type="text"
                                       placeholder="Search" id="search"
                                       title="search"
                                       inputRef={this.searchRef}
                                       autoFocus={true}
                                       InputProps={inputLabelProps}
                                       margin="dense"
                                       label="Team and Staff Search"
                                       variant="outlined"
                                       className="textField"
                                       fullWidth
                                       autoComplete="off"
                                       defaultValue={this.state.searchTerm}
                                       onKeyUp={(e) => this.searchRecipients(e)}
                            />


                            <SelectPersonAndTeams open={showSearchResults} results={this.state.recipients}
                                                  anchorEl={this.state.anchorEl} onSelect={this.addRecipients}/>

                            <div className="member-list">
                                <div className={"title"}>Send To</div>
                                <div className={"sent-to"}>
                                    {selectedRecipients.map((recipient, index) => {
                                        return (<Recipient recipient={recipient} key={index}
                                                           deleteHandler={this.removeRecipients}/>);

                                    })
                                    }
                                </div>
                            </div>

                            <TextField name="subject"
                                       type="text"
                                       placeholder="Subject" id="subject"
                                       title="Subject"
                                       required={true}
                                       autoComplete="Subject"
                                       InputProps={inputLabelProps}
                                       defaultValue={this.state.announcement.subject}
                                       margin="dense"
                                       label="Subject"
                                       variant="outlined"
                                       className="textField" fullWidth
                                       onKeyUp={(e) => this.handleInputChange(e)}/>

                            <TextField name="message"
                                       type="text"
                                       placeholder="Message" id="message"
                                       title="Message"
                                       multiline={true}
                                       required={true}
                                       autoComplete="Message"
                                       InputProps={inputLabelProps}
                                       defaultValue={this.state.announcement.message}
                                       margin="dense"
                                       label="Message"
                                       variant="outlined"
                                       onKeyUp={(e) => this.handleInputChange(e)}
                                       className="textField" fullWidth/>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose}
                                className="editorButton" variant="contained"> Cancel
                        </Button>
                        <Button onClick={() => this.sendAnnounce()}
                                className="editorButton" variant="contained"> Send
                        </Button>

                    </DialogActions>

                </Dialog>

        );
    }

    validate() {
        // Need to do validations...(?)
        return true;
    }

    sendAnnounce() {
        if(this.validate()) {
            Rest.authFetch(this.props.user, "/rest/messaging/sendmessage", {
                method: "POST",
                body: JSON.stringify(this.state.announcement)
            })
                    .then(response => {
                        if(response) {
                            toast.success("Announcement Sent!");
                            this.handleClose();
                        }
                        else {
                            toast.error("There Was an error!");
                        }
                    });

        }
    }

    // Element Closing Handlers
    handleClose() {
        if(this.props.onClose)
            this.props.onClose();
        this.setState({open: false});
    }

    removeRecipients(recipient, index) {
        if(recipient) {
            let recipients = [];
            if(recipient.type === "recipients") {
                recipients = [...this.state.announcement.recipients];
            }
            else if(recipient.type === "teams") {
                recipients = [...this.state.announcement.teams];
            }
            recipients.splice(index, 1);
            this.setState((state) => ({
                announcement: {
                    ...state.announcement,
                    [recipient.type]: recipients
                }
            }));
        }
    }

    // Recipient Handlers
    addRecipients(recipient) {
        if(recipient) {
            const type = recipient.type === "recipient" ? "recipients" : "teams"
            let recipients = [];
            if(recipient.type === "recipient") {
                recipients = [...this.state.announcement.recipients];
                recipients.push(recipient);
            }
            else if(recipient.type === "team") {
                recipients = [...this.state.announcement.teams];
                recipients.push(recipient);
            }
            this.setState((state) => ({
                searchTerm: "",
                announcement: {
                    ...state.announcement,
                    [type]: recipients
                }
            }));
        }
        this.searchRef.current.value = null;
        this.searchRef.current.focus();
        this.setState({showSearchResults: false})
    }

    searchRecipients(e) {
        this.handleInputChange(e);
        this.setState({
                          anchorEl: e.currentTarget
                      });
        const value = e.target.value.trim();
        if(value.length > 2) {
            this.getProfiles(value);
        }
        else {
            this.setState({
                              showSearchResults: false,
                          })
        }
    }


    getProfiles(searchTerm) {
        if(searchTerm) {
            Rest.authFetch(this.props.user, "/rest/staff/searchPersonsAndTeams/" + searchTerm)
                    .then(response => {
                        if(response.status === 401 || response.status === 403) {
                            this.setState({
                                              recipients: [],
                                              showSearchResults: false,
                                          });
                            toast.error("Unauthorized!");
                        }
                        else {
                            if(response.length > 0)
                                this.setState({
                                                  showSearchResults: true,
                                                  recipients: response
                                              });
                            else
                                this.setState({
                                                  recipients: [],
                                                  showSearchResults: false,
                                              });
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        if(name !== "searchTerm") {
            this.setState((state) => ({
                announcement: {
                    ...state.announcement,
                    [name]: value
                }
            }));
        }
        else {
            this.setState({
                              [name]: value
                          });

        }
    }


}


export default withUser(SendAnnouncement);