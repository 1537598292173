import React, {Component} from 'react';
import FileReader from "../../common/fileviewer/FileReader";
import {Rest} from "../../../util/rest";
import toast from "../../../util/toast";
import {withUser} from "../../../context/UserContext";
import Achievements from "../../login/Achievements";
import Rate from "./Rate";

class RecommendedReadingItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reading: props.reading,
            show: false,
            file: null,
            showRater: false
        };
        this.handleShowDocument = this.handleShowDocument.bind(this);
        this.documentClosed = this.documentClosed.bind(this);
        this.rate = this.rate.bind(this);
        this.rated = this.rated.bind(this);
        this.getRating();
    }


    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if(prevProps.reading !== this.props.reading) {
            this.setState({reading: this.props.reading}, () =>
                    this.getRating());
        }
    }

    render() {
        const reading = this.state.reading;
        const document = reading && reading.document ? reading.document : null;
        const title = document ? document.title : "";
        const badge = document.badge ? (document.badgeImageType + "," + document.badge) : null;
        const readingTime = document ? document.readingTime : 0;
        const show = this.state.show;
        const showRater = this.state.showRater;
        const currentFile = this.state.file;
        const rating = this.state.rating ? this.state.rating : 0;
        return (
                <div>
                    <div className="row pcmh-reading-item">
                        <div className="col-md-6 my-auto title" onClick={this.handleShowDocument}>{title}</div>

                        <div className="col-md-2 my-auto">
                            <div className="row">
                                <div className="col-md-12 text-center unSelectable">Est. Reading Time:</div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 text-center unSelectable">{readingTime} mins.</div>
                            </div>
                        </div>
                        <div className="col-md-3 my-auto">
                            <div className="row">
                                <div className="col-12 unSelectable">
                                    <Achievements stars={rating}/>
                                </div>
                            </div>
                            {
                                !this.state.rating ? <div className="row">
                                    <div className="col-12 unSelectable" style={{cursor: "pointer"}}
                                         onClick={this.rate}>Not rated yet
                                    </div>
                                </div> : null
                            }

                        </div>
                        {badge ? <div className="col-md-1 text-right my-auto">
                            <img src={badge} alt=""/>
                        </div> : null}
                    </div>
                    <div className="static-modal">
                        <FileReader show={show} file={currentFile}
                                    onClose={this.documentClosed}/>
                    </div>
                    <div className="static-modal">
                        <Rate show={showRater} document={document} onRatingComplete={this.rated}/>
                    </div>
                </div>
        );
    }

    handleShowDocument() {
        const reading = this.state.reading;
        const document = reading && reading.document ? reading.document : null;

        if(document) {
            Rest.authFetch(this.props.user, "/rest/content/" + document.contentId).then(response => {
                if(response.status === 401 || response.status === 403) {
                    this.setState({files: []});
                    toast.error("Unauthorized!")
                }
                else {
                    this.setState({file: response, show: true, start: new Date()});

                }
            })
                    .catch(err => {
                        console.log(err);
                    });
        }
    }

    documentClosed() {
        this.setState({show: false});
        let seconds = Math.round(((new Date()) - this.state.start) / 1000);
        let readingActivity = {
            id: 0,
            document: this.state.reading.document,
            readDate: this.state.start,
            readingAssignment: this.state.reading,
            staffMember: this.state.reading.staffMember,
            timeSpent: seconds
        };
        Rest.authFetch(this.props.user, "/rest/staff/addReadingActivity", {
            method: "PUT",
            body: JSON.stringify(readingActivity)
        }).then();
    }

    getRating() {
        if(this.state.reading && this.state.reading.document) {
            let documentId = this.state.reading.document.id;
            Rest.authFetch(this.props.user, "/rest/content/getrating/" + documentId).then(response => {
                if(response.status === 401 || response.status === 403) {
                    this.setState({rating: null});
                    toast.error("Unauthorized!")
                }
                else {
                    this.setState({rating: response});
                }
            })
                    .catch(err => {
                        console.log(err);
                    });
        }
    }

    rate() {
        this.setState({showRater: true});
    }

    rated() {
        this.setState({showRater: false});
        this.getRating();
    }
}

export default withUser(RecommendedReadingItem);