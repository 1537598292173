import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { withUser } from "../../../context/UserContext";
import { Rest } from "../../../util/rest";
import toast from "../../../util/toast";

import StaffHeader from "../staffsupport/StaffHeader";
import StaffProfile from "../staffsupport/StaffProfile";
import AssignmentsListTables from "../patientsupport/assigments/AssignmentsListTables";
import RecommendedReading from "../staffsupport/RecommendedReading";
import ProfileStyle from "./ProfileStyle";

const Profile = ({ user }) => {
    const [recommendedReading, setRecommendedReading] = useState([]);
    const [staffMember, setStaffMember] = useState({});

    useEffect(() => {
        getData(`/rest/staff/assigned/${user.staffMemberId}`, setRecommendedReading);
        getData(`/rest/staff/${user.staffMemberId}`, setStaffMember);
        return () => {
            setRecommendedReading([]);
            setStaffMember({});
        }
    }, [])

    const getData = async (url, stateHandler) => {
        const response = await Rest.authFetch(user, url)
            .catch(err => console.log(err));

        if (response.status >= 400) toast.error("Unauthorized!");
        else stateHandler(response);
    };

    return (
        <ProfileStyle>
            <StaffHeader staffMember={staffMember} />
            <StaffProfile profile={staffMember} />
            {!isEmpty(staffMember) && (
                <>
                    <br />
                    <AssignmentsListTables personType={"Staff"} person={staffMember} />
                </>
            )}
            <RecommendedReading readings={recommendedReading} heading="e-learning Tools" />
        </ProfileStyle>
    )
}

Profile.propTypes = {
    user: PropTypes.shape({
        tokenType: PropTypes.arrayOf(PropTypes.object),
        name: PropTypes.string,
        username: PropTypes.string,
        email: PropTypes.string,
        authorities: PropTypes.arrayOf(PropTypes.object),
        expiration: PropTypes.string,
        staffMemberId: PropTypes.string,
    }),
}

Profile.defaultProps = {
    user: {
        tokenType: [],
        name: '',
        username: '',
        email: '',
        authorities: [],
        expiration: '',
        staffMemberId: 0,
    },
};

export default withUser(Profile);
