import React, {Component} from 'react';
import PropTypes from "prop-types";
import './MessageItem.css'
import {DeleteOutlined} from "@ant-design/icons";
import Message from "./Message";
import {withUser} from "../../context/UserContext";
import {Rest} from "../../util/rest";
import toast from "../../util/toast";

const options = {
    year: 'numeric', month: 'numeric', day: 'numeric',
    hour: 'numeric', minute: 'numeric', second: 'numeric',
    hour12: true,
    timeZone: 'America/Puerto_Rico'
};

class MessageItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: this.props.message,
            anchorEl: null,
            reading: false
        };
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.message !== this.props.message) {
            this.setState({
                              message: this.props.message
                          });
        }
    }

    render() {
        const message = this.state.message;
        const announcement = message ? message.announcement : null
        const name = announcement.sender.fullName;
        const sentDate = Intl.DateTimeFormat('en-US', options).format(new Date(announcement.sentDateTime));
        const subject = announcement.subject;
        const messageText = announcement.message;
        const read = message.readDateTime ? "" : "read";
        const anchor = this.state.anchorEl;
        const reading = this.state.reading;
        return (
                <div>
                    <div className={"message-item unSelectable"} ref={this.myRef}>
                        <div className={"row"}>
                            <div className={"col-md-10"} onClick={(e) => this.showMessage(e)}>
                                <div className={"row"}>
                                    <div className={"col-md-6 name"}>
                                        <span className={read}>{name}</span>
                                    </div>
                                    <div className={"col-md-6 date"}>
                                        {sentDate}
                                    </div>
                                </div>
                                <div className={"row"}>
                                    <div className={"col-md-12 subject"}>
                                        {subject}
                                    </div>
                                </div>
                                <div className={"row"}>
                                    <div className={"col-md-12"}>
                                        <div className={"text"}>
                                            {messageText}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-md-2"} style={{margin: "auto"}}>
                                <div className={"row"}>
                                    <div className={"col-md-12"}>
                                        <DeleteOutlined className={"delete-icon"} onClick={(e) => this.deleteMe(e)}
                                                        type="delete" title="Delete Message"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Message open={reading} message={message} anchorEl={anchor} onClose={() => this.unShowMessage()}/>
                </div>
        );
    }

    deleteMe(e) {
        e.preventDefault();
        e.stopPropagation();
        if(this.props.deleteHandler) {
            this.props.deleteHandler(this.props.message, this.props.index)
        }
    }

    markAsRead(id) {
        Rest.authFetch(this.props.user, "/rest/messaging/read/" + id,
                       {
                           method: "PUT"
                       })
                .then(response => {
                    if(response.status === 401 || response.status === 403) {
                        this.setState({
                                          messageList: [],
                                      });
                        toast.error('Unauthorized!')
                    }
                    else {
                        this.getUnreadCount();
                    }
                })
                .catch(err => {
                    console.log(err);
                })

    }

    unShowMessage = () => {
        this.setState({reading: false})
    }

    showMessage(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
                          reading: true,
                          anchorEl: e.currentTarget
                      })
        if(this.state.message.readDateTime == null) {
            this.markAsRead(this.state.message.id);
            this.setState((state) => ({
                message: {
                    ...state.message,
                    readDateTime: new Date().toISOString()
                }
            }));
        }
    }
}

MessageItem.protoTypes = {

    message: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    onRead: PropTypes.func
}
export default withUser(MessageItem);