import React, { Component } from 'react';
import './SectionHeader.css'
import PropTypes from 'prop-types';
import { useDockedMenu, useDockedMenuUpdate } from "../../../context/MenuToggleContext";
import Announcement from "../../announcements/Announcement";

class SectionHeader extends Component {

    render() {
        const tabsStyle = {
            "--ts": this.props.children.length
        };
        return (
            <>
                <div className="header-container">
                    <h2>
                        {this.props.title}
                    </h2>
                    <div className="tabs-link" style={tabsStyle}>{this.props.children}</div>
                    <Announcement />
                </div>
            </>
        )
    }


}

SectionHeader.propTypes = {
    title: PropTypes.string
};

const withMenuToggle = (Component) => {
    return function WrappedComponent(props) {
        const dockedMenu = useDockedMenu();
        const dockedMenuUpdate = useDockedMenuUpdate();
        return <Component {...{ ...props, dockedMenu, dockedMenuUpdate }} />;
    }
}

export default withMenuToggle(SectionHeader);
