import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withUser} from "../../../../context/UserContext";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import './ProfileEditor.css';
import {getNewProfile} from "./ProfileAdmin"
import {Rest} from "../../../../util/rest";
import toast from '../../../../util/toast';
import isLength from 'validator/lib/isLength';
import isEmpty from 'validator/lib/isEmpty';
import MetaDataElement from "../content/MetaDataElement";
import {UsergroupAddOutlined} from "@ant-design/icons";
import AddTeam from "./AddTeam";
import CommunicationsEditor from "./CommunicationsEditor";

const inputLabelProps = {
    formlabelclasses: {
        root: 'textField',
        focused: 'focused'
    }
};

class ProfileEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open,
            profile: this.props.profile,
            errors: [],
            addingTeams: false
        };
        this.handleClose = this.handleClose.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.uploadPhoto = this.uploadPhoto.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleValueChange = this.handleValueChange.bind(this);
        this.handleRemoveTeam = this.handleRemoveTeam.bind(this);
        this.handleAddTeam = this.handleAddTeam.bind(this);
        this.handleAddTeamClose = this.handleAddTeamClose.bind(this);
    }


    componentDidUpdate(prevProps, prevState, snapShot) {
        if(prevProps.open !== this.props.open) {
            this.setState({
                              open: this.props.open,
                              originalProfile: this.props.profile,
                              profile: this.props.profile
                          });
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.open !== prevState.open) {
            return {open: nextProps.open};
        }
        else return null;
    }


    render() {
        const open = this.state.open;
        const profile = !this.state.profile ? getNewProfile() : this.state.profile;
        const teams = !profile || !profile.teams ? [] : this.state.profile.teams;
        const errors = !this.state.errors ? [] : this.state.errors;
        return (
                <div>

                    <Dialog open={open}
                            onClose={this.handleClose}
                            aria-labelledby="form-dialog-title"
                            // onClose={(event, reason) => {
                            //     if(reason !== 'backdropClick' && reason !== 'escapeKeyDown')
                            //         setShowAlert(false);
                            // }}
                            disableBackdropClick
                            disableEscapeKeyDown
                    >
                        <DialogTitle classes={{root: "editorHeader"}} id="form-dialog-title">Staff Member</DialogTitle>
                        <DialogContent className="container-fluid pcmh-profile-editor">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <TextField autoFocus margin="dense" name="title" id="title" label="Title"
                                                       type="text"
                                                       variant="outlined" InputLabelProps={inputLabelProps}
                                                       className="textField" fullWidth onKeyUp={this.handleInputChange}
                                                       defaultValue={profile.title}/>
                                        </div>

                                        <div className="col-md-8">
                                            <TextField margin="dense" name="firstName" id="firstName" label="Name"
                                                       type="text" required
                                                       variant="outlined" InputLabelProps={inputLabelProps}
                                                       className="textField" fullWidth
                                                       onKeyUp={this.handleInputChange}
                                                       defaultValue={profile.firstName}/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-8">
                                            <TextField margin="dense" name="firstLastName" id="firstLastName"
                                                       label="Last Name" type="text"
                                                       required
                                                       variant="outlined" InputLabelProps={inputLabelProps}
                                                       className="textField" fullWidth
                                                       onKeyUp={this.handleInputChange}
                                                       defaultValue={profile.firstLastName}/>
                                        </div>
                                        <div className="col-md-4">
                                            <TextField margin="dense" name="suffix" id="suffix" label="Suffix"
                                                       type="text"
                                                       variant="outlined" InputLabelProps={inputLabelProps}
                                                       className="textField" fullWidth
                                                       onKeyUp={this.handleInputChange}
                                                       defaultValue={profile.suffix}/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="segment">
                                                <div className="segment-title">Teams</div>
                                                <div className="row">
                                                    <div className="col-md-9">
                                                        {
                                                            teams.map(team => {
                                                                return <MetaDataElement item={team.name}
                                                                                        itemId={team.id}
                                                                                        key={team.id} canRemove
                                                                                        onDelete={this.handleRemoveTeam}/>
                                                            })
                                                        }
                                                    </div>
                                                    <div className="col-md-1 align-middle">
                                                        <UsergroupAddOutlined title="Add Team"
                                                                              className="iconButton"
                                                                              onClick={this.handleAddTeam}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="row">
                                        <div className="col-md-12 photo">
                                            {this.state.profile && this.state.profile.photo ?
                                             <img src={this.state.profile.imageType + ',' + this.state.profile.photo}
                                                  alt={this.state.profile.fullName} width={"100%"}/> : ""}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 text-right" style={{paddingRight: 0}}>
                                            <Button variant="contained"
                                                    className="editorButton" component='label'>
                                                Upload Photo
                                                <input ref={(ref) => { this.uploadInput = ref; }}
                                                       style={{display: 'none'}}
                                                       type="file" name="photo"
                                                       onChange={this.uploadPhoto}/>
                                            </Button>
                                            {/*</form>*/}
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-md-12 pr-0 mt-2"}>
                                    {profile.communications &&
                                     <CommunicationsEditor
                                             name={"communications"}
                                             onChange={this.handleInputChange}
                                             communications={profile.communications}
                                             personId={profile.id}
                                     />
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    {
                                        errors.map(error => { return <div className="profileErrors">{error}</div> })
                                    }
                                </div>
                            </div>
                        </DialogContent>
                        <div className="editorFooter">
                            <DialogActions>

                                <Button onClick={this.handleClose} variant="contained" className="editorButton">
                                    Cancel
                                </Button>
                                <Button onClick={this.handleSave} variant="contained" className="editorButton">
                                    Save
                                </Button>
                            </DialogActions>
                        </div>

                    </Dialog>
                    <AddTeam open={this.state.addingTeams} originalTeams={teams} onClose={this.handleAddTeamClose}/>
                </div>
        );
    }

    handleClose() {
        this.setState({open: false});
        this.props.editorClose(this.state.profile);
    }

    validate() {
        let valid = true;
        let profile = this.state.profile;
        let errors = [];
        if(profile.firstName === undefined || isEmpty(profile.firstName) || !isLength(profile.firstName, {
            min: 2,
            max: 50
        })) {
            valid = false;
            errors.push("First name must not be empty and length must be between 2 and 50")
        }
        if(profile.firstLastName === undefined || isEmpty(profile.firstLastName) || !isLength(profile.firstLastName, {
            min: 2,
            max: 50
        })) {
            valid = false;
            errors.push("Last name must not be empty and length must be between 2 and 50")
        }
        this.setState({errors: errors});
        return valid;
    }

    handleSave() {
        if(this.validate() === true) {
            this.saveProfile(this.state.profile)
        }
    }

    uploadPhoto(e) {
        if(e.target.files && e.target.files[0]) {
            let file = e.target.files[0];
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => this.setState((state) => ({
                filerError: e,
                profile: {
                    ...state.profile,
                    photo: reader.result.split(',')[1],
                    imageType: reader.result.split(',')[0]
                }
            }));
        }

    }


    handleValueChange(valueObject, e) {
        const name = e.target.name;
        const value = valueObject.value;
        this.setState((state) => ({
            profile: {
                ...state.profile,
                [name]: value
            }
        }));
    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;

        this.setState((state) => ({
            profile: {
                ...state.profile,
                [name]: value
            }
        }));
    }

    handleRemoveTeam(teamId) {
        if(teamId) {
            let teams = this.state.profile.teams;
            let index = teams.findIndex(existingTeam => existingTeam.id === teamId);
            teams.splice(index, 1);
            this.setState((state) => ({
                profile:
                        {
                            ...state.profile,
                            teams: teams
                        }
            }));
        }
    }

    handleAddTeam() {
        this.setState({addingTeams: true});
    }

    handleAddTeamClose(teams) {
        this.setState({addingTeams: false});
        if(teams) {
            this.setState((state) => ({
                profile: {
                    ...state.profile,
                    teams: teams
                }
            }));
        }
    }

    saveProfile(profile) {
        Rest.authFetch(this.props.user, "/rest/staff", {
            method: "PUT",
            body: JSON.stringify(profile)
        })
                .then(response => {
                    if(response && response.id > 0) {
                        toast.success("Staff member saved");
                        this.setState({
                                          profile: response
                                      }, () => this.handleClose());
                    }
                    else {
                        toast.error("Error saving Staff member");
                    }
                });
    }
}

ProfileEditor.protoTypes = {
    open: PropTypes.bool,
    profile: PropTypes.object.isRequired,
    editorClose: PropTypes.func
};

export default withUser(ProfileEditor);