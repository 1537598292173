import React, {useEffect, useState} from 'react'
import {isNull} from 'lodash'
import {Checkbox} from "@mui/material";
import {DeleteOutline, Link, LinkOff, ReorderOutlined} from "@mui/icons-material";
import moment from "moment";
import './DataSet.css'
import {DatePicker, Input, InputNumber, Select, TimePicker} from "antd";
import {Draggable} from "react-beautiful-dnd";
import {groupingOptions} from "./FilterGroup";

const dateParts = {
    "DATE": [{value: "DAY", label: "Days"}, {value: "WEEK", label: "Weeks"}, {
        value: "MONTH",
        label: "Months"
    }, {value: "YEAR", label: "Years"}],
    "TIME": [{value: "HOUR", label: "Hours"}, {value: "MINUTE", label: "Minutes"}, {value: "SECOND", label: "Seconds"}],
    "DATETIME": [{value: "DAY", label: "Days"}, {value: "WEEK", label: "Weeks"}, {
        value: "MONTH",
        label: "Months"
    }, {value: "YEAR", label: "Years"}, {value: "HOUR", label: "Hours"}, {
        value: "MINUTE",
        label: "Minutes"
    }, {value: "SECOND", label: "Seconds"}]
}
const operands = {
    "STRING": [{value: "EQUALS", label: "Equals"}, {value: "CONTAINS", label: "Contains"}, {
        value: "STARTS_WITH",
        label: "Starts with"
    }, {value: "ENDS_WITH", label: "Ends with"}],
    "TEXT": [{value: "EQUALS", label: "Equals"}, {value: "CONTAINS", label: "Contains"}, {
        value: "STARTS_WITH",
        label: "Starts with"
    }, {value: "ENDS_WITH", label: "Ends with"}],
    "INT": [{value: "EQUALS", label: "Equals"}, {value: "LESS_THAN", label: "Less than"}, {
        value: "LESS_THAN_OR_EQUAL",
        label: "Less than or equal to"
    }, {value: "GREATER_THAN", label: "Greater than"}, {
        value: "GREATER_THAN_OR_EQUAL",
        label: "Greater than or equal to"
    }, {value: "BETWEEN", label: "Between"}],
    "DECIMAL": [{value: "EQUALS", label: "Equals"}, {
        value: "LESS_THAN",
        label: "Less than"
    }, {value: "LESS_THAN_OR_EQUAL", label: "Less than or equal to"}, {
        value: "GREATER_THAN",
        label: "Greater than"
    }, {value: "GREATER_THAN_OR_EQUAL", label: "Greater than or equal to"}, {value: "BETWEEN", label: "Between"}],
    "DATE": [{value: "EQUALS", label: "Equals"}, {value: "LESS_THAN", label: "Less than"}, {
        value: "LESS_THAN_OR_EQUAL",
        label: "Less than or equal to"
    }, {value: "GREATER_THAN", label: "Greater than"}, {
        value: "GREATER_THAN_OR_EQUAL",
        label: "Greater than or equal to"
    }, {value: "BETWEEN", label: "Between"},
        {value: "LAST_N_DATE_PART", label: "Last"}],
    "TIME": [{value: "EQUALS", label: "Equals"}, {value: "LESS_THAN", label: "Less than"}, {
        value: "LESS_THAN_OR_EQUAL",
        label: "Less than or equal to"
    }, {value: "GREATER_THAN", label: "Greater than"}, {
        value: "GREATER_THAN_OR_EQUAL",
        label: "Greater than or equal to"
    }, {value: "BETWEEN", label: "Between"},
        {value: "LAST_N_DATE_PART", label: "Last"}],
    "DATETIME": [{value: "EQUALS", label: "Equals"}, {
        value: "LESS_THAN",
        label: "Less than"
    }, {value: "LESS_THAN_OR_EQUAL", label: "Less than or equal to"}, {
        value: "GREATER_THAN",
        label: "Greater than"
    }, {value: "GREATER_THAN_OR_EQUAL", label: "Greater than or equal to"}, {
        value: "BETWEEN",
        label: "Between"
    }, {value: "LAST_N_DATE_PART", label: "Last"}],
    "BOOLEAN": [{value: "IS_TRUE", label: "Is true"}, {value: "IS_FALSE", label: "Is False"}]
};

function AttributeFilter(props) {

    const [operand, setOperand] = useState();
    const [filterValue1, setFilterValue1] = useState();
    const [filterValue2, setFilterValue2] = useState();
    const [datePart, setDatePart] = useState();
    const [grouping, setGrouping] = useState("AND");
    const [filterGroup, setFilterGroup] = useState(null);
    const [selected, setSelected] = useState(false);

    useEffect(() => {
        setOperand(props.filter.operand);
        setFilterValue1(props.filter.filterValue1);
        setFilterValue2(props.filter.filterValue2);
        setGrouping(props.filter.grouping);
        setDatePart(props.filter.datePart);
        setFilterGroup(props.filterGroup);
        console.log('AttributeFilter:props.filterGroup', props.filterGroup);
        if(props.filter && filterValue1 === null) {
            const m = moment();
            switch(props.filter.attributeDataType) {
                case "DATE":
                    setFilterValue1(m.format("MM/DD/YYYY"));
                    break;
                case "DATETIME":
                    setFilterValue1(m.format("MM/DD/YYYY HH:mm"));
                    break;
                case "TIME":
                    setFilterValue1(m.format("HH:mm"));
                    break;
                case "STRING":
                case "TEXT":
                    setFilterValue1("");
                    break;
                case "INT":
                case "DECIMAL":
                    setFilterValue1(0);
                    break;
            }
        }
    }, []);

    useEffect(() => {

        const myFilter = props.filter;
        myFilter.operand = operand;
        myFilter.filterValue1 = filterValue1;
        myFilter.filterValue2 = filterValue2;
        myFilter.datePart = datePart;
        myFilter.grouping = grouping;
        // if(props.onUpdate)
        //     props.onUpdate(props.index, myFilter);
    }, [filterValue1, filterValue2, operand, datePart, grouping])

    useEffect(() => {
        if(operand === "LAST_N_DATE_PART" && isNaN(filterValue1)) {
            setFilterValue1(0);
        }
    }, [operand]);

    useEffect(()=>{
        console.log("selected->",selected);
        if(props.onSelect)
            props.onSelect('some value',selected);
    },[selected]);
    
    return (
            <Draggable key={props.index}
                       draggableId={String(props.filterGroup.id)+'_'+String(props.filter.id)}
                       index={props.index}>
                {(draggableProvided, snapshot) => (
                        <div index={props.index}
                             ref={draggableProvided.innerRef} {...draggableProvided.draggableProps}>
                            {props.index > 0 &&
                             <div className="row">
                                 <div className="col-2">
                                     <Select label="Grouping" options={groupingOptions} style={{width: "75px"}}
                                             value={grouping} onChange={(e, newValue) => setGrouping(newValue.value)}/>
                                 </div>
                             </div>}
                            <div className="row filter-item" index={props.index}>
                                <div className="col-1 align-self-center" {...draggableProvided.dragHandleProps}>
                                    <ReorderOutlined/>
                                </div>
                                <div className="col-4 attribute-name align-self-center">
                                    {props.filter && props.filter.attributeHealthElementTypeClassName + ": " + props.filter.attributeName}
                                </div>
                                <div className="col-5 align-self-center">
                                    <Select label="Operand" size="small" style={{width: "10vw"}}
                                            bordered
                                            value={operand}
                                            options={(props.filter && props.filter.attributeDataType) ? operands[props.filter.attributeDataType] : []}
                                            onChange={(e, newValue) => setOperand(newValue.value)}/>&nbsp;&nbsp;
                                    {"INT, DECIMAL".includes(props.filter.attributeDataType) && operand &&
                                     <InputNumber size="small" value={filterValue1}
                                                  onChange={(v) => setFilterValue1(v)}/>}
                                    {"INT, DECIMAL".includes(props.filter.attributeDataType) && operand === "BETWEEN" &&
                                     <>&nbsp;and&nbsp;<InputNumber size="small" value={filterValue2}
                                                                   onChange={(v) => setFilterValue2(v)}/></>}
                                    {"STRING, TEXT".includes(props.filter.attributeDataType) &&
                                     <Input size="small" value={filterValue1}
                                            onChange={(v) => setFilterValue1(v.target.value)}
                                            style={{width: "150px"}}/>}
                                    {("DATE" === props.filter.attributeDataType || "DATETIME" === props.filter.attributeDataType) && operand && operand !== "LAST_N_DATE_PART" &&
                                     <DatePicker size="small"
                                                 defaultValue={moment()}
                                                 value={filterValue1 ? moment(filterValue1, "DATETIME" === props.filter.attributeDataType ? "MM/DD/YYYY HH:mm" : "MM/DD/YYYY") : moment()}
                                                 onChange={(v) => v && setFilterValue1(v.format("DATETIME" === props.filter.attributeDataType ? "MM/DD/YYYY HH:mm" : "MM/DD/YYYY"))}
                                                 showTime={props.filter.attributeDataType === "DATETIME"}
                                                 format={"DATETIME" === props.filter.attributeDataType ? "MM/DD/YYYY hh:mm a" : "MM/DD/YYYY"}/>}
                                    {("DATE" === props.filter.attributeDataType || "DATETIME" === props.filter.attributeDataType) && operand === "BETWEEN" &&
                                     <>&nbsp;and&nbsp;<DatePicker size="small"
                                                                  defaultValue={moment()}
                                                                  value={filterValue2 ? moment(filterValue2, "DATETIME" === props.filter.attributeDataType ? "MM/DD/YYYY HH:mm" : "MM/DD/YYYY") : moment()}
                                                                  onChange={(v) => v && setFilterValue2(v.format("DATETIME" === props.filter.attributeDataType ? "MM/DD/YYYY HH:mm" : "MM/DD/YYYY"))}
                                                                  showTime={props.filter.attributeDataType === "DATETIME"}
                                                                  format={"DATETIME" === props.filter.attributeDataType ? "MM/DD/YYYY hh:mm a" : "MM/DD/YYYY"}/></>}
                                    {("DATE, TIME, DATETIME".includes(props.filter.attributeDataType) || "DATETIME" === props.filter.attributeDataType) && operand === "LAST_N_DATE_PART" &&
                                     <>
                                         <InputNumber size="small" value={isNaN(filterValue1) ? 0 : filterValue1}
                                                      onChange={(v) => setFilterValue1(v)}/>&nbsp;&nbsp;
                                         <Select label="Date Part" size="small" style={{width: "10vw"}}
                                                 className={"attribute-name"}
                                                 bordered
                                                 options={(props.filter && props.filter.attributeDataType) ? dateParts[props.filter.attributeDataType] : []}
                                                 onChange={(e, newValue) => setDatePart(newValue.value)}/>
                                     </>
                                    }
                                    {"TIME" === props.filter.attributeDataType && operand && operand !== "LAST_N_DATE_PART" &&
                                     <TimePicker size="small"
                                                 defaultValue={moment()}
                                                 value={filterValue1 ? moment(filterValue1, "HH:mm") : moment('12:00 am', 'h:mm a')}
                                                 onChange={(v) => v && setFilterValue1(v.format("HH:mm"))}
                                                 format="h:mm a"/>}
                                    {"TIME" === props.filter.attributeDataType && operand === "BETWEEN" &&
                                     <>&nbsp;and&nbsp;<TimePicker size="small"
                                                                  defaultValue={moment()}
                                                                  value={filterValue2 ? moment(filterValue2, "HH:mm") : moment('12:00 am', 'h:mm a')}
                                                                  onChange={(v) => v && setFilterValue2(v.format("HH:mm"))}
                                                                  format="h:mm a"/></>}
                                </div>
                                <div className="col-2 align-self-center">
                                    <div className="row">
                                        <div onClick={() => props.onDelete(props.index)} className="col-3 align-self-center">
                                            <DeleteOutline className="edit"/>
                                        </div>
                                        <div className="col-4 align-self-center">
                                            <Checkbox
                                                    size="small"
                                                    checked={selected}
                                                    onClick={() => setSelected(!selected)}/>
                                        </div>
                                        {props.linked &&
                                         <div className="col-3 align-self-center">
                                             <LinkOff onClick={() => unLinkHandler(filter.id)} style={{cursor:"pointer"}}/>
                                         </div>
                                        }
                                        {!props.linked && selected &&
                                         <div className="col-3 align-self-center">
                                             <Link onClick={() => linkHandler(filter.id)} style={{cursor:"pointer"}}/>
                                         </div>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>)}
            </Draggable>
    );
}

export default AttributeFilter;