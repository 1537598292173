import React, {useEffect, useState} from 'react'
import {withUser} from "../../../../../context/UserContext";
import '../PatientsViews.css'
import {DatePicker} from "antd";
import moment from "moment";
import {CaretLeftOutlined, CaretRightOutlined} from "@ant-design/icons";

function PatientSearch(props) {

    const [option, setOption] = useState("today");
    const [selectDate, setSelectDate] = useState(false);
    const [date, setDate] = useState(moment());

    useEffect(() => {
        option === "byDate" ? setSelectDate(true) : setSelectDate(false);
    }, [option]);

    useEffect(() => {
        if(props.onDateChange)
            props.onDateChange(date);
    }, [date]);
    
    

    return (
            <>
                <div className={"patient-search"}>
                    <div>
                        <span className={"edit"} onClick={()=>setDate(moment(date).subtract(1,'days'))}><CaretLeftOutlined/></span>
                        <span className={"date-picker"}><DatePicker value={date} format="MM/DD/YYYY" onChange={(e)=>setDate(moment(e))}/></span>
                        <span className={"edit"} onClick={()=>setDate(moment(date).add(1,'days'))}><CaretRightOutlined/></span>
                    </div>
                    <div>

                    </div>
                </div>
            </>
    )
}

export default withUser(PatientSearch)