import React, {useEffect, useState} from 'react';

import '../PatientsViews.css'
import moment from "moment";

function PatientInfo(props) {

    const [appointment,setAppointment]=useState(props.appointment)

    useEffect(() => {
        setAppointment(props.appointment)
    }, [props]);
    
    return (
            <>
                <div className="container-fluid patient-info">
                    {
                        appointment.patient ?
                        <div className="row">
                            <div className="col-10">
                                <div className="pcmh-today-name row">{moment(appointment.beginTime,["hh:mm:ss"]).format("h:mm a")}</div>
                                <div className="pcmh-today-name row">{appointment.patient.firstName+
                                                                      (appointment.patient.middleName.length>0?" "+appointment.patient.middleName:"")+
                                " "+appointment.patient.lastName}</div>
                                <div className="pcmh-today-identifiers row">DOB: {moment(appointment.patient.dob,["YYYY-MM-DD"]).format("MM/DD/YYYY")} | 
                                    Age: {moment(appointment.date).diff(moment(appointment.patient.dob.substring(0,10)),'years')} |
                                    Gender: {appointment.patient.gender}</div>
                                <div className="pcmh-today-identifiers row">MRN: {appointment.patient.clientPatientId}</div>
                            </div>
                            <div className="col-2 my-auto">
                                {/*<div className="risk-score" style={{background: backColor}}>*/}
                                {/*    {score.toLocaleString(undefined, {minimumFractionDigits: 1,maximumFractionDigits:1})}*/}
                                {/*</div>*/}
                            </div>
                        </div>
                                :
                        <div className="available">
                            <div>Available</div>
                        </div>
                    }
                </div>
            </>
    )

}

export default PatientInfo;