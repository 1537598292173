import React, {Component} from 'react';
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import 'react-tabs/style/react-tabs.css';
import './AgendaNotes.css'
import AgendaNoteEntry from "./AgendaNoteEntry";
import AgendaNoteItem from "./AgendaNoteItem";
import {withUser} from "../../../../context/UserContext";
import {Rest} from "../../../../util/rest";
import toast from "../../../../util/toast";
import AgendaIssueEntry from "./AgendaIssueEntry";
import IssueItem from "./IssueItem";
import Checkbox from "@mui/material/Checkbox";

class AgendaNotesIssues extends Component {
    constructor(props) {
        super(props);
        this.state = {
            agenda: props.agenda,
            issues: props.issues,
            team: props.team,
            showHistory: false
        };
        this.noteAdded = this.noteAdded.bind(this);
        this.issueAdded = this.issueAdded.bind(this);
        this.handleChangeHistory = this.handleChangeHistory.bind(this);
        this.issueStatusChanged = this.issueStatusChanged.bind(this);
        this.deleteNote = this.deleteNote.bind(this);
        this.deleteIssue = this.deleteIssue.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {

        if(prevProps.agenda !== this.props.agenda)
            this.setState({agenda: this.props.agenda});
        if(prevProps.issues !== this.props.issues)
            this.setState({issues: this.props.issues});
        if(prevProps.team !== this.props.team)
            this.setState({team: this.props.team});
    }


    render() {
        const agenda = this.state.agenda;
        const notes = agenda && agenda.notes ? agenda.notes.sort((i1, i2) => i2.dateEntered.getTime() - i1.dateEntered.getTime()) : [];
        const issues = this.state.issues ? this.state.issues.sort((i1, i2) => i2.createdDate.getTime() - i1.createdDate.getTime()) : [];
        const pendingIssues = issues.filter(issue => !issue.closed);
        const closedIssues = issues.filter(issue => issue.closed);
        const showHistory = this.state.showHistory;
        return (
                <div>
                    <Tabs>
                        <TabList className="agendaTabList">
                            <Tab selected className="unSelectedTab unSelectable"
                                 selectedClassName="selectedTab">Notes</Tab>
                            <Tab className="unSelectedTab unSelectable" selectedClassName="selectedTab">Issues</Tab>
                        </TabList>
                        <TabPanel className="notesTabPanel" selectedClassName="selectedPanel">
                            <div className="box"><AgendaNoteEntry onNew={this.noteAdded}/></div>
                            <div className="notes">
                                {
                                    notes.map((note, index) => <AgendaNoteItem key={index} note={note}
                                                                               onDelete={this.deleteNote}/>)
                                }
                            </div>
                        </TabPanel>
                        <TabPanel className="notesTabPanel" selectedClassName="selectedPanel">
                            <div><Checkbox onChange={this.handleChangeHistory}/>Show History</div>
                            <div className="box"><AgendaIssueEntry onNew={this.issueAdded}/></div>
                            <div className="pending-box">
                                <div className="notes">
                                    Pending Issues ({pendingIssues.length})
                                    {
                                        pendingIssues.map((issue, index) => <IssueItem key={index} issue={issue}
                                                                                       onChange={this.issueStatusChanged}
                                                                                       onDelete={this.deleteIssue}/>)
                                    }
                                </div>
                            </div>
                            {showHistory ?
                             <div className="history-box">
                                 <div className="notes">
                                     Resolved Issues
                                     {
                                         closedIssues.map((issue, index) => <IssueItem key={index} issue={issue}
                                                                                       onChange={this.issueStatusChanged}/>)
                                     }
                                 </div>
                             </div> : null}
                        </TabPanel>
                    </Tabs>

                </div>
        );


    }


    noteAdded(note) {
        note = {
            ...note,
            agendaId: this.props.agenda.id,
            authorId: this.props.staffMember.id
        };

        let notes = this.state.agenda.notes;
        if(!notes)
            notes = [];
        notes.splice(0, 0, note);
        this.setState((state) => ({
            agenda: {
                ...state.agenda,
                notes: notes
            }
        }));
        this.saveNote(note)
    }

    issueStatusChanged(issue) {
        if(issue.closed) {
            issue.closedDate = null;
        }
        else {
            issue.closedDate = new Date();
        }
        issue.closed = !issue.closed;
        this.saveIssue(issue);
    }

    issueAdded(issue) {
        issue.team = this.state.team;
        issue.createdDate = new Date();
        issue.closed = false;
        issue.closedDate = null;
        this.saveIssue(issue);

    }

    handleChangeHistory() {
        this.setState(state => ({showHistory: !state.showHistory}))
    }

    saveIssue(issue) {
        Rest.authFetch(this.props.user, "/rest/huddle/updateIssue", {
            method: "PUT",
            body: JSON.stringify(issue)
        })
                .then(() => {
                    if(this.props.issuesUpdated)
                        this.props.issuesUpdated();
                });

    }

    saveNote(note) {
        Rest.authFetch(this.props.user, "/rest/agenda/addNote", {
            method: "PUT",
            body: JSON.stringify(note)
        })
                .then(response => {
                    if(response && response.id > 0) {
                        let savedNote = response;
                        if(!(savedNote.dateEntered instanceof Date))
                            savedNote.dateEntered = new Date(savedNote.dateEntered);
                        let notes = this.state.agenda.notes;
                        let index = notes.findIndex((existingItem => existingItem.id === note.id));
                        if(index >= 0) {
                            notes[index] = savedNote;
                        }
                        else {
                            index = notes.findIndex((existingItem => existingItem.id === 0));
                            if(index >= 0)
                                notes[index] = savedNote;
                            else
                                notes.push(savedNote);
                        }
                        this.setState((state) => ({
                            agenda: {
                                ...state.agenda,
                                notes: notes
                            }
                        }));
                        if(this.props.notesUpdated)
                            this.props.notesUpdated(this.props.agenda.meetingDate);
                    }
                    else {
                        toast.error("Error updating Notes");
                    }
                });

    }

    deleteNote(note) {
        if(note && note.id > 0) {
            Rest.authFetch(this.props.user, "/rest/agenda/note/" + note.id, {
                method: "DELETE"
            })
                    .then(() => {
                        const notes = this.state.agenda && this.state.agenda.notes ? this.state.agenda.notes : [];
                        if(notes && notes.length > 0) {
                            const index = notes.findIndex((foundNote) => foundNote.id === note.id)
                            if(index > -1) {
                                notes.splice(index, 1);
                                this.setState((state) => (
                                        {
                                            agenda: {
                                                ...state.agenda,
                                                notes: notes
                                            }
                                        }));
                            }
                        }

                    });
        }
    }

    deleteIssue(issue) {
        if(issue && issue.id > 0) {
            Rest.authFetch(this.props.user, "/rest/agenda/issue/" + issue.id, {
                method: "DELETE"
            })
                    .then(() => {
                        const issues = this.state.agenda && this.state.agenda.issues ? this.state.agenda.issues : [];
                        if(issues && issues.length > 0) {
                            const index = issues.findIndex((foundIssue) => foundIssue.id === issue.id)
                            if(index > -1) {
                                issues.splice(index, 1);
                                this.setState({issues: issues})
                            }
                        }

                    })
                    .then(() => {
                        if(this.props.issuesUpdated)
                            this.props.issuesUpdated();
                    });
        }
    }
}

export default withUser(AgendaNotesIssues);