import React, {Component} from 'react';
import AssessmentQuestion from "./AssessmentQuestion";
import {Rest} from "../../../util/rest";
import {withUser} from "../../../context/UserContext";
import "./Assessment.css";
import toast from "../../../util/toast";
import AssessmentHeader from "./AssessmentHeader";
import {CaretDownOutlined, CaretUpOutlined} from "@ant-design/icons";

class Assessment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            assessment: null,
            groups: [],
            isLoading: true,
            formErrors: [],
            readOnly: false,
            validations: new Map(),
            validAssessment: true,
            answers: new Map()
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.save = this.save.bind(this);
        this.handleAnswer = this.handleAnswer.bind(this);
    }

    componentDidMount() {
        this.getAssessment()
    }

    render() {
        const groups = this.state.assessment ? this.state.assessment.template.groups : [];
        return (
                <div className="assessment">
                    {this.state.formErrors.length > 0 &&
                     <div className="form-errors alert alert-danger">
                         <ul>
                             {this.state.formErrors.map((error) => <li>{error}</li>)}
                         </ul>
                     </div>
                    }

                    {this.state.isLoading &&
                     <div className="text-center">
                         <div className="btn btn-primary-color btn-lg rounded shadow-sm">
                             <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"/>
                             Loading...
                         </div>
                     </div>
                    }
                    {!this.state.isLoading && this.state.assessment &&
                     <>
                         <AssessmentHeader assessment={this.state.assessment}
                                           handleSubmit={this.handleSubmit}
                                           readOnly={this.state.readOnly}
                                           validationErrors={!this.state.validAssessment}/>
                         <div className={"assessment-contents"}>
                             <div className={"groups"}>
                                 {groups.map((group, index) => {
                                     return (
                                             <div className="group" key={index}>
                                                 <div className="row group-control">
                                                     <div className="col-md-3">{group.name}</div>
                                                     <div className="col-md-8 instructions">{group.instructions && group.instructions.trim().length > 0 ? group.instructions : this.state.assessment.template.instructions}</div>
                                                     <div className="col-md-1 icon text-right">
                                                         {group.open ?
                                                          <CaretDownOutlined onClick={() => this.toggleGroup(index)}/> :
                                                          <CaretUpOutlined onClick={() => this.toggleGroup(index)}/>
                                                         }
                                                     </div>
                                                 </div>
                                                 {group.open ?
                                                  <div className={"questions"}>
                                                      {group.questions.map((question, index) => (
                                                              this.shouldShow(question) ?
                                                              <AssessmentQuestion key={index}
                                                                                  index={index}
                                                                                  question={question}
                                                                                  assessmentId={this.state.assessment.id}
                                                                                  readOnly={this.state.readOnly}
                                                                                  submitted={this.state.submitted}
                                                                                  onAnswer={this.handleAnswer}
                                                                                  valid={!this.state.submitted || (this.state.submitted && this.state.valid.get(question.id))}/> :
                                                              null
                                                      ))}
                                                  </div> : null}
                                             </div>)
                                 })}
                             </div>
                         </div>
                     </>
                    }
                </div>
        )
    }

    shouldShow(question) {
        if(this.state.assessment === null || question.dependencies.length === 0)
            return true;
        let show = true;
        const dependencies = question.dependencies.sort((a, b) => (a.order > b.order) ? 1 : -1)
        dependencies.forEach((dependency) => {

            //TODO: Implement inter dependencies (AND, OR, etc.)
            const dependentQuestionId = dependency.assessmentTemplateDependentQuestionId;
            const answers = this.state.answers.get(dependentQuestionId)
            switch(dependency.operator) {
                case "EQUALS": {
                    const dependentQuestion = this.findQuestion(dependentQuestionId)
                    switch(dependentQuestion.questionType) {
                        case "FREE_TEXT":
                            if(answers === undefined || answers.length === 0 || !answers[0].answerText || answers[0].answerText.length === 0)
                                show = false;
                            break;
                        case "SELECTION":
                            show = false;
                            if(answers !== undefined && answers.length > 0) {
                                answers.forEach((answer) => {
                                    if(answer.assessmentTemplateAnswerId === dependency.assessmentTemplateAnswerId)
                                        show = true;
                                });
                            }
                            break;
                        case "MULTIPLE_SELECTION":
                            //TODO: Implement
                            break;
                        case "RANGE":
                        case "NUMERIC_VALUE":
                            if(answers === undefined || answers.length === 0 ||
                               !answers[0].answerNumeric || answers[0].answerNumeric !== dependency.answerNumeric)
                                show = false;
                            break;
                        case "DATE":
                            //TODO: Implement
                            break;
                        default:
                    }
                    break;
                }
                    //TODO: Implement the rest
                case "GREATER":
                    break;
                case "LESS":
                    break;
                case "GREATER_OR_EQUAL":
                    break;
                case "LESS_OR_EQUAL":
                    break;
                case "NOT":
                    break;
                case "CONTAINS":
                    break;
                default:
            }
        });
        return show
    }

    findQuestion(id) {
        const questions = [];
        const groups = this.state.assessment.template.groups;
        groups.map((group) => questions.push(...group.questions))
        let question = questions.find((question) => question.id === id);
        if(question === undefined)
            question = null;
        return question;
    }

    toggleGroup(index) {
        let assessment = this.state.assessment;
        if(assessment.id > 0) {
            let template = assessment.template;
            let groups = template.groups;
            let group = groups[index];
            group.open = !group.open;
            groups[index] = group;
            template.groups = groups;
            assessment.template = template;
            this.setState({assessment: assessment});
        }

    }

    handleAnswer(question, answers) {
        const valid = this.state.validations;
        valid.set(question.id, (!question.required || (question.required && (answers && answers.length > 0))));
        const ans = this.state.answers;
        ans.set(question.id, answers)
        this.setState({valid: valid, answers: ans})
    }

    isValidForm() {
        let valid = true;
        for(const [, value] of this.state.valid.entries()) {
            if(!value) {
                valid = false;
                break;
            }
        }
        this.setState({validAssessment: valid})
        return valid;
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({submitted: true})
        if(this.isValidForm()) {
            const assessment = this.state.assessment;
            assessment.dateEnded = new Date();
            this.setState({
                              assessment: assessment
                          })
            this.save(assessment)
            if(this.props.history)
                this.props.history.goBack()
        }
    }

    save(assessment) {
        this.setState({
                          isLoading: true
                      })

        Rest.authFetch(this.props.user, "/rest/assessment", {
            method: "PUT",
            body: JSON.stringify(assessment)
        })
                .then(response => {
                    if(response) {
                        this.handleResponse(response);
                        if(response && response.dateEnded !== null) { toast.success("Assessment submitted");}
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.setState({
                                      isLoading: false
                                  })
                });
    }

    getAssessment() {
        const assessmentId = this.props.match.params.id
        Rest.authFetch(this.props.user, "/rest/assessment/" + assessmentId)
                .then(response => {
                    this.handleResponse(response);
                })
                .catch(err => {
                    console.log(err);
                    this.setState({
                                      isLoading: false
                                  })
                })
    }

    handleResponse(response) {
        if(response.status === 401 || response.status === 403) {
            toast.error("Unauthorized!")
        }
        else {
            const groups = response.template.groups
                    .map((group) => {
                        group.open = true;
                        return null;
                    });

            if(response.dateStarted === null) {
                response.dateStarted = new Date();
                this.save(response)
            }
            this.setState({
                              assessment: response,
                              groups: groups,
                              isLoading: false,
                              readOnly: response.dateEnded !== null
                          });
        }
    }

}

export default withUser(Assessment);