import React, {useEffect} from 'react'
import {DeleteOutline, ReorderOutlined} from "@mui/icons-material";
import {Draggable} from "react-beautiful-dnd";
import Checkbox from '@mui/material/Checkbox';
import {FormControlLabel} from "@mui/material";


function AttributeDisplay(props) {

    return (

            <Draggable key={props.index + 1}
                       draggableId={String(props.index)}
                       index={props.index}>
                {(draggableProvided, snapshot) => (
                        <div className="row attribute no-gutters" index={props.index}
                             ref={draggableProvided.innerRef} {...draggableProvided.draggableProps}>
                            <div className="col-1 align-self-center" {...draggableProvided.dragHandleProps}>
                                <ReorderOutlined/>
                            </div>
                            <div className="col-7 attribute-name align-self-center">
                                {props.attribute && props.attribute.attributeHealthElementTypeClassName+": "+props.attribute.attributeName}
                            </div>
                            <div className="col-3 align-self-center">
                                <FormControlLabel
                                        label="Mouse Over"
                                        control={
                                            <Checkbox
                                                    classes={{root: 'Checkbox-alt', checked: "checked"}}
                                                    checked={props.attribute && props.attribute.display === "MOUSEOVER"}
                                                    onClick={() => props.onUpdate(props.index, props.attribute && props.attribute.display === "MOUSEOVER" ? "IN_COLUMN" : "MOUSEOVER")}
                                                    size="small"/>}/>

                            </div>
                            <div className="col-1 align-self-center" style={{textAlign: "end"}}
                                 onClick={() => props.onDelete(props.index)}>
                                <DeleteOutline className="edit"/>
                            </div>
                        </div>
                )}
            </Draggable>


    );

}

export default AttributeDisplay;