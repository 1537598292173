import React from 'react';
import PropTypes from 'prop-types';
import {isEqual} from 'lodash';

import {Button, CircularProgress, TextField} from '@mui/material';

import {ResetPasswordStyle} from '../ResetPasswordStyle';


const AuthPasswordReset = (props) => {
    const {
        activeView,
        disablePasswordSubmitBtn,
        otpField,
        OTPLoading,
        passwordConfirmationHandler,
        passwordValidation,
        passwordValidationHandler,
        resendOTP,
        resetPasswordHandler,
        submitOTPHandler,
        submitPasswordLoading,
        userInfoChangeHandler
    } = props;

    return (
        <ResetPasswordStyle>
            {isEqual(activeView, OTP_FORM) && (
                <div className="otp-form">
                    <div className="divider" />
                    <div className="otp-form-header">
                        <h4>Reset Password</h4>
                    </div>
                    <div className="otp-form-inner">
                        <TextField
                            fullWidth
                            margin="dense"
                            label="OTP"
                            variant="outlined"
                            className="textField"
                            type="number"
                            onChange={(event) => userInfoChangeHandler(event, 'otp')}
                        />
                    </div>
                    <div className="divider" />
                    <div className="action-buttons">
                        <Button className="resend-otp-btn" variant="outlined" onClick={resendOTP}>
                            {OTPLoading
                                ? <CircularProgress color="secondary" className="loadingSpinner" size={24} />
                                : 'Resend OTP'}
                        </Button>
                        <Button
                            variant="outlined"
                            disabled={isEqual(otpField, 0)}
                            className={isEqual(otpField, 0) ? '' : 'submit-btn'}
                            onClick={submitOTPHandler}>
                            Validate
                        </Button>
                    </div>
                </div>
            )}

            {isEqual(activeView, PASSWORD_CONFIRMATION) && (
                <div className="otp-form">
                    <div className="divider" />
                    <div className="form-header">
                        <h4>New Password</h4>
                    </div>

                    <div className="otp-form-inner">

                        <div>
                            <div>Password must contain the following:</div>
                            <div className={passwordValidation.letter}>A <b>lowercase</b> letter</div>
                            <div className={passwordValidation.capital}>A <b>capital (uppercase)</b> letter</div>
                            <div className={passwordValidation.number}>A <b>number</b></div>
                            <div className={passwordValidation.length}>Minimum <b>6 characters</b></div>
                            <div className={passwordValidation.confirmPassword}><b>Confirm</b> Password</div>
                        </div>

                        <TextField
                            fullWidth
                            className="textField"
                            margin="dense"
                            label="Password"
                            type="password"
                            variant="outlined"
                            onChange={passwordValidationHandler}
                        />
                        <TextField
                            fullWidth
                            className="textField"
                            margin="dense"
                            label="Confirm Password"
                            type="password"
                            variant="outlined"
                            onChange={passwordConfirmationHandler}
                        />
                    </div>
                    <div className="divider" />
                    <div className="action-buttons">
                        <Button className="resend-otp-btn" variant="outlined" onClick={resendOTP}>
                            {OTPLoading
                                ? <CircularProgress color="secondary" className="loadingSpinner" size={24} />
                                : 'Resend OTP'}
                        </Button>
                        <Button
                            className={disablePasswordSubmitBtn ? 'disable-submit-btn' : "submit-btn"}
                            variant="outlined"
                            onClick={resetPasswordHandler}
                            disabled={disablePasswordSubmitBtn}
                        >{submitPasswordLoading
                            ? <CircularProgress color="secondary" className="loadingSpinner" size={24} />
                            : 'Submit'}
                        </Button>
                    </div>
                </div>
            )}
        </ResetPasswordStyle>
    )
};

const OTP_FORM = 'OTP_FORM';
const PASSWORD_CONFIRMATION = 'PASSWORD_CONFIRMATION';

AuthPasswordReset.propTypes = {
    OTPLoading: PropTypes.bool,
    activeView: PropTypes.string,
    disablePasswordSubmitBtn: PropTypes.bool,
    otpField: PropTypes.number,
    passwordConfirmationHandler: PropTypes.func,
    passwordValidation: PropTypes.shape({
        letter: PropTypes.string,
        capital: PropTypes.string,
        number: PropTypes.string,
        length: PropTypes.string,
        password: PropTypes.string,
        confirmPassword: PropTypes.string,
    }),
    passwordValidationHandler: PropTypes.func,
    resendOTP: PropTypes.func,
    resetPasswordHandler: PropTypes.func,
    submitOTPHandler: PropTypes.func,
    submitPasswordLoading: PropTypes.bool,
    userInfoChangeHandler: PropTypes.func,
};

export default AuthPasswordReset;
