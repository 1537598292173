import React, {Component} from 'react';
import AgendaItem from "./AgendaItem";
import './TemplateEditor.css'
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {CloseOutlined, EditOutlined, FileAddOutlined} from "@ant-design/icons";
import toast from "../../../../util/toast";
import {Rest} from "../../../../util/rest";
import {withUser} from "../../../../context/UserContext";

class TemplateEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            template: props.template,

        };
        this.newItem = this.newItem.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.itemChanged = this.itemChanged.bind(this);
        this.editItem = this.editItem.bind(this);
        this.handleEnterKey = this.handleEnterKey.bind(this);
        this.exitEditMode = this.exitEditMode.bind(this);
    }

    render() {
        const template = this.state.template;
        const items = this.state.template.items.sort((i1, i2) => i1.itemOrder - i2.itemOrder);
        const editMode = this.state.editMode;
        return (<div>
                    <div className="pcmh-content-header container-fluid">
                        <div className="row">
                            {editMode ? <div className="col-md-9 name my-auto">
                                          <input type="text"
                                                 autoFocus={true}
                                                 defaultValue={template.name}
                                                 className="editing"
                                                 onKeyUp={this.handleEnterKey}
                                                 onBlur={this.exitEditMode}
                                                 ref={this.input}
                                                 onFocus={(e) => e.target.select()}
                                          />
                                      </div>
                                      :
                             <div className="col-md-9 name my-auto" onClick={this.editItem}>{template.name}</div>}
                            <div className="col-md-1 icon my-auto" onClick={this.editItem}>
                                <EditOutlined title="Edit Name"/>
                            </div>
                            <div className="col-md-1 icon my-auto" onClick={this.newItem}>
                                <FileAddOutlined title="Add Item"/>
                            </div>
                            <div className="col-md-1 icon my-auto" onClick={this.cancel}>
                                <CloseOutlined title="Cancel"/>
                            </div>
                        </div>
                    </div>

                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="1">
                            {(provided) => <div ref={provided.innerRef}>
                                {items.map((item, index) => (
                                       <Draggable key={"drag" + item.id} draggableId={item.id.toString()} index={index}>
                                           {(provided) =>
                                                   <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                       <AgendaItem key={item.id} item={item}
                                                                   onRemove={this.removeItem}
                                                                   onChange={this.itemChanged}/>
                                                   </div>}
                                       </Draggable>
                                   )
                                )}
                                {provided.placeholder}
                            </div>}
                        </Droppable>
                    </DragDropContext>

                </div>
        );
    }

    onDragEnd(result) {
        if(!result.destination) {
            return;
        }

        const items = TemplateEditor.reorder(
                this.state.template.items,
                result.source.index,
                result.destination.index
        );

        this.setState((state) => ({
            template: {
                ...state.template,
                items: items
            },
        }), () => {
            this.save();
        });
    }


    newItem() {
        let items = [...this.state.template.items];
        items.push({
                       id: 0,
                       name: "New Item",
                       itemOrder: items.length + 1,
                       new: true
                   });
        this.setState((state) => ({
            template: {
                ...state.template,
                items: items
            },
        }));
    }

    editItem() {
        this.setState({editMode: true})
    }

    handleEnterKey(e) {
        if(e.keyCode === 13 || e.charCode === 13) {

            this.exitEditMode(e);
        }
        else if(e.keyCode === 27 || e.charCode === 27) {
            this.setState({editMode: false});
            if(this.props.newMode === true)
                this.cancel();
        }
        else {
            const name = e.target.value;
            this.setState((state) => ({
                template: {
                    ...state.template,
                    name: name
                },
            }));
        }
    }

    exitEditMode(event) {
        if(this.props.template.name !== event.target.value && event.target.value.length > 0) {
            this.save();
            this.setState({
                              editMode: false
                          });
        }
        else if(this.props.newMode === true && this.props.template.name === event.target.value) {
            this.cancel();
        }
    }

    itemChanged(item) {
        if(item) {
            let items = [...this.state.template.items]; // make a separate copy of the array
            let index = items.findIndex(x => x.itemOrder === item.itemOrder);
            items[index] = item;
            this.setState((state) => ({
                template: {
                    ...state.template,
                    items: items
                },
            }), () => {
                this.save();
            });
        }
    }

    removeItem(item) {
        let items = [...this.state.template.items]; // make a separate copy of the array
        let index = items.indexOf(item);
        items.splice(index, 1);
        this.setState((state) => ({
            template: {
                ...state.template,
                items: items
            },
        }), () => {
            this.save();
        });
    }

    save() {
        Rest.authFetch(this.props.user, "/rest/agenda/addTemplate", {
            method: "PUT",
            body: JSON.stringify(this.state.template)
        })
                .then(response => {
                    if(response && response.id > 0) {
                        // toast.success("Template saved");
                        this.setState({template: response});
                    }
                    else {
                        toast.error("Error saving Template");
                    }
                });
    }

    cancel() {
        if(this.props.onEditorClose)
            this.props.onEditorClose();
    }

    static reorder(list, startIndex, endIndex) {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        result.map((item, index) => item.itemOrder = index + 1);
        return result;
    };
}

export default withUser(TemplateEditor);