import React, {Component} from 'react';
import {withUser} from "../../../../context/UserContext";
import './TeamItem.css';
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {Rest} from "../../../../util/rest";
import MultipleAutocompleteDialog from "../MultipleAutocompleteDialog";
import CommunicationsEditor from "../staff/CommunicationsEditor";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import toast from "../../../../util/toast";

class TeamItem extends Component {
    constructor(props) {
        super(props);

        this.handleRemoveMember = this.handleRemoveMember.bind(this);
        this.enterEditMode = this.enterEditMode.bind(this);
        this.exitEditMode = this.exitEditMode.bind(this);
        this.handleEnterKey = this.handleEnterKey.bind(this);
        this.delete = this.delete.bind(this);
        this.saveMember = this.saveMember.bind(this);
        this.getMembers = this.getMembers.bind(this);
        this.handleOptionSelected = this.handleOptionSelected.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.state = {
            team: props.team,
            onEditMode: props.onEditMode,
            previousName: props.team.name,
            addingMembers: false,
            members: []
        }
    }

    componentDidMount() {
        this.setState({onEditMode: this.props.onEditMode});
    }

    /* no-unused-expressions: 0 */
    render() {
        return (
                <div>
                    <div className="teamItem container-fluid">
                        <div className="row">
                            <div className="col-md-12 col-xl-4 my-auto">
                                <div className="name">{
                                    this.state.onEditMode ? (
                                          <input type="text"
                                                 autoFocus
                                                 defaultValue={this.state.team.name}
                                                 className="editing"
                                                 onKeyUp={this.handleEnterKey}
                                                 onBlur={this.exitEditMode}
                                                 ref={this.input}
                                                 onFocus={(e) => this.state.team.new ? e.target.select() : null}/>)
                                          :
                                    <div onClick={this.enterEditMode}>{this.state.team.name}</div>
                                }</div>
                            </div>
                            <div className="col-md-8 col-lg-10 col-xl-6 my-auto">
                                <MultipleAutocompleteDialog
                                    value={this.state.team.members}
                                    onDialogSubmit={(member) => this.saveMember(member)}
                                    onOptionSelected={this.handleOptionSelected}
                                    onInputChange={this.handleInputChange}
                                    onChipDelete={this.handleRemoveMember}
                                    options={this.state.members}
                                    optionName={"Staff Member"}
                                    optionDisplayProp={"fullName"}
                                    dialogContent={(dialogValue, getProps, uploadPhoto) =>
                                        <React.Fragment>
                                            <div className="row">
                                                <div className="col-md-7">
                                                    <div className={"row"}>
                                                        <div className="col-md-4">
                                                            <TextField
                                                                autoFocus
                                                                margin="dense"
                                                                name="title"
                                                                label="Title"
                                                                variant="outlined"
                                                                className="textField"
                                                                fullWidth
                                                                value={dialogValue.title}
                                                                {...getProps}
                                                            />
                                                        </div>
                                                        <div className="col-md-8">
                                                            <TextField
                                                                autoFocus
                                                                margin="dense"
                                                                name="firstName"
                                                                label="Name"
                                                                required
                                                                variant="outlined"
                                                                className="textField"
                                                                fullWidth
                                                                value={dialogValue.firstName}
                                                                {...getProps}
                                                            />
                                                        </div>
                                                        <div className="col-md-8">
                                                            <TextField
                                                                autoFocus
                                                                margin="dense"
                                                                name="firstLastName"
                                                                label="Last Name"
                                                                required
                                                                variant="outlined"
                                                                className="textField"
                                                                fullWidth
                                                                value={dialogValue.firstLastName}
                                                                {...getProps}
                                                            />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <TextField
                                                                autoFocus
                                                                margin="dense"
                                                                name="suffix"
                                                                label="Suffix"
                                                                variant="outlined"
                                                                className="textField"
                                                                fullWidth
                                                                value={dialogValue.suffix}
                                                                {...getProps}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-5">
                                                    <div className="row">
                                                        <div className="col-md-12 photo">
                                                            <img
                                                                src={dialogValue.imageType + ',' + dialogValue.photo}
                                                                alt={dialogValue.fullName}
                                                                width={"100%"}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12 text-right" style={{paddingRight: 0}}>
                                                            <Button variant="contained" className="editorButton" component='label'>
                                                                Upload Photo
                                                                <input
                                                                    ref={(ref) => { this.uploadInput = ref; }}
                                                                    style={{display: 'none'}}
                                                                    type="file" name="photo"
                                                                    onChange={uploadPhoto}
                                                                />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"col-md-12 pr-0 mt-2"}>
                                                    <CommunicationsEditor
                                                        name={"communications"}
                                                        {...getProps}
                                                        communications={dialogValue.communications}
                                                        personId={dialogValue.id}
                                                    />
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                />
                            </div>
                            <div className="col-md-2 col-lg-1 edit my-auto" onClick={this.enterEditMode}>
                                <EditOutlined title="Edit"/>
                            </div>
                            <div className="col-md-2 col-lg-1 edit my-auto" onClick={this.delete}>
                                <DeleteOutlined title="Delete"/>
                            </div>
                        </div>
                    </div>
                </div>
        );
    }

    handleOptionSelected(members) {
        this.setState(state => ({
            team: {
                ...state.team,
                members: members
            }
        }), () => this.saveTeam(this.state.team))
    }

    handleInputChange(event, searchTerm) {
        this.getMembers(searchTerm)
    }

    handleRemoveMember(member) {
        let members = [...this.props.team.members]; // make a separate copy of the array
        let index = members.findIndex(existingMember => existingMember.id === member.id);
        members.splice(index, 1);
        let team = this.state.team;
        team.members = members;
        this.setState({team: team});
        this.saveTeam(team);

    }

    handleEnterKey(e) {
        if(e.keyCode === 13 || e.charCode === 13) {
            this.exitEditMode(e);
        }
        else if(e.keyCode === 27 || e.charCode === 27) {
            this.setState({onEditMode: false});
            if(this.props.onEndEdit) {
                this.props.onEndEdit(this.state.team)
            }
        }
    }

    exitEditMode(e) {
        this.setState({onEditMode: false});
        if(e.target.value) {
            if(e.target.value !== this.state.previousName) {
                let team = this.state.team;
                team.name = e.target.value;
                team.new = false;
                this.saveTeam(team);
                this.setState({
                                  team: team,
                                  previousName: e.target.value
                              })
            }
        }
    }

    enterEditMode() {
        this.setState({onEditMode: true})
    }

    delete() {
        if(this.state.team.id > 0) {
            Rest.authFetch(this.props.user, "/rest/team/deleteTeam/" + this.state.team.id, {
                method: "DELETE"
            })
                    .then(() => {
                        if(this.props.onDelete)
                            this.props.onDelete();
                    });
        }
        if(this.props.onEndEdit)
            this.props.onEndEdit();
    }

    saveTeam(team) {
        Rest.authFetch(this.props.user, "/rest/team/addTeam", {
            method: "PUT",
            body: JSON.stringify(team)
        }).then(response => {
            this.setState({team: response});
            if(this.props.onEndEdit) {
                this.props.onEndEdit(response);
            }
        });
    }

    getMembers(searchTerm) {
        if (searchTerm.trim() === "") {
            this.setState({
                members: []
            });
        } else {
            Rest.authFetch(this.props.user, `/rest/staff/searchStaff/${searchTerm}?teamId=${this.state.team.id}`).then(response => {
                if(response.status === 401 || response.status === 403) {
                    toast.error("Unauthorized!")
                }
                else {
                    this.setState({
                        members: response.content
                    });
                }
            })
            .catch(err => {
                console.log(err);
            })
        }
    }

    saveMember(member) {
        Rest.authFetch(this.props.user, `/rest/staff`, {
            method: "PUT",
            body: JSON.stringify(member)
        })
        .then(response => {
            if(response) {
                this.setState((state) => ({
                    team: {
                        ...state.team,
                        members: member.id < 1 ? [...state.team.members, response] : state.team.members.map((t) => t.id === response.id ? response : t)
                    }
                }), () => this.saveTeam(this.state.team));
            }
            else {
                toast.error("Error saving Staff Member");
            }
        });
    }
}

export default withUser(TeamItem);
