import React, {Component} from 'react';
import './Today.css'

class TodayDataElementHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            header: props.header
        };
    }

    render() {
        return (
                <th>{this.state.header}</th>
        );
    }
}

export default TodayDataElementHeader;
