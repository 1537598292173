import React, {useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import "./MultipleAutocompleteDialog.css";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const inputLabelProps = {
    formlabelclasses: {
        root: 'textField',
        focused: 'focused'
    },
};

export default function MultipleAutocompleteDialog(props) {
    const [value, setValue] = useState(props.value);
    const [open, toggleOpen] = useState(false);
    const [dialogValue, setDialogValue] = useState({id: 0});

    useEffect(() => {
        setValue(props.value)
    }, [props.value])

    // Autocomplete functions
    const handleChange = (event, newValue) => {
        if (typeof newValue[newValue.length - 1] === "string") {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
                toggleOpen(true);
                setDialogValue({
                    id: 0,
                    ...props.defaultEmptyValues,
                    [props.optionDisplayProp]: newValue[newValue.length - 1]
                });
            });
        } else if (newValue[newValue.length - 1] && newValue[newValue.length - 1].inputValue) {
            toggleOpen(true);
            setDialogValue({
                id: 0,
                ...props.defaultEmptyValues,
                [props.optionDisplayProp]: newValue[newValue.length - 1].inputValue
            });
        } else {
            setValue(newValue);
            props.onOptionSelected(newValue)
        }
    }

    const handleFilterOptions = (options, params) => {
        if (params.inputValue !== '') {
            options.push({
                inputValue: params.inputValue,
                [props.optionDisplayProp]: `Add "${params.inputValue}"`,
            });
        }

        return options;
    }

    const handleGetOptionLabel = (option) => {
        if (typeof option === 'string') {
            return option;
        }
        if (option.inputValue) {
            return option.inputValue;
        }
        return option[props.optionDisplayProp]
    }

    const handleInputChange = (event, newValue) => {
        props.onInputChange(event, newValue)
    };

    // Dialog functions
    const handleClose = () => {
        toggleOpen(false);
    };

    const handleInputDialogChange = (event) => {
        let {name, value, type} = event.target

        if(type === "checkbox") {
            value = event.target.checked
        }

        setDialogValue({ ...dialogValue, [name]: value })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        props.onDialogSubmit(dialogValue)
        handleClose();
    };

    const handleChipDelete = (option) => {
        setValue(value.filter((val) => val !== option))
        props.onChipDelete(option)
    };

    const handleChipClick = (option) => {
        toggleOpen(true);
        setDialogValue(option);
    };

    const uploadPhoto = (e) => {
        if(e.target.files && e.target.files[0]) {
            let file = e.target.files[0];
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () =>
                setDialogValue((prev) => ({
                    ...prev,
                    photo: reader.result.split(',')[1],
                    imageType: reader.result.split(',')[0]
                }))
        }
    }

    return (
        <div className={"multiple-autocomplete-dialog"}>
            <Autocomplete
                multiple
                options={props.options}
                getOptionLabel={handleGetOptionLabel}
                value={value}
                filterSelectedOptions
                fullWidth={true}
                onInputChange={handleInputChange}
                onChange={handleChange}
                filterOptions={handleFilterOptions}
                disableCloseOnSelect
                selectOnFocus
                clearOnBlur
                freeSolo
                renderOption={(option) => option[props.optionDisplayProp]}
                renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                        <Chip
                            label={option[props.optionDisplayProp]}
                            {...getTagProps({ index })}
                            onDelete={() => handleChipDelete(option)}
                            onClick={() => handleChipClick(option)}
                            className={"chip-item"}
                            variant={"outlined"}
                            title={`Edit ${props.titleName && option[props.titleName] ? option[props.titleName].toLowerCase() : props.optionName}`}
                            avatar={props.chipIcon && option.icon ? <FontAwesomeIcon icon={option.icon}/> : <Avatar alt={option[props.optionDisplayProp]} src={option.imageType + ',' + option.photo} />}
                        />
                    ))
                }
                renderInput={(params) => (
                    <TextField {...params} style={{backgroundColor: "transparent"}} variant={props.variant ? props.variant : "standard"} placeholder={"Type to Search"} label={props.optionName + "s"}/>
                )}
            />

            <Dialog open={open} fullWidth={true} onClose={handleClose} aria-labelledby="dialog-item-autocomplete" className={"dialog-item-autocomplete"}>
                <form onSubmit={handleSubmit}>
                    <DialogTitle classes={{root: "editorHeader"}}>{dialogValue.id < 1 ? "Add a new " : "Edit "} {props.optionName}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Do you need another {props.optionName}? Please, add it!
                        </DialogContentText>
                        {props.dialogContent(dialogValue, { onChange:handleInputDialogChange, inputProps:inputLabelProps }, uploadPhoto)}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="contained" className="editorButton">
                            Cancel
                        </Button>
                        <Button type="submit" variant="contained" className="editorButton">
                            {props.btnOkLabel ? props.btnOkLabel : "Save"}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}