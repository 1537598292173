import React, {Component} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import "../../../SmartPcmhClientApp.css";
import Section from "../common/Section";
import Tab from "../common/Tab";
import OverviewTab1 from "./OverviewTab1";

class Overview extends Component {


    render() {
        return (
                <Section title="Overview">
                    <Tab text="Tab 1" content={OverviewTab1}/>
                    <Tab text="Tab 2" active content={<div>Overview Tab 2</div>}/>
                    <Tab text="Tab 3" content={<div>Overview Tab 3</div>}/>
                </Section>
        )
    }
}

export default Overview;