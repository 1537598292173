import React, {Component} from 'react';
import Popper from "@mui/material/Popper";
import {UserAddOutlined} from "@ant-design/icons";
import './SendAnnouncement.css'

class SelectPersonAndTeams extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: props.open,
            results: props.results,
            anchorEl: props.anchorEl,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.open !== this.props.open)
            this.setState({open: this.props.open})
        if(prevProps.results !== this.props.results)
            this.setState({results: this.props.results})
        if(prevProps.anchorEl !== this.props.anchorEl)
            this.setState({anchorEl: this.props.anchorEl})
    }

    render() {
        const results = this.state.results ? this.state.results : []
        const anchorEl = this.state.anchorEl;
        return (
                <Popper open={this.state.open}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        anchorEl={anchorEl}
                        style={{zIndex: 1400}}>
                    <div className={"found-members"}>
                        {results.map((recipient, index) => {
                            return (
                                    <div key={index} className="row member-list-item">
                                        <div className="col-md-12 unSelectable"
                                             onClick={() => this.selectRecipient(index)}>
                                            <span>
                                                <UserAddOutlined/>
                                                {recipient.fullName}
                                            </span>
                                        </div>
                                    </div>
                            );
                        })}
                    </div>
                </Popper>
        );
    }

    selectRecipient(index) {
        if(this.props.onSelect)
            this.props.onSelect(this.state.results[index])
    }
}

export default SelectPersonAndTeams;