import React, {useState} from 'react';
import {EditOutlined} from "@ant-design/icons";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import moment from "moment";
import {Rest} from "../../../../util/rest";
import toast from "../../../../util/toast";
import {withUser} from "../../../../context/UserContext";

function AssignableEditorDialog(props) {
    const [open, setOpen] = useState(false);
    const [dialogValue, setDialogValue] = useState({
                                                       status: initializeStatus(),
                                                       date: moment().format("YYYY-MM-DD"),
        note: props.assignment.note
    })

    function initializeStatus() {
        let status
        if (props.assignment.dateEnded) {
            status = "Completed"
        } else if(props.assignment.dateStarted) {
            status = "In Progress"
        } else {
            status = "Not Started"
        }

        return status
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        const payload = {
            ...props.assignment,
            note: dialogValue.note
        }

        if (dialogValue.status === "Not Started") {
            payload.dateStarted = null
            payload.dateEnded = null
        } else if (dialogValue.status === "In Progress") {
            payload.dateStarted = dialogValue.date
            payload.dateEnded = null
        } else if (dialogValue.status === "Completed") {
            payload.dateEnded = dialogValue.date
        }

        save(payload)
    };

    const save = (payload) => {
        Rest.authFetch(props.user, `/rest/assignments/${payload.assignable.type.toLowerCase()}/${payload.id}`, {
            method: "PUT",
            body: JSON.stringify(payload)
        })
        .then(response => {
            if(response) {
                toast.success("Assignment saved");
                props.onDialogSubmitted()
                handleClose();
            }
            else {
                toast.error("Error saving Assignment");
            }
        });
    }

    const handleChange = (event) => {
        const {name, value} = event.target

        setDialogValue({
            ...dialogValue,
            [name]: value
        })
    }

    const handleDiscard = () => {
        Rest.authFetch(props.user, `/rest/${props.assignment.assignable.type.toLowerCase()}/${props.assignment.assignable.id}`, {
            method: 'DELETE'
        })
        .then(() => {
            props.onDialogSubmitted()
            handleClose();
        })
        .catch(err => {
            console.log(err);
        })
    }

    return (
        <div id={"assignable-dialog"}>
            <EditOutlined type="edit" title={"Edit " + props.assignment.assignable.type.toLowerCase()} className={"edit"} onClick={handleClickOpen}/>

            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true} maxWidth={"sm"}
                aria-labelledby="form-dialog-title">

                <DialogTitle classes={{root: "editorHeader"}} id={"sendMessage-dialog-title"}>
                    <span className="unSelectable">Edit Assignment</span>
                </DialogTitle>
                <DialogContent>

                    <div className={"row"}>
                        <div className={"col-sm-12"}>
                            <FormControl variant="outlined" className={"my-3"} fullWidth>
                                <InputLabel>Status</InputLabel>
                                <Select
                                    name={"status"}
                                    className={"w-100 textField"}
                                    onChange={handleChange}
                                    label="Change Status"
                                    value={dialogValue.status}
                                >
                                    <MenuItem value={"Not Started"}>Not Started</MenuItem>
                                    <MenuItem value={"In Progress"}>In Progress</MenuItem>
                                    <MenuItem value={"Completed"}>Completed</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className={"col-sm-5"}>
                            {dialogValue.status !== "Not Started" &&
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    name="date"
                                    value={dialogValue.date}
                                    onChange={handleChange}
                                    label={dialogValue.status === "In Progress" ? "Date Started" : "Date Completed"}
                                    type="date"
                                    className="textField textBox"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    required
                                />
                            }
                        </div>

                        <div className={"col-sm-7"}>
                            <TextField
                                fullWidth
                                margin="dense"
                                name="note"
                                multiline
                                rows={4}
                                value={dialogValue.note}
                                onChange={handleChange}
                                label="Note"
                                className="textField textBox"
                                variant="outlined"
                                spellCheck={false}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions className={"btns-assignable-dialog"}>
                    <Button onClick={handleClose} className="editorButton" variant="contained">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} className="editorButton" variant="contained">
                        Save
                    </Button>
                    {props.assignment.assignable.type === "ASSESSMENT" &&
                        <Button onClick={handleDiscard} className="discard" variant="contained">
                            Discard
                        </Button>
                    }

                </DialogActions>
            </Dialog>
        </div>
    );
}

export default withUser(AssignableEditorDialog);