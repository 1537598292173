import React, {useEffect, useState} from 'react'
import AdminHeader from "../../admin/AdminHeader";
import {FolderAddOutlined} from "@ant-design/icons";
import {Rest} from "../../../../util/rest";
import {toast} from "react-toastify";
import {withUser} from "../../../../context/UserContext";
import DataSet from "./DataSet";
import "./DataSet.css";
import DataSetEditor from "./DataSetEditor";

function DataSetAdmin(props) {

    const [dataSets, setDataSets] = useState([])
    const [addNew, setAddNew] = useState(false)
    const [isLoading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [editingDataSet, setEditingDataSet] = useState();
    const [deleting, setDeletingDataSet] = useState();
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        searchDataSets().then();
    }, [])

    useEffect(() => {
        if(deleting && deleting.id > 0) {
            deleteDataSet(deleting.id).then();
        }

    }, [deleting])

    useEffect(() => {
        searchDataSets().then()
    }, [searchTerm]);

    useEffect(() => {
        if(addNew && dataSets.findIndex((d) => d.id === 0) === -1) {
            const dataSet = {
                id: 0,
                name: "New Data Set",
                description: "This is a new data set",
                dataSetType: "DATASET",
                columns: [],
                filterGroups: []
            };
            dataSets.push(dataSet);
            setEditingDataSet(dataSet);
            setAddNew(false);
        }

    }, [addNew])

    

    const cancelEdit = () => {
        setEditingDataSet(null);
        setAddNew(false);
        const newIndex = dataSets.findIndex((d) => d.id === 0)
        if(newIndex >= 0)
            dataSets.splice(newIndex, 1);
        searchDataSets().then();
    };

    const searchDataSets = async () => {
        setLoading(true);
        let st = "";
        if(searchTerm)
            st = searchTerm;

        const response = await Rest.authFetch(
                props.user,
                `/rest/dataset/search/${st}?page=${page - 1}&type=DATASET`,
                {
                    method: "GET"
                }
        );
        if(response.status === 401 || response.status === 403) toast.error("Unauthorized!")
        else if(response.status === 500)
            toast.error("Error Getting Datasets")

        else {
            setDataSets(response.content);
            setPage(response.number + 1);
            setTotalPages(response.totalPages);
            setAddNew(false);
        }
        setLoading(false);
    };

    const deleteDataSet = async (id) => {
        const response = await Rest.authFetch(
                props.user,
                `/rest/dataset/${id}`,
                {
                    method: "DELETE"
                }
        );
        if(response.status === 401 || response.status === 403) toast.error("Unauthorized!")
        else if(response.status === 500)
            toast.error("Error Deleting Dataset")

        else {
            setDeletingDataSet(null);
        }
        searchDataSets().then();
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    }

    return (
            <div className={"dataset-admin"}>
                {!editingDataSet &&
                 <>
                     <AdminHeader
                             label={"Data Sets"}
                             onChange={(event) => setSearchTerm(event.target.value)}
                             onClickIcon={() => setAddNew(true)}
                             icon={<FolderAddOutlined title="Add Data Set"/>}
                             onPageChange={handlePageChange}
                             pageNumber={page}
                             totalPages={totalPages}
                     />
                     <div className={"datasets-container"}>
                         {dataSets && dataSets.length > 0 && dataSets.map((dataSet => {
                             return (<DataSet key={dataSet.id} dataSet={dataSet} onEdit={setEditingDataSet}
                                              onDelete={setDeletingDataSet}/>)
                         }))}
                     </div>
                     {isLoading &&
                      <div className="text-center my-5">
                          <button className="btn btn-primary-color btn-lg rounded shadow-sm" type="button" disabled>
                              <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"/>
                              Loading...
                          </button>
                      </div>
                     }
                 </>}
                {editingDataSet &&
                 <DataSetEditor dataSet={editingDataSet} cancelEdit={cancelEdit} 
                                   onFinishEditing={() => setEditingDataSet(null)}/>
                }
            </div>
    );
}

export default withUser(DataSetAdmin);