import React from 'react';

export const fakeUser = {
    accessToken: "eyJhbGciOiJIUzUxMiJ9.eyJzdGFmZm1lbWJlcmlkIjo2LCJyb2xlcyI6WyJST0xFX1VTRVIiLCJST0xFX0FETUlOIl0sIm5hbWUiOiJBZG1pbmlzdHJhdG9yIiwiZXhwaXJhdGlvbiI6MTU3MTMyNTM2MjMyOCwiaWQiOjEsImVtYWlsIjoiYWRtaW5AYWRtaW4uY29tIiwidXNlcm5hbWUiOiJhZG1pbnVzZXIifQ.dvw9N0iN84dpA5ANXy2jz1t469ifcoOrU4v1A95zqWNrvMwmk_W7voS-KaBvEZqkhIUtYLHuPr_YcqcLjxiE-w",
    tokenType: "Bearer ",
    name: "Jonell V. Haddeston",
    username: "jhaddeston",
    email: "jhaddeston@me.com",
    authorities: [
        "ROLE_USER",
        "ROLE_ADMIN",
        "ROLE_GUEST"
    ],
    expiration: "2029-10-01T14:41:36.113+0000",
    staffMemberId: 2001
};

export const UserContext = React.createContext({
    user: null,
    setUser: (user) => {
        console.debug(`Stub called with user: ${JSON.stringify(user)}`)
    }
});

// This function takes a component...
export function withUser(Component) {
    // ...and returns another component...
    return function UserComponent(props) {
        // ... and renders the wrapped component with the context User!
        // Notice that we pass through any additional props as well
        return (
            <UserContext.Consumer>
                {({user, setUser}) => <Component {...props} user={user} setUser={setUser}/>}
            </UserContext.Consumer>
        );
    };
}

