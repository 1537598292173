import React, {useEffect, useState} from 'react'
import {withUser} from "../../../../context/UserContext";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import "./PersonAssignDialog.css";
import moment from "moment/moment";
import {Rest} from "../../../../util/rest";
import {toast} from "react-toastify";


function PersonAssignDialog2(props) {

    const [assignmentType, setAssignmentType] = useState(props.assignmentType);
    const [assignment, setAssignment] = useState(null);
    const [assignmentId, setAssignmentId] = useState(0);
    const [dueDate, setDueDate] = useState(moment(new Date()).add(1, 'months').format('YYYY-MM-DD'));
    const [note, setNote] = useState("");
    const [options, setOptions] = useState([]);

    useEffect(() => {
        getOptions(assignmentType);
        clearOptions();
    }, [assignmentType]);

    useEffect(() => {
        setAssignment(options.find((o) => o.id === assignmentId));
    }, [assignmentId]);

    useEffect(() => {
        if(props.open)
            clearOptions();
    }, [props.open]);


    function clearOptions() {
        setAssignment(null);
        setAssignmentId(0);
    }

    function getOptions(value) {
        switch(value) {
            case "assessment":
                loadOptions("/rest/assessment/templates?filters=PUBLISHED");
                break;
            case "curriculum":
                loadOptions("/rest/curriculums")
                break;
            case "external":
                loadOptions("/rest/lms-courses")
                break;
            default:
                setOptions([]);
                break;
        }

    }

    function loadOptions(url) {
        Rest.authFetch(props.user, url)
                .then(response => {
                    if(response.status === 401 || response.status === 403) toast.error("Unauthorized!")
                    else if(response.status === 500)
                        toast.error("Error loading options");
                    else {
                        setOptions(response);
                    }
                })
                .catch(err => {
                    toast.error("Error loading options")
                    console.log(err);
                })
    }


    async function save(complete = false) {
        // todo: The following code accounts for curriculum which can contain multiple assignments
        // let children = []
        // if(assignmentType.toUpper() === "CURRICULUM") {
        //     const curriculumSelected = options.find((o) => assignment && o.id === assignment.id)
        //     children = curriculumSelected.trainings.map(t => ({
        //         id: 0,
        //         personId: props.person.id,
        //         assignable: t,
        //         dateDue: dueDate,
        //         parentId: 0
        //     }))
        // }
        //
        // const payload = {
        //     ...assignment,
        //     children: children
        // }
        const payload = {
            id: 0,
            personId: props.person.id,
            assignmentType: assignmentType,
            assignable: {id: assignmentId,type:assignmentType.toUpperCase(),name:assignment.name},
            note: note,
            dateDue: dueDate
        }
        Rest.authFetch(props.user, `/rest/assignments/${assignmentType.toLowerCase()}/${payload.id}`, {
            method: "PUT",
            body: JSON.stringify(payload)
        })
                .then(response => {
                    if(response) {
                        toast.success("Assignment saved");
                        if(complete)
                            props.history.push({
                                                   pathname: `/${response.assignable.type}/${response.id}`,
                                                   state: {from: props.location}
                                               });
                        props.onClose();
                    }
                    else {
                        toast.error("Error saving Assignment");
                    }
                });
    }


    return (
            <Dialog
                    className={"person-assign-dialog"}
                    open={props.open}
                    onClose={props.onClose}
                    fullWidth={true} maxWidth={"sm"}
                    aria-labelledby="sendMessage-dialog-title">

                <DialogTitle classes={{root: "editorHeader"}} id={"sendMessage-dialog-title"}>
                    <span className="unSelectable">Assignment</span>
                </DialogTitle>
                <DialogContent>
                    <TextField
                            fullWidth
                            margin="dense"
                            value={props.person ? props.person.fullName : ""}
                            label={props.personType}
                            className="textField textBox"
                            variant="outlined"
                            spellCheck={false}
                    />

                    <FormControl variant="outlined" className={"my-3"} fullWidth>
                        <InputLabel>Assignment Type</InputLabel>
                        <Select
                                name={"assignmentType"}
                                className={"w-100 textField"}
                                onChange={(e) => setAssignmentType(e.target.value)}
                                label="Assignment Type"
                                value={assignmentType}
                        >
                            <MenuItem value="assessment">Assessment</MenuItem>
                            {/*<MenuItem value="curriculum">Curriculum</MenuItem>*/}
                            <MenuItem value="external">LMS Course</MenuItem>
                            <MenuItem value="workflow">Workflow</MenuItem>
                            <MenuItem value="other/group">Other/Group</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl variant="outlined" className={"my-3"} fullWidth>
                        <InputLabel>{`Choose the ${assignmentType}`}</InputLabel>
                        <Select
                                name={"assignable"}
                                className={"w-100 textField"}
                                onChange={(e) => setAssignmentId(e.target.value)}
                                value={assignmentId}
                                label={`Choose the ${assignmentType}`}
                        >
                            <MenuItem value="0" disabled><span
                                    className={"disabled-option"}>- Choose -</span></MenuItem>
                            {(options && options.length > 0) ?

                             options.map((option) => <MenuItem key={option.id}
                                                               value={option.id}>{option.name}</MenuItem>) : null
                            }
                        </Select>
                    </FormControl>

                    <div className={"row"}>
                        <div className={"col-sm-5"}>
                            <TextField
                                    fullWidth
                                    margin="dense"
                                    name="dateDue"
                                    value={dueDate}
                                    onChange={(e) => setDueDate(e.target.value)}
                                    label="Due Date"
                                    type="date"
                                    className="textField textBox"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{inputProps: {min: new Date().toISOString().split("T")[0]}}}
                            />
                        </div>

                        <div className={"col-sm-7"}>
                            <TextField
                                    fullWidth
                                    margin="dense"
                                    name="note"
                                    multiline
                                    rows={4}
                                    value={note}
                                    onChange={(e) => setNote(e.target.value)}
                                    label="Note"
                                    className="textField textBox"
                                    variant="outlined"
                                    spellCheck={false}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} className="editorButton" variant="contained">
                        Cancel
                    </Button>
                    <Button
                            onClick={() => save(false)}
                            disabled={!props.person || props.person.personId < 1 ||
                                      !assignment || dueDate.trim() === ""}
                            className="editorButton"
                            variant="contained"
                    >
                        Assign
                    </Button>
                    {assignmentType === "assessment" &&
                     <Button
                             onClick={() => save(true)}
                             disabled={props.person.id < 1 || assignmentId < 1 || dueDate.trim() === ""}
                             className="editorButton"
                             variant="contained"
                     >
                         Start
                     </Button>
                    }

                </DialogActions>
            </Dialog>
    );
}

export default withUser(PersonAssignDialog2);