import React, {Component} from 'react';
import TeamHeader from "./TeamHeader";
import TeamMembers from "./TeamMembers"
import './Team.css'
import TeamPerformance from "./TeamPerformance";
import {Rest} from "../../../util/rest";
import toast from "../../../util/toast";

import {withUser} from "../../../context/UserContext";
import RecommendedReading from "./RecommendedReading";

class Team extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teams: []
        }
    }

    componentDidMount() {
        this.getStaffMember()
    }

    render() {
        const teams = this.state.teams && this.state.teams.length>0? this.state.teams : [{id:0,name:"Not a member of a team"}];
        const team = teams[0];
        const reading = [];
        return (

                <div>
                    <TeamHeader teams={teams}/>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card-deck">
                                <TeamPerformance team={team}/>
                                <TeamMembers team={team}/>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{marginTop: "8px"}}>
                        <div className="col-md-12">
                            <RecommendedReading readings={reading} heading="Team e-learning Tools"/>
                        </div>
                    </div>
                </div>
        )
    }

    getStaffMember() {
        if(this.props.user && this.props.user.staffMemberId) {
            let memberId = this.props.user.staffMemberId;
            Rest.authFetch(this.props.user, "/rest/staff/" + memberId).then(response => {
                if(response.status === 401 || response.status === 403) {
                    this.setState({files: []});
                    toast.error("Unauthorized!")
                }
                else {
                    this.setState({teams: response.teams});
                }
            })
                    .catch(err => {
                        console.log(err);
                    });
        }
    }
}

export default withUser(Team);