import React, {Component} from 'react';

class StaffHeader extends Component {


    render() {
        const staffMember = this.props.staffMember;
        const name = staffMember ? staffMember.fullName : "Not a Staff Member";
        const teams = staffMember ? staffMember.teams : [];
        const team = teams && teams.length > 0 ? teams[0] : null;
        return (

                <div className="pcmh-content-header">
                    <div className="row">
                        <div className="col-md-6">
                            {<h2>{name}</h2>}
                        </div>
                        <div className="col-md-6 text-right my-auto">
                            {team ? <h3>{team.name}</h3> : null}
                        </div>
                    </div>
                </div>
        );
    }
}

export default StaffHeader;